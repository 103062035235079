import { useEffect, useRef } from "react"
import { Automation, AutomationInput, AutomationRule, AutomationTrigger, Measurement, SetPoint } from "types"

// const displayUnit = (type: 'celsius' | 'fahrenheit' | 'humidity' | 'pm25' | 'voc' | 'temperature') => {
const displayUnit = (type: SetPoint | Measurement) => {
  if (type.includes('humidity')) return ' %'

  switch (type) {
    case 'celsius': return '°C'
    case 'fahrenheit': return '°F'
    // case 'humidity': return ' RH'
    case 'voc': return 'ppb'
    case 'epa_aqi': return 'AQI'
    default: return ''
  }
}

const parseMeasurement = (measurement: Measurement) => {
  switch (measurement) {
    case 'pm25': return 'PM2.5'
    case 'voc': return 'VOC'
    case 'temperature': return 'temperature'
    case 'humidity': return 'humidity'
    case 'dew_point': return 'dew point'
    case 'epa_aqi': return 'EPA AQI'
  }
}

const humanizeMeasurement = (type, measurement) => {
  return `${type.includes('outdoor') ? 'Outdoor' : 'Indoor'} ${parseMeasurement(measurement)}`
}

// gets the default unit for airflow
const findUnit = measurement => {
  switch (measurement) {
    case 'voc': return 'ppb'
    case 'pm25': return 'ugm3'
    case 'epa_aqi': return 'AQI index'
    default: return ''
  }
}

const parseUnit = unit => {
  switch (unit) {
    case 'ugm3': return 'μg/m³'
    case 'count': return 'count/cm³'
    default: return 'ppb'
  }
}

const isNumber = value => typeof value === 'number'

const airflowPhrasingFromFactor = {
  voc: {
    ppb: {
      'good': 'worse than Good (212 ppb)',
      'fair': 'worse than Fair (850 ppb)',
    },
    ugm3: {
      'good': 'worse than Good (500 μg/m³)',
      'fair': 'worse than Fair (2000 μg/m³)',
    },
  },
  pm25: {
    ugm3: {
      'good': 'worse than Good (9 μg/m³)',
      'fair': 'worse than Fair (33 μg/m³)',
    },
    count: {
      'good': 'worse than Good (20.5 count/cm³)',
      'fair': 'worse than Fair (58 count/cm³)',
    },
  },
}

const displayAirflowPhrasing = ({ value, measurement, unit }) => {
  if (measurement === 'voc') {
    if (unit === 'ppb') {
      if (value < 212) {
        return 'worse than Good (<212 ppb)'
      }
      if (value >= 212) {
        return 'worse than Fair (<850 ppb)'
      }
    } else {
      if (value < 500) {
        return 'worse than Good (<500 μg/m³)'
      }
      if (value >= 500) {
        return 'worse than Fair (<2000 μg/m³)'
      }
    }
  }
  if (measurement === 'pm25') {
    if (unit === 'ugm3') {
      if (value <= 9) {
        return 'worse than Good (<9 μg/m³)'
      }
      if (value > 9) {
        return 'worse than Fair (<33 μg/m³)'
      }
    } else {
      if (value <= 20.4) {
        return 'worse than Good (<20.5 count/cm³)'
      }
      if (value > 20.4) {
        return 'worse than Fair (<58 count/cm³)'
      }
    }
  }
}

const start_set_point = {
  voc: {
    ppb: {
      'good': 212,
      'fair': 850,
    },
    ugm3: {
      'good': 500,
      'fair': 2000,
    },
  },
  pm25: {
    ugm3: {
      'good': 9,
      'fair': 33.3,
    },
    count: {
      'good': 20.5,
      'fair': 58,
    },
  },
  epa_aqi: {
    aqi: {
      'fair': 50,
      'poor': 100,
    },
  },
}

const stop_set_point = {
  voc: {
    ppb: {
      'good': 191,
      'fair': 765,
    },
    ugm3: {
      'good': 455,
      'fair': 1818,
    },
  },
  pm25: {
    ugm3: {
      'good': 8,
      'fair': 29.9,
    },
    count: {
      'good': 18.6,
      'fair': 52.7,
    },
  },
}

const displayRange = {
  voc: {
    ppb: {
      'good': '0-212 ppb',
      'fair': '212-850 ppb',
      'poor': '850+ ppb',
    },
    ugm3: {
      'good': '0-500 μg/m³',
      'fair': '500-2000 μg/m³',
      'poor': '2000+ μg/m³',
    },
  },
  pm25: {
    ugm3: {
      'good': '0-9 μg/m³',
      'fair': '9-33.3 μg/m³',
      'poor': '33.3+ μg/m³',
    },
    count: {
      'good': '0-20.5 count/cm³',
      'fair': '20.5 - 58 count/cm³',
      'poor': '58+ count/cm³',
    },
  },
}

const findFactor = ({ measurement, unit }) => startPoint => {
  const startPoints = start_set_point[measurement]?.[unit]
  if (!startPoints) return 'unknown'
  return Object.keys(startPoints).reduce((prev, curr) => {
    if (startPoint >= startPoints[curr]) return curr
    return prev
  }, 'fair')
}

const useSkipFirstRender = (fn: () => void, deps: any[]) => {
  const firstRender = useRef(true)

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    fn()
  }, [...deps])
}

const tempId = () => -Math.floor(1000 + Math.random() * 9000)

// dew point celsius - 0 - 25 rising
// dew point fahr - 32 - 72 rising
const min = (measurement: Measurement, unit: 'celsius' | 'fahrenheit' | null, rising: boolean) => {
  switch (measurement) {
    case 'humidity': {
      return rising ? 1 : 1
    }
    case 'dew_point': {
      return unit === 'celsius' ? 1 : 33
    }
    case 'temperature': {
      return unit === 'celsius' ? 1 : 32
    }
    default: { return 1 }
  }
}

const max = (measurement: Measurement, unit: 'celsius' | 'fahrenheit' | null, rising: boolean) => {
  switch (measurement) {
    case 'humidity': {
      return rising ? 99 : 99
    }
    case 'dew_point': {
      return unit === 'celsius' ? 23 : 72
    }
    case 'temperature': {
      return unit === 'celsius' ? 35 : 100
    }
    default: { return 1 }
  }
}

const triggerToRule = (trigger: AutomationTrigger, automation: Automation): AutomationRule => ({
  id: trigger.id,
  automationId: automation.id,
  zone: automation.zone,
  inputs: automation.inputs.filter(x => trigger.inputs.includes(x.id)),
  outputs: automation.outputs.filter(x => trigger.outputs.includes(x.id)),
  trigger,
  dwellingId: automation.dwelling_id,
})

const automationsToRules = (automations: Automation[]) => {
  return automations.map(automation =>
    automation.rules.map(rule =>
      triggerToRule(rule, automation))).flatMap(x => x)
}

const secondToHour = (x: number) => x ? x / 60 / 60 : 1
const hourToSecond = (x: number) => x ? x * 60 * 60 : 3600

const alerts = {
  'dew-point-out-of-range': 'dew-point-out-of-range',
  'no-airflow': 'no-airflow',
  'co2-high': 'co2-high',
  'pm-high': 'pm-high',
  'rh-out-of-range': 'rh-out-of-range',
  'temperature-out-of-range': 'temperature-out-of-range',
  'tvoc-high': 'tvoc-high',
}

const findAlertType = (rule: AutomationRule) => {
  switch (rule.inputs[0].measurement) {
    case 'pm25': {
      return alerts['pm-high']
    }
    case 'voc': {
      return alerts['tvoc-high']
    }
    case 'temperature': {
      return alerts['temperature-out-of-range']
    }
    case 'humidity': {
      return alerts['rh-out-of-range']
    }
    case 'dew_point': {
      return alerts['dew-point-out-of-range']
    }
    case 'airflow': {
      return alerts['no-airflow']
    }
    case 'epa_aqi':
    default: {
      console.warn('Unsupported alert ', rule.inputs[0].type)
      return 'unknown'
    }
  }
}

const nullIfInThePast = (timeout: string | null) => {
  if (!timeout) return null
  return (new Date(timeout) < new Date()) ? null : timeout
}

const isIndoorConditionInput = (input: AutomationInput) => {
  return (input.type === 'cam_range') || (input.type === 'cam_threshold') || (input.type === 'ecosense_threshold')
}

const isOutdoorConditionInput = (input: AutomationInput) => {
  return (input.type === 'outdoor_weather_threshold') || (input.type === 'outdoor_weather_range')
}

const isWithin = amount => (x, y) => Math.abs(x - y) <= amount
const isWithinFive = isWithin(5)

export {
  min,
  max,
  tempId,
  secondToHour,
  hourToSecond,
  start_set_point,
  stop_set_point,
  displayRange,
  displayAirflowPhrasing,
  airflowPhrasingFromFactor,
  findFactor,
  findUnit,
  parseUnit,
  displayUnit,
  parseMeasurement,
  humanizeMeasurement,
  useSkipFirstRender,
  automationsToRules,
  findAlertType,
  isNumber,
  nullIfInThePast,
  isIndoorConditionInput,
  isOutdoorConditionInput,
  isWithinFive,
}
