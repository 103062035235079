import {
  Card, Box, Typography, TextField, Button, Select, MenuItem, FormControl, IconButton,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { EmployeeCount } from 'utils/constants/company-lookup-options'
import { Country } from 'utils/constants/province-lookup-options'
import { OnboardingProfile } from 'state-mngt/models/onboarding-profile'
import { removeHttp } from 'utils/onboarding-utils'
import InfoTooltip from 'ui/info-tooltip'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { useMixPanel } from 'features/analytics/mixpanel-provider'

export const FormTwo = (props: {
    profile: OnboardingProfile,
    onProfileChange: (profile: OnboardingProfile) => void,
    onNextValueChange: (value: number) => void,
    onIsValidValueChange: (formNum: number, isValid: boolean) => void,
    isParentFormValid: boolean,
}) => {
  const { mixpanel } = useMixPanel()
  const {
    profile, onProfileChange, isParentFormValid, onNextValueChange, onIsValidValueChange,
  } = props
  const [companyName, setCompanyName] = useState<string>('') // required
  const [country, setCountry] = useState<string>(Country.CANADA) // required
  const [website, setWebsite] = useState<string>('')
  const [employeeCount, setEmployeeCount] = useState<string>(EmployeeCount.ONE_TO_TEN)
  const [companyNameRequiredError, setCompanyNameRequiredError] = useState<boolean>(false)
  const [btnVisible, setBtnVisible] = useState<boolean>(false)
  const [activeCard, setActiveCard] = useState<boolean>(true)

  useEffect(() => {
    if(!companyNameRequiredError) {
      onIsValidValueChange(2, true)
    } else {
      onIsValidValueChange(2, false)
    }

  }, [ companyName, companyNameRequiredError, onIsValidValueChange ])

  /**
     * callback fn for 'Next' click
     * set btn visibility
     * send progress change to parent component
     * send profile to parent component
     */
  const handleNext = () => {
    if (mixpanel) {
      mixpanel.track('pp_onboardingPage_cardTwo_nextButton')
    }
    changeProfile(country)
    onNextValueChange(30)
    setBtnVisible(false)
    setActiveCard(false)
  }

  /**
     * updated profile to parent form
     * @param countryValue - string
     */
  const changeProfile = (countryValue: string) => {

    const updatedProfile: OnboardingProfile = {
      ...profile,
      company_name: companyName,
      website: removeHttp(website),
      country: countryValue,
      employeeCount,
    }

    onProfileChange(updatedProfile)
  }

  /**
     * callback fn for employeeCount value change
     * sets employeeCount state
     * @param event - ChangeEvent
     */
  const handleEmployeeCountChange = (event: React.ChangeEvent<{value: unknown}>) => {
    const input = event.target.value as string
    if (input) {
      setEmployeeCount(input)

      const updatedProfile: OnboardingProfile = {
        ...profile,
        employeeCount:input,
      }

      onProfileChange(updatedProfile)
    }
  }

  /**
     * callback fn for country value change
     * sets country state
     * @param event - ChangeEvent
     */
  const handleCountryChange = (event: React.ChangeEvent<{value: unknown}>) => {
    if (event.target.value) {
      setCountry(event.target.value as string)
      changeProfile(event.target.value as string)
    }
  }

  /**
     * callback fn for company name value change
     * sets company name state
     * @param event - ChangeEvent
     */
  const handleCompanyNameChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const input = e.target.value as string

    const updatedProfile: OnboardingProfile = {
      ...profile,
      country,
      company_name: input,
    }

    onProfileChange(updatedProfile)

    if(input.trim() === ''){
      setCompanyNameRequiredError(true)
    } else {
      setBtnVisible(true)
      setCompanyNameRequiredError(false)
    }

    setCompanyName(input)
  }

  const handleWebsiteChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const input = e.target.value as string
    setWebsite(input)

    const updatedProfile: OnboardingProfile = {
      ...profile,
      website: input,
    }

    onProfileChange(updatedProfile)
  }

  return (
    <Card
      elevation={3}
      style={{
        width: '570px',
        padding: '2rem',
        margin: '2rem 0',
      }}
      className={activeCard ? 'onboarding-card' : 'onboarding-fade'}
    >
      {profile && (
        <Box display="column">
          <Box display="flex" mb={2}>
            <Typography>My service company is called</Typography>
            <Box ml={2} flex="auto">
              <TextField id="onboarding-company-name"
                required
                size="small"
                placeholder="Service company name*"
                defaultValue=""
                error={companyNameRequiredError}
                style={{
                  marginRight: '1rem',
                }}
                helperText={companyNameRequiredError ? 'Service company name is required' : ''}
                onChange={handleCompanyNameChange}
                fullWidth
              />
            </Box>
          </Box>
          <Box display="flex" mb={2} justifyContent="space-between">
            <Box display="flex" flex="auto">
              <Typography>Our website is</Typography>
              <Box ml={2} flex="auto">
                <TextField id="onboarding-url"
                  required
                  size="small"
                  placeholder="website.com"
                  defaultValue=""
                  onChange={handleWebsiteChange}
                  fullWidth
                />
              </Box>
            </Box>
            <InfoTooltip
              placement="right-start"
              arrow
              interactive
              title="If you don't have a website, you can also enter your service company's Facebook page or other web profile link."
            >
              <IconButton style={{
                padding: 0,
              }}>
                <InfoOutlinedIcon style={{
                  margin: '.4rem',
                }} fontSize="small" />
              </IconButton>
            </InfoTooltip>
          </Box>
          <Box display="flex" mb={2} alignItems="center">
            <Typography>We have</Typography>
            <Box mx={2}>
              <FormControl size="small">
                <Select
                  id="onboarding-employee-num-select"
                  value={employeeCount}
                  onChange={handleEmployeeCountChange}
                  variant="outlined"
                >
                  <MenuItem value={EmployeeCount.ONE_TO_TEN}>1-10</MenuItem>
                  <MenuItem value={EmployeeCount.ELEVEN_TO_FIFTY}>11-50</MenuItem>
                  <MenuItem value={EmployeeCount.FIFTY_ONE_TO_ONE_HUNDRED}>51-100</MenuItem>
                  <MenuItem value={EmployeeCount.MORE_THAN_HUNDRED}>100+</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Typography>technicians.</Typography>
          </Box>
          <Box display="flex" mb={2} alignItems="center">
            <Typography>We are located in</Typography>
            <Box mx={2}>
              <FormControl size="small">
                <Select
                  id="onboarding-country-select"
                  defaultValue={Country.CANADA}
                  onChange={handleCountryChange}
                  variant="outlined"
                >
                  <MenuItem value={Country.CANADA}>{Country.CANADA}</MenuItem>
                  <MenuItem value={Country.AMERICA}>{Country.AMERICA}</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>
      )}
      { btnVisible && activeCard && (
        <Box display="flex" justifyContent="flex-end">
          <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleNext}
            disabled={!isParentFormValid || companyNameRequiredError}
          >
            Next
          </Button>
        </Box>
      )}
    </Card>
  )
}
