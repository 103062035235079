import {
  Avatar, Box, createStyles, Grid, IconButton, Link, List,
  ListItem,
  ListItemAvatar,
  ListItemText, makeStyles, Tab, Tabs, Typography,
} from '@material-ui/core'
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded'
import { useMixPanel } from 'features/analytics/mixpanel-provider'
import { ResourcesPageTour, RESOURCES_PAGE_TOUR_SELECTORS } from 'features/product-tour'
import { PRODUCT_TOUR_QUERY_KEYS } from 'features/product-tour/pro-portal-tour'
import pdfIconImageUrl from 'features/resources/sales-and-marketing/pdf-icon.svg'
import 'features/resources/sales-and-marketing/sales-and-marketing.scss'
import React, { useCallback, useEffect, useState } from 'react'
import { generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { ROUTE_PATHS } from 'routes/routes'
import { theme } from 'theme'
import TabPanel from 'ui/tab-panel/tab-panel'
import { addPropsToTab } from 'utils/add-tab-prop'
import { HavenEmails } from 'utils/constants/resources/emails'
import { HavenLinks } from 'utils/constants/resources/links'
import { cacVideos, camVideos, personalUseProgramVideos } from 'utils/constants/resources/videos'
import { downloadFile } from 'utils/download-file'
import { usePageTitle } from 'utils/hooks/router'
import useDecodedSearchParams from 'utils/hooks/useDecodedSearchParams'

const useStyles = makeStyles(() =>
  createStyles({
    comingSoon: {
      color: theme.palette.primary.main,
      fontWeight: 700,
    },
    borderColor: {
      border: '1px solid ' + theme.palette.primary.main,
    },
    download: {
      color: '#FFFFFF',
      backgroundColor: theme.palette.primary.main,
    },
  }),
)

const Clarification = () => (
  <Typography variant="body1">
    To request printed literature or co-branded options, contact <Link
      target="_blank"
      href={`mailto:${HavenEmails.MARKETING}`}
    >
      {HavenEmails.MARKETING}
    </Link>
  </Typography>
)

export enum TabName {
  Guides = 'guides',
  Brochures = 'brochures',
  Specs = 'specs',
  Cases = 'cases',
  Videos = 'videos',
}

/**
 * Displays Sales & Marketing page with static and dynamic content (form cloud)
 */

const SalesAndMarketing = () => {
  const classes = useStyles()
  // const { tabName } = useParams<{ tabName?: TabName }>();
  const { tabName } = useParams()
  const navigate = useNavigate()
  const { mixpanel } = useMixPanel()
  const [searchParams] = useDecodedSearchParams()

  const currentPageTour = searchParams.get(PRODUCT_TOUR_QUERY_KEYS.currentPageTour) || ''
  const [startProPortalTour, setStartProPortalTour] = useState(false)

  usePageTitle('Sales And Marketing')

  useEffect(() => {
    if (mixpanel) {
      mixpanel.track('pp_resource_salesAndMarketingPage_open')
    }
  })

  useEffect(() => {
    if (currentPageTour) {
      setStartProPortalTour(true)
    }
  }, [currentPageTour])

  useEffect(() => {
    if (!tabName || !Object.values(TabName).includes(tabName as TabName)) {
      navigate(generatePath(ROUTE_PATHS.resources.salesAndMarketing.tab.absolute, {
        tabName: TabName.Guides,
      }), {
        replace: true,
      })
    }
  }, [tabName, navigate])

  const handleProductTourEnd = () => {
    setStartProPortalTour(false)
  }

  /**
   * callback function to save selected tab value to state
   * @param event click event
   * @param newValue new tab value based on which tab is clicked
   */
  const handleTabClick = useCallback((event: React.ChangeEvent<{}>, newValue: TabName) => {
    navigate(generatePath(ROUTE_PATHS.resources.salesAndMarketing.tab.absolute, {
      tabName: newValue,
    }))
  }, [navigate])


  return (
    <Box m={2}>
      <Typography color="primary" variant="h2">Sales & Marketing</Typography>
      <Box
        m={1}
        style={{
          borderBottom: '1px solid #DDD',
          margin: 0,
        }}
      >
        <Tabs
          value={tabName}
          onChange={handleTabClick}
          indicatorColor="primary"
          aria-label="Sales And Marketing page tabs"
        >
          <Tab
            data-tour={`${RESOURCES_PAGE_TOUR_SELECTORS.GUIDES}`}
            label="Guides & Playbooks"
            {...addPropsToTab('sales', TabName.Guides)} />

          <Tab
            data-tour={`${RESOURCES_PAGE_TOUR_SELECTORS.BROCHURES}`}
            label="Brochures"
            {...addPropsToTab('sales', TabName.Brochures)} />

          <Tab
            data-tour={`${RESOURCES_PAGE_TOUR_SELECTORS.SPECS}`}
            label="Spec Sheets"
            {...addPropsToTab('sales', TabName.Specs)} />

          <Tab
            data-tour={`${RESOURCES_PAGE_TOUR_SELECTORS.CASES}`}
            label="Case Studies"
            {...addPropsToTab('sales', TabName.Cases)} />

          <Tab
            data-tour={`${RESOURCES_PAGE_TOUR_SELECTORS.VIDEOS}`}
            label="Video Links"
            {...addPropsToTab('sales', TabName.Videos)} />
        </Tabs>
      </Box>
      <TabPanel activeValue={tabName} tabValue={TabName.Guides}>
        <Box py={2}>
          <Grid container spacing={3}>
            <Grid item md={4}>
              <Typography variant="h5" gutterBottom >Sales Success</Typography>
              <List>
                <ListItem className={classes.borderColor + ' sales-file-list-item'} >
                  <ListItemAvatar>
                    <img src={pdfIconImageUrl} alt="pdf icon"/>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Get Started With HAVEN"
                    secondary="Aug 12, 2022"
                    primaryTypographyProps={{
                      variant: 'subtitle1',
                    }}
                    secondaryTypographyProps={{
                      variant: 'caption',
                    }}
                  />
                  <IconButton
                    aria-label="download"
                    onClick={
                      () => downloadFile('Get-Started-With-Haven.pdf', `${HavenLinks.MarketingS3}/guides/`)
                    }
                  >
                    <Avatar className={classes.download}>
                      <GetAppRoundedIcon />
                    </Avatar>
                  </IconButton>
                </ListItem>
                <ListItem className={classes.borderColor + ' sales-file-list-item'} >
                  <ListItemAvatar>
                    <img src={pdfIconImageUrl} alt="pdf icon"/>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Contractor Success Playbook"
                    secondary="Aug 12, 2022"
                    primaryTypographyProps={{
                      variant: 'subtitle1',
                    }}
                    secondaryTypographyProps={{
                      variant: 'caption',
                    }}
                  />
                  <IconButton
                    aria-label="download"
                    onClick={
                      () => downloadFile('Contractor-Success-Playbook.pdf', `${HavenLinks.MarketingS3}/guides/`)
                    }
                  >
                    <Avatar className={classes.download}>
                      <GetAppRoundedIcon />
                    </Avatar>
                  </IconButton>
                </ListItem>
                <ListItem className={classes.borderColor + ' sales-file-list-item'} >
                  <ListItemAvatar>
                    <img src={pdfIconImageUrl} alt="pdf icon"/>
                  </ListItemAvatar>
                  <ListItemText
                    primary="French - Contractor Success Playbook"
                    secondary="Aug 12, 2022"
                    primaryTypographyProps={{
                      variant: 'subtitle1',
                    }}
                    secondaryTypographyProps={{
                      variant: 'caption',
                    }}
                  />
                  <IconButton
                    aria-label="download"
                    onClick={
                      () => downloadFile('French-Contractor-Success-Playbook.pdf', `${HavenLinks.MarketingS3}/guides/`)
                    }
                  >
                    <Avatar className={classes.download}>
                      <GetAppRoundedIcon />
                    </Avatar>
                  </IconButton>
                </ListItem>
              </List>
            </Grid>
            <Grid item md={12}>
              <Clarification />
            </Grid>
          </Grid>
        </Box>
      </TabPanel>
      <TabPanel activeValue={tabName} tabValue={TabName.Brochures}>
        <Box py={2}>
          <Grid container spacing={3}>
            <Grid item md={4}>
              <Typography variant="h5" gutterBottom >HVAC/IAQ Professionals</Typography>
              <List>
                <ListItem className={classes.borderColor + ' sales-file-list-item'} >
                  <ListItemAvatar>
                    <img src={pdfIconImageUrl} alt="pdf icon"/>
                  </ListItemAvatar>
                  <ListItemText
                    primary="HAVEN for Pros"
                    secondary="Aug 12, 2022"
                    primaryTypographyProps={{
                      variant: 'subtitle1',
                    }}
                    secondaryTypographyProps={{
                      variant: 'caption',
                    }}
                  />
                  <IconButton
                    aria-label="download"
                    onClick={() => downloadFile('HAVEN-for-HVAC-Professionals.pdf', `${HavenLinks.MarketingS3}/brochures/`)}
                  >
                    <Avatar className={classes.download}>
                      <GetAppRoundedIcon  />
                    </Avatar>
                  </IconButton>
                </ListItem>
                <ListItem className={classes.borderColor + ' sales-file-list-item'} >
                  <ListItemAvatar>
                    <img src={pdfIconImageUrl} alt="pdf icon"/>
                  </ListItemAvatar>
                  <ListItemText
                    primary="French - HAVEN for Pros"
                    secondary="Aug 12, 2022"
                    primaryTypographyProps={{
                      variant: 'subtitle1',
                    }}
                    secondaryTypographyProps={{
                      variant: 'caption',
                    }}
                  />
                  <IconButton
                    aria-label="download"
                    onClick={() => downloadFile('French-Haven-For-Pros.pdf', `${HavenLinks.MarketingS3}/brochures/`)}
                  >
                    <Avatar className={classes.download}>
                      <GetAppRoundedIcon  />
                    </Avatar>
                  </IconButton>
                </ListItem>
                <ListItem className={classes.borderColor + ' sales-file-list-item'} >
                  <ListItemAvatar>
                    <img src={pdfIconImageUrl} alt="pdf icon"/>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Personal Use Program Flyer"
                    secondary="Feb 28, 2022"
                    primaryTypographyProps={{
                      variant: 'subtitle1',
                    }}
                    secondaryTypographyProps={{
                      variant: 'caption',
                    }}
                  />
                  <IconButton
                    aria-label="download"
                    onClick={() => downloadFile('PUP-Sheet-Web.pdf', `${HavenLinks.MarketingS3}/brochures/`)}
                  >
                    <Avatar className={classes.download}>
                      <GetAppRoundedIcon  />
                    </Avatar>
                  </IconButton>
                </ListItem>
                <ListItem className={classes.borderColor + ' sales-file-list-item'} >
                  <ListItemAvatar>
                    <img src={pdfIconImageUrl} alt="pdf icon"/>
                  </ListItemAvatar>
                  <ListItemText
                    primary="HAVEN for IAQ Diagnostics"
                    secondary="Feb 28, 2022"
                    primaryTypographyProps={{
                      variant: 'subtitle1',
                    }}
                    secondaryTypographyProps={{
                      variant: 'caption',
                    }}
                  />
                  <IconButton
                    aria-label="download"
                    onClick={() => downloadFile('IAQ-Diagnostic-Brochure-Web.pdf', `${HavenLinks.MarketingS3}/brochures/`)}
                  >
                    <Avatar className={classes.download}>
                      <GetAppRoundedIcon  />
                    </Avatar>
                  </IconButton>
                </ListItem>
              </List>
            </Grid>
            <Grid item md={4}>
              <Typography variant="h5" gutterBottom >Homeowners</Typography>
              <List>
                <ListItem className={classes.borderColor + ' sales-file-list-item'} >
                  <ListItemAvatar>
                    <img src={pdfIconImageUrl} alt="pdf icon"/>
                  </ListItemAvatar>
                  <ListItemText
                    primary="HAVEN for Homeowners"
                    secondary="Sep 5, 2021"
                    primaryTypographyProps={{
                      variant: 'subtitle1',
                    }}
                    secondaryTypographyProps={{
                      variant: 'caption',
                    }}
                  />
                  <IconButton
                    aria-label="download"
                    onClick={() => downloadFile('HAVEN-for-Homeowners.pdf', `${HavenLinks.MarketingS3}/brochures/`)}
                  >
                    <Avatar className={classes.download}>
                      <GetAppRoundedIcon  />
                    </Avatar>
                  </IconButton>
                </ListItem>
                <ListItem className={classes.borderColor + ' sales-file-list-item'} >
                  <ListItemAvatar>
                    <img src={pdfIconImageUrl} alt="pdf icon"/>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Indoor Air Quality Impact on Health"
                    secondary="Sep 5, 2021"
                    primaryTypographyProps={{
                      variant: 'subtitle1',
                    }}
                    secondaryTypographyProps={{
                      variant: 'caption',
                    }}
                  />
                  <IconButton
                    aria-label="download"
                    onClick={() => downloadFile('Indoor-Air-Quality-Impact-on-Health.pdf', `${HavenLinks.MarketingS3}/brochures/`)}
                  >
                    <Avatar className={classes.download}>
                      <GetAppRoundedIcon  />
                    </Avatar>
                  </IconButton>
                </ListItem>
                <a
                  href={'https://indd.adobe.com/view/ab4dd8ea-a744-447d-bd5f-ad9db4f49a66'}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: theme.palette.text.primary,
                  }}
                >
                  <ListItem className={classes.borderColor + ' sales-file-list-item'} >
                    <ListItemAvatar>
                      <img src={pdfIconImageUrl} alt="pdf icon"/>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Indoor Air Quality in Your Home"
                      secondary="Aug 12, 2022"
                      primaryTypographyProps={{
                        variant: 'subtitle1',
                      }}
                      secondaryTypographyProps={{
                        variant: 'caption',
                      }}
                    />
                  </ListItem>
                </a>
              </List>
            </Grid>
            <Grid item md={4}>
              <Typography variant="h5" gutterBottom >Commercial</Typography>
              <List>
                <ListItem className={classes.borderColor + ' sales-file-list-item'} >
                  <ListItemAvatar>
                    <img src={pdfIconImageUrl} alt="pdf icon"/>
                  </ListItemAvatar>
                  <ListItemText
                    primary="HAVEN for Light Commercial"
                    secondary="Jul 19, 2021"
                    primaryTypographyProps={{
                      variant: 'subtitle1',
                    }}
                    secondaryTypographyProps={{
                      variant: 'caption',
                    }}
                  />
                  <IconButton
                    aria-label="download"
                    onClick={
                      () => downloadFile('Light-Commercial-Buildings.pdf', `${HavenLinks.MarketingS3}/brochures/`)
                    }
                  >
                    <Avatar className={classes.download}>
                      <GetAppRoundedIcon  />
                    </Avatar>
                  </IconButton>
                </ListItem>
                <ListItem className={classes.borderColor + ' sales-file-list-item'} >
                  <ListItemAvatar>
                    <img src={pdfIconImageUrl} alt="pdf icon"/>
                  </ListItemAvatar>
                  <ListItemText
                    primary="HAVEN for Home Builders"
                    secondary="Aug 12, 2022"
                    primaryTypographyProps={{
                      variant: 'subtitle1',
                    }}
                    secondaryTypographyProps={{
                      variant: 'caption',
                    }}
                  />
                  <IconButton
                    aria-label="download"
                    onClick={
                      () => downloadFile('Haven-For-Home-Builders.pdf', `${HavenLinks.MarketingS3}/brochures/`)
                    }
                  >
                    <Avatar className={classes.download}>
                      <GetAppRoundedIcon  />
                    </Avatar>
                  </IconButton>
                </ListItem>
              </List>
            </Grid>
            <Grid item md={12}>
              <Clarification />
            </Grid>
          </Grid>
        </Box>
      </TabPanel>
      <TabPanel activeValue={tabName} tabValue={TabName.Specs}>
        <Box py={2}>
          <Grid container spacing={3}>
            <Grid item md={4}>
              <Typography variant="h5" gutterBottom >HAVEN Bundle</Typography>
              <List>
                <ListItem className={classes.borderColor + ' sales-file-list-item'} >
                  <ListItemAvatar>
                    <img src={pdfIconImageUrl} alt="pdf icon"/>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Monitor and Controller Spec Sheet"
                    secondary="Nov 02, 2022"
                    primaryTypographyProps={{
                      variant: 'subtitle1',
                    }}
                    secondaryTypographyProps={{
                      variant: 'caption',
                    }}
                  />
                  <IconButton
                    aria-label="download"
                    onClick={() => downloadFile('Monitor-and-Controller-Spec Sheet.pdf', `${HavenLinks.MarketingS3}/specifications/`)}
                  >
                    <Avatar className={classes.download}>
                      <GetAppRoundedIcon  />
                    </Avatar>
                  </IconButton>
                </ListItem>
                <ListItem className={classes.borderColor + ' sales-file-list-item'} >
                  <ListItemAvatar>
                    <img src={pdfIconImageUrl} alt="pdf icon"/>
                  </ListItemAvatar>
                  <ListItemText
                    primary="French - Central Air Monitor Spec Sheet"
                    secondary="Aug 12, 2022"
                    primaryTypographyProps={{
                      variant: 'subtitle1',
                    }}
                    secondaryTypographyProps={{
                      variant: 'caption',
                    }}
                  />
                  <IconButton
                    aria-label="download"
                    onClick={() => downloadFile('French-Central-Air-Monitor-Spec-Sheet.pdf', `${HavenLinks.MarketingS3}/specifications/`)}
                  >
                    <Avatar className={classes.download}>
                      <GetAppRoundedIcon  />
                    </Avatar>
                  </IconButton>
                </ListItem>
                <ListItem className={classes.borderColor + ' sales-file-list-item'} >
                  <ListItemAvatar>
                    <img src={pdfIconImageUrl} alt="pdf icon"/>
                  </ListItemAvatar>
                  <ListItemText
                    primary="French - Central Air Controller Spec Sheet"
                    secondary="Aug 12, 2022"
                    primaryTypographyProps={{
                      variant: 'subtitle1',
                    }}
                    secondaryTypographyProps={{
                      variant: 'caption',
                    }}
                  />
                  <IconButton
                    aria-label="download"
                    onClick={
                      () =>
                        downloadFile('French-Central-Air-Controller-Spec-Sheet.pdf', `${HavenLinks.MarketingS3}/specifications/`)
                    }
                  >
                    <Avatar className={classes.download}>
                      <GetAppRoundedIcon  />
                    </Avatar>
                  </IconButton>
                </ListItem>
              </List>
            </Grid>
            <Grid item md={4}>
              <Typography variant="h5" gutterBottom >Pro Portal</Typography>
              <List>
                <ListItem className={classes.borderColor + ' sales-file-list-item'} >
                  <ListItemAvatar>
                    <img src={pdfIconImageUrl} alt="pdf icon"/>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Pro Portal Spec Sheet"
                    secondary="Aug 12, 2022"
                    primaryTypographyProps={{
                      variant: 'subtitle1',
                    }}
                    secondaryTypographyProps={{
                      variant: 'caption',
                    }}
                  />
                  <IconButton
                    aria-label="download"
                    onClick={
                      () =>
                        downloadFile('Pro-Portal-Spec-Sheet.pdf', `${HavenLinks.MarketingS3}/specifications/`)
                    }
                  >
                    <Avatar className={classes.download}>
                      <GetAppRoundedIcon  />
                    </Avatar>
                  </IconButton>
                </ListItem>
                <ListItem className={classes.borderColor + ' sales-file-list-item'} >
                  <ListItemAvatar>
                    <img src={pdfIconImageUrl} alt="pdf icon"/>
                  </ListItemAvatar>
                  <ListItemText
                    primary="French - Pro Portal Spec Sheet"
                    secondary="Aug 12, 2022"
                    primaryTypographyProps={{
                      variant: 'subtitle1',
                    }}
                    secondaryTypographyProps={{
                      variant: 'caption',
                    }}
                  />
                  <IconButton
                    aria-label="download"
                    onClick={
                      () =>
                        downloadFile('French-Pro-Portal-Spec-Sheet.pdf', `${HavenLinks.MarketingS3}/specifications/`)
                    }
                  >
                    <Avatar className={classes.download}>
                      <GetAppRoundedIcon  />
                    </Avatar>
                  </IconButton>
                </ListItem>
              </List>
            </Grid>
            <Grid item md={12}>
              <Clarification />
            </Grid>
          </Grid>
        </Box>
      </TabPanel>
      <TabPanel activeValue={tabName} tabValue={TabName.Cases}>
        <Box py={2}>
          <Grid container spacing={3}>
            <Grid item md={4}>
              <Typography variant="h5" gutterBottom ></Typography>
              <List>
                <ListItem className={classes.borderColor + ' sales-file-list-item'} >
                  <ListItemAvatar>
                    <img src={pdfIconImageUrl} alt="pdf icon"/>
                  </ListItemAvatar>
                  <ListItemText
                    primary="HAVEN Case Study - Relative Humidity"
                    secondary="Jul 19, 2021"
                    primaryTypographyProps={{
                      variant: 'subtitle1',
                    }}
                    secondaryTypographyProps={{
                      variant: 'caption',
                    }}
                  />
                  <IconButton
                    aria-label="download"
                    onClick={
                      () => downloadFile('Relative-Humidity.pdf', `${HavenLinks.MarketingS3}/case-studies/`)
                    }
                  >
                    <Avatar className={classes.download}>
                      <GetAppRoundedIcon  />
                    </Avatar>
                  </IconButton>
                </ListItem>
              </List>
            </Grid>
            <Grid item md={12}>
              <Clarification />
            </Grid>
          </Grid>
        </Box>
      </TabPanel>
      <TabPanel activeValue={tabName} tabValue={TabName.Videos}>
        <Box py={2}>
          <Grid container spacing={3}>
            <Grid item md={4}>
              <Typography variant="h5" gutterBottom >Central Air Monitor</Typography>
              <List>
                {camVideos.map((s, index) => (
                  <a
                    href={s.url}
                    key={index}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: theme.palette.text.primary,
                    }}
                  >
                    <ListItem className={classes.borderColor + ' sales-file-list-item'} >
                      <ListItemAvatar>
                        <img src="/video-icon.png" alt="video icon" width="70"/>
                      </ListItemAvatar>
                      <ListItemText
                        primary={s.title}
                        secondary={s.date}
                        primaryTypographyProps={{
                          variant: 'subtitle1',
                        }}
                        secondaryTypographyProps={{
                          variant: 'caption',
                        }}
                      />
                    </ListItem>
                  </a>
                ))}
              </List>
            </Grid>
            <Grid item md={4}>
              <Typography variant="h5" gutterBottom >Central Air Controller</Typography>
              <List>
                {cacVideos.map((s, index) => (
                  <a
                    href={s.url}
                    key={index}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: theme.palette.text.primary,
                    }}
                  >
                    <ListItem className={classes.borderColor + ' sales-file-list-item'} >
                      <ListItemAvatar>
                        <img src="/video-icon.png" alt="video icon" width="70"/>
                      </ListItemAvatar>
                      <ListItemText
                        primary={s.title}
                        secondary={s.date}
                        primaryTypographyProps={{
                          variant: 'subtitle1',
                        }}
                        secondaryTypographyProps={{
                          variant: 'caption',
                        }}
                      />
                    </ListItem>
                  </a>
                ))}
              </List>
            </Grid>
            <Grid item md={4}>
              <Typography variant="h5" gutterBottom >Getting Started</Typography>
              <List>
                {personalUseProgramVideos.map((s, index) => (
                  <a
                    href={s.url}
                    key={index}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: theme.palette.text.primary,
                    }}
                  >
                    <ListItem className={classes.borderColor + ' sales-file-list-item'} >
                      <ListItemAvatar>
                        <img src="/video-icon.png" alt="video icon" width="70"/>
                      </ListItemAvatar>
                      <ListItemText
                        primary={s.title}
                        secondary={s.date}
                        primaryTypographyProps={{
                          variant: 'subtitle1',
                        }}
                        secondaryTypographyProps={{
                          variant: 'caption',
                        }}
                      />
                    </ListItem>
                  </a>
                ))}
              </List>
            </Grid>
          </Grid>
        </Box>
      </TabPanel>

      <ResourcesPageTour startTour={startProPortalTour} onEndTour={handleProductTourEnd} />
    </Box>
  )
}

export default SalesAndMarketing
