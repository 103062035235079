import { useEffect, useState } from "react"
import httpService from "state-mngt/services/data/http-service"
import useEcosenseStore, {
  EcosenseTelemetry,
  selectTelemetry,
} from "stores/ecosense"

/**
 * checks the existing data to see if there is overlap
 * with what's already in the store. used to make sure
 * we do not fetch data that's already in the store.
 */
const adjustStartAndEndTimes = (data, startTime: Date, endTime: Date) => {
  if (!data?.length) return {
    startTime, endTime, 
  }

  const adjusted = {
    startTime,
    endTime,
  }

  if (new Date(data[0].timestamp) < endTime) {
    adjusted['endTime'] = new Date(data[0].timestamp)
  } else {
    adjusted['endTime'] = endTime
  }

  if (new Date(data[data.length - 1].timestamp) > startTime) {
    adjusted['startTime'] = new Date(data[data.length - 1].timestamp)
  } else {
    adjusted['startTime'] = startTime
  }

  if (adjusted.endTime > adjusted.startTime) return adjusted

  // data already exists
  return {
    startTime: null,
    endTime: null,
  }
}

const useEcosenseTelemetry = (serialNumbers: string[], dataMin: Date, dataMax: Date, fetch = false) => {
  const [loading, setLoading] = useState(false)
  const allEcosenseTelemetry = useEcosenseStore.getState()
  const telemetry = useEcosenseStore(selectTelemetry(serialNumbers, dataMin, dataMax))
  const set = useEcosenseStore.setState

  useEffect(() => {
    if (!fetch) return
    if (!serialNumbers?.length) return

    const get = async (serialNumber: string, start: Date = dataMin, stop: Date = dataMax) => {
      try {
        const response = await httpService.getOnce<{
          num_telemetry: number,
          telemetry: EcosenseTelemetry[],
        }>(`/ecosense/device/${serialNumber}/telemetry_range?start_timestamp=${start.toISOString()}&stop_timestamp=${stop.toISOString()}`)

        return response?.telemetry || []

      } catch (e) {
        console.error(e)
      }
    }

    const getAll = async () => {
      const promiseMap = new Map()

      for (const serialNumber of serialNumbers) {
        if (!allEcosenseTelemetry[serialNumber]) {
          promiseMap.set(serialNumber, get(serialNumber))
          continue
        }
        const { startTime, endTime } = adjustStartAndEndTimes(allEcosenseTelemetry[serialNumber], dataMin, dataMax)
        if (startTime && endTime) promiseMap.set(serialNumber, get(serialNumber, startTime, endTime))
      }

      const setter = (serialNumber: string, data: EcosenseTelemetry[]) =>
        set(prev => ({
          ...prev,
          [serialNumber]: [
            ...(prev[serialNumber] || []),
            ...data,
          ].sort((a, b) =>
            new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()),
        }))

      const promises = [...promiseMap.values()]
      const keys = [...promiseMap.keys()]

      if (!promises.length) return

      try {
        setLoading(true)
        const responses = await Promise.all(promises)
        keys.forEach((key, index) => setter(key, responses[index]))
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    }

    if (!loading) getAll()
  }, [
    dataMin?.getTime(),
    dataMax?.getTime(),
    serialNumbers.join(''),
    JSON.stringify(allEcosenseTelemetry),
  ])

  return {
    telemetry, loading, 
  }
}

export {
  adjustStartAndEndTimes,
}

export default useEcosenseTelemetry
