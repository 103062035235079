import useIsTouched from './useIsTouched'
import { persistTemporaryRule, resetTemporaryRule } from './store'
import { useEffect, useState } from 'react'
import useSaveTemporaryRuleToAutomation from './useSaveTemporaryRuleToAutomation'

export const useResetAfterTimeout = (values, setters, ms = 10000) => {
  useEffect(() => {
    const tos = values.map((value, i) => setTimeout(() => setters[i](''), ms))
    return () => tos.map(x => clearTimeout(x))
  }, [...values])
}

/**
 * save, cancel, touched state for an
 * automation rule. basically all the
 * persisting stuff that gets passed
 * to Footer
 */
const useRuleState = (ruleId: number) => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')
  const save = useSaveTemporaryRuleToAutomation(ruleId)
  const touched = useIsTouched(ruleId)

  useResetAfterTimeout([success, error], [setSuccess, setError])

  const handleCancel = () => {
    resetTemporaryRule(ruleId)
  }

  const handleSave = async () => {
    setLoading(true)
    try {
      await save()
      persistTemporaryRule(ruleId)
      setSuccess('Saved ✓')
    } catch (e) {
      console.error(e)
      setError('Couldn\'t save')
    }
    setLoading(false)
  }

  return {
    touched,
    loading,
    success,
    error,
    setError,
    handleCancel,
    handleSave,
  }
}

export default useRuleState
