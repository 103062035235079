import React, { useState, useEffect } from 'react'
import {
  CircularProgress, Typography, Box, makeStyles,
} from '@material-ui/core'

interface Props {
  durationMs: number;
  onFinish?: () => void;
}

const useStyles = makeStyles((theme) => ({
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    color: theme.palette.primary.dark,
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
}))

const BackTimer: React.FC<Props> = ({ durationMs, onFinish }) => {
  const classes = useStyles()
  const [lastDuration, setLastDuration] = useState(durationMs)

  useEffect(() => {
    const timer = setInterval(() => {
      setLastDuration((prevDuration) => {
        const next = Math.max(prevDuration - 1000, 0)
        if (next === 0) {
          onFinish && onFinish()
        }
        return next
      })
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [onFinish])
  const progress = 100 - (lastDuration * 100) / durationMs
  const size = 72

  return (
    <Box position="relative" display="inline-flex" width={size}>
      <CircularProgress variant="determinate" className={classes.bottom} size={size} thickness={3} value={100} />
      <CircularProgress
        thickness={3}
        className={classes.top}
        style={{
          transform: 'rotate(-90deg)',
        }}
        size={size}
        variant="determinate"
        value={progress}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="body1" component="div">
          {new Date(lastDuration).getSeconds()}
        </Typography>
      </Box>
    </Box>
  )
}

export default BackTimer
