import React from 'react'
import {
  Box, Card, CardContent, CardActions, Button, makeStyles,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import { ROUTE_PATHS } from 'routes/routes'
import addBgImage from 'features/preferences/team-mngt/team-list/components/team-list-add/add-bg.png'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    backgroundImage: `url(${addBgImage})`,
    backgroundSize: 'contain',
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
  },
  footer: {
    backgroundColor: 'white',
  },
  content: {
    background: 'rgba(0, 138, 147, .8)',
  },
  text: {
    color: 'white',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    minHeight: 172,
    display: 'flex',
    alignItems: 'center',
  },
}))

const TeamListAdd = () => {
  const classes = useStyles()

  return (
    <Box width={388}>
      <Card classes={{
        root: classes.root,
      }}>
        <CardContent classes={{
          root: classes.content,
        }}>
          <Box px={1}>
            <div className={classes.text}>Invite new members to your Management, Sales and Service & Install teams</div>
          </Box>
        </CardContent>
        <CardActions classes={{
          root: classes.footer,
        }}>
          <Box width="100%" p={1} display="flex" justifyContent="flex-end">
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to={ROUTE_PATHS.teamMngt.add.absolute}
            >
              Add members
            </Button>
          </Box>
        </CardActions>
      </Card>
    </Box>
  )
}

export default TeamListAdd
