import { PieChartData } from 'ui/pie-chart/pie-chart'
import { theme } from 'theme'

/**
 * Generate pie chart options
 * @param data - PieChartData[]
 * @param colors  - string[]
 * @param title - string
 */
export const getPieChartOptions = (data: PieChartData[], colors: string[], title: string): Highcharts.Options => {
  return {
    title: {
      text: title,
      align: 'left',
      // verticalAlign: 'top',
      floating: true, // need it to stop interfering with pie size
      style: {
        color: theme.palette.primary.main,
        fontSize: '13px',
      },
      x: 124,
    },
    chart: {
      // animation: false,
      plotShadow: false,
      type: 'pie',
      height: 148,
      reflow: true,
    },
    noData: {
      style: {
        color: 'transparent', // hide no data message
      },
    },
    credits: {
      enabled: false, // hide default message 'created by Highcharts'
    },
    plotOptions: {
      pie: {
        animation: false,
        slicedOffset: 0,
        allowPointSelect: true,
        colors,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
        innerSize: 20,
        point: {
          events: {
            legendItemClick: (e) => {
              e.preventDefault()
            },
          },
        },
      },
    },
    tooltip: {
      pointFormat: '{series.name} <b>{point.percentage:.1f}%</b>',
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    legend: {
      align: 'right',
      layout: 'vertical',
      verticalAlign: 'middle',
      // y: 0,
      useHTML: true,
      symbolHeight: 8,
      labelFormat: ('' +
        '<span style="margin-top:3px;display:flex;justify-content:space-between;align-items:center;width: 90px;">' +
        '<span style="font-size: 10px">{name}</span>' +
        '<span style="font-size: 10px">{percentage:.0f}%</span>' +
        '</span>'
      ),
    },
    series: [{
      name: '',
      colorByPoint: true,
      data,
      type: 'pie',
      animation: false,
    }],
  }
}

/**
 * Generate pie chart options for report
 * @param data - PieChartData[]
 * @param colors  - string[]
 */
export const getReportPieChartOptions = (data: PieChartData[], colors: string[]): Highcharts.Options => {
  return {
    title: {
      text: '',
    },
    chart: {
      plotShadow: false,
      type: 'pie',
      height: 80,
      width: 80,
      animation: false,
    },
    noData: {
      style: {
        color: 'transparent', // hide no data message
      },
    },
    credits: {
      enabled: false, // hide default message 'created by Highcharts'
    },
    plotOptions: {
      pie: {
        animation: false,
        slicedOffset: 0,
        allowPointSelect: false,
        colors,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
        showInLegend: false,
        innerSize: 10,
        point: {
          events: {
            legendItemClick: (e) => {
              e.preventDefault()
            },
          },
        },
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            opacity: 1,
          },
        },

      },
      series: {
        animation: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    series: [{
      name: '',
      colorByPoint: true,
      data,
      type: 'pie',
    }],
  }
}

