import * as React from 'react'
import { Box, Button } from "@material-ui/core"
import { CHARTS_PAGE_TOUR_SELECTORS } from 'features/product-tour'
import { useMixPanel } from 'features/analytics'
import { Link, generatePath, useParams } from 'react-router-dom'
// import { ROUTE_PATHS } from 'routes/routes'

function ReportButton({ zone }) {
    const { mixpanel } = useMixPanel()
    const { dwellingId } = useParams()

    return (
        <Box ml="auto" mr="10px">
            <Button
                id={CHARTS_PAGE_TOUR_SELECTORS.BUILD_A_REPORT}
                variant="contained"
                color="primary"
                onClick={() => {
                    if (mixpanel) {
                        mixpanel.track('pp_customerDrillDownPage_buildReportButton')
                    }
                }}
                component={Link}
                to={`/customers/${dwellingId}/report?zone=${zone.replace('#', '%23')}`}
            >
                Build a report
            </Button>
        </Box>

    )
}

export default ReportButton
