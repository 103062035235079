import { APIInterval } from 'utils/constants/time-interval-api'
import {
  Dwelling, DwellingPermission, DwellingContent, DwellingProfile,
} from 'state-mngt/models/dwelling'
import {
  DeviceTelemetry, DeviceTelemetryQuality, CacDeviceTelemetry, DeviceContent,
} from 'state-mngt/models/device'
import httpService from 'state-mngt/services/data/http-service'
import { ReportResponse } from 'state-mngt/models/report'
import { CancelableFetch } from 'state-mngt/models/http'
import { b64EncodeUnicode } from 'utils/report-utils'
import demoService from 'state-mngt/services/data/demo-service'
import {
  getProductTourFakeDeviceContent,
  getProductTourFakeDwelling,
  getProductTourFakeDwellingContent,
  getProductTourFakeDwellingPermission,
  isProductTourFakeDevice,
  isProductTourFakeDwelling,
} from 'features/product-tour/pro-portal-tour'
import { OutdoorAir } from 'state-mngt/models/outdoor-air'

class DwellingService {
  /**
   * calls /dwellings/admin_overview?preferred_service_company_id= end point
   * @param serviceCompanyId number
   */
  getDwellings = (serviceCompanyId: number): CancelableFetch<Dwelling[]> => {
    return httpService.getWithAbort('/dwellings/admin_overview?preferred_service_company_id=' + serviceCompanyId)
  }

  getDemoDwellings = (serviceCompanyId: number): Promise<Dwelling[]> => {
    return Promise.resolve(demoService.getDwellings())
  }

  /**
   * calls /dwelling/{id} endpoint
   * @param dwellingId number
   */
  getDwelling = (dwellingId: number): CancelableFetch<Dwelling> | Promise<Dwelling> => {
    if (demoService.isDemoDwellingId(dwellingId)) {
      return Promise.resolve(demoService.getDwelling(dwellingId))
    }

    if (isProductTourFakeDwelling(dwellingId)) {
      return Promise.resolve(getProductTourFakeDwelling())
    }

    return httpService.get('/dwelling/' + dwellingId)
  }
  /**
   * calls /dwelling/{id}/contents endpoint
   * @param dwellingId number
   */
  getDwellingContents = (dwellingId: number): Promise<DwellingContent> => {
    if (demoService.isDemoDwellingId(dwellingId)) {
      return Promise.resolve(demoService.getDwellingContent(dwellingId))
    }

    if (isProductTourFakeDwelling(dwellingId)) {
      return Promise.resolve(getProductTourFakeDwellingContent())
    }

    return httpService.get(`/dwelling/${dwellingId}/contents`)
  }

  getDwellingProfile = (dwellingId: number, zone: string): CancelableFetch<DwellingProfile> => {
    return httpService.getWithAbort(`/dwelling/${dwellingId}/profile?zone=${zone}`)
  }
  /**
   * calls /device/{id} endpoint
   * @param deviceId number
   */
  getDevice = (deviceId: number): CancelableFetch<DeviceContent> | Promise<DeviceContent> => {
    if (isProductTourFakeDevice(deviceId)) {
      return Promise.resolve(getProductTourFakeDeviceContent())
    }

    return httpService.getWithAbort('/device/' + deviceId)
  }
  /**
   * calls /device/{id}/telemetry_current endpoint
   * @param deviceId number
   */
  getTelemetryCurrent = (deviceId: number): CancelableFetch<DeviceTelemetry> | Promise<DeviceTelemetry> => {
    if (demoService.isDemoDevice(deviceId)) {
      return Promise.resolve(demoService.getDeviceTelemetryCurrent(deviceId))
    }

    return httpService.getWithAbort('/device/' + deviceId + '/telemetry_current')
  }
  /**
   * calls /device/{id}/telemetry_range?start_time=&endpoint=&interval= endpoint
   * @param deviceId number
   * @param startTime Date object
   * @param endTime Date object
   * @param interval APIInterval enum
   */
  getTelemetryRange = (
    deviceId: number,
    startTime: Date,
    endTime: Date,
    interval: APIInterval,
  ): CancelableFetch<DeviceTelemetry[]> | Promise<DeviceTelemetry[]> => {
    if (demoService.isDemoDevice(deviceId)) {
      return Promise.resolve(demoService.getDeviceTelemetryRange({
        deviceId,
        startTime,
        endTime,
        interval,
      }))
    }
    const start = encodeURIComponent(startTime.toISOString())
    const end = encodeURIComponent(endTime.toISOString())
    const paramUri = 'start_time=' + start + '&end_time=' + end + '&interval=' + interval
    return httpService.getWithAbort('/device/' + deviceId + '/telemetry_range?' + paramUri)
  }

  /**
   * calls /device/{id}/telemetry_range?start_time=&endpoint=&interval= endpoint
   * @param deviceId number
   * @param startTime Date object
   * @param endTime Date object
   * @param interval APIInterval enum
   */
  getCacTelemetryRange = (
    deviceId: number,
    startTime: Date,
    endTime: Date,
    interval: APIInterval,
  ): CancelableFetch<CacDeviceTelemetry[]> => {
    const start = encodeURIComponent(startTime.toISOString())
    const end = encodeURIComponent(endTime.toISOString())
    const paramUri = 'start_time=' + start + '&end_time=' + end + '&interval=' + interval
    return httpService.getWithAbort('/device/' + deviceId + '/telemetry_range?' + paramUri)
  }

  /**
   * calls /device/{id}/telemetry_quality_range?start_time=&endpoint=&end_time=endpoint
   * @param deviceId number
   * @param startTime Date object
   * @param endTime Date object
   */
  getDevicePerformance = (
    deviceId: number,
    startTime: Date,
    endTime: Date,
  ): CancelableFetch<DeviceTelemetryQuality> | Promise<DeviceTelemetryQuality> => {

    if (demoService.isDemoDevice(deviceId)) {
      return Promise.resolve(demoService.getDevicePerformance({
        deviceId,
      }))
    }

    const start = encodeURIComponent(startTime.toISOString())
    const end = encodeURIComponent(endTime.toISOString())
    const paramUri = 'start_time=' + start + '&end_time=' + end

    return httpService.getWithAbort('/device/' + deviceId + '/telemetry_quality_range?' + paramUri)
  }

  /**
   * calls /outdoor_air/{id}/telemetry_range?start_time=&endpoint=&interval= endpoint
   * @param outdoor_air string
   * @param startTime Date object
   * @param endTime Date object
   */
  getOutdoorAir = (postalCode: string, startTime: Date, endTime: Date)
    : Promise<OutdoorAir[] | void> => {

    const start = encodeURIComponent(startTime.toISOString())
    const end = encodeURIComponent(endTime.toISOString())
    const _postalCode = postalCode.split(' ').join('') // remove spaces

    const paramUri = 'start_time=' + start + '&end_time=' + end
    return httpService.getOnce('/outdoor_air/' + _postalCode + '/range?' + paramUri)
  }

  /**
   * Get an array of dwelling permissions given the dwelling id, if more than one admin is in the db, both will be returned
   * @param dwellingId - number
   * @return array of DwellingsPermissions
   */
  getDwellingPermission = async (dwellingId: number): Promise<DwellingPermission[]> => {
    if (isProductTourFakeDevice(dwellingId)) {
      return Promise.resolve(getProductTourFakeDwellingPermission())
    }

    return httpService.get('/dwelling_permissions?dwelling_id=' + dwellingId)
  }

  /**
   * calls /report endpoint to convert html to pdf
   * @param html string
   */
  // generatePDF = async (html: string): Promise<ReportResponse> => {
  //   return httpService.post('/report/',
  //     {
  //       html: b64EncodeUnicode(html)
  //     });
  // };

  /**
   * create mock data
   * @param startTime Date object
   * @param endTime Date object
   * @param interval APIInterval enum
   */
  mockedData = async (startTime: Date, endTime: Date, interval: APIInterval): Promise<DeviceTelemetry[]> => {
    const initTime = startTime.getTime()
    const length = interval === APIInterval.FIVE_MIN ? (endTime.getTime() - initTime) / (5 * 60 * 1000) :
      interval === APIInterval.ONE_HOUR ? (endTime.getTime() - initTime) / (60 * 60 * 1000) :
        interval === APIInterval.ONE_DAY ? (endTime.getTime() - initTime) / (24 * 60 * 60 * 1000) :
          1000
    return Array(length).fill(null).map((v, i) => ({
      timestamp: new Date(initTime + (5 * 60 * 1000 * i)).toISOString(),
      airflow: 16.5 + (Math.random() * 15),
      voc_mc: 8.6 + (Math.random() * 5),
      voc_count: 6 + (Math.random() * 5),
      pm_count: i,
      pm_mc: (Math.random() < 0.9 ? 20 * Math.random() : 0) +
        (Math.random() * 5) +
        (Math.random() > 0.9 ? 200 * Math.random() : 0),
      temperature: 20 + (Math.random() * 5),
      humidity: 50 + (Math.random() * 15),
      voc_status: 'good',
      pm_status: 'good',
      combined_status: 'good',
      highest_factor: '',
    }))
  }
}

const dwellingService = new DwellingService()

export default dwellingService
