import { DwellingForReport } from 'state-mngt/models/dwelling'

export const GET_REPORT_DWELLING = 'GET_REPORT_DWELLING'
export const SET_REPORT_DWELLING = 'SET_REPORT_DWELLING'

export interface GetDwellingForReportAction {
    type: typeof GET_REPORT_DWELLING;
    dwelling: DwellingForReport
}

export interface SetDwellingForReportAction {
    type: typeof SET_REPORT_DWELLING;
    dwelling: DwellingForReport;
}

export const getDwellingForReport = (dwelling: DwellingForReport): GetDwellingForReportAction => ({
  type: GET_REPORT_DWELLING, dwelling,
})

export const setDwellingForReport = (dwelling: DwellingForReport): SetDwellingForReportAction => ({
  type: SET_REPORT_DWELLING, dwelling,
})
