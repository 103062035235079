import { AnyAction } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { CompanyProfile } from 'state-mngt/models/company-profile'
import {
  NewServiceCompany,
  ServiceCompany,
  ServiceCompanyInviteDeleteInfo,
  ServiceCompanyInviteResult,
  ServiceCompanyInvites,
} from 'state-mngt/models/serviceCompany'
import {
  TeamMember, User, UserRole, UserServiceCompanyInvite,
} from 'state-mngt/models/user'
import companyService from 'state-mngt/services/company-service'
import { RootState } from 'state-mngt/store'
import { MemberStatus, MemberTeam } from 'utils/constants/member-enums'
import { HttpError } from 'state-mngt/models/http'

export const SET_COMPANY_DETAILS = 'SET_COMPANY_DETAILS'
export const SET_COMPANY_DETAILS_ERROR = 'SET_COMPANY_DETAILS_ERROR'
export const SET_COMPANY_LOADING = 'SET_COMPANY_LOADING'
export const SET_COMPANY_MEMBERS = 'SET_COMPANY_MEMBERS'
export const SET_COMPANY_MEMBERS_ERROR = 'SET_COMPANY_MEMBERS_ERROR'
export const UPDATE_COMPANY_MEMBER_DETAILS = 'UPDATE_COMPANY_MEMBER_DETAILS'
export const DELETE_COMPANY_MEMBER = 'DELETE_COMPANY_MEMBER'
export const SET_COMPANY_INVITES = 'SET_COMPANY_INVITES'
export const SET_COMPANY_INVITES_ERROR = 'SET_COMPANY_INVITES_ERROR'
export const SET_COMPANY_MEMBERS_AND_INVITES = 'SET_COMPANY_MEMBERS_AND_INVITES'
export const SET_COMPANY_MEMBERS_AND_INVITES_ERROR = 'SET_COMPANY_MEMBERS_AND_INVITES_ERROR'
export const DELETE_COMPANY_INVITE = 'DELETE_COMPANY_INVITE'

export interface SetCompanyDetailsAction {
    type: typeof SET_COMPANY_DETAILS;
    companyDetails: ServiceCompany;
}

export interface SetCompanyDetailsErrorAction {
    type: typeof SET_COMPANY_DETAILS_ERROR;
}

export interface SetCompanyLoadingAction {
  type: typeof SET_COMPANY_LOADING ;
  isLoading: boolean;
}

export interface SetCompanyMembersAction {
    type: typeof SET_COMPANY_MEMBERS;
    members: User[];
}

export interface SetCompanyMembersErrorAction {
    type: typeof SET_COMPANY_MEMBERS_ERROR;
}

export interface UpdateCompanyMemberDetailsAction {
    type: typeof UPDATE_COMPANY_MEMBER_DETAILS;
    editedMember: User;
}

export interface DeleteCompanyMemberAction {
  type: typeof DELETE_COMPANY_MEMBER;
  userId: number;
}

export interface SetCompanyInvitesAction {
  type: typeof SET_COMPANY_INVITES;
  payload: ServiceCompanyInvites;
}

export interface SetCompanyInvitesErrorAction {
  type: typeof SET_COMPANY_INVITES_ERROR;
}

export interface SetCompanyMembersAndInvitesAction {
  type: typeof SET_COMPANY_MEMBERS_AND_INVITES;
  payload: TeamMember[];
}

export interface SetCompanyMembersAndInvitesErrorAction {
  type: typeof SET_COMPANY_MEMBERS_AND_INVITES_ERROR;
}

export interface DeleteCompanyInvite {
  type: typeof DELETE_COMPANY_INVITE;
  payload: ServiceCompanyInviteDeleteInfo;
}

export const setCompanyDetails = (companyDetails: ServiceCompany): SetCompanyDetailsAction => ({
  type: SET_COMPANY_DETAILS,
  companyDetails,
})

export const setCompanyDetailsError = (): SetCompanyDetailsErrorAction => ({
  type: SET_COMPANY_DETAILS_ERROR,
})

export const setCompanyLoading = (isLoading: boolean): SetCompanyLoadingAction => ({
  type: SET_COMPANY_LOADING ,
  isLoading,
})

export const setCompanyMembers = (members: User[]): SetCompanyMembersAction => ({
  type: SET_COMPANY_MEMBERS,
  members,
})

export const setCompanyMembersError = (): SetCompanyMembersErrorAction => ({
  type: SET_COMPANY_MEMBERS_ERROR,
})

export const editCompanyMemberDetails = (editedMember: User): UpdateCompanyMemberDetailsAction => ({
  type: UPDATE_COMPANY_MEMBER_DETAILS,
  editedMember,
})

export const deleteCompanyMember = (userId: number): DeleteCompanyMemberAction => ({
  type: DELETE_COMPANY_MEMBER,
  userId,
})

export const setCompanyInvites = (invites: ServiceCompanyInvites): SetCompanyInvitesAction => ({
  type: SET_COMPANY_INVITES,
  payload: invites,
})

export const setCompanyInvitesError = (): SetCompanyInvitesErrorAction => ({
  type: SET_COMPANY_INVITES_ERROR,
})

export const setCompanyMembersAndInvites = (membersAndInvites: TeamMember[]): SetCompanyMembersAndInvitesAction => ({
  type: SET_COMPANY_MEMBERS_AND_INVITES,
  payload: membersAndInvites,
})

export const setCompanyMembersAndInvitesError = (): SetCompanyMembersAndInvitesErrorAction => ({
  type: SET_COMPANY_MEMBERS_AND_INVITES_ERROR,
})

export const deleteCompanyInvite = (inviteDeleteInfo: ServiceCompanyInviteDeleteInfo): DeleteCompanyInvite => ({
  type: DELETE_COMPANY_INVITE,
  payload: inviteDeleteInfo,
})

type ThunkResult<R = void> = ThunkAction<R, RootState, undefined, AnyAction>;

/**
 * calls api to get service company details
 * @param companyId - number
 * @returns - ServiceCompany | undefined
 */
export const getCompanyDetails = (companyId: number): ThunkResult => async dispatch => {
  const company = await companyService.getServiceCompany(companyId)
    .catch(() => null)

  if (company) {
    dispatch(setCompanyDetails(company))
  } else {
    dispatch(setCompanyDetailsError())
  }
}

/**
 * calls api to create new service company
 * @param company - NewServiceCompany
 * @returns - {id:number} | undefined
 */
export const createCompany = (company: NewServiceCompany): ThunkResult<Promise<{id: number} | undefined>> =>
  async () => {
    const newCompany = await companyService.createServiceCompany(company)
      .catch(() => null )

    if (newCompany) {
      return newCompany
    } else {
      return undefined
    }
  }

/**
 * calls api to create company profile
 * @param companyProfile - Company Profile
 * @param companyId - number
 * @returns - boolean
 */
export const createCompanyProfile = (companyProfile: CompanyProfile, companyId: number)
  : ThunkResult<Promise<boolean>> =>
  async () => {
    let profileUpdated = false

    try {
      await companyService.createCompanyProfile(companyProfile, companyId)
      profileUpdated = true
    } catch {

    }

    return profileUpdated
  }

/**
 * Call API to update company details
 * @param companyId - number
 * @param updatedDetails - ServiceCompany
 */
export const updateCompanyDetails = (companyId: number, updatedDetails: ServiceCompany)
  : ThunkResult<Promise<boolean>> =>
  async dispatch => {

    dispatch(setCompanyLoading(true))

    let detailsForStore = updatedDetails

    if(updatedDetails.logo) {
      const comma = updatedDetails.logo?.charAt(0) === ','? '' : ','
      detailsForStore = {
        ...updatedDetails,
        logo_url: 'data:image/png;base64' + comma + updatedDetails.logo,
      }
    }

    let detailsUpdated = false

    try {
      await companyService.updateServiceCompany(companyId, detailsForStore)

      detailsUpdated = true
      dispatch(setCompanyDetails(detailsForStore))
      dispatch(setCompanyLoading(false))
    } catch {
      dispatch(setCompanyLoading(false))
    }

    return detailsUpdated
  }

/**
 * call api to delete member role API, removes one role at a time
 * @param role - UserRole
 */
export const deleteMemberRole = (role: UserRole): ThunkResult<Promise<HttpError | void>> =>
  async () => {
    try {
      const result = await companyService.deleteServiceCompanyRole(role)

      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.reject(error)
    }
  }

/**
 * Thunk function that calls API for getting company members
 * @param companyId - The id of the company to retrieve its employees/members.
 */
export const getCompanyMembers = (companyId: number) : ThunkResult =>
  async dispatch => {
    try {
      const companyMembersWithoutStatus = await companyService.getCompanyMembers(companyId)

      const companyMembersWithStatus = companyMembersWithoutStatus.map(currentMemberWithoutStatus => {
        return {
          ...currentMemberWithoutStatus,
          status: MemberStatus.ACTIVE,
        }
      })

      dispatch(setCompanyMembers(companyMembersWithStatus))
    } catch {
      dispatch(setCompanyMembersError())
    }
  }

/**
 * Thunk function that calls API for getting company members and users that have been invited to the company
 * @param companyId - The id of the company to retrieve its employees/members and invites sent out.
 */
export const getCompanyMembersAndInvites = (companyId: number) : ThunkResult =>
  async dispatch => {
    try {
      /*
       TODO-lsantos:
        This thunk function may have performance issues in the future if the list of invites have too many items.
        In the future the filtering that is being done in this function can be done in the server.
       */

      const companyMembers = await companyService.getCompanyMembers(companyId)
      const allInvites = await companyService.getCompanyInvites(companyId)

      const companyMembersAndInvites: TeamMember[] = companyMembers.map(currentMember => {
        return {
          id: currentMember.id as number,
          email: currentMember.email,
          first_name: currentMember.first_name,
          last_name: currentMember.last_name,
          team: (currentMember.team ? currentMember.team : MemberTeam.None),
          status: MemberStatus.ACTIVE,
          invite_code: '',
          roles: (currentMember.roles ? currentMember.roles : []),
        }
      })


      /*
       * Loop through invites array to add ONLY the latest invites sent out to users that do not belong to a company yet
       * or invites that had not been expired.
       * Sometimes users may join a company using an email different from the one that was originally invited.
       * In this case we want to skip processing invites where the user_id is present in the list of company members
       * because that means the invite that contains user_id had been previously accepted. Using email for that check
       * would NOT work properly.
       * Even if the invite had been previously accepted, and therefore it has a user_id, that user could have been
       * removed from the company and the invite could still be existent in the list of invites. Because of that we will
       * also skip processing invites that have the status as active/accepted.
       */
      for (const currentInvite of allInvites.invites) {
        /*
         * Skip processing if current invite is active/accepted.
         * Users with accepted invites may belong to the company or not. Either way, that invite should not be displayed
         * here. If the user from the invite already belongs to a company, the check bellow will detect that.
         */
        if (currentInvite.status === MemberStatus.ACTIVE)
          continue

        const foundUser = companyMembers.find(value => {
          return value.id === currentInvite.user_id
        })

        // Skip processing if current invite user already belongs to a service company.
        if (foundUser)
          continue

        /*
         * Get the latest invite sent out since we can have multiple invites for the same user email, for example,
         * when an invitation is expired and the manager send another invite for that same user email.
         */
        const latestInviteSentOut = allInvites.invites.reduce((previousValue, currentValue) => {
          if (previousValue.email === currentValue.email) {
            return (
              Date.parse(previousValue.expire_timestamp) > Date.parse(currentValue.expire_timestamp) ?
                previousValue : currentValue
            )
          }
          else {
            return previousValue
          }
        }, currentInvite)

        if (latestInviteSentOut) {
          const foundInvite = companyMembersAndInvites.find(value => {
            return value.email === latestInviteSentOut.email
          })

          // Add invite to the list.
          if (!foundInvite) {
            companyMembersAndInvites.push({
              id: -1 as number,
              first_name: '',
              last_name: '',
              email: latestInviteSentOut.email,
              team: latestInviteSentOut.team,
              status: latestInviteSentOut.status,
              invite_code: latestInviteSentOut.code,
            })
          }
        }
      }

      dispatch(setCompanyMembersAndInvites(companyMembersAndInvites))
    } catch {
      dispatch(setCompanyMembersAndInvitesError())
    }
  }

/**
 * Thunk function that calls API for getting all the invites that were sent for a specific company.
 * @param companyId - The company id to retrieve its history of invites.
 */
export const getCompanyInvites = (companyId: number): ThunkResult =>
  async dispatch => {
    try {
      const invites = await companyService.getCompanyInvites(companyId)

      dispatch(setCompanyInvites(invites))
    } catch {
      dispatch(setCompanyInvitesError())
    }
  }

/**
 * Thunk function that calls API for inviting a user email to join a service company. It invites one user at a time.
 * @param invite - The invite information to whom send the invite for and for which company and team-mngt.
 */
export const inviteUserToJoinCompany = (invite: UserServiceCompanyInvite):
  ThunkResult<Promise<ServiceCompanyInviteResult>> =>
  async () => {
    try {
      const inviteResult = await companyService.inviteUserToServiceCompany(invite)

      return Promise.resolve({
        ...inviteResult,
        invite,
      })
    } catch (error: any) {
      return Promise.reject({
        ...error,
        invite,
      })
    }
  }

/**
 * Thunk function that calls API for resending an invitation to a user based on its latest pending invite code.
 * It's the same API call for inviting however with different behaviour, error handler.
 * @param inviteCode - The invite code that will be resent.
 */
export const resendInviteForUserToServiceCompany = (inviteCode: string):
  ThunkResult<Promise<HttpError>> =>
  async () => {
    try {
      const inviteResult = await companyService.resendInviteForUserToServiceCompany(inviteCode)

      return Promise.resolve(inviteResult)
    } catch (error: any) {
      return Promise.reject(error)
    }
  }

/**
 * Thunk function that removes a previously sent invitation for a user to join a service company.
 * It removes one invite at a time,
 * @param inviteDeleteInfo - Information that identifies the invite to be removed, that is the company id and
 * a previously sent invite code.
 */
export const deleteInviteForUserToJoinCompany = (inviteDeleteInfo: ServiceCompanyInviteDeleteInfo):
  ThunkResult<Promise<ServiceCompanyInviteResult>> =>
  async dispatch => {
    try {
      const result = await companyService.deleteInviteForUserToJoinCompany(inviteDeleteInfo)

      dispatch(deleteCompanyInvite(inviteDeleteInfo))

      return result
    } catch (error: any) {
      return Promise.reject(error)
    }
  }
