import React from 'react'
import { Typography, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  ruleTitle: {
    color: '#7A8D98',
    fontSize: 12,
  },
}))

function RuleTitle(props) {
  const classes = useStyles()

  return (
    <Typography className={classes.ruleTitle} {...props} />
  )
}

export default RuleTitle
