import {
  TextField, InputAdornment, Tooltip, IconButton, Divider, 
} from '@material-ui/core'
import { Search, Clear } from '@material-ui/icons'
import React, { useState } from 'react'


interface Props {
  placeholder?: string;
  searchBarStyle?: React.CSSProperties;
  onSearchEnter?: (value: string) => void;
  onSearchClear?: (previousValue: string) => void;
  onSearchInputChange?: (previousValue: string, currentValue: string) => void;
}


/**
 * Modular UI Component that displays a search bar with a default style allowing to be customizable.
 * It contains the following callbacks:
 * 1 - onSearchEnter: When the search icon button is clicked or the key enter is pressed.
 * 2 - onSearchClear: When the clear icon button is clicked. It does not trigger onSearchInputChange callback.
 * 3 - onSearchInputChange: When any text input change
 * @param props
 * @constructor
 */
export const SearchBar = (props: Props) => {

  const {
    placeholder, searchBarStyle, onSearchEnter, onSearchClear, onSearchInputChange, 
  } = props

  const [searchText, setSearchText] = useState<string>('')

  const onTextChange = (text: string): void => {
    // Does this so we can save the previous text before useState overwrites it.
    const previousText = searchText

    setSearchText(text)

    if (onSearchInputChange) {
      // A minimal delay is used for giving time for the UI to update the search bar in case the parent component also
      // re-render this search bar for any reason.
      setTimeout(() => {
        onSearchInputChange(previousText, text)
      }, 50)
    }
  }

  return (
    <TextField
      id="search-bar"
      value={searchText}
      autoComplete="on"
      onChange={(event: any) => onTextChange(event.target.value) }

      onKeyDown={(event: any) => {
        if (event.keyCode == 13 || event.key === 'Enter') {
          if (onSearchEnter)
            onSearchEnter(event.target.value)
        }
      }}

      placeholder={placeholder}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              style={{
                visibility: searchText ? 'visible' : 'hidden',
              }}
              onClick={() => {
                if (onSearchClear)
                  onSearchClear(searchText)

                setSearchText('')
              }}
            >
              <Tooltip title="Clear Search">
                <Clear fontSize="small" />
              </Tooltip>
            </IconButton>
            <Divider
              orientation="vertical"
              style={{
                height: '25px',
              }}
            />
            <IconButton
              color="primary"
              onClick={() => {
                if (onSearchEnter)
                  onSearchEnter(searchText)
              }}
            >
              <Tooltip title="Confirm Search">
                <Search fontSize="small" />
              </Tooltip>
            </IconButton>
          </InputAdornment>
        ),
        style: searchBarStyle,
        inputProps: {
          'aria-label': 'Search',
        },
      }}
    />
  )
}

SearchBar.defaultProps = {
  placeholder: 'Search',
  searchBarStyle: {
    backgroundColor: '#FFFFFF',
    padding: '.5rem',
    width: 600,
    margin: '0px 0',
    borderRadius: 2,
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)',
    minHeight: 'auto',
  },
}
