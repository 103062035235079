import { Tooltip, withStyles, Theme } from '@material-ui/core'

/**
 * Display warning type tooltip with theme warning color background
 * @param theme Theme object
 * @returns CustomTooltip JSX.element
 */
const WarningTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.text.primary,
    maxWidth: 300,
    padding: '16px',
    lineHeight: '16px',
    boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.15)',
    ...theme.typography.caption,
    '& p': {
      margin: 0,
    },
    '& p:not(:last-child)': {
      marginBottom: '1em',
    },
  },
  arrow: {
    '&::before': {
      backgroundColor: theme.palette.warning.light,
    },
  },
}))(Tooltip)

export default WarningTooltip
