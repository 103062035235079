import React from 'react'
import {
  Box, makeStyles, Typography, IconButton, Divider, Button,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useMixPanel } from 'features/analytics/mixpanel-provider'


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  header: {
    backgroundColor: theme.brand.contrast.main,
    color: theme.palette.text.primary,
    borderRadius: '10px 10px 0 0',
  },
  content: {
    fontSize: '1rem',
    '& > ul': {
      marginLeft: '1em',
    },
    '& li': {
      marginBottom: '0px',
    },
  },
  footer: {
    '& > button': {
      marginLeft: '1em',
    },
  },
  progress: {
    marginRight: 'auto',
  },
}))

export type PreviousStepCallbackFn = (previousStep?: number, currentStep?: number, totalSteps?: number) => void;
export type NextStepCallbackFn = (nextStep?: number, currentStep?: number, totalSteps?: number) => void;
export type CloseStepCallbackFn = () => void;


interface TourStepContentProps {
  tourStepName: string;
  title: string;
  content: React.ReactNode;
  previousStepText?: string;
  nextStepText?: string;
  onPreviousStep?: PreviousStepCallbackFn;
  onNextStep?: NextStepCallbackFn;
  currentStep: number;
  totalSteps: number;
  onCloseStep: CloseStepCallbackFn;
}


/**
 * Component that renders a dialog with a title, content, steps counter and button actions.
 * A dialog with information regarding the current step being displayed in a tour in the application providing ways to
 * navigate between previous/next steps throughout the tour.
 */
export const TourStepContent = (props: TourStepContentProps): JSX.Element => {
  const {
    tourStepName, title, content, previousStepText = 'Previous', nextStepText = 'Next', currentStep, totalSteps,
    onPreviousStep = () => {}, onNextStep = () => {}, onCloseStep,
  } = props
  const classes = useStyles()
  const { mixpanel } = useMixPanel()

  return (
    <Box borderRadius={10} className={classes.root}>
      <Box
        className={classes.header}
        px={3}
        py={2}
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Typography variant="subtitle1">{title}</Typography>
        <IconButton
          color="inherit"
          size="small"
          onClick={() => {
            if (mixpanel) {
              mixpanel.track('pp_tourStep_' + tourStepName + '_closeButton_' + currentStep)
            }

            onCloseStep()
          }}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box py={2} px={3} className={classes.content}>
        {content}
      </Box>
      <Divider />
      <Box py={1} px={2} display="flex" justifyContent="flex-end" alignItems="center" className={classes.footer}>
        <span className={classes.progress}>
          {currentStep}/{totalSteps}
        </span>
        {currentStep > 1 && (
          <Button
            color="primary"
            onClick={() => {
              if (mixpanel) {
                mixpanel.track('pp_tourStep_' + tourStepName + '_previousButton_' + currentStep)
              }

              onPreviousStep(currentStep - 1, currentStep, totalSteps)
            }}
          >
            {previousStepText}
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (mixpanel) {
              mixpanel.track('pp_tourStep_' + tourStepName + '_nextButton_' + currentStep)
            }

            onNextStep(currentStep + 1, currentStep, totalSteps)
          }}>
          {nextStepText}
        </Button>
      </Box>
    </Box>
  )
}
