import React, { useCallback, useEffect, useState } from 'react'
import Tour, { ReactourStep } from 'reactour'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  Dialog, DialogTitle, makeStyles, Typography, IconButton, Button, DialogContent, Box,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { DEMO_STEPS } from 'features/demo-data/demo-data'
import { ROUTE_PATHS } from 'routes/routes'
import { UserDemoName } from 'state-mngt/models/user'
import {
  cancelDemo, goToNextDemoStep, goToPreviousDemoStep, startDemo,
} from 'state-mngt/actions/user-actions'
import { selectDwellingDetails } from 'state-mngt/selectors/customer-selectors'
import { getUserDemoActiveDemo, getUserDemoActiveStep } from 'state-mngt/selectors/user-selectors'
import demoService from 'state-mngt/services/data/demo-service'
import user1 from 'features/demo-data/images/user-1.svg'
import user2 from 'features/demo-data/images/user-2.svg'
import user3 from 'features/demo-data/images/user-3.svg'
import { useMixPanel } from 'features/analytics/mixpanel-provider'
import { CUSTOMER_HISTORY_CONTAINER_ID } from 'features/customer-drill-down/charts/device-chart/chart-utils'
import { TourStepContent } from 'ui/tour-step-content'

const useStyles = makeStyles((theme) => ({
  title: {
    margin: 0,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  footerStart: {
    marginRight: 'auto',
  },
  nextButton: {
    minWidth: 220,
  },
  helper: {
    padding: '0 !important',
    bottom: 40,
    top: 'auto !important',
    left: '40px !important',
    transform: 'translate(0,0) !important',
    backgroundColor: 'transparent !important',
    maxWidth: '350px !important',
  },
  mask: {
    color: theme.palette.grey[500],
    opacity: '50%',
  },
}))

const DemoDataTour = () => {
  const classes = useStyles()
  const dwelling = useSelector(selectDwellingDetails)
  const dispatch = useDispatch()
  const { mixpanel } = useMixPanel()
  const navigate = useNavigate()

  const currentStep = useSelector(getUserDemoActiveStep)
  const demoName = useSelector(getUserDemoActiveDemo)
  const [isOpen, setOpen] = useState(false)

  const onPrevious = useCallback(
    (previousStep?: number, currentStep?: number, totalSteps?: number) => {
      if (previousStep !== undefined && previousStep > 0) {
        dispatch(goToPreviousDemoStep())
      }
    },
    [dispatch],
  )

  const onNext = useCallback(
    (nextStep?: number, currentStep?: number, totalSteps?: number) => {
      dispatch(goToNextDemoStep())
      if (demoName !== null) {
        if (nextStep !== undefined && nextStep > DEMO_STEPS[demoName]) {
          navigate(ROUTE_PATHS.customers.root.absolute)
        }
      }
    },
    [dispatch, demoName, navigate],
  )

  const onCancel = useCallback(() => {
    dispatch(cancelDemo())
    setOpen(false)
    navigate(-1)
  }, [dispatch, navigate])

  useEffect(() => {
    if (dwelling) {
      if (dwelling.isDemo) {
        const demoName = demoService.getDemoNameByDwellingId(dwelling.id)
        if (demoName !== null) {
          setOpen(true)
          dispatch(startDemo(demoName))
        }
      }
    }
  }, [dispatch, dwelling])

  let steps: ReactourStep[] = []
  if (demoName === UserDemoName.Demo1) {
    steps = [
      {
        selector: `#${CUSTOMER_HISTORY_CONTAINER_ID.demoCustomer}`,
        content: (
          <TourStepContent
            title="Major PM2.5 issues."
            tourStepName={demoName}
            content={
              <>
                <Typography variant="body1" gutterBottom>
                  When Pat’s contractor installed a HAVEN Monitor on May 1st, they also cleaned the ducts and replaced
                  the filter with the identical 1” filter.
                </Typography>
                <br/>
                <Typography variant="body1">
                  Filtration issues were detected immediately post-install. This is inferred from the
                  Monitor's PM2.5 data.
                </Typography>
              </>
            }
            currentStep={1}
            totalSteps={4}
            previousStepText="Back"
            nextStepText="Next"
            onPreviousStep={onPrevious}
            onNextStep={onNext}
            onCloseStep={onCancel}
          />
        ),
      },
      {
        selector: `#${CUSTOMER_HISTORY_CONTAINER_ID.demoCustomer}`,
        content: (
          <TourStepContent
            title="Closer inspection of data"
            tourStepName={demoName}
            content={
              <>
                <Typography variant="body1" gutterBottom>
                  A HAVEN Comfort Consultant monitored the data in the Pro Portal for a while and data revealed
                  persistent chronic issues peaking during the evening times, probably caused by Pat’s large family and
                  their dogs spending time together.
                </Typography>
                <br/>
                <Typography variant="body1">
                  Another visit was booked a week after the install to walk through the home and explore possible
                  solutions.
                </Typography>
              </>
            }
            currentStep={2}
            totalSteps={4}
            previousStepText="Previous"
            nextStepText="Next"
            onPreviousStep={onPrevious}
            onNextStep={onNext}
            onCloseStep={onCancel}
          />
        ),
      },
      {
        selector: `#${CUSTOMER_HISTORY_CONTAINER_ID.demoCustomer}`,
        content: (
          <TourStepContent
            title="Mystery solved."
            tourStepName={demoName}
            content={
              <>
                <Typography variant="body1" gutterBottom>
                  After the inspection, the contractor concluded that the 1” filter wasn’t enough to deal with
                  the chronic PM2.5 issues caused by this large family with pets and their activities.
                </Typography>
                <br/>
                <Typography variant="body1">
                  The contractor replaced the 1” filter with a high MERV13 rating HEPA filter.
                </Typography>
              </>
            }
            currentStep={3}
            totalSteps={4}
            previousStepText="Previous"
            nextStepText="Next"
            onPreviousStep={onPrevious}
            onNextStep={onNext}
            onCloseStep={onCancel}
          />
        ),
      },
      {
        selector: `#${CUSTOMER_HISTORY_CONTAINER_ID.demoCustomer}`,
        content: (
          <TourStepContent
            title="Looking good"
            tourStepName={demoName}
            content={
              <>
                <Typography variant="body1" gutterBottom>
                  The data shows that installing a MERV13 rating 4” filter was successful in helping
                  filtration levels within safe ranges, long-term.
                </Typography>
              </>
            }
            currentStep={4}
            totalSteps={4}
            previousStepText="Previous"
            nextStepText="Finish"
            onPreviousStep={onPrevious}
            onNextStep={onNext}
            onCloseStep={onCancel}
          />
        ),
      },
    ]
  }
  if (demoName === UserDemoName.Demo2) {
    steps = [
      {
        selector: `#${CUSTOMER_HISTORY_CONTAINER_ID.demoCustomer}`,
        content: (
          <TourStepContent
            title="Relative Humidity in summer"
            tourStepName={demoName}
            content={
              <>
                <Typography variant="body1" gutterBottom>
                  Right away after the contractor installed a HAVEN Monitor on June 5th, it was clear that
                  Relative Humidity levels were floating above 70%, which is above the suggested range.
                </Typography>
              </>
            }
            currentStep={1}
            totalSteps={4}
            previousStepText="Back"
            nextStepText="Next"
            onPreviousStep={onPrevious}
            onNextStep={onNext}
            onCloseStep={onCancel}
          />
        ),
      },
      {
        selector: `#${CUSTOMER_HISTORY_CONTAINER_ID.demoCustomer}`,
        content: (
          <TourStepContent
            title="Solving the issue"
            tourStepName={demoName}
            content={
              <>
                <Typography variant="body1" gutterBottom>
                  A HAVEN Comfort Consultant cross-referenced RH data with Temperature confirming that the
                  Relative Humidity levels were in fact too high and required attention.
                </Typography>
                <br/>
                <Typography variant="body1">
                  They had installed a properly sized AC unit in the house only 3 years ago, so knew
                  that wasn’t an issue.
                </Typography>
              </>
            }
            currentStep={2}
            totalSteps={4}
            previousStepText="Previous"
            nextStepText="Next"
            onPreviousStep={onPrevious}
            onNextStep={onNext}
            onCloseStep={onCancel}
          />
        ),
      },
      {
        selector: `#${CUSTOMER_HISTORY_CONTAINER_ID.demoCustomer}`,
        content: (
          <TourStepContent
            title="Solving the issue"
            tourStepName={demoName}
            content={
              <>
                <Typography variant="body1" gutterBottom>
                  A HAVEN Comfort Consultant shared a Home Air Report with Hubert that clearly showed the
                  issues in the home.
                </Typography>
                <br/>
                <Typography variant="body1" gutterBottom>
                  Hubert used the report as a reference point when talking to his HVAC Pro. The decision was made
                  to install a whole-home dehumidifier and a HAVEN Controller for automating it based on HAVEN data.
                </Typography>
              </>
            }
            currentStep={3}
            totalSteps={4}
            previousStepText="Previous"
            nextStepText="Next"
            onPreviousStep={onPrevious}
            onNextStep={onNext}
            onCloseStep={onCancel}
          />
        ),
      },
      {
        selector: `#${CUSTOMER_HISTORY_CONTAINER_ID.demoCustomer}`,
        content: (
          <TourStepContent
            title="It worked."
            tourStepName={demoName}
            content={
              <>
                <Typography variant="body1" gutterBottom>
                  The data shows that RH levels dropped almost immediately after installing the dehumidifier and
                  Controller, and continue to stay within the optimal range.
                </Typography>
              </>
            }
            currentStep={4}
            totalSteps={4}
            previousStepText="Previous"
            nextStepText="Finish"
            onPreviousStep={onPrevious}
            onNextStep={onNext}
            onCloseStep={onCancel}
          />
        ),
      },
    ]
  }
  if (demoName === UserDemoName.Demo3) {
    steps = [
      {
        selector: `#${CUSTOMER_HISTORY_CONTAINER_ID.demoCustomer}`,
        content: (
          <TourStepContent
            title="Oh no! tVOC levels are severe!"
            tourStepName={demoName}
            content={
              <>
                <Typography variant="body1" gutterBottom>
                  Ever since the HAVEN Monitor started detecting Vida’s air quality, her home experienced chronic
                  VOC events.
                </Typography>
              </>
            }
            currentStep={1}
            totalSteps={4}
            previousStepText="Back"
            nextStepText="Next"
            onPreviousStep={onPrevious}
            onNextStep={onNext}
            onCloseStep={onCancel}
          />
        ),
      },
      {
        selector: `#${CUSTOMER_HISTORY_CONTAINER_ID.demoCustomer}`,
        content: (
          <TourStepContent
            title="Vida took action"
            tourStepName={demoName}
            content={
              <>
                <Typography variant="body1" gutterBottom>
                  She reached out to her contractor a week post-install and explained her concerns with her
                  air quality since she was expecting a child and had regular headaches.
                </Typography>
                <br/>
                <Typography variant="body1">
                  Her contractor explained that painting the new nursery and getting new furniture could be elevating
                  VOC levels for longer periods of time.
                </Typography>
              </>
            }
            currentStep={2}
            totalSteps={4}
            previousStepText="Previous"
            nextStepText="Next"
            onPreviousStep={onPrevious}
            onNextStep={onNext}
            onCloseStep={onCancel}
          />
        ),
      },
      {
        selector: `#${CUSTOMER_HISTORY_CONTAINER_ID.demoCustomer}`,
        content: (
          <TourStepContent
            title="The solution"
            tourStepName={demoName}
            content={
              <>
                <Typography variant="body1" gutterBottom>
                  Vida's contractor proposed to install a HAVEN Controller to automate her HVAC fan and respond
                  to the ventilation (VOC) issues. It would also help increase the number of air changes per hour -
                  effectively replacing stale indoor air with more outdoor air and dissipating the VOC buildup.
                </Typography>
                <br/>
                <Typography variant="body1" gutterBottom>
                  1 week later, installation techs connected the HAVEN Controller to the system.
                </Typography>
              </>
            }
            currentStep={3}
            totalSteps={4}
            previousStepText="Previous"
            nextStepText="Next"
            onPreviousStep={onPrevious}
            onNextStep={onNext}
            onCloseStep={onCancel}
          />
        ),
      },
      {
        selector: `#${CUSTOMER_HISTORY_CONTAINER_ID.demoCustomer}`,
        content: (
          <TourStepContent
            title="No more headaches"
            tourStepName={demoName}
            content={
              <>
                <Typography variant="body1" gutterBottom>
                  You can see that VOC levels have been able to stay fairly low since the Controller was installed.
                </Typography>
                <br/>
                <Typography variant="body1">
                  Bonus! Vida confirmed that her headaches had drastically decreased as well.
                </Typography>
                <br/>
                <Typography variant="body1">
                  She had no idea that her IAQ could be affecting her health like that, not to mention that of
                  her soon-to-arrive baby.
                </Typography>
              </>
            }
            currentStep={4}
            totalSteps={4}
            previousStepText="Previous"
            nextStepText="Finish"
            onPreviousStep={onPrevious}
            onNextStep={onNext}
            onCloseStep={onCancel}
          />
        ),
      },
    ]
  }

  if (isOpen && currentStep === 0) {
    let image = user1
    if (demoName === UserDemoName.Demo2) {
      image = user2
    } else if (demoName === UserDemoName.Demo3) {
      image = user3
    }
    return (
      <Dialog open>
        <DialogTitle
          disableTypography
          classes={{
            root: classes.title,
          }}
        >
          <Typography variant="h4" color="secondary">
            {demoName === UserDemoName.Demo1 && 'Welcome to Pat Parker’s home!'}
            {demoName === UserDemoName.Demo2 && 'Welcome to Hubert Hernández’s home!'}
            {demoName === UserDemoName.Demo3 && 'Welcome to Vida Valdes’s home!'}
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {
              if (mixpanel) {
                mixpanel.track('pp_demoDataTourPage_' + demoName + 'Dialog_closeButton')
              }

              onCancel()
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box textAlign="center">
            <Box mb={2}>
              <img
                width={132}
                src={image}
                alt=""
                style={{
                  margin: '0 auto',
                }}
              />
            </Box>
            <Box mb={2} maxWidth={480} mx="auto">
              {demoName === UserDemoName.Demo1 && (
                <>
                  <Typography variant="body1" gutterBottom>
                    I’ve been experiencing  some <b>eye and throat irritation</b> symptoms for a while, especially in
                    the evening. Could the air in my home be responsible?
                  </Typography>
                  <br/>
                  <Typography variant="body1">
                    <b>DID YOU KNOW?</b>
                  </Typography>
                  <Typography variant="body1">
                    Exposure to fine particles (PM2.5) can cause eye, nose, throat, and lung irritation, coughing,
                    sneezing, runny nose, and shortness of breath. Let’s take a look at the data.
                  </Typography>
                </>
              )}
              {demoName === UserDemoName.Demo2 && (
                <>
                  <Typography variant="body1" gutterBottom>
                    “I noticed that <b>mold</b> has started growing in my upstairs bathroom a while ago.
                    I don’t know what I can do about it, but it worries me”.
                  </Typography>
                  <br/>
                  <Typography variant="body1">
                    <b>DID YOU KNOW?</b>
                  </Typography>
                  <Typography variant="body1">
                    High Relative Humidity (RH) levels can cause mold and mildew growth, which in turn can trigger
                    symptoms and even cause allergies and asthma.
                  </Typography>
                </>
              )}
              {demoName === UserDemoName.Demo3 && (
                <>
                  <Typography variant="body1" gutterBottom>
                    “I’ve been <b>suffering from headaches</b> for a while and I’m convinced it’s caused by the
                    <b> poor air quality</b> in my home”.
                  </Typography>
                  <br/>
                  <Typography variant="body1">
                    <b>DID YOU KNOW?</b>
                  </Typography>
                  <Typography variant="body1">
                    tVOC (Volatile Organic Compounds) can cause an eye, nose and throat discomfort, headaches,
                    fatigue, allergic skin reactions.
                  </Typography>
                </>
              )}
            </Box>
            <Button
              color="primary"
              variant="contained"
              classes={{
                root: classes.nextButton,
              }}
              onClick={() => {
                if (mixpanel) {
                  mixpanel.track('pp_demoDataTourPage_' + demoName + 'Dialog_okButton')
                }

                onNext(1)
              }}
            >
              Ok
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Tour
      steps={steps}
      isOpen={isOpen}
      onRequestClose={() => {}}
      prevButton={<Button>Prev</Button>}
      nextButton={<Button>Next</Button>}
      showButtons={false}
      showCloseButton={false}
      showNavigation={false}
      showNumber={false}
      className={classes.helper}
      maskClassName={classes.mask}
      goToStep={(currentStep || 1) - 1}
    />
  )
}

export default DemoDataTour
