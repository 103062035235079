import { HISTORY_QUERY_KEYS } from "utils/constants/customer"
import useDecodedSearchParams from "./useDecodedSearchParams"

const useCurrentZone = () => {
  const [searchParams] = useDecodedSearchParams()
  const zone = decodeURIComponent(searchParams.get(HISTORY_QUERY_KEYS.zone) || '')

  return zone
}

export default useCurrentZone
