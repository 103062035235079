import { DeviceTelemetry } from 'state-mngt/models/device'
import { APIInterval } from 'utils/constants/time-interval-api'
import { TimeInterval } from 'utils/constants/time-interval'
import { TELEMETRY_THRESHOLD, TelemetryStatus } from 'utils/constants/telemetry-threshold'
import {
  getCeilDateToMinutes,
  getFloorDateToMinutes,
  getMidnightDate,
  getTodayMidnightDate,
  minusDays,
  setHour,
} from 'utils/time-utils'

const getMsIncrement = (interval: APIInterval): number => {
  switch (interval) {
    case APIInterval.FIVE_MIN: {
      return TimeInterval.MINUTE * 5
    }
    case APIInterval.ONE_HOUR: {
      return TimeInterval.HOUR
    }
    case APIInterval.ONE_DAY: {
      return TimeInterval.DAY
    }
    default: {
      return TimeInterval.MINUTE
    }
  }
}

export const compose = (...fns: Array<any>) => <T>(value: T): T => fns.reduce((acc: T, fn) => fn(acc), value)
export const addTelemetry = (
  start: Date,
  end: Date,
  defaultRecord: Partial<DeviceTelemetry> = {
  },
) => (value: DeviceTelemetry[]): DeviceTelemetry[] => {
  const result: DeviceTelemetry[] = [...value]
  const startMs = start.valueOf()
  const endMs = end.valueOf()
  const diffMs = getMsIncrement(APIInterval.FIVE_MIN)

  for (let currentMs = startMs; currentMs <= endMs; currentMs += diffMs) {
    result.push({
      ...getFakeTelemetryRecord(),
      ...defaultRecord,
      timestamp: new Date(currentMs).toISOString(),
    })
  }

  return result
}

export const updateTelemetryRecord = (
  date: Date,
  record: Partial<DeviceTelemetry>,
) => (value: DeviceTelemetry[]): DeviceTelemetry[] => {
  const updatedTimestamp = date.toISOString()
  return value.map((item) => item.timestamp !== updatedTimestamp ? item : ({
    ...item,
    ...record,
  }))
}

const getTendValues = (start: number, end: number, steps: number): number[] => {
  const result: number[] = [start]
  const diff = Number(((end - start) / (steps - 1)).toFixed(1))
  for (let i = 1; i < steps - 1; i += 1) {
    const currentValue = start + diff * i
    const nextValue = start + diff * (i + 1)
    result.push(start + diff * i + (nextValue - currentValue) * Math.random())
  }
  result.push(end)
  return result
}

const getRandomArbitrary = (min: number, max: number): number => {
  return Math.random() * (max - min) + min
}

const getFakeTelemetryRecord = (): DeviceTelemetry => ({
  timestamp: '',
  airflow: parseFloat(getRandomArbitrary(0, 1).toFixed(2)),
  voc_mc: 0,
  voc_count: 0,
  pm_count: getRandomArbitrary(0.1, TELEMETRY_THRESHOLD.pmCountFair * 0.5),
  pm_mc: getRandomArbitrary(1, TELEMETRY_THRESHOLD.pmMcFair * 0.5),
  temperature: 0,
  humidity: 0,
  voc_status: '',
  pm_status: '',
  combined_status: '',
  highest_factor: '',
})

const createDayTelemetry = (
  dayDate: Date,
  recordsMap: { [hour: string]: Partial<DeviceTelemetry> },
): DeviceTelemetry[] => {
  const result: DeviceTelemetry[] = []
  for (let hour = 0; hour < 24; hour += 1) {
    const currentRecord = recordsMap[hour] ? {
      ...getFakeTelemetryRecord(),
      ...recordsMap[hour],
    } : getFakeTelemetryRecord()
    const nextRecord = recordsMap[hour + 1] ? {
      ...getFakeTelemetryRecord(),
      ...recordsMap[hour + 1],
    } : getFakeTelemetryRecord()
    const airflowValues = getTendValues(currentRecord.airflow, nextRecord.airflow, 60 / 5)
    const vocMcValues = getTendValues(currentRecord.voc_mc, nextRecord.voc_mc, 60 / 5)
    const vocCountValues = getTendValues(currentRecord.voc_count, nextRecord.voc_count, 60 / 5)
    const pmCountValues = getTendValues(currentRecord.pm_count, nextRecord.pm_count, 60 / 5)
    const pmMcValues = getTendValues(currentRecord.pm_mc, nextRecord.pm_mc, 60 / 5)
    const temperatureValues = getTendValues(currentRecord.temperature, nextRecord.temperature, 60 / 5)
    const humidityValues = getTendValues(currentRecord.humidity, nextRecord.humidity, 60 / 5)
    const startMs = setHour(dayDate, hour + 1).valueOf()
    const endMs = setHour(dayDate, hour + 2).valueOf()
    const diffMs = getMsIncrement(APIInterval.FIVE_MIN)

    const timestampValues = []
    for (let currentMs = startMs; currentMs < endMs; currentMs += diffMs) {
      // @ts-ignore
      timestampValues.push(new Date(currentMs).toISOString())
    }

    for (let interval = 0; interval < temperatureValues.length; interval += 1) {
      result.push({
        ...currentRecord,
        timestamp: timestampValues[interval],
        airflow: airflowValues[interval],
        voc_mc: vocMcValues[interval],
        voc_count: vocCountValues[interval],
        pm_count: pmCountValues[interval],
        pm_mc: pmMcValues[interval],
        temperature: temperatureValues[interval],
        humidity: humidityValues[interval],
      })
    }
  }
  return result
}

const updateTelemetryRecords = (records: DeviceTelemetry[]) => (value: DeviceTelemetry[]): DeviceTelemetry[] => {
  let result = [...value]
  for (let i = 0; i < records.length; i += 1) {
    result = updateTelemetryRecord(new Date(records[i].timestamp), records[i])(result)
  }
  return result
}

const removeTelemetryRecords = (from: Date, to: Date) => (value: DeviceTelemetry[]): DeviceTelemetry[] => {
  const fromMs = from.valueOf()
  const toMs = to.valueOf()
  return value.filter(({ timestamp }) => {
    const ms = new Date(timestamp).valueOf()
    if (ms >= fromMs && ms <= toMs) {
      return false
    }
    return true
  })
}

const fillHours = (
  from: number,
  to: number,
  hourTelemetry: Partial<DeviceTelemetry>,
): { [hour: string]: Partial<DeviceTelemetry> } => {
  const result: { [hour: string]: Partial<DeviceTelemetry> } = {
  }
  for (let hour = from; hour <= to; hour += 1) {
    result[hour] = hourTelemetry
  }
  return result
}

export const demo1Range = {
  start: getMidnightDate(minusDays(getTodayMidnightDate(), 90)),
  installed: getMidnightDate(minusDays(getTodayMidnightDate(), 60)),
  end: getTodayMidnightDate(),
}

export const createDemo1Telemetry = compose(
  compose(
    addTelemetry(demo1Range.installed, demo1Range.end),
    updateTelemetryRecords(createDayTelemetry(getMidnightDate(minusDays(getTodayMidnightDate(), 5)), {
      18: {
        pm_count: TELEMETRY_THRESHOLD.pmCountFair * 1.2,
        pm_mc: TELEMETRY_THRESHOLD.pmMcFair * 1.2,
        airflow: TELEMETRY_THRESHOLD.airflow * 10,
      },
      19: {
        pm_count: TELEMETRY_THRESHOLD.pmCountFair * 0.1,
        pm_mc: TELEMETRY_THRESHOLD.pmMcFair * 0.1,
        airflow: TELEMETRY_THRESHOLD.airflow * 10,
      },
    })),
    updateTelemetryRecords(createDayTelemetry(getMidnightDate(minusDays(getTodayMidnightDate(), 6)), {
      18: {
        pm_count: TELEMETRY_THRESHOLD.pmCountFair * 1.6,
        pm_mc: TELEMETRY_THRESHOLD.pmMcFair * 1.6,
        airflow: TELEMETRY_THRESHOLD.airflow * 10,
      },
      19: {
        pm_count: TELEMETRY_THRESHOLD.pmCountFair * 0.1,
        pm_mc: TELEMETRY_THRESHOLD.pmMcFair * 0.1,
        airflow: TELEMETRY_THRESHOLD.airflow * 10,
      },
    })),
    updateTelemetryRecords(createDayTelemetry(getMidnightDate(minusDays(getTodayMidnightDate(), 8)), {
      18: {
        pm_count: TELEMETRY_THRESHOLD.pmCountFair * 0.7,
        pm_mc: TELEMETRY_THRESHOLD.pmMcFair * 0.7,
        airflow: TELEMETRY_THRESHOLD.airflow * 10,
      },
      19: {
        pm_count: TELEMETRY_THRESHOLD.pmCountFair * 0.1,
        pm_mc: TELEMETRY_THRESHOLD.pmMcFair * 0.1,
        airflow: TELEMETRY_THRESHOLD.airflow * 10,
      },
    })),
    updateTelemetryRecords(createDayTelemetry(getMidnightDate(minusDays(getTodayMidnightDate(), 12)), {
      18: {
        pm_count: TELEMETRY_THRESHOLD.pmCountFair * 2,
        pm_mc: TELEMETRY_THRESHOLD.pmMcFair * 2,
        airflow: TELEMETRY_THRESHOLD.airflow * 10,
      },
      19: {
        pm_count: TELEMETRY_THRESHOLD.pmCountFair * 0.1,
        pm_mc: TELEMETRY_THRESHOLD.pmMcFair * 0.1,
        airflow: TELEMETRY_THRESHOLD.airflow * 10,
      },
    })),
    updateTelemetryRecords(createDayTelemetry(getMidnightDate(minusDays(getTodayMidnightDate(), 14)), {
      24: {
        pm_count: 0,
        pm_mc: 0,
      },
    })),
    removeTelemetryRecords(
      getMidnightDate(minusDays(getTodayMidnightDate(), 13)),
      getMidnightDate(minusDays(getTodayMidnightDate(), 12)),
    ),
    updateTelemetryRecords(createDayTelemetry(getMidnightDate(minusDays(getTodayMidnightDate(), 16)), {
      18: {
        pm_count: TELEMETRY_THRESHOLD.pmCountPoor * 10,
        pm_mc: TELEMETRY_THRESHOLD.pmMcPoor * 10,
        airflow: TELEMETRY_THRESHOLD.airflow * 10,
      },
    })),
    updateTelemetryRecords(createDayTelemetry(getMidnightDate(minusDays(getTodayMidnightDate(), 17)), {
      18: {
        pm_count: TELEMETRY_THRESHOLD.pmCountPoor,
        pm_mc: TELEMETRY_THRESHOLD.pmMcPoor,
        airflow: TELEMETRY_THRESHOLD.airflow * 10,
      },
    })),
    updateTelemetryRecords(createDayTelemetry(getMidnightDate(minusDays(getTodayMidnightDate(), 18)), {
      18: {
        pm_count: TELEMETRY_THRESHOLD.pmCountPoor * 10,
        pm_mc: TELEMETRY_THRESHOLD.pmMcPoor * 10,
        airflow: TELEMETRY_THRESHOLD.airflow * 10,
      },
    })),
    updateTelemetryRecords(createDayTelemetry(getMidnightDate(minusDays(getTodayMidnightDate(), 20)), {
      18: {
        pm_count: TELEMETRY_THRESHOLD.pmCountPoor * 9,
        pm_mc: TELEMETRY_THRESHOLD.pmMcPoor * 9,
        airflow: TELEMETRY_THRESHOLD.airflow * 10,
      },
    })),
    updateTelemetryRecords(createDayTelemetry(getMidnightDate(minusDays(getTodayMidnightDate(), 22)), {
      18: {
        pm_count: TELEMETRY_THRESHOLD.pmCountPoor * 10,
        pm_mc: TELEMETRY_THRESHOLD.pmMcPoor * 10,
        airflow: TELEMETRY_THRESHOLD.airflow * 10,
      },
    })),
    updateTelemetryRecords(createDayTelemetry(getMidnightDate(minusDays(getTodayMidnightDate(), 24)), {
      18: {
        pm_count: TELEMETRY_THRESHOLD.pmCountPoor * 9,
        pm_mc: TELEMETRY_THRESHOLD.pmMcPoor * 9,
        airflow: TELEMETRY_THRESHOLD.airflow * 10,
      },
    })),
    updateTelemetryRecords(createDayTelemetry(getMidnightDate(minusDays(getTodayMidnightDate(), 26)), {
      18: {
        pm_count: TELEMETRY_THRESHOLD.pmCountPoor * 10,
        pm_mc: TELEMETRY_THRESHOLD.pmMcPoor * 10,
        airflow: TELEMETRY_THRESHOLD.airflow * 10,
      },
    })),
    updateTelemetryRecords(createDayTelemetry(getMidnightDate(minusDays(getTodayMidnightDate(), 28)), {
      18: {
        pm_count: TELEMETRY_THRESHOLD.pmCountPoor * 9,
        pm_mc: TELEMETRY_THRESHOLD.pmMcPoor * 9,
        airflow: TELEMETRY_THRESHOLD.airflow * 10,
      },
    })),
    updateTelemetryRecords(createDayTelemetry(getMidnightDate(minusDays(getTodayMidnightDate(), 29)), {
      18: {
        pm_count: TELEMETRY_THRESHOLD.pmCountPoor * 10,
        pm_mc: TELEMETRY_THRESHOLD.pmMcPoor * 10,
        airflow: TELEMETRY_THRESHOLD.airflow * 10,
      },
    })),
    updateTelemetryRecords(createDayTelemetry(getMidnightDate(minusDays(getTodayMidnightDate(), 33)), {
      18: {
        pm_count: TELEMETRY_THRESHOLD.pmCountPoor * 9,
        pm_mc: TELEMETRY_THRESHOLD.pmMcPoor * 9,
        airflow: TELEMETRY_THRESHOLD.airflow * 10,
      },
    })),
    updateTelemetryRecords(createDayTelemetry(getMidnightDate(minusDays(getTodayMidnightDate(), 34)), {
      18: {
        pm_count: TELEMETRY_THRESHOLD.pmCountPoor * 10,
        pm_mc: TELEMETRY_THRESHOLD.pmMcPoor * 10,
        airflow: TELEMETRY_THRESHOLD.airflow * 10,
      },
    })),
    updateTelemetryRecords(createDayTelemetry(getMidnightDate(minusDays(getTodayMidnightDate(), 35)), {
      18: {
        pm_count: TELEMETRY_THRESHOLD.pmCountPoor * 9,
        pm_mc: TELEMETRY_THRESHOLD.pmMcPoor * 9,
        airflow: TELEMETRY_THRESHOLD.airflow * 10,
      },
    })),
    updateTelemetryRecords(createDayTelemetry(getMidnightDate(minusDays(getTodayMidnightDate(), 36)), {
      18: {
        pm_count: TELEMETRY_THRESHOLD.pmCountPoor * 10,
        pm_mc: TELEMETRY_THRESHOLD.pmMcPoor * 10,
        airflow: TELEMETRY_THRESHOLD.airflow * 10,
      },
    })),
    updateTelemetryRecords(createDayTelemetry(getMidnightDate(minusDays(getTodayMidnightDate(), 37)), {
      18: {
        pm_count: TELEMETRY_THRESHOLD.pmCountPoor * 9,
        pm_mc: TELEMETRY_THRESHOLD.pmMcPoor * 9,
        airflow: TELEMETRY_THRESHOLD.airflow * 10,
      },
    })),
  ),
)

export const pickTelemetry = (
  startTime: Date,
  endTime: Date,
  interval: APIInterval,
) => (value: DeviceTelemetry[]): DeviceTelemetry[] => {
  const result: DeviceTelemetry[] = []
  let index = 0
  const normalizeStartDate = getFloorDateToMinutes(5, startTime)
  const normalizeEndDate = getCeilDateToMinutes(5, endTime)
  normalizeStartDate.setHours(normalizeStartDate.getHours() + Math.floor(normalizeStartDate.getMinutes()/60))
  normalizeEndDate.setHours(normalizeEndDate.getHours() + Math.round(normalizeEndDate.getMinutes()/60))
  if (interval === APIInterval.FIVE_MIN) {
    normalizeStartDate.setUTCSeconds(0, 0)
    normalizeEndDate.setUTCSeconds(0, 0)
  } else {
    normalizeStartDate.setUTCMinutes(0, 0, 0)
    normalizeEndDate.setUTCMinutes(0, 0, 0)
  }
  const startMs = normalizeStartDate.valueOf()
  const endMs = normalizeEndDate.valueOf()
  while (index < value.length) {
    const item = value[index]
    const currentItemMs = new Date(item.timestamp).valueOf()
    if (currentItemMs >= startMs && currentItemMs < endMs) {
      result.push(item)
    }
    index += 1
  }
  return result
}

const getAverageRecord = (items: DeviceTelemetry[]): DeviceTelemetry => {
  return items.reduce((acc, item, index, array) => {
    const airflow = acc.airflow + item.airflow / array.length
    const pmCount = acc.pm_count + item.pm_count / array.length
    let pmStatus = TelemetryStatus.Good
    if (pmCount > TELEMETRY_THRESHOLD.pmCountFair) {
      pmStatus = TelemetryStatus.Fair
    }
    if (pmCount > TELEMETRY_THRESHOLD.pmCountPoor) {
      pmStatus = TelemetryStatus.Poor
    }
    return {
      ...acc,
      pm_status: pmStatus,
      humidity: acc.humidity + item.humidity / array.length,
      temperature: acc.temperature + item.temperature / array.length,
      pm_count: pmCount,
      pm_mc: acc.pm_mc + item.pm_mc / array.length,
      voc_count: acc.voc_count + item.voc_count / array.length,
      voc_mc: acc.voc_mc + item.voc_mc / array.length,
      airflow: index === array.length - 1 ? parseFloat(airflow.toFixed(2)) : airflow,
    }
  }, {
    timestamp: items[0].timestamp,
    airflow: 0,
    humidity: 0,
    combined_status: '',
    highest_factor: '',
    temperature: 0,
    pm_count: 0,
    pm_mc: 0,
    pm_status: '',
    voc_count: 0,
    voc_mc: 0,
    voc_status: '',
  })
}

export const combineTelemetry = (interval: APIInterval) => (value: DeviceTelemetry[]): DeviceTelemetry[] => {
  const result: DeviceTelemetry[] = []
  if (value.length === 0) {
    return result
  }

  let itemsInPeriod: DeviceTelemetry[] = []
  let index = 0
  const diffMs = getMsIncrement(interval)
  let startIntervalMs = new Date(value[0].timestamp).valueOf()
  let endIntervalMs = startIntervalMs + diffMs

  while (index < value.length) {
    const currentItem = value[index]
    const currentItemMs = new Date(currentItem.timestamp).valueOf()
    if (currentItemMs >= endIntervalMs) {
      if (itemsInPeriod.length > 0) {
        result.push(getAverageRecord(itemsInPeriod))
      }
      startIntervalMs = endIntervalMs
      endIntervalMs = startIntervalMs + diffMs
      itemsInPeriod = []
    }
    if (currentItemMs >= startIntervalMs && currentItemMs < endIntervalMs) {
      itemsInPeriod.push(currentItem)
    }
    index += 1
  }

  return result
}
