import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import {
  Box, Button, Card, Grid, TextField, Typography,
} from '@material-ui/core'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxTypes'
import { usePageTitle } from 'utils/hooks/router'
import OnboardingLinearProgress from 'ui/liner-progress'
import 'features/onboarding/onboarding.scss'
import { useEffectOnce } from 'utils/hooks/lifecycle'
import { selectUser, selectUserRole } from 'state-mngt/selectors/user-selectors'
import { ROUTE_PATHS } from 'routes/routes'
import { User } from 'state-mngt/models/user'
import { updateUser } from 'state-mngt/actions/user-actions'
import { Skeleton } from '@material-ui/lab'
import { useNavigate } from 'react-router-dom'

// import images as base64
const IconLock = require('features/join-service-company/images/lock.png')


/**
 * It allows the user to enter an invitation code to join a service company. The invite code is either entered manually
 * by the user or automatically (programmatically) if this page is open from an email invite which contains extra
 * information in the URL.
 * @constructor
 */
export const JoinServiceCompanyOnBoarding = () => {
  usePageTitle('Join Service Company')

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const user = useAppSelector(selectUser)
  const userCompanyRole = useAppSelector(selectUserRole)

  const [loading, setLoading] = useState(true)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [firstNameRequiredError, setFirstNameRequiredError] = useState<boolean>(false)
  const [lastNameRequiredError, setLastNameRequiredError] = useState<boolean>(false)
  const [enableFinishButton, setEnableFinishButton] = useState(false)
  const [progress, setProgress] = useState<number>(50)


  useEffectOnce((markAsCalled) => {
    if (userCompanyRole) {
      markAsCalled()

      if (userCompanyRole.service_company_id === -1) {
        navigate(ROUTE_PATHS.joinServiceCompany.error.serviceCompanyDoesNotExist.absolute, {
          replace: true,
        })
      }
    }
  }, [userCompanyRole])

  useEffectOnce((markAsCalled) => {
    if (user) {
      markAsCalled()
      setLoading(false)
      setFirstName(user.first_name || '')
      setLastName(user.last_name || '')
      setEmail(user.email || '')
    }
  }, [user])

  useEffect(() => {
    if (firstName.trim() && lastName.trim() && email.trim()) {
      setProgress(90)
      setEnableFinishButton(true)
    } else {
      setEnableFinishButton(false)
    }
  }, [firstName, lastName, email])

  /**
   * Callback for first name value change
   * sets first name state
   * @param e - ChangeEvent
   */
  const handleFirstNameChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const input = e.target.value as string
    setFirstName(input)
    setFirstNameRequiredError(input.trim() === '')

    if (input.trim() === '') {
      setProgress(50)
    }
  }

  /**
   * Callback for last name value change
   * sets last name state
   * @param e - ChangeEvent
   */
  const handleLastNameChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const input = e.target.value as string
    setLastName(input)
    setLastNameRequiredError(input.trim() === '' ? true : false)

    if (input.trim() === '') {
      setProgress(50)
    }
  }

  /**
   * Callback for the finish button.
   * It handles the next step.
   */
  const handleFinishClick = async () => {
    if (firstName && lastName && email) {
      const updatedUser: User = {
        ...user!,
        first_name: firstName.trim(),
        last_name: lastName.trim(),
      }

      if (updatedUser.id) {
        setProgress(100)
        setEnableFinishButton(false)

        await dispatch(updateUser(updatedUser.id, updatedUser, true))
      }

      navigate(ROUTE_PATHS.customers.root.absolute, {
        replace: true,
      })
    }
  }

  return(
    <>
      <Box id="onboarding-container" display="flex" flexDirection="column">
        <Grid container className="home-page">
          <Grid item sm={2} />
          <Grid item sm={10}>
            <Box mt="30vh" mb={4}>
              <Typography variant="h1" gutterBottom>Almost there...</Typography>
              <Typography variant="h5" gutterBottom>Please finish with your profile details.</Typography>
            </Box>
            <Card
              elevation={3}
              style={{
                width: '560px',
                padding: '2rem',
                borderRadius: 20,
              }}
            >
              <Box display="flex" mb={4}>
                <Box display="flex" width={83}>
                  <Typography>My name is</Typography>
                </Box>
                <Box
                  display="flex"
                  ml={2}
                  style={{
                    width: '100%',
                    flex: 1,
                  }}
                >
                  { loading ?
                    (
                      <Typography variant="h4"
                        style={{
                          width: '100%',
                          flex: 1,
                        }}
                      >
                        <Skeleton variant="rect" animation="wave" />
                      </Typography>
                    ) : (
                      <>
                        <TextField
                          id="onboarding-first-name"
                          required
                          size="small"
                          placeholder="First Name"
                          value={firstName}
                          error={firstNameRequiredError}
                          helperText={firstNameRequiredError ? 'First name is required' : ''}
                          onChange={handleFirstNameChange}
                          style={{
                            marginRight: '1rem',
                          }}
                        />
                        <TextField
                          id="onboarding-last-name"
                          required
                          size="small"
                          placeholder="Last Name"
                          value={lastName}
                          error={lastNameRequiredError}
                          helperText={lastNameRequiredError ? 'Last name is required' : ''}
                          onChange={handleLastNameChange}
                        />
                      </>
                    )
                  }
                </Box>
              </Box>
              <Box display="flex" mb={4}>
                <Box display="flex" width={83}>
                  <Typography>My email is</Typography>
                </Box>
                <Box
                  ml={2}
                  flex="auto"
                  style={{
                    position: 'relative',
                  }}
                >
                  { loading ?
                    (
                      <Typography variant="h4">
                        <Skeleton variant="rect" animation="wave" />
                      </Typography>
                    ) : (
                      <>
                        <TextField
                          id="onboarding-user-email"
                          fullWidth
                          disabled
                          size="small"
                          placeholder='email@gmail.com'
                          value={email}
                        />
                        <img
                          src={IconLock}
                          alt="Lock icon"
                          style={{
                            marginRight: '4px',
                            position: 'absolute',
                            right: '0',
                          }}
                          width="15px"
                          height="21px"
                        />
                      </>
                    )
                  }
                </Box>
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  fullWidth
                  disabled={!enableFinishButton}
                  onClick={handleFinishClick}
                >
                  Finish
                </Button>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box
        style={{
          backgroundColor: '#EEE',
        }}
        width="100%"
        padding="2rem 1rem 1rem"
      >
        <OnboardingLinearProgress
          value={progress}
          style={{
            width: '50%',
            margin: '0 auto',
          }}
        />
      </Box>
    </>
  )
}
