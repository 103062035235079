import {
  Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, makeStyles, Typography,
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { ServiceCompany } from 'state-mngt/models/serviceCompany'
import { selectIsCompanyDetailsLoading } from 'state-mngt/selectors/company-selectors'
import { ErrorAutoHideWithDismissAction, SuccessAutoHideWithDismissAction } from 'ui/custom-snackbar-provider'
import DragAndDrop from 'ui/drag-and-drop'
import { updateCompanyDetails } from 'state-mngt/actions/company-actions'
import { useMixPanel } from 'features/analytics/mixpanel-provider'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxTypes'

const useStyles = makeStyles({
  dialogContainer: {
    display: 'flex',
    padding: '40px',
  },
  dragContainer: {
    width: '300px',
    height: '200px',
    border: '1px dashed grey',
  },
})


export const CompanyLogoDialog = (props: { companyDetails: ServiceCompany; open: boolean; setOpen: any;}) => {
  const { open, companyDetails, setOpen } = props

  const classes = useStyles()
  const dispatch = useAppDispatch()
  const { mixpanel } = useMixPanel()
  const { enqueueSnackbar } = useSnackbar()

  const isLoading = useAppSelector(selectIsCompanyDetailsLoading)

  const [imageData, setImageData] = useState<string>()
  const [isSaving, setIsSaving] = useState<boolean>(false)


  useEffect(()=> {
    if(isSaving && !isLoading){
      setOpen(false)
    }
  }, [isLoading, isSaving, setOpen])

  /**
     * Callback function for when user click 'cancel' on the logo modal
     */
  const handleCancel = () => {
    if (mixpanel) {
      mixpanel.track('pp_companyPage_cancelLogoEditButton')
    }
    setOpen(false)
  }

  /**
     * Callback for user click 'Save' on logo modal
     */
  const handleSave = async () => {
    if (mixpanel) {
      mixpanel.track('pp_companyPage_saveLogoEditButton')
    }
    setIsSaving(true)

    const updatedDetails = {
      ...companyDetails,
      logo: imageData,
    }

    const wasCompayDetailsUpdated = await dispatch(updateCompanyDetails(companyDetails.id, updatedDetails))

    if (wasCompayDetailsUpdated)
      enqueueSnackbar('Company details updated successfully!', SuccessAutoHideWithDismissAction())
    else
      enqueueSnackbar('Company details updated failed!', ErrorAutoHideWithDismissAction())
  }

  /**
     * triggered when user drop file to drop zone
     * @param imageData - string
     */
  const handleDrop = (imageData: string) => {
    const prepString = imageData.substr(22, imageData.length)
    setImageData(prepString)
  }

  return (
    <Dialog aria-labelledby="No Customers" open={open} >
      { !isSaving &&
                <>
                  <DialogContent className={classes.dialogContainer}>
                    <form style={{
                      marginTop: '20px',
                    }}>
                      <Typography color="primary" variant="h5" gutterBottom>Add your company&apos;s logo</Typography>
                      <DragAndDrop handleDrop={handleDrop}></DragAndDrop>
                    </form>
                  </DialogContent>
                  <DialogActions>
                    <Button color="secondary" onClick={handleCancel}>Cancel</Button>
                    <Button
                      color="secondary"
                      disabled={imageData ? false : true}
                      variant="contained"
                      onClick={handleSave}
                      autoFocus
                      style={{
                        margin: '1rem',
                      }}>
                            SAVE
                    </Button>
                  </DialogActions>
                </>
      }
      { isSaving &&
                <DialogContent className={classes.dialogContainer}>
                  <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
                    <CircularProgress />
                  </Box>
                </DialogContent>
      }
    </Dialog>
  )
}
