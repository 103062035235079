import { SimplePaletteColorOptions } from '@material-ui/core'
import { PaletteColorOptions, unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles'

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    brand: {
      contrast: SimplePaletteColorOptions
    }
  }
  interface ThemeOptions {
    brand: {
      contrast: SimplePaletteColorOptions
    }
  }
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    darkerBlew: '#108b8a',
    blewStream: '#00c4c1',
    blewTint: '#bcf0f0',
    blewLightTint: '#bcf0f0',
    darkerFlow: '#ffd600',
    yellowFlow: '#ffe623',
    flowLightTint: '#fffbde',
    charcoalGrey: '#9bb3bf',
    charcoalTint: '#d4e1e8',
    charcoalLightTint: '#eaf1f5',
    charcoalLightestTint: '#f7fbfd',
    secondaryGrey: '#535f66',
    secondaryGreyTint: '#606f78',
  }
  interface PaletteOptions {
    darkerBlew: '#108b8a',
    blewStream: '#00c4c1',
    blewTint: '#bcf0f0',
    blewLightTint: '#bcf0f0',
    darkerFlow: '#ffd600',
    yellowFlow: '#ffe623',
    flowLightTint: '#fffbde',
    charcoalGrey: '#9bb3bf',
    charcoalTint: '#d4e1e8',
    charcoalLightTint: '#eaf1f5',
    charcoalLightestTint: '#f7fbfd',
    secondaryGrey: '#535f66',
    secondaryGreyTint: '#606f78',
  }
}

/**
 * The above unstable strick mode is an ongoing issue with Material UI, should not be removed until that problem is fixed
 */
export const theme = createMuiTheme({
  brand: {
    contrast: {
      main: '#FFE623',
      light: '#FFFBDE',
      dark: '#FFD600',
    },
  },
  palette: {
    darkerBlew: '#108b8a',
    blewStream: '#00c4c1',
    blewTint: '#bcf0f0',
    blewLightTint: '#bcf0f0',
    darkerFlow: '#ffd600',
    yellowFlow: '#ffe623',
    flowLightTint: '#fffbde',
    charcoalGrey: '#9bb3bf',
    charcoalTint: '#d4e1e8',
    charcoalLightTint: '#eaf1f5',
    charcoalLightestTint: '#f7fbfd',
    secondaryGrey: '#535f66',
    secondaryGreyTint: '#606f78',
    primary: {
      main: '#00C4C1',
      light: '#E9FBFF',
    },
    secondary: {
      main: '#008A93',
    },
    success: {
      main: '#39DD7B',
      light: '#E5FFF0',
    },
    error: {
      main: '#F96C6C',
      light: '#FFE9E9',
    },
    warning: {
      main: '#FFC700',
      light: '#FFF7DB',
      dark: '#ECAA00',
    },
    info: {
      main: '#2196f3', // not defined, this is placeholder
      light: '#ECFFFF',
    },
    grey: {
      100: '#EAF1F5',
      200: '#D4E1E8',
      300: '#9BB3BF',
      400: '#606F78',
      500: '#535F66',
      600: '#535F6680',
    },
    text: {
      primary: '#535F66',
      secondary: '#9BB3BF',
      disabled: '#d4e1e8',
    },
    background: {
      default: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: ['Rubik', 'Helvetica Neue', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontSize: '2.5rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '2.25rem',
      fontWeight: 500,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 500,
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 500,
    },
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
    body1: {
    },
    body2: {
      fontSize: '0.875rem',
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 400,
    },
    button: {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
  },
  overrides: {
    MuiToolbar: {
      gutters: {
        paddingLeft: 0,
      },
    },
    MuiStepIcon: {
      root: {
        fill: '#068CA7',
      },
      active: {
        fill: '#DAA283',
      },
      completed: {
        fill: '#58EAC7',
      },
    },
    MuiSlider: {
      markLabel: {
        // fontSize: 64,
        '&[data-index="0"]': {
          transform: 'none',
        },
        '&[data-index="1"]': {
          // left: '98% !important',
          transform: 'translateX(-100%)'
        },
        // '&:last-child': {
        // },
      },
      root: {
        // color: '#068CA7',
        // height: 8
      },
      thumb: {
        // height: 24,
        // width: 24,
        // backgroundColor: '#FFF',
        // border: '2px solid currentColor',
        // marginTop: -8,
        // marginLeft: -12,
        // '&:focus, &:hover, &$active': {
        //   boxShadow: 'inherit'
        // }
      },
      active: {
      },
      valueLabel: {
        // left: 'calc(-50% + 4px)'
      },
      track: {
        // height: 8,
        // borderRadius: 4
      },
      rail: {
        // height: 8,
        // borderRadius: 4
      },
    },
    MuiButton: {
      contained: {
        // textTransform: 'none',
        // borderRadius: '6px',
      },
      containedSizeSmall: {
        height: '30px',
      },
      containedSecondary: {
        // color: 'white'
        bg: '#F96C6C',
      },
      containedPrimary: {
        color: 'white',
      },
      outlined: {
        // color: 'white',
        // borderColor: '#FFFFFF'
      },
      outlinedSecondary: {
        color: '#F96C6C',
        borderColor: '#F96C6C',
        '&:hover': {
          color: 'red',
          borderColor: 'red',
        },
      },
      text: {
        padding: 0,
        color: '#606f78',
        minWidth: 'auto',
        textTransform: 'none',
        transition: 'color .2s',
        '&:hover': {
          backgroundColor: 'transparent',
          background: 'transparent',
          color: 'black',
        },
      },
      textPrimary: {
        transition: 'color .2s',
        '&:hover': {
          backgroundColor: 'transparent',
          background: 'transparent',
          color: '#108b8a',
        },
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: '40px',
      },
    },
    MuiDialogTitle: {
      root: {
        '& h2': {
          color: '#068CA7',
          fontWeight: '400',
        },
      },
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: 700,
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: '#535F6680',
      },
    },
    MuiCardActionArea: {
      focusHighlight: {
        backgroundColor: 'white',
      },
    },
    MuiDivider: {
      light: {
        backgroundColor: '#9BB3BF',
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: '12px',
        color: '#263238',
      },
    },
    MuiPaper: {
      elevation3: {
        boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.15)',
      },
    },
    MuiCardHeader: {
      action: {
        alignSelf: 'center',
      },
    },
  },
})
