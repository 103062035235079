import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { connect, ConnectedProps } from 'react-redux'
import { RootState } from 'state-mngt/store'
import { UserDemoName } from 'state-mngt/models/user'
import { getUserDemoActiveDemo, getUserDemoActiveStep } from 'state-mngt/selectors/user-selectors'
import particleProblem1 from 'features/demo-data/images/particle-problem-1.jpg'
import particleProblem2 from 'features/demo-data/images/particle-problem-2.jpg'
import particleProblem3 from 'features/demo-data/images/particle-problem-3.jpg'
import particleProblem4 from 'features/demo-data/images/particle-problem-4.jpg'
import humidityHorrors1 from 'features/demo-data/images/humidity-horrors-1.jpg'
import humidityHorrors2 from 'features/demo-data/images/humidity-horrors-2.jpg'
import humidityHorrors3 from 'features/demo-data/images/humidity-horrors-3.jpg'
import humidityHorrors4 from 'features/demo-data/images/humidity-horrors-4.jpg'
import vocVexation1 from 'features/demo-data/images/voc-vexation-1.jpg'
import vocVexation2 from 'features/demo-data/images/voc-vexation-2.jpg'
import vocVexation3 from 'features/demo-data/images/voc-vexation-3.jpg'
import vocVexation4 from 'features/demo-data/images/voc-vexation-4.jpg'
import { CUSTOMER_HISTORY_CONTAINER_ID } from 'features/customer-drill-down/charts/device-chart/chart-utils'

const connector = connect(
  (state: RootState) => ({
    activeDemo: getUserDemoActiveDemo(state),
    activeStep: getUserDemoActiveStep(state),
  }),
  {
  },
)

type Props = ConnectedProps<typeof connector>;

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 1041,
    // maxHeight: 818,
    width: 'auto',
    height: 'auto',
  },
  image: {
    width: '100%',
    paddingBottom: '78%',
    backgroundPosition: 'top center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
}))

const getImage = (activeDemo: UserDemoName, activeStep: number): string => {
  switch (activeDemo) {
    case UserDemoName.Demo1: {
      if (activeStep === 1) {
        return particleProblem1
      }
      if (activeStep === 2) {
        return particleProblem2
      }
      if (activeStep === 3) {
        return particleProblem3
      }
      if (activeStep === 4) {
        return particleProblem4
      }
    }
    case UserDemoName.Demo2: {
      if (activeStep === 1) {
        return humidityHorrors1
      }
      if (activeStep === 2) {
        return humidityHorrors2
      }
      if (activeStep === 3) {
        return humidityHorrors3
      }
      if (activeStep === 4) {
        return humidityHorrors4
      }
    }
    case UserDemoName.Demo3: {
      if (activeStep === 1) {
        return vocVexation1
      }
      if (activeStep === 2) {
        return vocVexation2
      }
      if (activeStep === 3) {
        return vocVexation3
      }
      if (activeStep === 4) {
        return vocVexation4
      }
    }
    default: {
      return ''
    }
  }
}

const DemoCustomerHistory: React.FC<Props> = ({ activeDemo, activeStep }) => {
  const classes = useStyles()
  let image = ''

  if (activeDemo !== null && activeStep !== null) {
    image = getImage(activeDemo, activeStep)
  }

  return (
    <Box id={CUSTOMER_HISTORY_CONTAINER_ID.demoCustomer} className={classes.root}>
      <div
        className={classes.image}
        style={{
          backgroundImage: `url(${image})`,
        }}
      />
    </Box>
  )
}

export default connector(DemoCustomerHistory)
