import React, { useEffect, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { ROUTE_PATHS } from 'routes/routes'
import { ResourcesPageIntroDialog } from 'features/product-tour/resources-page/resources-page-intro-dialog'
import { useTour } from '@reactour/tour'
import { ExitTourDialog } from 'features/product-tour/exit-tour-dialog'
import { getResourcesPageTourSteps } from 'features/product-tour/resources-page/resources-page-tour-steps'
import { PRODUCT_TOUR_QUERY_KEYS, ProductTourPageName } from 'features/product-tour/pro-portal-tour'
import { TabName } from 'features/resources/sales-and-marketing/sales-and-marketing'
import { TourFinishDialog } from 'features/product-tour/tour-finish-dialog'
import { usePrevious } from 'utils/hooks/lifecycle'
import { enableDemoMode } from 'state-mngt/actions/user-actions'
import { useAppDispatch } from 'utils/hooks/reduxTypes'

export interface ResourcesPageTourProps {
  startTour: boolean;
  onEndTour?: () => void;
}


/**
 * A component that controls the UI workflow for the product tour on the resources page. It only contains UI logic.
 * @param props ResourcesPageTourProps
 */
export const ResourcesPageTour = (props: ResourcesPageTourProps): JSX.Element => {
  const { startTour, onEndTour } = props

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const {
    setIsOpen, setCurrentStep, setSteps, steps,
  } = useTour()

  const [isIntroDialogOpen, setIsIntroDialogOpen] = useState(false)
  const [isTourFinishDialogOpen, setIsTourFinishDialogOpen] = useState(false)
  const wasTourFinishDialogOpen = usePrevious(isTourFinishDialogOpen)
  const [isExitDialogOpen, setIsExitDialogOpen] = useState(false)


  // Display Intro Dialog whenever tour starts or hide when tour ends.
  useEffect(() => {
    setIsIntroDialogOpen(startTour)
  }, [startTour])

  // Activate different tabs through the steps.
  const navigateBetweenResourcesTabs = (step: number) => {
    switch (step) {
      case 1: {
        navigate({
          pathname: generatePath(ROUTE_PATHS.resources.salesAndMarketing.tab.absolute, {
            tabName: TabName.Guides,
          }),
          search: `&${PRODUCT_TOUR_QUERY_KEYS.currentPageTour}=${ProductTourPageName.Resources}`,
        })
        break
      }
      case 2: {
        navigate({
          pathname: generatePath(ROUTE_PATHS.resources.salesAndMarketing.tab.absolute, {
            tabName: TabName.Brochures,
          }),
          search: `&${PRODUCT_TOUR_QUERY_KEYS.currentPageTour}=${ProductTourPageName.Resources}`,
        })
        break
      }
      case 3: {
        navigate({
          pathname: generatePath(ROUTE_PATHS.resources.salesAndMarketing.tab.absolute, {
            tabName: TabName.Specs,
          }),
          search: `&${PRODUCT_TOUR_QUERY_KEYS.currentPageTour}=${ProductTourPageName.Resources}`,
        })
        break
      }
      case 4: {
        navigate({
          pathname: generatePath(ROUTE_PATHS.resources.salesAndMarketing.tab.absolute, {
            tabName: TabName.Cases,
          }),
          search: `&${PRODUCT_TOUR_QUERY_KEYS.currentPageTour}=${ProductTourPageName.Resources}`,
        })
        break
      }
      case 5: {
        navigate({
          pathname: generatePath(ROUTE_PATHS.resources.salesAndMarketing.tab.absolute, {
            tabName: TabName.Videos,
          }),
          search: `&${PRODUCT_TOUR_QUERY_KEYS.currentPageTour}=${ProductTourPageName.Resources}`,
        })
        break
      }
    }
  }

  // The actual tour steps only start when the user proceed from the intro dialog.
  const handleResourcesPageIntroDialogContinue = () => {
    setIsIntroDialogOpen(false)
    setSteps(getResourcesPageTourSteps(handlePreviousStep, handleNextStep, handleCloseStep))
    setCurrentStep(0)
    setIsOpen(true)
  }

  const handlePreviousStep = (previousStep?: number, currentStep?: number, totalSteps?: number) => {
    setCurrentStep((prevStep: number) => prevStep - 1)

    if (previousStep) {
      navigateBetweenResourcesTabs(previousStep)
    }
  }

  const handleNextStep = (nextStep?: number, currentStep?: number, totalSteps?: number) => {

    if (currentStep === totalSteps) {
      handleExitTourDialogExit()
      setIsTourFinishDialogOpen(true)
    } else {
      setCurrentStep((prevStep: number) => prevStep + 1)

      if (nextStep) {
        navigateBetweenResourcesTabs(nextStep)
      }
    }
  }

  const handleCloseStep = () => {
    setIsOpen(false)
    setIsIntroDialogOpen(false)
    setIsTourFinishDialogOpen(false)
    setIsExitDialogOpen(true)
  }

  const handleTourFinishDialogFinish = () => {
    navigate(ROUTE_PATHS.customers.root.absolute, {
      replace: true,
    })
  }

  const handleTourFinishDialogViewDemoData = () => {
    dispatch(enableDemoMode())
    navigate(ROUTE_PATHS.customers.root.absolute, {
      replace: true,
    })
  }

  const handleExitTourDialogContinue = () => {
    setIsExitDialogOpen(false)

    // If tour steps are greater than 0 it means tour has already started. Otherwise, the intro dialog was being displayed.
    if (steps.length > 0) {
      setIsOpen(true)
    } else {
      // It can be either the intro dialog or the tour finish dialog.
      if (wasTourFinishDialogOpen) {
        setIsTourFinishDialogOpen(true)
      } else {
        setIsIntroDialogOpen(true)
      }
    }
  }

  const handleExitTourDialogExit = () => {
    setIsExitDialogOpen(false)
    setIsIntroDialogOpen(false)

    setSteps([])
    setCurrentStep(0)
    setIsOpen(false)

    if (onEndTour) {
      onEndTour()
    }
  }


  return (
    <>
      <ResourcesPageIntroDialog
        isOpen={isIntroDialogOpen}
        onContinue={handleResourcesPageIntroDialogContinue}
        onClose={handleCloseStep} />

      <TourFinishDialog
        isOpen={isTourFinishDialogOpen}
        onFinishTour={handleTourFinishDialogFinish}
        onViewDemoData={handleTourFinishDialogViewDemoData}
        onClose={handleCloseStep} />

      <ExitTourDialog
        isOpen={isExitDialogOpen}
        onContinueTour={handleExitTourDialogContinue}
        onExitTour={handleExitTourDialogExit} />
    </>
  )
}
