import React from 'react'
import { EcosenseDevice } from 'state-mngt/models/dwelling'
import { ReportOptions } from 'state-mngt/models/report'
import { TelemetryDataType } from 'utils/constants/telemetry-data-type'

export const reportDefaultOptions: ReportOptions[] = [
  {
    type: TelemetryDataType.PM,
    isChecked: true,
    comments: '',
    hasComments: false,
    label: 'PM2.5 (Particles)',
    info: (
      <>
        <p
          style={{
            marginTop: 0,
          }}
        >
          {
            'High levels of particles can be a good indication that filtration is lacking in your home. Exposure to inhalable particles (PM10 and smaller) can trigger asthma or allergy symptoms, and can have longterm effects on both your lungs and your heart'
          }
          .
        </p>
        <p
          style={{
            marginTop: 0,
          }}
        >
          Children and older adults may be at greater risk from PM exposure.
        </p>
      </>
    ),
    causes: [
      'Frying food',
      'Sweeping & dusting',
      'Non-HEPA vacuum',
      'Pets',
      'Dirty carpets or upholstery',
      'Smoke',
      'Street pollution',
      'Overloaded or inefficient filter',
      'HVAC system needs maintenance',
    ],
    thresholdMg: [
      <span key={0}>
        (0 - 11.7ug/m<sup>3</sup>)
      </span>,
      <span key={1}>
        (11.8 - 33.2ug/m<sup>3</sup>)
      </span>,
      <span key={2}>
        (Above 33.3ug/m<sup>3</sup>)
      </span>,
    ],
    thresholdCount: [
      <span key={0}>
        (0 - 20.4M count/m<sup>3</sup>)
      </span>,
      <span key={1}>
        (20.6M - 58M count/m<sup>3</sup>)
      </span>,
      <span key={2}>
        (Above 58M count/m<sup>3</sup>)
      </span>,
    ],
    tabLabel: 'Filtration (Particles/PM2.5)',
    pieChartLabel: 'Time spent in each particle threshold*',
  },
  {
    type: TelemetryDataType.VOC,
    isChecked: true,
    comments: '',
    hasComments: false,
    label: 'tVOC (Chemicals)',
    info: (
      <>
        <p
          style={{
            marginTop: 0,
          }}
        >
          {
            'Levels of chemicals in the air are almost higher indoors. However, high levels of chemicals (or total volatile organic compounds) can be a good indication that your home may be suffering from poor ventilation.'
          }
        </p>
        <p
          style={{
            marginTop: 0,
          }}
        >
          {
            'Furniture, building materials, cleaning &amp; hobby supplies, even cosmetics can all release chemicals. Different chemicals and levels of exposure may cause a variety of mild to severe symptoms.'
          }
        </p>
      </>
    ),
    causes: [
      'Solvents, varnishes, paints, glue',
      'Wood preservatives',
      'Candles, air fresheners',
      'Fuels, automotive products',
      'Nail polish, hair spray',
      'Dry-cleaned clothing',
      'Pesticide',
      'Building materials',
      'Aerosol sprays, cleaners & disinfectants',
      'Copiers, printers, correction fluids, carbonless copy paper',
      'Upholstery, carpets',
    ],
    thresholdMg: [
      <span key={0}>
        (0 - 499ug/m<sup>3</sup>)
      </span>,
      <span key={1}>
        (500 - 1999ug/m<sup>3</sup>)
      </span>,
      <span key={2}>
        (Above 2000ug/m<sup>3</sup>)
      </span>,
    ],
    thresholdPPB: ['(0 - 211PPB)', '(212 - 849PPB)', '(Above 850PPB)'],
    tabLabel: 'Ventilation (Chemicals/tVOC)',
    pieChartLabel: 'Time spent in each chemical threshold*',
  },
  {
    type: TelemetryDataType.INDOOR_DEW_POINT,
    isChecked: true,
    comments: '',
    hasComments: false,
    label: 'Indoor Dew Point',
    info: <></>,
    causes: [],
    tabLabel: 'Indoor Dew Point',
    pieChartLabel: '',
  },
  {
    type: TelemetryDataType.HUMIDITY, // Indoor RH
    isChecked: true,
    comments: '',
    hasComments: false,
    label: 'Indoor RH (Relative Humidity)',
    info: (
      <>
        <p
          style={{
            marginTop: 0,
          }}
        >
          {
            'Relative humidity (RH) affects the well-being of humans as well as the house itself. It contributes to key concepts like "thermal comfort" and "dew point". Excess moisture can cause mold to grow. Low humidity can cause paint to chip, wood to crack, and exacerbate respiratory illnesses and skin conditions.'
          }
        </p>
        <p
          style={{
            marginTop: 0,
          }}
        >
          {
            'Levels are affected by outdoor temp and RH, indoor activities and how the house was built. It\'s important to understand that solving for high/low RH needs to take many factors into consideration.'
          }
        </p>
        <p
          style={{
            margin: 0,
          }}
        >
          {
            '*Achieving EPA-recommended 30-60% may not be achievable in certain climates. **HAVEN currently focuses on solving for high RH, as dehumidification solutions tend to be slightly less complex to solve in many regions than low RH.'
          }
        </p>
      </>
    ),
    causes: [
      'Showers',
      'Cooking',
      'Laundry',
      'Outdoor moisture',
      'Mismanaged or poorly chosen AC unit (issues include oversized system, single speed system in certain homes, AC always on, or negative pressure allowing moist outdoor air to be drawn into the home)',
      'Leaky building envelope',
    ],
    tabLabel: 'Indoor Relative Humidity (RH)',
    thresholdMg: [
      <span key={0}>(0-19%)</span>,
      <span key={1}>(20-29%)</span>,
      <span key={2}>(30 - 59%)</span>,
      <span key={3}>(60 - 69%)</span>,
      <span key={4}>(&gt;&#61; 70%)</span>,
    ],
    pieChartLabel: 'Time spent inside and outside the recommended relative humidity levels*',
  },
  {
    type: TelemetryDataType.TEMPERATURE, // Indoor Temperature
    isChecked: true,
    comments: '',
    hasComments: false,
    label: 'Indoor Temperature',
    info: (
      <>
        <p
          style={{
            marginTop: 0,
          }}
        >
          {
            'Temperature has a large effect on the "thermal comfort" within a home, along with relative humidity and airflow conditions.'
          }
        </p>
        <p
          style={{
            marginTop: 0,
          }}
        >
          If airflow within a home is hovering around 0.2m/s and relative humidity is around 50%, ASHRAE recommends
          temperatures range between 68.5<sup>o</sup>F-75<sup>o</sup>F (20<sup>o</sup>C-24<sup>o</sup>C) in cooler
          seasons, and 75<sup>o</sup>F-80.5<sup>o</sup>F (24<sup>o</sup>C -27<sup>o</sup>C) in warmer seasons.
        </p>
      </>
    ),
    causes: [],
    tabLabel: 'Temperature',
    pieChartLabel: '',
  },
  {
    type: TelemetryDataType.OUTDOOR_AQI,
    isChecked: true,
    comments: '',
    hasComments: false,
    label: 'Outdoor AQI',
    info: <></>,
    causes: [],
    tabLabel: 'Outdoor AQI',
    pieChartLabel: '',
  },
  {
    type: TelemetryDataType.OUTDOOR_DEW_POINT,
    isChecked: true,
    comments: '',
    hasComments: false,
    label: 'Outdoor Dew Point',
    info: <></>,
    causes: [],
    tabLabel: 'Outdoor Dew Point',
    pieChartLabel: '',
  },
  {
    type: TelemetryDataType.OUTDOOR_HUMIDITY,
    isChecked: true,
    comments: '',
    hasComments: false,
    label: 'Outdoor Humidity',
    info: <></>,
    causes: [],
    tabLabel: 'Outdoor Humidity',
    pieChartLabel: '',
  },
  {
    type: TelemetryDataType.OUTDOOR_TEMPERATURE,
    isChecked: true,
    comments: '',
    hasComments: false,
    label: 'Outdoor Temperature',
    info: <></>,
    causes: [],
    tabLabel: 'Outdoor Temperature',
    pieChartLabel: '',
  },
]

const defaultEcosenseOption = (device: EcosenseDevice) => ({
  type: TelemetryDataType.ECOSENSE_RADON,
  id: device.serial_number,
  isChecked: true,
  comments: '',
  hasComments: false,
  label: `Radon (${device.name})`,
  info: <></>,
  causes: [],
  tabLabel: 'Radon',
  pieChartLabel: '',
})

export { defaultEcosenseOption }
