import { useEffect, useState } from "react"
import { OutdoorAirStore, useOutdoorAirStore } from "./store"
import { OutdoorAir } from "state-mngt/models/outdoor-air"

const selectGet = store => store.get

const selectOutdoorAir = (postalCode: string, startTime: Date, endTime: Date) =>
  (store: OutdoorAirStore): OutdoorAir[] => {
    if (!postalCode) return []
    return store.data[postalCode]?.filter(x =>
      (new Date(x.timestamp) >= startTime) && (new Date(x.timestamp) <= endTime)) || []

  }

const outdoorDataTypes = ['aqi', 'dew_point', 'outdoor_humidity', 'outdoor_temperature']

const contains = dataTypes => dataTypes.some(type => outdoorDataTypes.includes(type))

const useOutdoorAir = (postalCode: string, startTime: Date, endTime: Date, dataTypes: string[]) => {
  const [loading, setLoading] = useState(false)
  const data = useOutdoorAirStore(selectOutdoorAir(postalCode, startTime, endTime))
  const get = useOutdoorAirStore(selectGet)

  useEffect(() => {
    if (!postalCode) return
    if (!startTime || !endTime) return
    if (!contains(dataTypes)) return

    get(postalCode, startTime, endTime, setLoading)
  }, [
    postalCode,
    dataTypes.join(''),
    startTime?.getTime(),
    endTime?.getTime(),
  ])

  return {
    data, loading, 
  }
}

export default useOutdoorAir
