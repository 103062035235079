import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import { CHARTS_PAGE_TOUR_SELECTORS } from 'features/product-tour';
import React, { useCallback } from 'react';
import { Device } from 'types';
import useCurrentZone from 'utils/hooks/useCurrentZone';
import useDevicesInZone from './equipment/useDevicesInZone';
import Muted from './equipment/muted';
import { capitalize } from 'utils/string-utils';

const useStyles = makeStyles((theme) => ({
  selectMenu: {
    paddingTop: 7,
    paddingBottom: 7,
  },
  chip: {
    marginLeft: theme.spacing(1),
    textTransform: 'uppercase',
  },
}))

interface CustomerSelectZoneProps {
  allZones: string[]
  setZone: any
}

const CustomerSelectZone = (props: CustomerSelectZoneProps) => {
  const {
    allZones,
    setZone,
  } = props

  const currentZone = useCurrentZone()
  const classes = useStyles()
  const devicesInZone = useDevicesInZone()

  const onChangeZone = useCallback((event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    setZone(event.target.value as string)
  }, [setZone])

  const getDisplaySelectedZoneName = (zoneName: string) => {
    if (zoneName === 'whole home') {
      return 'Whole Home'
    }

    if (zoneName === 'upstairs') {
      return 'Upstairs'
    }

    if (zoneName === 'downstairs') {
      return 'Downstairs'
    }

    return capitalize(zoneName)
  }

  const cam = Object.values(devicesInZone).filter(x => x.type === 'cam')[0]
  const cacs = Object.values(devicesInZone).filter(x => x.type === 'cac')

  return (
    <Box>
      {allZones?.length ? (
        <FormControl
          id={CHARTS_PAGE_TOUR_SELECTORS.ZONE_NAVIGATION}
          size="small"
          variant="outlined">
          <InputLabel id="dwelling-select-zone">Zone</InputLabel>
          <Select
            label="Zone"
            labelId="dwelling-select-zone"
            renderValue={(selected: unknown) => (
              <Box
                display="flex"
                alignItems="center">
                <Typography>{getDisplaySelectedZoneName(selected as string)}</Typography>
                {cam && (
                  <Chip
                    color="secondary"
                    label={`MO ${cam.plastic_serial_number}`}
                    size="small"
                    className={classes.chip}
                  />
                )}
                {cacs.length ?
                  cacs.map((device: Device) => (
                    <Chip
                      key={device.id}
                      color="secondary"
                      label={`CO ${device.plastic_serial_number}`}
                      size="small"
                      className={classes.chip}
                    />
                  )) : null}
              </Box>
            )}
            classes={{
              selectMenu: classes.selectMenu,
            }}
            onChange={onChangeZone}
            value={currentZone}
          >
            {allZones.map((zone) => (
              <MenuItem key={zone} value={zone}>
                {getDisplaySelectedZoneName(zone as string)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Typography>
          <Muted>Loading zones...</Muted>
        </Typography>
      )}
    </Box>
  )
}

export default CustomerSelectZone
