import { Reducer } from 'redux'
import {
  GetDwellingForReportAction, SetDwellingForReportAction, GET_REPORT_DWELLING, SET_REPORT_DWELLING,
} from 'state-mngt/actions/report-actions'
import { DwellingForReport } from 'state-mngt/models/dwelling'

interface ReportState {
    isLoading: boolean;
    dwelling?: DwellingForReport;
}

const initState: ReportState = {
  isLoading: true,
}

const report: Reducer<ReportState, GetDwellingForReportAction | SetDwellingForReportAction> = (state = initState, action) => {
  switch (action.type) {
    case GET_REPORT_DWELLING: {
      return {
        ...state, dwelling: action.dwelling, isLoading: false,
      }
    }
    case SET_REPORT_DWELLING: {
      return {
        ...state, dwelling: action.dwelling, isLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default report
