import {
  Box,
  Card,
  CardActionArea,
  Checkbox,
  createStyles,
  FormControlLabel,
  FormGroup,
  makeStyles,
  TextField,
  Typography,
  Button,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { ReportOptions } from 'state-mngt/models/report'
import PersonIcon from '@material-ui/icons/Person'
import HomeIcon from '@material-ui/icons/Home'
import { User } from 'state-mngt/models/user'
import CreateIcon from '@material-ui/icons/Create'
import { DwellingEditDialog } from 'features/build-report/report-controls/dwelling-edit-dialog'
import { ReportTimeControl } from 'features/build-report/report-controls/report-time-control'
import store from 'state-mngt/store'
import { Dwelling } from 'stores/dwelling'

const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
    cardHeader: {
      color: theme.palette.grey[300],
    },
    cardParameter: {
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 5,
    },
    activeCardParameter: {
      borderColor: theme.palette.primary.main,
      boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
    },
  }),
)

export const ReportControls = (props: {
  dwelling: Dwelling;
  admin: User;
  setOptionChange: (values: ReportOptions[]) => void;
  tabClick: any;
  options: ReportOptions[];
  startTime: Date;
  endTime: Date;
  setStart: any;
  setEnd: any;
  smallTabClicked: number;
}) => {
  const {
    setOptionChange,
    tabClick,
    options,
    startTime,
    endTime,
    setStart,
    setEnd,
    dwelling,
    admin,
    smallTabClicked,
  } = props
  const classes = useStyles()
  const [activeCardIndex, setActiveCardIndex] = useState<number>(0)
  const [optionsState, setOptionsState] = useState<ReportOptions[]>(options)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const dwellingFromStore = store.getState().report.dwelling
  const [initialTab, setInitialTab] = useState<boolean>(true)

  useEffect(() => {
    setActiveCardIndex(smallTabClicked) // when small tab is clicked, the big card will reflect this change

    if (!initialTab) {
      scrollToActiveCard(smallTabClicked)
    }
    if (smallTabClicked === 0 && initialTab) {
      setInitialTab(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smallTabClicked])

  useEffect(() => {
    setOptionsState(options)
  }, [options])

  /**
   * callback fn for checkbox changes
   * Send to parent element
   * @param event - React.ChangeEvent<HTMLInputElement>
   */
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newOptions = options.map((option) =>
      option.type === event.target.name ?
        {
          ...option,
          isChecked: event.target.checked,
        } :
        option,
    )
    setOptionChange(newOptions)
    setOptionsState(newOptions)
  }

  /**
   * callback fn for comments select/option changes
   * @param event - React.ChangeEvent<{ value: unknown }>
   */
  const handleCommentsSelect = (event: React.ChangeEvent<{ name?: string; checked: boolean }>) => {
    const newOptions = options.map((option) =>
      option.type === event.target.name ?
        {
          ...option,
          hasComments: event.target.checked,
        } :
        option,
    )
    setOptionChange(newOptions)
    setOptionsState(newOptions)
  }

  /**
   * callback fn for comment changes to pass back to parent
   * @param event - React.ChangeEvent<{ value: unknown }>
   */
  const handleCommentsChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const newOptions = options.map((option) =>
      option.type === event.target.name ?
        {
          ...option,
          comments: event.target.value as string,
        } :
        option,
    )
    setOptionChange(newOptions)
  }

  /**
   * callback fn for comment state changes
   * @param event - React.ChangeEvent<{ value: unknown }>
   */
  const handleCommentsStateChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const newOptions = options.map((option) =>
      option.type === event.target.name ?
        {
          ...option,
          comments: event.target.value as string,
        } :
        option,
    )
    setOptionsState(newOptions)
  }

  /**
   * callback fn for card click
   * card index clicked send to parent element
   */
  const handleTabChange = (index: number) => {
    setActiveCardIndex(index)
    tabClick(index)
  }

  /**
   * callback fn for time changed in the child component
   * @param startTime - Date
   * @param endTime - Date
   */

  /**
   * callback fn for customer detail edit dialog open click
   * Note: no db changes intended for this form
   */
  const handleDialogOpen = () => {
    setOpenDialog(true)
  }

  /**
   * for auto scroll for left tab clicks that triggers the scroll to active card
   */
  const scrollToActiveCard = (index: number) => {
    const element = document.getElementById('report-controls')
    const activeCard = document.getElementById('control-card-' + index)

    if (element && activeCard) {
      const cardTop = activeCard.offsetTop // now is set to id: report-controls-inner as 'offset parent', do not remove the style position relative
      element.scroll(0, cardTop)
    }
  }

  return (
    <Box
      id="report-controls"
      style={{
        marginBottom: '86px',
      }}
    >
      <Box
        id="report-controls-inner"
        style={{
        }}
        px={2}
        pt='32px'
      >
        {dwelling && admin && openDialog && (
          <DwellingEditDialog open={openDialog} dwelling={dwellingFromStore!} setOpen={setOpenDialog} />
        )}
        <Paper className={classes.card} elevation={3}>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
            <Typography variant="subtitle1" className={classes.cardHeader}>
              Customize customer & location details
            </Typography>
            <Button
              color="primary"
              variant="outlined"
              aria-label="edit dwelling"
              onClick={handleDialogOpen}
              endIcon={<CreateIcon />}
            >
              Edit
            </Button>
          </Box>
          <List disablePadding>
            <ListItem disableGutters>
              <ListItemIcon>
                <PersonIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary={
                  dwellingFromStore ?
                    `${dwellingFromStore.first_name} ${dwellingFromStore.last_name}` :
                    `${admin.first_name} ${admin.last_name}`
                }
              />
            </ListItem>
            <ListItem disableGutters>
              <ListItemIcon>
                <HomeIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={dwellingFromStore ? dwellingFromStore.street_1 : dwelling.street_1} />
            </ListItem>
          </List>
        </Paper>
        <Paper className={classes.card} elevation={3}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="subtitle1" className={classes.cardHeader}>
              Choose a timeframe for the report
            </Typography>
          </Box>
          <ReportTimeControl
            setStart={setStart}
            setEnd={setEnd}
            startTime={startTime}
            endTime={endTime}
          />
        </Paper>
        <Paper className={classes.card} elevation={3}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="subtitle1" className={classes.cardHeader}>
              Add Parameters
            </Typography>
          </Box>
          <Box mb={2}>
            <FormGroup row>
              {options.map((value, index) => {
                return (
                  <FormControlLabel
                    key={value.id || value.type}
                    control={
                      <Checkbox
                        color="primary"
                        checked={value.isChecked}
                        onChange={handleCheckboxChange}
                        name={value.type}
                      />
                    }
                    label={value.label}
                  />
                )
              })}
            </FormGroup>
          </Box>
          <Typography variant="subtitle1" className={classes.cardHeader} gutterBottom>
            Edit Parameters
          </Typography>
          {optionsState
            .filter((o) => o.isChecked)
            .map((option, index) => {
              return (
                <Box key={option.id || option.type} mb={2}>
                  <Card
                    className={`${classes.cardParameter} ${index === activeCardIndex ? classes.activeCardParameter : ''
                    }`}
                    id={'control-card-' + index}
                    elevation={0}
                  >
                    <CardActionArea disableRipple onClick={() => handleTabChange(index)}>
                      <Box display="flex" alignItems="center" justifyContent="space-between" px={2} py={1}>
                        <Typography color="primary" variant="subtitle1">
                          {option.label}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                          PAGE {index + 1}
                        </Typography>
                      </Box>
                      <Divider />
                      <Box px={2} py={1}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={option.hasComments}
                              onChange={handleCommentsSelect}
                              name={option.type}
                            />
                          }
                          label="Include comments"
                        />
                        <TextField
                          size="small"
                          id={option.type + '-comments'}
                          label="Add comments"
                          multiline
                          rows={4}
                          value={option.comments}
                          variant="outlined"
                          name={option.type}
                          type="text"
                          fullWidth
                          inputProps={{
                            maxLength: 501,
                          }}
                          error={option.comments.length > 500}
                          helperText={option.comments.length > 500 ? 'Maximum 500 character limit reached' : ''}
                          style={{
                            display: option.hasComments ? 'block' : 'none',
                            minWidth: '240px',
                          }}
                          margin="normal"
                          onChange={handleCommentsStateChange}
                          onBlur={handleCommentsChange}
                        />
                      </Box>
                    </CardActionArea>
                  </Card>
                </Box>
              )
            })}
        </Paper>
      </Box>
    </Box>
  )
}
