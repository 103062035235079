import React, { useEffect, useState } from 'react'
import { ExitTourDialog } from 'features/product-tour/exit-tour-dialog'
import { CustomersPageTour } from 'features/product-tour/customers-page'
import { WelcomeProPortalDialog } from 'features/product-tour/welcome-pro-portal-dialog'


export interface WelcomeProPortalTourProps {
  startTour: boolean;
  onEndTour?: () => void;
}


/**
 * A component that controls the UI workflow for the product tour. It only contains UI logic.
 * @param props WelcomeProPortalTourProps
 */
export const WelcomeProPortalTour = (props: WelcomeProPortalTourProps): JSX.Element => {
  const { startTour, onEndTour } = props

  const [startCustomersPageTour, setStartCustomersPageTour] = useState(false)
  const [isIntroDialogOpen, setIsIntroDialogOpen] = useState(false)
  const [isExitDialogOpen, setIsExitDialogOpen] = useState(false)


  // Display Intro Dialog whenever tour starts or hide when tour ends.
  useEffect(() => {
    setIsIntroDialogOpen(startTour)
  }, [startTour])

  // The actual tour only starts when the user proceed from the intro dialog.
  const handleWelcomeTourDialogShowMeAround = () => {
    setIsIntroDialogOpen(false)
    setStartCustomersPageTour(true)
  }

  const handleCloseStep = () => {
    setIsIntroDialogOpen(false)
    setIsExitDialogOpen(true)
  }

  const handleExitTourDialogContinue = () => {
    setIsExitDialogOpen(false)
    setIsIntroDialogOpen(true)
  }

  const handleExitTourDialogExit = () => {
    setIsExitDialogOpen(false)
    setStartCustomersPageTour(false)

    if (onEndTour) {
      onEndTour()
    }
  }


  return (
    <>
      {/* First page for the tour. The following routes must be opened sequentially from there. */}
      <CustomersPageTour startTour={startCustomersPageTour} onEndTour={handleExitTourDialogExit}/>

      <WelcomeProPortalDialog
        isOpen={isIntroDialogOpen}
        onSkipTheTour={handleCloseStep}
        onShowMeAround={handleWelcomeTourDialogShowMeAround}
        onClose={handleCloseStep} />

      <ExitTourDialog
        isOpen={isExitDialogOpen}
        onContinueTour={handleExitTourDialogContinue}
        onExitTour={handleExitTourDialogExit} />
    </>
  )
}
