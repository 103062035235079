import {
  Card,
  Box,
  Button,
  Typography,
  FormControl,
  MenuItem,
  Select,
  Input,
  Checkbox,
  TextField,
  FormControlLabel,
  FormGroup,
  Grid,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { hearAboutUsOptions } from 'utils/constants/company-lookup-options'
import { OnboardingProfile } from 'state-mngt/models/onboarding-profile'
import { useMixPanel } from 'features/analytics/mixpanel-provider'

export const FormSeven = (props: {
  profile: OnboardingProfile;
  onIsValidValueChange: (formNum: number, isValid: boolean) => void;
  onFormSave: (profile: OnboardingProfile) => void;
  isParentFormValid: boolean;
}) => {
  const {
    profile, isParentFormValid, onIsValidValueChange, onFormSave,
  } = props
  const { mixpanel } = useMixPanel()

  const [hearAboutUs, setHearAboutUs] = useState<string[]>([]) // required
  const [wantBeta, setWantBeta] = useState<boolean>(true)
  const [comments, setComments] = useState<string>('')

  const [btnVisible, setBtnVisible] = useState<boolean>(false)
  const [activeCard, setActiveCard] = useState<boolean>(true)

  const [otherSources, setOtherSources] = useState<string>('') // required if 'others' is checked

  useEffect(() => {
    if (hearAboutUs.length > 0) {
      if (hearAboutUs.includes('others') && otherSources.trim() === '') {
        onIsValidValueChange(5, false)
      } else {
        onIsValidValueChange(5, true)
      }
    } else {
      onIsValidValueChange(5, false)
    }
  }, [hearAboutUs, otherSources, onIsValidValueChange])

  /**
   * callback fn for 'Save' click
   * set btn visibility
   * send progress change to parent component
   * send profile to parent component
   */
  const handleSave = () => {
    if (mixpanel) {
      mixpanel.track('pp_onboardingPage_cardFive_saveButton')
    }
    const replaceOthers = hearAboutUs.filter((i) => i !== 'others')

    if (hearAboutUs.includes('others') && otherSources.trim() !== '') {
      replaceOthers.push(otherSources)
    }

    const updatedProfile: OnboardingProfile = {
      ...profile,
      wantBeta,
      comments,
      hearAboutUs: replaceOthers,
    }
    
    onFormSave(updatedProfile)
    setActiveCard(false)
  }

  /**
   * callback fn for hear about us value change
   * sets hear about us state
   * @param event - ChangeEvent
   */
  const handleHearAboutUsChange = (event: React.ChangeEvent<{ value: unknown }>, checked: boolean) => {
    const input = event.target.value as string
    const tempData = [...hearAboutUs] 
    const indexOfInput = tempData.indexOf(input)

    if (checked) {
      if (indexOfInput === -1) {
        tempData.push(input)
        setBtnVisible(true)
        setHearAboutUs(tempData)
      }
    } else {
      tempData.splice(indexOfInput, 1)
      setHearAboutUs(tempData)
    }
  }

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 280,
        width: 250,
      },
    },
    getContentAnchorEl: null,
  }

  /**
   * callback fn for other hear about us sources value change
   * sets other source state
   * @param event - ChangeEvent
   */
  const handleOtherSourcesChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const input = e.target.value as string
    setOtherSources(input)
  }

  /**
   * callback fn for want beta value change
   * sets want beta state
   * @param event - ChangeEvent
   */
  const handleBetaChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const input = event.target.value as number
    setWantBeta(input === 1 ? true : false)
  }

  /**
   * callback fn for additional comments value change
   * sets additional comments state
   * @param event - ChangeEvent
   */
  const handleCommentsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setComments(event.target.value as string)
  }

  return (
    <Card
      elevation={3}
      style={{
        width: '570px',
        padding: '2rem',
        margin: '2rem 0',
      }}
      className={activeCard ? 'onboarding-card' : 'onboarding-fade'}
    >
      {profile && (
        <>
          <Typography variant="caption">Please select at least one checkbox to proceed*</Typography>
          <Box mt={1} mb={2} display="flex">
            <Typography><b>I heard about HAVEN IAQ via</b></Typography>
          </Box>
          <FormControl component="fieldset" variant="standard">
            <FormGroup>
              <Grid container spacing={2}>
                {hearAboutUsOptions.map((option, index) => (
                  <Grid item xs={12} sm={3} md={6} key={index}>
                    <Box display="flex">
                      <FormControlLabel
                        key={hearAboutUsOptions[index].value}
                        control={
                          <Checkbox 
                            checked={hearAboutUs.includes(option.value)} 
                            onChange={handleHearAboutUsChange} 
                            name={option.value}
                            value={option.value}
                          />
                        }
                        label={hearAboutUsOptions[index].label}
                        style={{
                          marginRight: 0,
                          padding: 0,
                        }}
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </FormGroup>
          </FormControl>
          {hearAboutUs.includes('others') && (
            <Box mt={2} display="flex">
              <Typography>Information source*</Typography>
              <TextField
                id="onboarding-sources-others"
                required
                size="small"
                placeholder="List source(s)"
                defaultValue={otherSources.trim()}
                error={otherSources.trim() === ''}
                style={{
                  marginLeft: '1rem',
                  width: '280px',
                }}
                helperText={otherSources.trim() === '' ? 'Source is required if "Others" is checked' : ''}
                onChange={handleOtherSourcesChange}
              />
            </Box>
          )}

          <Box mt={2} mb={2} display="flex">
            <Typography><b>I</b></Typography>
            <FormControl
              size="small"
              style={{
                marginLeft: '1rem',
                width: '80px',
              }}
            >
              <Select
                id="onboarding-beta"
                defaultValue={1}
                onChange={handleBetaChange}
                input={<Input />}
                MenuProps={MenuProps}
              >
                <MenuItem value={1}><b>am</b></MenuItem>
                <MenuItem value={0}><b>am not</b></MenuItem>
              </Select>
            </FormControl>
            &nbsp;
            <Typography><b>interested in becoming a HAVEN beta tester</b></Typography>
          </Box>

          <Box mt={1}>
            <Typography>Anything else you want to add?</Typography>
            <TextField
              variant="outlined"
              fullWidth
              value={comments}
              multiline
              rows={3}
              rowsMax={8}
              onChange={handleCommentsChange}
            />
          </Box>
        </>
      )}

      {btnVisible && activeCard && (
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleSave}
            disabled={!isParentFormValid || !hearAboutUs.length}
          >
            Save
          </Button>
        </Box>
      )}
    </Card>
  )
}
