import React from 'react'
import InfoIcon from '@material-ui/icons/Info'
import InfoTooltip from 'ui/info-tooltip'
import WarningIcon from '@material-ui/icons/Warning'
import { Box, Divider } from '@material-ui/core'
import WarningTooltip from 'ui/warning-tooltip'
import { theme } from 'theme'

// for pm units on Account page
export const pmTooltip = (
  <InfoTooltip
    placement="right-start"
    arrow
    title={
      <>
        <span>
          <b>
            count/centimeter<sup>3</sup>
          </b>{' '}
          — This is the average number of inhalable particles counted per cubic meter of air that is sampled.
        </span>
        <p>
          <b>
            μg/meter<sup>3</sup>
          </b>{' '}
          — Micrograms per cubic meter (μg/m<sup>3</sup>) refers to the average concentration of 2.5 micron (PM2.5)
          particles detected in a cubic meter of air.{' '}
        </p>
        <p>
          Micrograms per cubic meter is the standard measurement used by the EPA for producing the Air Quality Index
          (AQI).
        </p>
      </>
    }
  >
    <InfoIcon
      style={{
        transform: 'translate(.25rem, .25rem)',
      }}
      fontSize="small"
    />
  </InfoTooltip>
)

// for voc units on Account page
export const vocTooltip = (
  <InfoTooltip
    placement="right-start"
    arrow
    title={
      <>
        <span>
          <b>ppb</b> — This number represents the average parts per billion (ppb) of volatile organic compounds (VOC)
          detected in a sample of air.{' '}
        </span>
        <p>
          <b>
            ug/meter<sup>3</sup>
          </b>{' '}
          — Micrograms per cubic meter refers to the concentration of volatile organic compounds (VOC) detected in a
          sample of air.
        </p>
      </>
    }
  >
    <InfoIcon
      style={{
        transform: 'translate(.25rem, .25rem)',
      }}
      fontSize="small"
    />
  </InfoTooltip>
)

// for temperature units on Account page
export const temperatureTooltip = (
  <InfoTooltip
    placement="right-start"
    arrow
    title={
      <>
        <span>
          <b>°C (Celcius)</b> — The unit of measuring temperature commonly used in Canada (the ol' homeland of HAVEN!)
          and the rest of the world.
        </span>
        <p>
          <b>°F (Fahrenheit)</b> — The unit of measuring temperature commonly used in the USA (sorry guys, you're pretty
          much on your own with this bizarre temperature scale!)
        </p>
      </>
    }
  >
    <InfoIcon
      style={{
        transform: 'translate(.25rem, .25rem)',
      }}
      fontSize="small"
    />
  </InfoTooltip>
)

// for Company details on Company page
export const companyDetailsTooltip = (
  <InfoTooltip
    placement="right-start"
    arrow
    title="This is where you can manage your service company's details. This information will be displayed to your
    customers inside the HAVEN app, which creates more brand awareness for your company, and allows your customers to
    get in touch with you when they need help with their HVAC systems."
  >
    <InfoIcon
      style={{
        margin: '.4rem',
      }}
      fontSize="small"
    />
  </InfoTooltip>
)

// for roles on Company page
export const roleTooltip = (
  <InfoTooltip
    placement="top-end"
    arrow
    title={
      <>
        <span>
          <b>Technician</b> — Technician permissions allow you to access to the HAVEN Pro features within the HAVEN
          mobile app. This means they can create new customer accounts, install HAVEN devices and connect them to WiFi.
          They can also log into the HAVEN Pro portal to access the Training and Sales tools.
        </span>
        <p>
          <b>Analyst</b> — Analyst permissions allow you to log into the HAVEN Pro Web Portal to monitor and interpret
          their customers' indoor air quality data.
        </p>
        <p>
          <b>Manager </b> — One team member can be granted Manager permissions, which allow them to edit details about
          the service company and manage team members.
        </p>
      </>
    }
  >
    <InfoIcon
      style={{
        transform: 'translate(.25rem, .25rem)',
      }}
      fontSize="small"
    />
  </InfoTooltip>
)

// for certification on Company page
export const certificationTooltip = (
  <InfoTooltip
    placement="top-end"
    arrow
    title="In order to maintain HAVEN Pro status, we require service companies to stay up-to-date on the newest HAVEN updates via the HAVEN Pro Certification Training modules. When a new module is released, the Admin user of the service company will have 30 days to review the content and take a short quiz."
  >
    <InfoIcon
      style={{
        transform: 'translate(.25rem, .25rem)',
      }}
      fontSize="small"
    />
  </InfoTooltip>
)

// for customer details on Customer drill down page
export const customerDetailsTooltip = (
  <InfoTooltip
    placement="right-start"
    arrow
    title={
      <Box>
        <p>
          Your customer's contact info ready for when you want to contact them about observations you've made in the
          data or even just their next maintenance visit.
        </p>
      </Box>
    }
  >
    <InfoIcon
      style={{
        margin: '.4rem',
      }}
      fontSize="small"
    />
  </InfoTooltip>
)

// for history (chart) on Customer drill down page
export const customerHistoryTooltip = (
  <InfoTooltip
    placement="right-start"
    arrow
    title={
      <Box>
        <p>
          Dive into your customer's historical data for more advanced troubleshooting and remote diagnostics. This is
          where you can start to recognize patterns in their air quality that you can use to inform your remediation
          strategies and maintenance.
        </p>
      </Box>
    }
  >
    <InfoIcon
      style={{
        margin: '.4rem',
      }}
      fontSize="small"
    />
  </InfoTooltip>
)

// for charts tab on Customer drill down page
export const devicesTooltip = (
  <InfoTooltip
    placement="right-start"
    arrow
    title={
      <Box>
        <p>
          These are the HAVEN Central Air Monitors that are associated with this specific dwelling. Any Controller or
          equipment connected to the same system will show up when a Monitor is selected.
        </p>
        <p>
          If a customer has more than one Monitor installed at this location, you can select which device you want to
          see the data for here.
        </p>
      </Box>
    }
  >
    <InfoIcon
      style={{
        margin: '.4rem',
      }}
      fontSize="small"
    />
  </InfoTooltip>
)

// for equipment tab on Customer drill down page
export const equipmentTooltip = (
  <InfoTooltip
    placement="right-start"
    arrow
    title={
      <Box>
        <p>Details about the HAVEN Central Air Monitor you currently have selected.</p>
      </Box>
    }
  >
    <InfoIcon
      style={{
        margin: '.4rem',
      }}
      fontSize="small"
    />
  </InfoTooltip>
)

// for current status on Customer drill down page
export const currentStatusTooltip = (
  <InfoTooltip
    placement="right-start"
    arrow
    title={
      <Box>
        <div>
          <p>
            The Current Status section updates every minute based on the Monitor’s real-time readings. At a glance you
            can an overview of:
          </p>
          <ul
            style={{
              marginLeft: '1rem',
            }}
          >
            <li>When HVAC fan is on or off</li>
            <li>If off, when did it last run</li>
            <li>If Monitor is picking up any spikes in PM2.5 and tVOC</li>
            <li>Whether the relative humidity and temperature levels are within acceptable ranges</li>
          </ul>
          <Divider />
          <br />
          <p>
            <b>Please note:</b>
          </p>
          <p>
            When the HVAC system is INACTIVE, PM2.5 data will be unavailable. tVOC, RH and temp will only reflect the
            ambient environment inside the duct — not the entire zone that the HVAC system is servicing.
          </p>
        </div>
      </Box>
    }
  >
    <InfoIcon
      style={{
        margin: '.4rem',
      }}
      fontSize="small"
    />
  </InfoTooltip>
)

// for current status (inactive warning) on Customer drill down page
export const currentStatusWarningTooltip = (
  <WarningTooltip
    placement="right-start"
    arrow
    title={
      <Box>
        <p>When HVAC fan is inactive, data shouldn't be used to represent the whole home's air quality.</p>
      </Box>
    }
  >
    <WarningIcon
      style={{
        marginLeft: '.4rem',
        color: theme.palette.warning.main,
      }}
      fontSize="small"
    />
  </WarningTooltip>
)
