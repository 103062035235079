import {
  VentilatingDehumidifierAutomationActivation,
  VentilatingDehumidifierAutomations,
} from 'state-mngt/models/automation'
import { CancelableFetch } from 'state-mngt/models/http'
import httpService from 'state-mngt/services/data/http-service'

// legacy
class AutomationService {
  /**
   * calls /automation/ventilating_dehumidifiers?deviceId=deviceId&equipmentId=equipmentId
   * @param deviceId number
   * @param equipmentId number
   */
  getVentilatingDehumidifierAutomations = (deviceId?: number, equipmentId?: number)
    : CancelableFetch<VentilatingDehumidifierAutomations> | Promise<VentilatingDehumidifierAutomations> => {
    const paramUri = new URLSearchParams()

    if (deviceId)
      paramUri.append('device_id', deviceId.toString(10))

    if (equipmentId)
      paramUri.append('equipment_id', equipmentId.toString(10))

    return httpService.getWithAbort('/automation/ventilating_dehumidifiers' + (paramUri.toString() ? '?' + paramUri.toString() : ''))
  }

  /**
   * calls /automation/ventilating_dehumidifier/{id}
   * @param id The automation id to be queried for.
   */
  getVentilatingDehumidifierAutomationActivation = (id: number)
    : CancelableFetch<VentilatingDehumidifierAutomationActivation> | Promise<VentilatingDehumidifierAutomationActivation> => {

    return httpService.getWithAbort('/automation/ventilating_dehumidifier/' + id.toString())
  }

  /**
   * calls /automation/ventilating_dehumidifier/{id} endpoint
   * @param automationId number
   */
  updateVentilatingDehumidifierAutomation = async (automationId: number, updatedAutomation: object): Promise<void> => {
    return httpService.post('/automation/ventilating_dehumidifier/' + automationId, updatedAutomation)
  }
}

export const automationService = new AutomationService()
