import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import {
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@material-ui/core'
import { ServiceCompany } from 'state-mngt/models/serviceCompany'
import { Autocomplete } from '@material-ui/lab'
import { ErrorAutoHideWithDismissAction, SuccessAutoHideWithDismissAction } from 'ui/custom-snackbar-provider'
import { Country, provinceOptions, stateOptions } from 'utils/constants/province-lookup-options'
import { updateCompanyDetails } from 'state-mngt/actions/company-actions'
import classes from 'features/preferences/company/company-edit-dialog/company-edit-dialog.module.scss'
import PhoneInput from 'ui/phone-input'
import EmailInput from 'ui/email-input'
import PostalCodeInput from 'ui/postal-code-input'
import { useMixPanel } from 'features/analytics/mixpanel-provider'
import { selectIsCompanyDetailsLoading } from 'state-mngt/selectors/company-selectors'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxTypes'


const CompanyEditDialogTemplate = (props: { companyDetails: ServiceCompany; open: boolean; setOpen: any;}) => {
  const { open, companyDetails, setOpen } = props

  const { mixpanel } = useMixPanel()
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const isCompanyLoading = useAppSelector(selectIsCompanyDetailsLoading)

  /**
   * DECLARED HERE SO IT CAN BE CALLED DURING STATE INITIALIZATION. I did not want to use hoisting.
   * Validate the selected province input depending on the country selection.
   * @param province The current selected province. Empty input or inputs that do not match province options array for
   * the specific countries are invalid.
   * @return True if validation is ok. False otherwise.
   */
  const validateProvinceSelection = (province: string): boolean => {
    if (!country || !province.trim()) {
      return false
    }

    if (country === Country.CANADA) {
      return provinceOptions.some(p => province === p.label)
    }

    if (country === Country.AMERICA) {
      return stateOptions.some(s => province === s.label)
    }

    return true
  }

  const [name, setName] = useState<string>(companyDetails.name) // required
  const [email, setEmail] = useState<string>(companyDetails.email) // required
  const [phone, setPhone] = useState<string>(companyDetails.phone) // required
  const [street_1, setStreet1] = useState<string>(companyDetails.street_1) // required
  const [street_2, setStreet2] = useState<string>(companyDetails.street_2)
  const [city, setCity] = useState<string>(companyDetails.city) // required
  const [province, setProvince] = useState<string>(companyDetails.province) // required
  const [country, setCountry] = useState<Country>(companyDetails.country) // required
  const [postalCode, setPostalCode] = useState<string>(companyDetails.postal_code) // required

  const [nameRequiredError, setNameRequiredError] = useState<boolean>(!companyDetails.name.trim())
  const [emailRequiredError, setEmailRequiredError] = useState<boolean>(!companyDetails.email)
  const [phoneRequiredError, setPhoneRequiredError] = useState<boolean>(!companyDetails.phone)
  const [streetRequiredError, setStreetRequiredError] = useState<boolean>(!companyDetails.street_1.trim())
  const [cityRequiredError, setCityRequiredError] = useState<boolean>(!companyDetails.city.trim())
  const [provinceRequiredError, setProvinceRequiredError] = useState<boolean>(
    !validateProvinceSelection(companyDetails.province.trim()),
  )
  const [countryRequiredError, setCountryRequiredError] = useState<boolean>(!companyDetails.country.trim())
  const [postalCodeRequiredError, setPostalCodeRequiredError] = useState<boolean>(!companyDetails.postal_code)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [enableSaveButton, setEnableSaveButton]  = useState<boolean>(false)


  // It will close this dialog if the button save has been clicked or if the company details is not loading.
  useEffect(()=> {
    if(isSaving && !isCompanyLoading){
      setOpen(false)
    }
  }, [isCompanyLoading, isSaving, setOpen])

  // It validates the save button based on error on required fields.
  useEffect(() => {
    if (nameRequiredError || emailRequiredError || phoneRequiredError || streetRequiredError || cityRequiredError ||
      provinceRequiredError || countryRequiredError || postalCodeRequiredError) {
      setEnableSaveButton(false)
    } else {
      setEnableSaveButton(true)
    }
  },
  [
    nameRequiredError, emailRequiredError, phoneRequiredError, streetRequiredError, cityRequiredError,
    provinceRequiredError, countryRequiredError, postalCodeRequiredError,
  ],
  )

  /**
   * Callback for name value change and sets name state and error if not validated.
   * @param event - ChangeEvent
   */
  const handleNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const input = event.target.value as string
    setName(input)
    setNameRequiredError(!input.trim())
  }

  /**
   * Callback for street value change and sets street state and error if not validated.
   * @param event - ChangeEvent
   */
  const handleStreetChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const input = event.target.value as string
    setStreet1(input)
    setStreetRequiredError(!input.trim())
  }

  /**
   * Callback for city value change and sets city state and error if not validated.
   * @param event - ChangeEvent
   */
  const handleCityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const input = event.target.value as string
    setCity(input)
    setCityRequiredError(!input.trim())
  }

  /**
   * Change province/state options based on 'country' selection
   * @param countryParam - string
   */
  const selectProvinceOptions = (countryParam: string): string[] => {
    if (!countryParam) {
      return []
    }

    if (countryParam === Country.CANADA) {
      return provinceOptions.map(p => p.label)
    }
    if (countryParam === Country.AMERICA) {
      return stateOptions.map(s => s.label)
    }

    return []
  }

  /**
   * Callback for province value change and sets province state and error if not validated.
   * @param event - ChangeEvent
   * @param value - string
   */
  const handleProvinceChange = (event: React.ChangeEvent<{}>, value: string | null) => {
    const input = value || ''
    setProvince(input)
    const isSelectionValid = validateProvinceSelection(input.trim())
    setProvinceRequiredError(!isSelectionValid)
  }

  /**
   * Change
   * @param event - ChangeEvent
   */
  /**
   * Callback for country value change and sets country state and error if not validated.
   * The province/state value options will also be changed when user toggle countries.
   * Note since in db there are values that are not valid countries, they will appear as option but not trigger any
   * selection changes
   * @param event - ChangeEvent
   */
  const handleCountryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const input = event.target.value as Country
    setCountry(input)
    setCountryRequiredError(!input.trim())

    setProvince('')
    setProvinceRequiredError(true)
  }

  /**
   * Closes modal when clicking 'cancel' button
   */
  const handleCancel = () => {
    if (mixpanel) {
      mixpanel.track('pp_companyPage_cancelCompanyEditButton')
    }

    setOpen(false)
  }

  /**
   * Save updated details, call API update method with company id and details
   */
  const handleSave = async () => {
    if (mixpanel) {
      mixpanel.track('pp_companyPage_saveCompanyEditButton')
    }

    const updatedDetails = {
      id: companyDetails.id,
      name,
      email,
      phone,
      logo: companyDetails.logo,
      street_1: street_1,
      street_2: street_2,
      city,
      province,
      country,
      postal_code: postalCode,
      logo_url: companyDetails.logo_url,
    }

    setIsSaving(true)
    const wasCompayDetailsUpdated = await dispatch(updateCompanyDetails(companyDetails.id, updatedDetails))

    if (wasCompayDetailsUpdated)
      enqueueSnackbar('Company details updated successfully!', SuccessAutoHideWithDismissAction())
    else
      enqueueSnackbar('Company details updated failed!', ErrorAutoHideWithDismissAction())
  }


  return (
    <Dialog aria-labelledby="Company Edit" open={open} >
      { !isSaving && (
        <>
          <DialogContent className={classes.dialogContainer}>
            <form style={{
              marginTop: '20px',
            }}>
              <Grid container spacing={3}>
                <Typography color="primary" variant="h5" gutterBottom>Edit your service company details</Typography>
                <Grid item sm={12}>
                  <TextField id="company-name"
                    fullWidth
                    label="Name"
                    required
                    defaultValue={name}
                    error={nameRequiredError}
                    helperText={nameRequiredError ? 'Name is required' : ''}
                    onChange={handleNameChange}
                  />
                </Grid>
                <Grid item sm={12}>
                  <TextField id="company-address-1"
                    fullWidth
                    label="Address Line 1"
                    required
                    defaultValue={street_1}
                    error={streetRequiredError}
                    helperText={streetRequiredError ? 'Address Line 1 is required' : ''}
                    onChange={handleStreetChange}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField id="company-address-2"
                    fullWidth
                    label="Address Line 2"
                    defaultValue={street_2}
                    onChange={e => setStreet2(e.target.value)}
                  />
                </Grid>
                <Grid item sm={6}>
                  {country && (
                    <PostalCodeInput
                      name="company-zipcode"
                      id="company-zipcode"
                      label={country === Country.AMERICA ? 'Zip' : 'Postal Code'}
                      country={country}
                      inputValue={postalCode}
                      touched={true}
                      fullWidth={true}
                      onPostalCodeValueChange={setPostalCode}
                      onPostalCodeErrorChange={setPostalCodeRequiredError}
                    />
                  )}
                </Grid>
                <Grid item sm={4}>
                  <FormControl fullWidth>
                    <InputLabel id="company-country-label">Country</InputLabel>
                    <Select
                      labelId="company-country-label"
                      id="company-country"
                      value={country}
                      error={countryRequiredError}
                      onChange={handleCountryChange}>
                      <MenuItem value={Country.CANADA}>{Country.CANADA}</MenuItem>
                      <MenuItem value={Country.AMERICA}>{Country.AMERICA}</MenuItem>
                      {country !== Country.CANADA && country !== Country.AMERICA &&
                        <MenuItem value={country}>{country}</MenuItem>
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={4}>
                  <Autocomplete
                    id="company-province"
                    options={selectProvinceOptions(country)}
                    style={{
                      transform: 'translateY(-8px)',
                    }}
                    value={province}
                    size="small"
                    onChange={(handleProvinceChange)}
                    renderInput={(params) => (
                      <TextField {...params}
                        label={country !== Country.AMERICA ? 'Province' : 'State'}
                        required
                        margin="normal"
                        variant="outlined"
                        error={provinceRequiredError}
                        helperText={provinceRequiredError ? 'Selection is required' : ''}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={4}>
                  <TextField id="company-city"
                    fullWidth
                    label="City"
                    required
                    defaultValue={city}
                    error={cityRequiredError}
                    helperText={cityRequiredError ? 'City is required' : ''}
                    onChange={handleCityChange}
                  />
                </Grid>
                <Grid item sm={6}>
                  <EmailInput
                    name="company-office-email"
                    id="company-office-email"
                    inputValue={email}
                    touched={true}
                    fullWidth={true}
                    onEmailErrorChange={setEmailRequiredError}
                    onEmailValueChange={setEmail}
                  />
                </Grid>
                <Grid item sm={6}>
                  <PhoneInput
                    name="company-office-phone"
                    id="company-office-phone"
                    inputValue={phone}
                    fullWidth={true}
                    touched={true}
                    onPhoneValueChange={setPhone}
                    onPhoneErrorChange={setPhoneRequiredError}
                  />
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={handleCancel}>Cancel</Button>
            <Button
              color="secondary"
              disabled={!enableSaveButton}
              variant="contained"
              onClick={handleSave}
              autoFocus
              style={{
                margin: '1rem',
              }}
            >
              SAVE
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default CompanyEditDialogTemplate
