import { User } from "state-mngt/models/user"
import { create } from "zustand"

interface UserStore { [key: number]: User }

const useUserStore = create<UserStore>((set, get) => ({
}))

const selectUser = (id: number | null) => (store: UserStore) => id ? store[id] : null

export {
  selectUser,
}

export default useUserStore
