import { Dwelling, DwellingContent, DwellingPermission } from 'state-mngt/models/dwelling'
import { getMidnightDate, getTodayMidnightDate, minusDays } from 'utils/time-utils'
import {
  CacChannel, Device, DeviceContent, DeviceInstallType, DeviceType,
} from 'state-mngt/models/device'
import { DeviceTriggerType } from 'state-mngt/models/automation'
import { User } from 'state-mngt/models/user'
import { UserPreferencesEnum } from 'utils/constants/preference-enums'


export const PRODUCT_TOUR_FAKE_USER_ID = -100
export const PRODUCT_TOUR_FAKE_DWELLING_ID = -100
const PRODUCT_TOUR_FAKE_MONITOR_DEVICE_ID = -1
const PRODUCT_TOUR_FAKE_CONTROLLER_DEVICE_ID = -2

export enum ProductTourPageName {
  Customers = 'customers',
  Charts = 'charts',
  Equipment = 'equipment',
  CustomerDetails = 'customerDetails',
  Resources = 'resources',
}

export const PRODUCT_TOUR_QUERY_KEYS = {
  currentPageTour: 'currentPageTour',
}


export function isProductTourFakeUser(userId: number) {
  return userId === PRODUCT_TOUR_FAKE_USER_ID
}

export function isProductTourFakeDwelling(dwellingId: number) {
  return dwellingId === PRODUCT_TOUR_FAKE_DWELLING_ID
}

export function isProductTourFakeDevice(deviceId: number) {
  return deviceId < 0
}


export function getProductTourFakeUser(): User {
  return {
    id: 1,
    email: 'pat.parker@gmail.com',
    phone: '778-867-4295',
    beta: false,
    pm_unit: UserPreferencesEnum.PM_COUNT,
    voc_unit: UserPreferencesEnum.VOC_COUNT,
    temperature_unit: UserPreferencesEnum.FAHRENHEIT,
    pro_portal_demo_enabled: false,
    pro_portal_tour_enabled: true,
    pro_portal_tour_notification_shown: true,
  }
}

export function getProductTourFakeDwelling(): Dwelling {
  const devices = getDevices()

  return {
    id: PRODUCT_TOUR_FAKE_DWELLING_ID,
    name: 'Pat Parker',
    create_timestamp: getTodayMidnightDate().toISOString(),
    admin_id: null,
    customer_name: null,
    first_name: 'Pat',
    last_name: 'Parker',
    address: '3439 Terra Vita pl',
    street_1: '944 Daisy Street',
    street_2: '',
    city: 'Vancouver',
    province: 'BC',
    postal_code: 'V5Y3A9',
    country: 'Canada',
    cam_ids: getCamIds(devices),
    cac_ids: getCacIds(devices),
    poor_average_time_percentage: 0,
    poor_pm_time_percentage: 0,
    poor_voc_time_percentage: 0,
    poor_humidity_time_percentage: 0,
    device_status_good: true,
    filter_status_good: true,
    isDemo: false,
  }
}

export function getProductTourFakeDwellingContent(): DwellingContent {
  return {
    devices: getDevices(),
    equipment: [],
    ecosense_devices: [],
  }
}

function getDevices(): Device[] {
  return [
    {
      id: PRODUCT_TOUR_FAKE_MONITOR_DEVICE_ID,
      type: DeviceType.Cam,
      name: 'Haven Monitor',
      dwelling_id: PRODUCT_TOUR_FAKE_DWELLING_ID,
      install_timestamp: getMidnightDate(minusDays(getTodayMidnightDate(), 60)).toISOString(),
      hub_id: '',
      hub_device_id: '',
      plastic_serial_number: '0000-0127',
      pcb_serial_number: '',
      zone: 'Downstairs',
      equipment_id: null,
    },
    {
      id: PRODUCT_TOUR_FAKE_CONTROLLER_DEVICE_ID,
      type: DeviceType.Cac,
      name: 'Haven Controller',
      dwelling_id: PRODUCT_TOUR_FAKE_DWELLING_ID,
      install_timestamp: new Date().toISOString(),
      hub_id: '',
      hub_device_id: '',
      plastic_serial_number: '3000-0004',
      pcb_serial_number: '',
      zone: 'Downstairs',
      equipment_id: null,
    },
  ]
}


export function getProductTourFakeDwellingPermission(): DwellingPermission[] {
  return [
    {
      user_id: PRODUCT_TOUR_FAKE_USER_ID,
      dwelling_id: PRODUCT_TOUR_FAKE_DWELLING_ID,
      permission: 0,
    },
  ]
}

export function getProductTourFakeDeviceContent(): DeviceContent {
  return {
    cam_device_id: PRODUCT_TOUR_FAKE_MONITOR_DEVICE_ID,
    dwelling_id: PRODUCT_TOUR_FAKE_DWELLING_ID,
    equipment_id: null,
    hub_device_id: '',
    hub_id: '',
    id: PRODUCT_TOUR_FAKE_CONTROLLER_DEVICE_ID,
    install_timestamp: '2022-03-29T17:28:47.064696+00:00',
    install_type: DeviceInstallType.PersonalUseProgram,
    name: '',
    pcb_serial_number: '',
    plastic_serial_number: '3000-1304',
    type: DeviceType.Cac,
    zone: 'Downstairs',
    zone_size: 2000,
  }
}

export function getProductTourFakeDeviceCacChannels(): CacChannel[] {
  return [
    {
      id: -201,
      index: 0,
      active: false,
      equipment_id: -1,
      active_high: false,
      trigger_type: DeviceTriggerType.Schedule,
      override_timeout: null,
      scheduler_disable_timestamp: null,
      scheduler_active: false,
      iaq_controls_active: false,
      start_timestamp: '',
      end_timestamp: '',
    },
    {
      id: -202,
      index: 1,
      active: false,
      equipment_id: -1,
      active_high: false,
      trigger_type: DeviceTriggerType.Schedule,
      override_timeout: null,
      scheduler_disable_timestamp: null,
      scheduler_active: false,
      iaq_controls_active: false,
      start_timestamp: '',
      end_timestamp: '',
    },
  ]
}

function getCamIds(devices: Device[]): number[] {
  return devices.filter((device) => device.type === DeviceType.Cam).map(({ id }) => id)
}

function getCacIds(devices: Device[]): number[] {
  return devices.filter((device) => device.type === DeviceType.Cac).map(({ id }) => id)
}
