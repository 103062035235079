import { AnyAction } from 'redux'
import { ThunkAction } from 'redux-thunk'
import {
  VentilatingDehumidifierAutomationActivation,
  VentilatingDehumidifierAutomations,
} from 'state-mngt/models/automation'
import { HttpError, RequestsAdapter } from 'state-mngt/models/http'
import { ActionPayload } from 'state-mngt/models/redux'
import { automationService } from 'state-mngt/services/automation-service'
import { RootState } from 'state-mngt/store'

export enum ActionType {
  SetVentilatingDehumidifierAutomations
    = 'automation/SET_VENTILATING_DEHUMIDIFIER_AUTOMATIONS',

  SetVentilatingDehumidifierAutomationsActivationDetails
    = 'automation/SET_VENTILATING_DEHUMIDIFIER_AUTOMATIONS_ACTIVATION_DETAILS',

  SetLoadingVentilatingDehumidifierAutomations
    = 'automation/SET_LOADING_VENTILATING_DEHUMIDIFIER_AUTOMATIONS',

  SetLoadingVentilatingDehumidifierAutomationsActivationDetails
    = 'automation/SET_LOADING_VENTILATING_DEHUMIDIFIER_AUTOMATIONS_ACTIVATION_DETAILS',

  CleanVentilatingDehumidifierAutomationsAndDetails = 'automation/CLEAN_VENTILATING_DEHUMIDIFIER_AUTOMATIONS_AND_DETAILS'
}

export type SetVentilatingDehumidifierAutomationsAction
  = ActionPayload<ActionType.SetVentilatingDehumidifierAutomations, VentilatingDehumidifierAutomations>;

export type SetVentilatingDehumidifierAutomationsActivationDetailsAction =
  ActionPayload<ActionType.SetVentilatingDehumidifierAutomationsActivationDetails, VentilatingDehumidifierAutomationActivation[]>;

export type SetLoadingVentilatingDehumidifierAutomations =
  ActionPayload<ActionType.SetLoadingVentilatingDehumidifierAutomations, boolean>;

export type SetLoadingVentilatingDehumidifierAutomationsActivationDetails =
  ActionPayload<ActionType.SetLoadingVentilatingDehumidifierAutomationsActivationDetails, boolean>;

export interface CleanVentilatingDehumidifierAutomationsAndDetails {
  type: ActionType.CleanVentilatingDehumidifierAutomationsAndDetails
}


export const setVentilatingDehumidifierAutomations = (automations: VentilatingDehumidifierAutomations)
  : SetVentilatingDehumidifierAutomationsAction => ({
  type: ActionType.SetVentilatingDehumidifierAutomations,
  payload: automations,
})

export const setVentilatingDehumidifierAutomationsActivationDetails =
  (automationsActivationDetails: VentilatingDehumidifierAutomationActivation[])
  : SetVentilatingDehumidifierAutomationsActivationDetailsAction => ({
    type: ActionType.SetVentilatingDehumidifierAutomationsActivationDetails,
    payload: automationsActivationDetails,
  })

export const setLoadingVentilatingDehumidifierAutomations = (isLoading: boolean)
  : SetLoadingVentilatingDehumidifierAutomations => ({
  type: ActionType.SetLoadingVentilatingDehumidifierAutomations,
  payload: isLoading,
})

export const setLoadingVentilatingDehumidifierAutomationsActivationDetails = (isLoading: boolean)
  : SetLoadingVentilatingDehumidifierAutomationsActivationDetails => ({
  type: ActionType.SetLoadingVentilatingDehumidifierAutomationsActivationDetails,
  payload: isLoading,
})

export const cleanVentilatingDehumidifierAutomationsAndDetails = (): CleanVentilatingDehumidifierAutomationsAndDetails => ({
  type: ActionType.CleanVentilatingDehumidifierAutomationsAndDetails,
})


type ThunkResult<R = void> = ThunkAction<R, RootState, undefined, AnyAction>;


// export const getVentilatingDehumidifierAutomations =
//   (requestsAdapter: RequestsAdapter, deviceId?: number, equipmentId?: number): ThunkResult<void> =>
//     async (dispatch, getState) => {
//       try {
//         dispatch(setLoadingVentilatingDehumidifierAutomations(true));

//         const automations = await requestsAdapter
//           .make(automationService.getVentilatingDehumidifierAutomations(deviceId, equipmentId))
//           .catch(requestsAdapter.throwOnAbort);

//         if (automations)
//           dispatch(setVentilatingDehumidifierAutomations(automations));

//         dispatch(setLoadingVentilatingDehumidifierAutomations(false));
//       } catch {
//         // aborted
//         dispatch(setLoadingVentilatingDehumidifierAutomations(false));
//       }
//     };

// export const getVentilatingDehumidifierAutomationActivationDetails =
//   (requestsAdapter: RequestsAdapter, automationsActivation: VentilatingDehumidifierAutomationActivation[]): ThunkResult<void> =>
//     async (dispatch, getState) => {
//       try {
//         // // If there are more than 10 automations-editor, let's divide the requests of up to 30 days max per request.
//         // // The requests will be sent in order asynchronously and returned once all the requests return.
//         // if (automationsActivation.length > 10) {
//         //   // The array of requests that will be sent to the server.
//         //   let requestPromises: any[] = [];
//         // }

//         dispatch(setLoadingVentilatingDehumidifierAutomationsActivationDetails(true));

//         const result: VentilatingDehumidifierAutomationActivation[] = [];
//         for (const currentAutomationActivation of automationsActivation) {
//           const automationActivation = await requestsAdapter
//             .make(automationService.getVentilatingDehumidifierAutomationActivation(currentAutomationActivation.id))
//             .catch(requestsAdapter.throwOnAbort);

//           if (automationActivation) {
//             result.push({
//               ...automationActivation,
//               id: currentAutomationActivation.id
//             });
//           }
//         }

//         if (result.length > 0)
//           dispatch(setVentilatingDehumidifierAutomationsActivationDetails(result));

//         dispatch(setLoadingVentilatingDehumidifierAutomationsActivationDetails(false));
//       } catch {
//         // aborted
//         dispatch(setLoadingVentilatingDehumidifierAutomationsActivationDetails(false));
//       }
//     };

export const updateVentilatingDehumidifierAutomation = (automationId: number, updatedAutomation: object)
  : ThunkResult<Promise<HttpError | void>> =>
  async (dispatch, getState) => {

    try {
      const result = await automationService.updateVentilatingDehumidifierAutomation(automationId, updatedAutomation)

      const allAutomationActivationDetails =
        getState().automations.ventilatingDehumidifierAutomationsActivationDetails

      // Since the reducer stores an array of all the automation activation details, let's find the one we want to update
      // and then dispatch an update to the reduce store.
      const index = allAutomationActivationDetails.findIndex(element => element.id == automationId)

      // If found update element.
      if (index !== -1) {
        allAutomationActivationDetails[index] = {
          ...allAutomationActivationDetails[index], ...updatedAutomation, 
        }
        dispatch(setVentilatingDehumidifierAutomationsActivationDetails(allAutomationActivationDetails))
      }

      return Promise.resolve(result)
    } catch (error: any) {
      return Promise.reject(error)
    }
  }
