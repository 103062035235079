import React, { useEffect } from 'react'
import {
  Dialog, makeStyles, Typography, DialogContent, DialogActions, Button, DialogTitle, IconButton,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import TourFinishImage from 'features/product-tour/get-started-menu.png'
import { useMixPanel } from 'features/analytics/mixpanel-provider'
import { theme } from 'theme'


const useStyles = makeStyles({
  dialogTitle: {
    margin: 0,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '500px',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})


export interface TourFinishIntroDialogProps {
  isOpen: boolean;
  onFinishTour?: () => void;
  onViewDemoData?: () => void;
  onClose?: () => void;
}


export const TourFinishDialog = (props: TourFinishIntroDialogProps) => {
  const classes = useStyles()
  const {
    isOpen = true, onFinishTour, onViewDemoData, onClose,
  } = props
  const { mixpanel } = useMixPanel()


  const handleFinishTour = () => {
    if (mixpanel) {
      mixpanel.track('pp_productTour_tourFinishDialog_finishButton')
    }

    if (onFinishTour) {
      onFinishTour()
    }
  }


  const handleViewDemoData = () => {
    if (mixpanel) {
      mixpanel.track('pp_productTour_tourFinishDialog_viewDemoDataButton')
    }

    if (onViewDemoData) {
      onViewDemoData()
    }
  }

  const handleCloseDialog = () => {
    if (mixpanel) {
      mixpanel.track('pp_productTour_tourFinishDialog_closeButton')
    }

    if (onClose) {
      onClose()
    }
  }

  useEffect(() => {
    if (mixpanel && isOpen) {
      mixpanel.track('pp_productTour_tourFinishDialog_open')
    }
  }, [mixpanel, isOpen])


  return (
    <Dialog
      id="tour-finish-intro-dialog"
      aria-labelledby="tour-finish-intro-dialog-title"
      open={isOpen}
      onClose={ (event: object, reason: string) => {
        if (reason !== 'backdropClick') {
          handleCloseDialog()
        }
      }}>
      <DialogTitle id="tour-finish-intro-dialog-title" disableTypography className={classes.dialogTitle}>
        <Typography variant="h3" color="secondary">We hope you found<br/> the tour useful</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseDialog}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <img
          src={TourFinishImage}
          alt="Tour Finish Image"
          style={{
            marginBottom: '30px',
          }}
        />
        <Typography variant="body1" color="textPrimary">
          You can access it at any time by clicking the “Quick guide” button in the top navigation bar.<br/><br/>
          Remember those Demo Data accounts?<br/>
          Feel free to explore!
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleFinishTour}
          style={{
            flex: 1,
            margin: '1rem',
          }}>
          <Typography variant="button">Finish Tour</Typography>
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleViewDemoData}
          style={{
            flex: 1,
            margin: '1rem',
          }}>
          <Typography variant="button">View Demo Data</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  )
}
