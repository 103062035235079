import { create } from "zustand"

export interface EcosenseTelemetry {
    radon: number
    timestamp: string
}

export interface EcosenseStore {
    [key: string]: EcosenseTelemetry[]
}

const useEcosenseStore = create<EcosenseStore>(set => ({

}))

// checks if the chart is missing data, if yes returns
// the earliest point on the chart it can find
// const useIsIncomplete = (serialNumebrs: string[], startTime: Date, endTime: Date) => {
//     const data = useEcosenseStore.getState()

//     if (!data) return []
//     if (!serialNumebrs.length) return []

//     return serialNumebrs.map(x => {
//         const _data = data[x]

//         const earliest = (new Date(_data[0].timestamp) >= startTime) ?
//             new Date(_data[0].timestamp) : null
//         const latest = (new Date(_data[_data.length - 1].timestamp) <= endTime) ?
//             new Date(_data[0].timestamp) : null

//         return {
//             earliest,
//             latest,
//         }
//     })
// }


// const useDataExists = (serialNumbers: string[], startTime: Date, endTime: Date) => {
//     const data = useEcosenseStore.getState()
//     return serialNumbers.map(x => {
//         return data[x]
//             ?.some(data => new Date(data.timestamp) >= startTime && new Date(data.timestamp) <= endTime)

//     })
// }

const withinRange = (data: EcosenseTelemetry, min: Date, max: Date) => {
  const _date = new Date(data.timestamp)
  return (_date < max) && (_date > min)
}

const selectLatest = (serialNumbers: string[]) => (store: EcosenseStore) => {
  if (!serialNumbers) return {
  }
  return Object.keys(store)
    .filter(x => serialNumbers.includes(x))
    .reduce((prev, curr) => ({
      ...prev,
      [curr]: store[curr][store[curr].length - 1],
    }), {
    })
}

const selectTelemetry = (
  serialNumbers: string[],
  min: Date,
  max: Date,
) => (store: EcosenseStore) => {
  if (!min || !max) return {
  }

  return Object.fromEntries(
    Object.entries(store)
      .filter(([key, value]) =>
        serialNumbers.includes(key))
      .map(([key, value]) =>
        [key, value.filter(data => withinRange(data, min, max))]),
  )
}

export {
  selectTelemetry,
  selectLatest,
  useEcosenseStore,
  // useIsIncomplete,
  // useDataExists,
}

export default useEcosenseStore
