import { Dwelling, DwellingContent, DwellingPermission } from 'state-mngt/models/dwelling'
import { Reducer } from 'redux'
import {
  ActionType,
  CleanCustomersAction,
  CleanCustomerDetailsAction,
  LoadDwellingContentPendingAction,
  LoadDwellingContentRejectAction,
  LoadDwellingContentResolveAction,
  SetCustomerDetailsAction,
  SetDwellingPermissionAction,
} from 'state-mngt/actions/customer-actions'
import { LoadEntity } from 'state-mngt/models/redux'
import { startLoadEntity, rejectLoadEntity, resolveLoadEntity } from 'utils/reducer-utils'

interface CustomerState {
  isLoadingDwelling: boolean;
  isLoadingDwellingPermission: boolean;
  customerDetails?: Dwelling;
  dwellingPermission?: DwellingPermission[];
  dwellingContentMap: {
    [id: string]: LoadEntity<DwellingContent>;
  };
}

const initState: CustomerState = {
  isLoadingDwelling: true,
  isLoadingDwellingPermission: true,
  dwellingContentMap: {
  },
}

type ReducerAction = SetCustomerDetailsAction
  | SetDwellingPermissionAction
  | LoadDwellingContentRejectAction
  | LoadDwellingContentResolveAction
  | LoadDwellingContentPendingAction
  | CleanCustomersAction
  | CleanCustomerDetailsAction

const customer: Reducer<CustomerState, ReducerAction> = (state = initState, action): CustomerState => {
  switch (action.type) {
    case ActionType.GetCustomerDetails: {
      return {
        ...state,
        customerDetails: action.payload,
        isLoadingDwelling: false,
      }
    }
    case ActionType.SetDwellingPermission: {
      return {
        ...state,
        dwellingPermission: action.payload,
        isLoadingDwellingPermission: false,
      }
    }
    case ActionType.LoadDwellingContentPending: {
      return {
        ...state,
        dwellingContentMap: {
          ...state.dwellingContentMap,
          [action.payload]: startLoadEntity(),
        },
      }
    }
    case ActionType.LoadDwellingContentResolve: {
      return {
        ...state,
        dwellingContentMap: {
          ...state.dwellingContentMap,
          [action.payload.id]: resolveLoadEntity(action.payload.value),
        },
      }
    }
    case ActionType.LoadDwellingContentReject: {
      return {
        ...state,
        dwellingContentMap: {
          ...state.dwellingContentMap,
          [action.payload]: rejectLoadEntity(),
        },
      }
    }
    case ActionType.CleanCustomers: {
      return {
        ...initState,
      }
    }
    case ActionType.CleanCustomerDetails: {
      return {
        ...state,
        customerDetails: undefined,
        isLoadingDwelling: true,
      }
    }
    default: {
      return state
    }
  }
}

export default customer
