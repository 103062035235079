import { EquipmentContent } from 'state-mngt/models/equipment'
import {
  CacChannel, DeviceContent, DeviceHardwareStatus, DeviceAirflow,
} from 'state-mngt/models/device'
import { Filter } from 'state-mngt/models/filter'
import { CancelableFetch } from 'state-mngt/models/http'
import httpService, { HTTPService } from 'state-mngt/services/data/http-service'
import {
  getProductTourFakeDeviceCacChannels,
  isProductTourFakeDevice,
} from 'features/product-tour/pro-portal-tour'

class EquipmentService {
  private readonly httpService: HTTPService

  constructor(httpService: HTTPService) {
    this.httpService = httpService
  }

  getDeviceContent(deviceId: number): CancelableFetch<DeviceContent> {
    return this.httpService.getWithAbort(`/device/${deviceId}`)
  }

  getEquipmentContent(equipmentId: number): CancelableFetch<EquipmentContent> {
    return this.httpService.getWithAbort(`/equipment/${equipmentId}`)
  }

  getDeviceHardwareState(deviceId: number): CancelableFetch<DeviceHardwareStatus> {
    return this.httpService.getWithAbort(`/device/${deviceId}/hardware_state`)
  }

  getDeviceAirflow(deviceId: number, startTime: string): CancelableFetch<DeviceAirflow[]> {
    return this.httpService.getWithAbort(`/device/${deviceId}/airflow?start_time=${startTime}`)
  }

  getDeviceCacChannels(deviceId: number): CancelableFetch<CacChannel[]> | Promise<CacChannel[]> {
    if (isProductTourFakeDevice(deviceId)) {
      return Promise.resolve(getProductTourFakeDeviceCacChannels())
    }

    return this.httpService.getWithAbort(`/device/${deviceId}/cac_channels`)
  }

  getEquipmentFilter(filterId: number): CancelableFetch<Filter> {
    return this.httpService.getWithAbort(`/filter/${filterId}`)
  }
}

const equipmentService = new EquipmentService(httpService)

export default equipmentService
