/**
 * Enum for time interval in millisecond
 */
export enum TimeInterval {
    MINUTE = 60000,
    HOUR = 3600000,
    DAY = 86400000,
    WEEK = 604800000,
}

export enum TimeUnit {
  Milliseconds,
  Seconds,
  Minutes,
  Hours,
  Days,
}

export enum Timeframe {
  Hour = '1h',
  Day = '1d',
  Week = '1w',
  Weeks2 = '2w',
  Month = '1m',
  Months3 = '3m',
  Months6 = '6m',
  Year = '12m',
}
