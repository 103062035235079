import { Box } from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import React from 'react'

interface TabPanelProps {
  children?: React.ReactNode;
  tabValue?: unknown;
  activeValue?: unknown;
  style?: CSSProperties;
  className?: string;
  isActive?: boolean;
  renderOnInactive?: boolean;
}
/**
 * Render tab panel container content for selected tab
 * @param {TabPanelProps} props - TabPanelProps
 */
const TabPanel = (props: TabPanelProps) => {
  const {
    children, activeValue, tabValue, className, isActive, renderOnInactive, ...other
  } = props
  const isTabActive = isActive !== undefined ? isActive : activeValue === tabValue
  const isContentRendered = isTabActive || renderOnInactive

  return isContentRendered ? (
    <div
      role="tabpanel"
      id={`tabpanel-${tabValue}`}
      aria-labelledby={`tab-${tabValue}`}
      {...other}
    >
      <Box style={{
        display: isTabActive ? 'block' : 'none',
      }} className={className}>
        {children}
      </Box>
    </div>
  ) : null
}

export default TabPanel
