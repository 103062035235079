import { Reducer } from 'redux'
import { Device, DeviceTelemetry } from 'state-mngt/models/device'
import {
  DeviceDataSetting,
  SetDeviceAction,
  SetDeviceCurrentTelemetryAction,
  SetDeviceDataSettingAction,
  GET_DEVICE_DETAILS,
  SET_DEVICE_TELEMETRY_CURRENT,
  SET_DEVICE_DATA_SETTING,
} from 'state-mngt/actions/device-actions'

interface DeviceState {
  isLoading: boolean;
  device?: Device;
  deviceCurrentTelemetry?: DeviceTelemetry;
  deviceDataSetting?: DeviceDataSetting;
}

const initState: DeviceState = {
  isLoading: true,
}

type ReducerAction = SetDeviceAction
  | SetDeviceCurrentTelemetryAction
  | SetDeviceDataSettingAction

const device: Reducer<DeviceState, ReducerAction> = (state = initState, action) => {
  switch (action.type) {
    case GET_DEVICE_DETAILS: {
      return {
        ...state,
        device: action.device,
        isLoading: false,
      }
    }
    case SET_DEVICE_TELEMETRY_CURRENT: {
      return {
        ...state,
        deviceCurrentTelemetry: action.deviceCurrentTelemetry,
        isLoading: false,
      }
    }
    case SET_DEVICE_DATA_SETTING: {
      return {
        ...state,
        deviceDataSetting: action.deviceDataSetting,
        isLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default device
