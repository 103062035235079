import { TimeInterval, TimeUnit, Timeframe } from 'utils/constants/time-interval'

/**
 * converts UTC time to local time
 * @param date - date object
 * @returns date - iso string without seconds
 */
export const convertUTCTimeToCurrentTime = (date: Date) => {
  return new Date(date.getTime() - (date.getTimezoneOffset() * TimeInterval.MINUTE)).toISOString().slice(0, 16)
}

/**
 * Convert string interval to milliseconds
 * @param interval - string: '1h' | '1d' | '1w'
 * @returns interval in milliseconds
 */
export const getMillisecondsForTimeFrame = (timeframe: Timeframe) => {
  const current = new Date()

  if (timeframe === Timeframe.Hour) {
    return current.getTime() - TimeInterval.HOUR
  } else if (timeframe === Timeframe.Day) {
    return current.getTime() - TimeInterval.DAY
  } else if (timeframe === Timeframe.Week) {
    return current.getTime() - TimeInterval.WEEK
  } if (timeframe === Timeframe.Weeks2) {
    return current.getTime() - TimeInterval.WEEK * 2
  } else {
    const numOfMonth = Number(timeframe.substr(0, timeframe.length - 1))
    // Set to num of month(s) ago
    current.setMonth(current.getMonth() - numOfMonth)

    return current.getTime()
  }

}

export const getPrintedDate = (timestamp: string): string => {
  const date = new Date(timestamp)
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
}

export const getPrintedTime = (timestamp: string): string => {
  const date = new Date(timestamp)
  return date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
  })
}

export const getPrintedTimestamp = (timestamp: string): string => {
  if (Math.abs(new Date().valueOf() - new Date(timestamp).valueOf()) < TimeInterval.DAY) {
    return getPrintedTime(timestamp)
  }
  return getPrintedDate(timestamp)
}

export const addDays = (date: Date, days: number): Date => {
  const result = new Date()
  result.setDate(date.getDate() + days)
  return result
}

export const minusDays = (date: Date, days: number): Date => {
  const result = new Date()
  result.setDate(date.getDate() - days)
  return result
}

export const getMidnightDate = (date?: Date): Date => {
  const newDate = date ? new Date(date.getTime()) : new Date()
  newDate.setUTCHours(0, 0, 0, 0)
  return newDate
}

export const getTodayMidnightDate = () => getMidnightDate()

export const setHour = (date: Date, hour: number): Date => {
  const newDate = new Date(date.getTime())
  newDate.setHours(hour)
  return newDate
}

export const convertToTimeUnit = (amount: number, from: TimeUnit, to: TimeUnit): number => {
  if (from === to) {
    return amount
  }
  let inMs = amount
  if (from === TimeUnit.Seconds) {
    inMs = inMs * 1000
  } else if (from === TimeUnit.Minutes) {
    inMs = inMs * 1000 * 60
  } else if (from === TimeUnit.Hours) {
    inMs = inMs * 1000 * 60 * 60
  } else if (from === TimeUnit.Days) {
    inMs = inMs * 1000 * 60 * 60 * 24
  }

  if (to === TimeUnit.Milliseconds) {
    return inMs
  }
  const inSeconds = inMs / 1000
  if (to === TimeUnit.Seconds) {
    return Math.ceil(inSeconds)
  }
  const insMinutes = inSeconds / 60
  if (to === TimeUnit.Minutes) {
    return Math.ceil(insMinutes)
  }
  const inHours = insMinutes / 60
  if (to === TimeUnit.Hours) {
    return Math.ceil(inHours)
  }
  const inDays = inHours / 24

  return Math.ceil(inDays)
}

export const getFloorDateToMinutes = (minutes: number, date = new Date()) => {
  const ms = 1000 * 60 * minutes // convert minutes to ms
  return new Date(Math.floor(date.getTime() / ms) * ms)
}

export const getCeilDateToMinutes = (minutes: number, date = new Date()) => {
  const ms = 1000 * 60 * minutes // convert minutes to ms
  return new Date(Math.ceil(date.getTime() / ms) * ms)
}
