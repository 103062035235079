import React, { useCallback } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Slide,
  Typography,
} from '@material-ui/core'
import { generatePath, Link, useNavigate } from 'react-router-dom'
import CloseIcon from '@material-ui/icons/Close'
import { useDispatch, useSelector } from 'react-redux'
import { UserDemoName } from 'state-mngt/models/user'
import { ROUTE_PATHS } from 'routes/routes'
import { DEMO_CUSTOMER_IDS } from 'features/demo-data/demo-data'
import { startDemo } from 'state-mngt/actions/user-actions'
import { getPreferableDemo, getUserCompletedDemos } from 'state-mngt/selectors/user-selectors'
import mapImage from 'features/demo-data/images/map.svg'
import user1 from 'features/demo-data/images/user-1.svg'
import user1Inactive from 'features/demo-data/images/user-1-inactive.svg'
import user2 from 'features/demo-data/images/user-2.svg'
import user2Inactive from 'features/demo-data/images/user-2-inactive.svg'
import user3 from 'features/demo-data/images/user-3.svg'
import user3Inactive from 'features/demo-data/images/user-3-inactive.svg'
import equipmentImage from 'features/demo-data/images/equipment.svg'
import PmIcon from 'features/demo-data/icons/pm'
import RhIcon from 'features/demo-data/icons/rh'
import VocIcon from 'features/demo-data/icons/voc'
import { useMixPanel } from 'features/analytics/mixpanel-provider'
import DemoOptionItem from 'features/demo-data/demo-option-item'

const useStyles = makeStyles((theme) => ({
  title: {
    margin: 0,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  footerStart: {
    marginRight: 'auto',
  },
  nextButton: {
    minWidth: 220,
  },
}))

export enum DialogType {
  Intro = 'Intro',
  Choose = 'Choose',
  Outro = 'Outro',
}

interface Props {
  isOpen: boolean;
  type: DialogType;
  onNext: () => void;
  onClose: () => void;
}

const isNextActive = (demoName: UserDemoName, preferableDemo: UserDemoName | null, completedDemos: UserDemoName[]) => {
  if (preferableDemo !== null) {
    return preferableDemo === demoName
  }
  const isDemo1Completed = completedDemos.includes(UserDemoName.Demo1)
  if (demoName === UserDemoName.Demo1) {
    return !isDemo1Completed
  }
  const isDemo2Completed = completedDemos.includes(UserDemoName.Demo2)
  if (demoName === UserDemoName.Demo2) {
    return isDemo1Completed && !isDemo2Completed
  }
  if (demoName === UserDemoName.Demo3) {
    const isDemo3Completed = completedDemos.includes(UserDemoName.Demo3)
    return isDemo1Completed && isDemo2Completed && !isDemo3Completed
  }

  return false
}

const DemoDialog: React.FC<Props> = ({
  type, isOpen, onNext, onClose,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { mixpanel } = useMixPanel()

  const onStartDemo = useCallback(
    (name: UserDemoName) => {
      if (mixpanel) {
        mixpanel.track('pp_customersPage_' + (type || '') + 'DemoDataDialog_start_' + name)
      }

      dispatch(startDemo(name))
      navigate(generatePath(ROUTE_PATHS.customers.details.root.absolute, {
        dwellingId: DEMO_CUSTOMER_IDS[name].toString(),
      }))
    },
    [dispatch, navigate],
  )

  const completedDemos = useSelector(getUserCompletedDemos)
  const preferableDemo = useSelector(getPreferableDemo)
  const isDemo1Completed = completedDemos.includes(UserDemoName.Demo1)
  const isDemo2Completed = completedDemos.includes(UserDemoName.Demo2)
  const isDemo3Completed = completedDemos.includes(UserDemoName.Demo3)

  const demosComponent = (
    <Box display="flex">
      <Box width="33%" mx={2}>
        <DemoOptionItem
          isCompleted={isDemo1Completed}
          isNext={isNextActive(UserDemoName.Demo1, preferableDemo, completedDemos)}
          imageUrl={isDemo1Completed ? user1Inactive : user1}
          label={<span>Pat Parker’s<br/> Particle problems</span>}
          onClick={() => onStartDemo(UserDemoName.Demo1)}
          buttonIcon={<PmIcon />}
        />
      </Box>
      <Box width="33%" mx={2}>
        <DemoOptionItem
          isCompleted={isDemo2Completed}
          isNext={isNextActive(UserDemoName.Demo2, preferableDemo, completedDemos)}
          imageUrl={isDemo2Completed ? user2Inactive : user2}
          label={<span>Hubert Hernández’s<br/> (Relative) Humidity Horrors</span>}
          onClick={() => onStartDemo(UserDemoName.Demo2)}
          buttonIcon={<RhIcon />}
        />
      </Box>
      <Box width="33%" mx={2}>
        <DemoOptionItem
          isCompleted={isDemo3Completed}
          isNext={isNextActive(UserDemoName.Demo3, preferableDemo, completedDemos)}
          imageUrl={isDemo3Completed ? user3Inactive : user3}
          label={<span>Vida Valdes’s<br/> VOC Vexations</span>}
          onClick={() => onStartDemo(UserDemoName.Demo3)}
          buttonIcon={<VocIcon />}
        />
      </Box>
    </Box>
  )

  return (
    <Dialog open={isOpen} onClose={onClose} TransitionComponent={Slide} keepMounted>
      <DialogTitle
        disableTypography
        classes={{
          root: classes.title,
        }}
      >
        <Typography variant="h3" color="secondary">
          {type === DialogType.Intro && 'Your Demo Data is ready to explore'}
          {type === DialogType.Choose && 'Choose your own adventure'}
          {type === DialogType.Outro && `Great job finishing all ${completedDemos.length} demos!`}
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {type === DialogType.Intro && (
          <Box display="flex" alignItems="center" px={4} mb={2}>
            <img src={mapImage} alt="" />
            <Box>
              <Typography variant="body1" gutterBottom>
                We've created 3 demo accounts for you to explore and learn what types of indoor air quality (IAQ)
                issues HAVEN can help you identify and better manage for your customers.
              </Typography>
            </Box>
          </Box>
        )}
        {type === DialogType.Choose && (
          <Box textAlign="center">
            <Box mb={4}>
              <Typography variant="body1">
                You’ll find out how using longer term, historical data can help you find the most effective solution
                for a particular customer.
              </Typography>
            </Box>
            {demosComponent}
          </Box>
        )}
        {type === DialogType.Outro && (
          <Box textAlign="center">
            <Box mb={4}>
              <Typography variant="body1" gutterBottom>
                You can continue to access them from the Customers page. To turn them off, go to My Account {'>'} Settings.
              </Typography>
              <Typography variant="body1">
                In the meantime, here are some resources that might be useful.
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center">
              <Box width="33%" mx={2}>
                <DemoOptionItem
                  isCompleted={false}
                  imageUrl={equipmentImage}
                  label={<span>Join the<br/>Personal Use Program<br/><br/></span>}
                  button={(
                    <Button
                      component={Link}
                      to={ROUTE_PATHS.howToBuy.personalUseProgram.absolute}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        if (mixpanel) {
                          mixpanel.track('pp_customersPage_' + (type || '') + 'DemoDataDialogFinished_purchaseButton')
                        }

                        onClose()
                      }}
                      fullWidth
                    >
                      Purchase
                    </Button>
                  )}
                />
              </Box>
            </Box>
          </Box>
        )}

      </DialogContent>
      <DialogActions>
        {type === DialogType.Intro && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => onNext()}
            classes={{
              root: classes.nextButton,
            }}
          >
            Next
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default DemoDialog
