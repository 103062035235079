import * as React from 'react'
import { useState } from "react"
import { displayUnit, humanizeMeasurement, max, min } from "./util"
import Stack from "ui/stack"
import { Slider, Typography } from "@material-ui/core"
import { setTemporaryRuleInput } from './store'
import Muted from './muted'
import Emphasis from './emphasis'

const marks = ({ min, max, unit }) => [
    {
        value: min,
        label: `${min}${unit}`,
    },
    {
        value: max,
        label: `${max}${unit}`,
    },
]

function OutdoorWeatherRange({ ruleId, input }) {
    // store it locally just so it updates visually on change without rendering the whole tree
    const [localRange, setLocalRange] = useState<number[]>([])

    const {
        set_point_unit,
        measurement,
        rising,
        type,
        high_end_start_set_point,
        low_end_start_set_point,
    } = input

    React.useEffect(() => {
        if (high_end_start_set_point && low_end_start_set_point) {
            setLocalRange([low_end_start_set_point, high_end_start_set_point])
        }
    }, [
        high_end_start_set_point,
        low_end_start_set_point,
    ])

    const _min = min(measurement, set_point_unit, rising)
    const _max = max(measurement, set_point_unit, rising)

    const _marks = marks({
        min: min(measurement, set_point_unit, rising),
        max: max(measurement, set_point_unit, rising),
        unit: displayUnit(set_point_unit || input.measurement),
    })

    const onChange = (event, value) => {
        setLocalRange(value)
    }

    const handleChangeCommitted = (event, value) => {
        setTemporaryRuleInput(ruleId, input?.id, {
            high_end_start_set_point: value[1],
            low_end_start_set_point: value[0],
        })
    }

    const humanizedMeasurement = humanizeMeasurement(type, measurement)

    return (
        <Stack>
            <Typography
                variant='body1'
            >
                <Muted>
                    <Emphasis>
                        {humanizedMeasurement}
                    </Emphasis>
                    {` `}is between{` `}
                    <Emphasis>
                        {localRange[0]}
                    </Emphasis>
                    {` `}and{` `}
                    <Emphasis>
                        {localRange[1]}{displayUnit(set_point_unit || input.measurement)}
                    </Emphasis>
                </Muted>
            </Typography>
            <Slider
                track={rising ? 'inverted' : 'normal'}
                value={localRange}
                min={_min}
                max={_max}
                marks={_marks}
                onChange={onChange}
                onChangeCommitted={handleChangeCommitted}
                aria-labelledby={input.type} />
        </Stack>
    )
}

export default OutdoorWeatherRange
