import React from 'react'
import {
  selectEquipmentAutomations,
  selectEquipmentRulesByPillar,
  selectEquipments,
  setAutomation,
  useAutomationsStore,
  useEquipmentStore,
  useRulesStore,
} from './store'
import {
  AutomationPillar,
  AutomationRule,
  AutomationRuleGroup,
  EquipmentTypes,
  RuleRefs,
} from 'types'
import Stack from 'ui/stack'
import useClasses from './useClasses'
import Muted from './muted'
import RuleGroup from './rule-group'
import useCurrentZone from 'utils/hooks/useCurrentZone'
import ColumnTitle from './columnTitle'
import { useLoadingStore } from './useGetData'
import Loading from './loading'
import { Box, Typography } from '@material-ui/core'
import AddNew from './install/add-new'
import { defaultHighHumidity, defaultLowHumidity } from './install/data'
import { findCompressor, findFan } from 'stores/util'
import { useIsEnabled } from 'stores/feature-flag'

const mapToRefs = (rule: AutomationRule): RuleRefs => ({
  ...rule,
  trigger: rule.trigger.id,
  inputs: rule.inputs.map(x => x.id),
  outputs: rule.outputs.map(x => x.id),
})

const groupRules = (rules: AutomationRule[]): AutomationRuleGroup[] => {
  let groups: AutomationRuleGroup[] = []

  for (const rule of rules) {
    // does this rule share an input with any of the 
    // existing groups?
    const sharedInput = rule.inputs.find(ruleInput =>
      groups.some(group =>
        group.rules.some(rule =>
          rule.inputs.includes(ruleInput.id))))

    // find the group the shared input is in
    const group = sharedInput && groups.find(group =>
      rule.inputs.some(input =>
        group.rules.some(groupRule =>
          groupRule.inputs.includes(input.id))))

    if (sharedInput && group) {
      // add this rule to its group
      groups = groups.map(_group => {
        if (_group.id === group.id) {
          return {
            ...group,
            sharedInputs: [
              ...group.sharedInputs,
              sharedInput.id,
            ],
            rules: [
              ...group.rules,
              mapToRefs(rule),
            ],
          }
        }
        return group
      })
      continue
    }

    const newGroup: AutomationRuleGroup = {
      id: groups.length + 1,
      rules: [mapToRefs(rule)],
      sharedInputs: sharedInput ? [sharedInput.id] : [],
    }

    groups = [...groups, newGroup]
  }
  return groups
}

const useFilterByZone = (rules: AutomationRule[]) => {
  const currentZone = useCurrentZone()
  return rules.filter(rule => rule.zone.toLowerCase() === currentZone.toLowerCase())
}

const Pillar = ({ pillar, dwellingId, ...rest }: { pillar: AutomationPillar, dwellingId: number }) => {
  const rules = useRulesStore(selectEquipmentRulesByPillar(dwellingId)(pillar))
  const _rules = useFilterByZone(rules)
  const ruleGroups = groupRules(_rules)

  if (!_rules?.length) return (
    <Muted>
      No {pillar} automation rules
    </Muted>
  )

  return (
    <Stack
      {...rest}
    >
      {
        ruleGroups.map(group => (
          <RuleGroup
            key={group.id}
            ruleGroup={group}
          />
        ))
      }
    </Stack>
  )
}

/**
 * set a new default automation
 *
 * depending on the equipment
 * 
 * get the outputs in the dwelling
 * use those outputs to get equipment
 */
const newAutomation = ({ updatedAutomation, equipment, outputs, pillar }) => {
  // const [automation, setAutomation] = u

  const airHandler = equipment.find(x => x.type === EquipmentTypes.CENTRAL_AIR_HANDLER)
  const mechanicalVentilation = equipment.find(x => x.type === EquipmentTypes.MECHANICAL_VENTILATION)

  const ventilatingDehumidifierFan = findFan(
    outputs,
    equipment.find(x =>
      x.type === EquipmentTypes.VENTILATING_DEHUMIDIFIER))

  const ventilatingDehumidifierCompressor = findCompressor(
    outputs,
    equipment.find(x =>
      x.type === EquipmentTypes.VENTILATING_DEHUMIDIFIER))
  const compressorOutput = ventilatingDehumidifierCompressor ? outputs.find(x => x.equipment_id === ventilatingDehumidifierCompressor?.id) : false

  const outdoorDamper = equipment.find(x => x.type === EquipmentTypes.OUTDOOR_AIR_DAMPER)
  const humidifier = equipment.find(x => x.type === EquipmentTypes.HUMIDIFIER)
  const humidifierOutput = outputs.find(x => x.equipment_id === humidifier?.id)

  if (pillar === 'humidity') {
    if (humidifier && humidifierOutput) {
      const lowHumidityAutomationData = defaultLowHumidity(humidifierOutput)

      updatedAutomation = {
        ...updatedAutomation,
        rules: [
          ...updatedAutomation.rules,
          ...lowHumidityAutomationData.rules,
        ],
      }
    }
    if (ventilatingDehumidifierCompressor && compressorOutput) {
      const highHumidityAutomationData = defaultHighHumidity(compressorOutput)

      updatedAutomation = {
        ...updatedAutomation,
        rules: [
          ...updatedAutomation.rules,
          ...highHumidityAutomationData.rules,
        ],
      }

    }
  }
  if (pillar === 'filtration') {

  }

  setAutomation(updatedAutomation.id, updatedAutomation)
}

function NewAutomation({ pillar, dwellingId }) {
  const rules = useRulesStore(selectEquipmentRulesByPillar(dwellingId)(pillar))
  const _rules = useFilterByZone(rules)
  const zone = useCurrentZone()

  const outputs = _rules.flatMap(x => x.outputs)
  const allEquipment = outputs.map(x => x.equipment_id)
  const equipment = Object.values(useEquipmentStore(selectEquipments(allEquipment)) || {})
  const automations = useAutomationsStore(selectEquipmentAutomations(dwellingId, zone))

  const isEnabled = useIsEnabled('install')
  if (!isEnabled) return null

  let updatedAutomation = automations?.[0] ? { ...automations[0] } : null

  if (!updatedAutomation) return null

  // (
  //   <Muted>No installation found.</Muted>
  // )

  return (
    <AddNew onClick={() => newAutomation({ updatedAutomation, equipment, outputs, pillar })}>
      New {pillar} automation
    </AddNew>
  )
}

function Automations({ dwellingId, hideTitle = false }: { dwellingId?: number, hideTitle?: boolean }) {
  const classes = useClasses()
  const loading = useLoadingStore()

  if (!dwellingId) return <Muted>No dwelling selected</Muted>
  if (loading) return <Loading />

  return (
    <div className={classes.root}>
      {hideTitle ? (
        null
      ) : (
        <Box>
          <Typography style={{ marginBottom: '24px' }} variant='h6'>
            Automations
          </Typography>
        </Box>
      )}
      <div className={classes.gridContainer}>
        <Stack justify='flex-start'>
          <ColumnTitle>
            Humidity
          </ColumnTitle>
          <Pillar
            pillar='humidity'
            dwellingId={dwellingId}
          />
          <NewAutomation
            pillar='humidity'
            dwellingId={dwellingId}
          />
        </Stack>
        <Stack justify='flex-start'>
          <ColumnTitle>
            Ventilation
          </ColumnTitle>
          <Pillar
            pillar='ventilation'
            dwellingId={dwellingId}
          />
          <NewAutomation
            pillar='ventilation'
            dwellingId={dwellingId}
          />
        </Stack>
        <Stack justify='flex-start'>
          <ColumnTitle>
            Filtration
          </ColumnTitle>
          <Pillar
            pillar='filtration'
            dwellingId={dwellingId}
          />
          <NewAutomation
            pillar='filtration'
            dwellingId={dwellingId}
          />
        </Stack>
      </div>
    </div>
  )
}

export default Automations
