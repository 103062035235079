import { StepType } from '@reactour/tour'
import {
  CloseStepCallbackFn,
  NextStepCallbackFn,
  PreviousStepCallbackFn,
  TourStepContent,
} from 'ui/tour-step-content'
import { Typography } from '@material-ui/core'
import React from 'react'
import { getTourProviderStyle } from 'features/product-tour/tour-provider-with-custom-options'


/**
 * CSS selectors of DOM elements to be highlighted during the product tour.
 */
export const CHARTS_PAGE_TOUR_SELECTORS = {
  ZONE_NAVIGATION: 'zone-navigation',
  DEVICE_CURRENT_STATUS: 'device-current-status',
  DEVICE_CHART_HISTORY: 'device-chart-history',
  BUILD_A_REPORT: 'build-a-report',
}

/**
 * Get an array of steps used with "@reactour/tour" library for the charts page.
 * Each step contains elements that will be highlighted using the CSS selectors and popovers, a custom popup with an
 * explanation about the current step and buttons for going back or forward between the steps.
 * @param previousStepHandler PreviousStepCallbackFn function for handling the click of the previous button.
 * @param nextStepHandler NextStepCallbackFn function for handling the click of the next button.
 * @param closeStepHandler CloseStepCallbackFn function for handling the click of the close icon button.
 */
export const getChartsPageTourSteps = (previousStepHandler: PreviousStepCallbackFn,
  nextStepHandler: NextStepCallbackFn, closeStepHandler: CloseStepCallbackFn) : StepType[] => {

  const tourStepName = 'chartsPageTour'

  return [
    {
      selector: `#${CHARTS_PAGE_TOUR_SELECTORS.ZONE_NAVIGATION}`,
      content: (props: any) => (
        <TourStepContent
          title="Zone-based navigation"
          tourStepName={tourStepName}
          content={
            <>
              <Typography variant="body1" gutterBottom>
                Many customers have a single HVAC system, which allows HAVEN to measure the air in their entire home.
              </Typography>
              <br/>
              <Typography variant="body1">
                Other homes have more than one HVAC system — in this case, additional HAVEN equipment can be installed
                to represent different Zones, which you can select in the Zone dropdown here.
              </Typography>
            </>
          }
          currentStep={props.currentStep + 1}
          totalSteps={props.steps.length}
          previousStepText="Previous"
          nextStepText="Next"
          onPreviousStep={previousStepHandler}
          onNextStep={nextStepHandler}
          onCloseStep={closeStepHandler}
        />
      ),
    },
    {
      selector: `#${CHARTS_PAGE_TOUR_SELECTORS.DEVICE_CURRENT_STATUS}`,
      content: (props: any) => (
        <TourStepContent
          title="Current status"
          tourStepName={tourStepName}
          content={
            <>
              <Typography variant="body1">
                Have a quick glance at the current status, which can be useful for troubleshooting:
              </Typography>
              <br/>
              <ul style={{
                marginTop: 0,
                marginLeft: '2rem',
              }}>
                <li>Is the HVAC fan ON or OFF</li>
                <li>When last telemetry was received from the HAVEN Monitor</li>
                <li>Current PM2.5, tVOC, Relative Humidity and Temperature readings</li>
              </ul>
            </>
          }
          currentStep={props.currentStep + 1}
          totalSteps={props.steps.length}
          previousStepText="Previous"
          nextStepText="Next"
          onPreviousStep={previousStepHandler}
          onNextStep={nextStepHandler}
          onCloseStep={closeStepHandler}
        />
      ),
      styles: {
        ...getTourProviderStyle(),
        popover: (base: any) => {
          const { maxWidth } = base

          return {
            ...getTourProviderStyle().popover(base),
            left: `calc(100vw - (${maxWidth}px + 40px))`,
          }
        },
      },
    },
    {
      selector: `#${CHARTS_PAGE_TOUR_SELECTORS.DEVICE_CHART_HISTORY}`,
      content: (props: any) => (
        <TourStepContent
          title="Historical data"
          tourStepName={tourStepName}
          content={
            <>
              <Typography variant="body1">
                Get insights into past trends and IAQ events:
              </Typography>
              <br/>
              <ul style={{
                marginTop: 0,
                marginLeft: '2rem',
              }}>
                <li>Select a timeframe and adjust chart parameters to get into the nitty-gritty.</li>
                <li>Click and drag on the chart to zoom in up to 1 minute intervals!</li>
                <li>The pink Airflow bar chart at the bottom indicates whether equipment was ON or OFF.</li>
                <li>
                  This is also where you will see Controller-connected equipment and its associated
                  Automation activity.
                </li>
              </ul>
            </>
          }
          currentStep={props.currentStep + 1}
          totalSteps={props.steps.length}
          previousStepText="Previous"
          nextStepText="Next"
          onPreviousStep={previousStepHandler}
          onNextStep={nextStepHandler}
          onCloseStep={closeStepHandler}
        />
      ),
    },
    {
      selector: `#${CHARTS_PAGE_TOUR_SELECTORS.BUILD_A_REPORT}`,
      content: (props: any) => (
        <TourStepContent
          title="Build a report"
          tourStepName={tourStepName}
          content={
            <>
              <ul style={{
                marginTop: 0,
                marginLeft: '2rem',
              }}>
                <li>
                  Use IAQ home performance reports as a main communication method to build trust with your
                  customers in a consumer-friendly format.
                </li>
                <li>
                  Or select a specific time frame to communicate a particular issue within their home that
                  needs to be addressed.
                </li>
              </ul>
              You can't manage what you don't measure  :)
            </>
          }
          currentStep={props.currentStep + 1}
          totalSteps={props.steps.length}
          previousStepText="Previous"
          nextStepText="Continue Tour"
          onPreviousStep={previousStepHandler}
          onNextStep={nextStepHandler}
          onCloseStep={closeStepHandler}
        />
      ),
    },
  ]
}
