import {
  Box, LinearProgress, Typography, withStyles,
} from '@material-ui/core'
import React from 'react'

const LinearProgressWithLabel = (props: any) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress thickness={14} size={40} variant="determinate" color="secondary" {...props} />
      </Box>
      <Box minWidth={35} mt={1}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(props.value)}% Complete`}</Typography>
      </Box>
    </Box>
  )
}
const OnboardingLinearProgress = withStyles((theme) => ({
  root: {
    height: 14,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
  },
}))(LinearProgressWithLabel)

export default OnboardingLinearProgress
