import { Reducer } from 'redux'
import {
  SET_COMPANY_DETAILS, SET_COMPANY_DETAILS_ERROR, SET_COMPANY_LOADING, SET_COMPANY_MEMBERS, SET_COMPANY_MEMBERS_ERROR,
  UPDATE_COMPANY_MEMBER_DETAILS, DELETE_COMPANY_MEMBER, SET_COMPANY_INVITES, SET_COMPANY_INVITES_ERROR,
  SET_COMPANY_MEMBERS_AND_INVITES, SET_COMPANY_MEMBERS_AND_INVITES_ERROR, DELETE_COMPANY_INVITE,
  SetCompanyDetailsAction, SetCompanyDetailsErrorAction, SetCompanyMembersAction, SetCompanyMembersErrorAction,
  SetCompanyLoadingAction, UpdateCompanyMemberDetailsAction, DeleteCompanyMemberAction,
  SetCompanyInvitesAction, SetCompanyInvitesErrorAction,
  SetCompanyMembersAndInvitesAction, SetCompanyMembersAndInvitesErrorAction,  DeleteCompanyInvite,
} from 'state-mngt/actions/company-actions'
import { TeamMember, User } from 'state-mngt/models/user'
import { ServiceCompany, ServiceCompanyInvites } from 'state-mngt/models/serviceCompany'

interface CompanyState {
  isLoading: boolean;
  isLoadingMembers: boolean;
  isLoadingInvites: boolean;
  isLoadingMembersAndInvites: boolean;
  companyDetails?: ServiceCompany;
  members?: User[];
  editedMember?: User;
  invites?: ServiceCompanyInvites;
  membersAndInvites?: TeamMember[];
}

const initState: CompanyState = {
  isLoading: true,
  isLoadingMembers: true,
  isLoadingInvites: true,
  isLoadingMembersAndInvites: true,
}

const company: Reducer<
  CompanyState,
  SetCompanyDetailsAction | SetCompanyDetailsErrorAction | SetCompanyLoadingAction | SetCompanyMembersAction
  | SetCompanyMembersErrorAction | UpdateCompanyMemberDetailsAction | DeleteCompanyMemberAction
  | SetCompanyInvitesAction | SetCompanyInvitesErrorAction
  | SetCompanyMembersAndInvitesAction | SetCompanyMembersAndInvitesErrorAction | DeleteCompanyInvite
  > = (state = initState, action) => {
    switch (action.type) {
      case SET_COMPANY_DETAILS: {
        return {
          ...state, companyDetails: action.companyDetails, isLoading: false,
        }
      }
      case SET_COMPANY_DETAILS_ERROR: {
        return {
          ...state, isLoading: false,
        }
      }
      case SET_COMPANY_LOADING: {
        return {
          ...state, isLoading: action.isLoading,
        }
      }
      case SET_COMPANY_MEMBERS: {
        return {
          ...state, members: action.members, isLoadingMembers: false,
        }
      }
      case SET_COMPANY_MEMBERS_ERROR: {
        return {
          ...state, isLoadingMembers: false,
        }
      }
      case UPDATE_COMPANY_MEMBER_DETAILS: {
        const updatedMembers =  state.members?.map(m => m.id === action.editedMember.id ? action.editedMember: m)
        return {
          ...state, members: updatedMembers,
        }
      }
      case DELETE_COMPANY_MEMBER: {
        return {
          ...state, members: state.members?.filter(m=> m.id !== action.userId),
        }
      }
      case SET_COMPANY_INVITES: {
        return {
          ...state, invites: action.payload, isLoadingInvites: false,
        }
      }
      case SET_COMPANY_INVITES_ERROR: {
        return {
          ...state, isLoadingInvites: false,
        }
      }
      case SET_COMPANY_MEMBERS_AND_INVITES: {
        return {
          ...state, membersAndInvites: action.payload, isLoadingMembersAndInvites: false,
        }
      }
      case SET_COMPANY_MEMBERS_AND_INVITES_ERROR: {
        return {
          ...state, isLoadingMembersAndInvites: false,
        }
      }
      case DELETE_COMPANY_INVITE: {
        return {
          ...state,
          membersAndInvites: state.membersAndInvites?.filter(m=> m.invite_code !== action.payload.invite_code),
        }
      }
      default: {
        return state
      }
    }
  }

export default company
