import { create } from "zustand"

export interface Quality {
    good: number
    fair: number
    poor: number
    no_data: number
}

export interface HumidityQuality {
    too_humid: number
    humid: number
    good: number
    dry: number
    too_dry: number
    no_data: number
}

export interface QualityData {
    dew_point_status: DewPointQuality | null
    humidity_status: HumidityQuality | null
    voc_status: Quality | null
    pm_status: Quality | null
}

export interface DewPointQuality {
    dry_and_comfy: number
    sticky: number
    humid: number
    no_data: number
}

const initialData = {
  'dew_point_status': null,
  'humidity_status': null,
  'voc_status': null,
  'pm_status': null,
}

export interface QualityStore {
    [monitorId: number]: QualityData
}

const useQualityStore = create<QualityStore>(set => ({

}))

export { initialData }

export default useQualityStore
