import React, { useEffect, useState } from 'react'
import { DewPointQuality, HumidityQuality, Quality } from 'stores/quality'
import useMonitorQuality from '../device-chart/useMonitorQuality'
import PieChart from 'ui/pie-chart/pie-chart'
import { Box, CircularProgress, Grid } from '@material-ui/core'
import {
  pieChartColors,
  pieChartHumidityColors,
} from '../device-chart/chart-utils'
import useEcosenseTelemetry from 'utils/hooks/useEcosenseTelemetry'
import useHasMonitor from 'utils/hooks/useHasMonitorInZone'
import { EcosenseStore, EcosenseTelemetry } from 'stores/ecosense'
import useEcosenseDevicesInCurrentDwellingZone from 'utils/hooks/useEcosenseDevicesInCurrentDwellingZone'
import {
  formatForPieChart,
  formatHumidityForPieChart,
} from 'utils/report-utils'

// come up with how many in each range
const radonRanges = [
  {
    name: 'good', check: (x: EcosenseTelemetry) => x.radon <= 100, 
  },
  {
    name: 'fair', check: (x: EcosenseTelemetry) => (x.radon < 150) && (x.radon > 100), 
  },
  {
    name: 'poor', check: (x: EcosenseTelemetry) => x.radon >= 150, 
  },
]

const initialCounts = {
  good: 0,
  fair: 0,
  poor: 0,
}

const initialPercentages = {
  good: 0,
  fair: 0,
  poor: 0,
  no_data: 0,
}

interface Counts {
    [key: string]: typeof initialCounts
}

interface Percentages {
    [key: string]: typeof initialPercentages
}

const count = (telem: EcosenseStore, totalTime) => {
  const ms = 3600000 // ecosense telem always come in at 1h intervals

  const counts: Counts = Object.keys(telem).reduce((prev, curr) =>
    ({
      ...prev,
      [curr]: {
        good: 0, fair: 0, poor: 0, 
      }, 
    }), {
  })

  const percentages: Percentages = Object.keys(telem).reduce((prev, curr) =>
    ({
      ...prev,
      [curr]: {
        good: 0, fair: 0, poor: 0, no_data: 0, 
      }, 
    }), {
  })

  for (const key in telem) {
    const currentCounts = {
      good: 0, fair: 0, poor: 0, 
    }
    for (const item of telem[key]) {
      const passedCheck = radonRanges.find(x => x.check(item))

      if (passedCheck) {
        currentCounts[passedCheck.name] = (currentCounts[passedCheck.name] || 0) + 1
      }
    }
    counts[key] = currentCounts
  }

  Object.keys(counts).forEach(deviceKey => {
    Object.keys(counts[deviceKey]).forEach(status => {
      const count = counts[deviceKey][status]

      // number of intances multiplied by the increment each
      // instance represents. then divided by the total time
      percentages[deviceKey][status] = Math.round(((count * ms) / totalTime) * 100)
    })

    const total = Object.values(percentages[deviceKey]).reduce((prev, curr) => prev + curr, 0)
    percentages[deviceKey]['no_data'] = Math.round(100 - total)
  })

  return percentages
}

const useEcosenseQuality = (serialNumbers: string[], startTime: Date, endTime: Date): Percentages => {
  const { telemetry } = useEcosenseTelemetry(serialNumbers, startTime, endTime)
  const [percentages, setPercentages] = useState<Percentages>(serialNumbers.reduce((prev, curr) => ({
    ...prev,
    [curr]: {
    }, 
  }), {
  }))

  useEffect(() => {
    if (!Object.keys(telemetry || {
    }).length) return
    if (!startTime || !endTime) return
    const _percentages = count(telemetry, (endTime.getTime() - startTime.getTime()))
    setPercentages(_percentages)
  }, [
    JSON.stringify(telemetry),
    startTime.getTime(),
    endTime.getTime(),
  ])

  return percentages
}

interface HistoryPieChartsProps {
    startTime: Date
    endTime: Date
}

const PMPieChart = ({ pm_status, isReport = false }) => {
  return (
    <PieChart
      data={formatForPieChart(pm_status)}
      title="Filtration (PM2.5)"
      colors={pieChartColors}
      isReport={isReport}
    />

  )
}

const VOCPieChart = ({ voc_status, isReport = false }) => {
  return (
    <PieChart
      data={formatForPieChart(voc_status)}
      title="Ventilation (tVOC)"
      colors={pieChartColors}
      isReport={isReport}
    />
  )
}

const HumidityPieChart = ({ humidity_status, isReport = false }) => {
  return (
    <PieChart
      data={formatHumidityForPieChart(humidity_status)}
      title="Humidity (RH)"
      colors={pieChartHumidityColors}
      isReport={isReport}
    />
  )
}

const EcosensePieChart = ({ name, quality, isReport = false }) => {
  return (
    <PieChart
      data={formatForPieChart(quality)}
      title={`Radon (${name})`}
      colors={pieChartColors}
      isReport={isReport}
    />
  )
}

export interface AllDeviceQuality {
    pm_status: Quality | null
    humidity_status: HumidityQuality | null
    dew_point_status: DewPointQuality | null
    voc_status: Quality | null
    ecosense: Percentages
}

const useAllDeviceQuality = ({ startTime, endTime }): AllDeviceQuality => {
  const monitorQuality = useMonitorQuality({
    startTime, endTime, 
  })
  const ecosenseDevices = useEcosenseDevicesInCurrentDwellingZone()

  const ecosenseQuality = useEcosenseQuality(
    ecosenseDevices.map(x => x.serial_number),
    startTime,
    endTime,
  )

  return {
    ...monitorQuality,
    ecosense: ecosenseQuality,
  }
}


const HistoryPieCharts = ({ startTime, endTime }: HistoryPieChartsProps) => {
  const hasMonitor = useHasMonitor()

  const {
    pm_status,
    humidity_status,
    voc_status,
    ecosense,
  } = useAllDeviceQuality({
    startTime, endTime, 
  })

  const ecosenseDevices = useEcosenseDevicesInCurrentDwellingZone()
  const showEcosense = Boolean(ecosenseDevices.length && Object.keys(ecosense).length)

  return (
    <>
      {
        hasMonitor ? (
          <>
            <Grid
              item
              sm={4}
            >
              <Box
                width="280px"
                height='140px'
              >
                {pm_status ? (
                  <PMPieChart
                    pm_status={pm_status}
                  />
                ) : hasMonitor ? (
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    height='100%'
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  null
                )}
              </Box>
            </Grid>
            <Grid
              item
              sm={4}
            >
              <Box
                width="280px"
                height='140px'
              >
                {voc_status ? (
                  <VOCPieChart
                    voc_status={voc_status}
                  />
                ) : hasMonitor ? (
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    height='100%'
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  null
                )}
              </Box>
            </Grid>
            <Grid
              item
              sm={4}
            >
              <Box
                width="280px"
                height='148px'
              >
                {humidity_status ? (
                  <HumidityPieChart
                    humidity_status={humidity_status}
                  />
                ) : hasMonitor ? (
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    height='100%'
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  null
                )}
              </Box>
            </Grid>
          </>
        ) : (
          <p style={{
            color: 'grey', 
          }}>No monitor found</p>
        )
      }
      {showEcosense && Object.keys(ecosense).map((key, index) => (
        <Grid
          key={key}
          sm={4}
          item
        >
          <Box
            width="280px"
            height='148px'
          >
            <EcosensePieChart
              quality={ecosense[key]}
              name={ecosenseDevices[index].name}
            />
          </Box>
        </Grid>
      ))}
    </>
  )
}

export {
  useAllDeviceQuality,
  VOCPieChart,
  PMPieChart,
  HumidityPieChart,
  EcosensePieChart,
}

export default HistoryPieCharts
