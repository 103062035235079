import * as React from 'react'
import AutomationRuleCard from './automation-rule-card'
import Stack from 'ui/stack'

function EmptyState({ children }) {
    return (
        <AutomationRuleCard style={{
            display: 'flex',
            justifyContent: 'center',
            padding: 16,
        }}>
            <Stack align='center'>
                <p style={{
                    color: 'rgba(0, 0, 0, 0.26)',
                    margin: 0,
                }}>{children}</p>
            </Stack>
        </AutomationRuleCard>
    )
}

export default EmptyState
