import { Dwelling } from 'state-mngt/models/dwelling'
import { Reducer } from 'redux'
import { ServiceCompanyRole } from 'state-mngt/models/serviceCompany'
import { User, UserDemo, UserDemoModal } from 'state-mngt/models/user'
import { DEMO_COUNTS } from 'features/demo-data/demo-data'
import {
  ActionType,
  CancelDemoAction,
  CompleteDemoIntroAction,
  DisableDemoModeAction,
  EnableDemoModeAction,
  FinishDemoAction,
  HideHintElementAction,
  SetCompanyRoleAction,
  SetCompletedDemosAction,
  SetDemoStepAction,
  SetDwellingsAction,
  SetUserAction,
  StartDemoAction,
} from 'state-mngt/actions/user-actions'

interface UserState {
  dwellings?: Dwelling[];
  role?: ServiceCompanyRole;
  user: User | null;
  isLoading: boolean;
  isLoadingDwellings: boolean;
  isLoadingRole: boolean;
  isLoadingUser: boolean;
  loadingUserError: boolean;
  demo: UserDemo;
}

const initState: UserState = {
  isLoading: true,
  isLoadingDwellings: true,
  isLoadingRole: true,
  isLoadingUser: true,
  loadingUserError: false,
  user: null,
  demo: {
    isActive: false,
    demoModal: null,
    activeDemo: null,
    preferableDemo: null,
    activeStep: null,
    completedDemos: [],
    isCompletedDemosLoaded: false,
    hintElements: {
    },
  },
}

type ReducerAction = SetDwellingsAction
  | SetCompanyRoleAction
  | SetUserAction
  | EnableDemoModeAction
  | DisableDemoModeAction
  | SetDemoStepAction
  | StartDemoAction
  | FinishDemoAction
  | CancelDemoAction
  | HideHintElementAction
  | SetCompletedDemosAction
  | CompleteDemoIntroAction;

const user: Reducer<UserState, ReducerAction> = (state = initState, action): UserState => {
  switch (action.type) {
    case ActionType.SetUserDwellings: {
      return {
        ...state,
        dwellings: action.payload,
        isLoadingDwellings: false,
      }
    }
    case ActionType.SetCompanyRole: {
      return {
        ...state,
        role: action.payload,
        isLoading: false,
        isLoadingRole: false,
      }
    }
    case ActionType.SetUser: {
      return {
        ...state,
        user: action.payload.user,
        isLoadingUser: action.payload.isLoadingUser,
        loadingUserError: action.payload.loadingUserError,
      }
    }
    case ActionType.SetCompletedDemos: {
      return {
        ...state,
        demo: {
          ...state.demo,
          completedDemos: action.payload,
          isCompletedDemosLoaded: true,
        },
      }
    }
    case ActionType.EnableDemoMode: {
      return {
        ...state,
        demo: {
          ...state.demo,
          isActive: true,
          preferableDemo: action.payload,
          demoModal: state.demo.completedDemos.length > 0 ?
            UserDemoModal.Choose :
            UserDemoModal.Intro,
        },
      }
    }
    case ActionType.DisableDemoMode: {
      return {
        ...state,
        demo: {
          ...state.demo,
          isActive: false,
          demoModal: null,
          preferableDemo: null,
        },
      }
    }
    case ActionType.StartDemo: {
      return {
        ...state,
        demo: {
          ...state.demo,
          isActive: true,
          activeDemo: action.payload.name,
          activeStep: action.payload.step,
          hintElements: action.payload.hintElements,
        },
      }
    }
    case ActionType.FinishDemo: {
      let updatedCompletedDemos = state.demo.completedDemos
      if (state.demo.activeDemo) {
        if (!updatedCompletedDemos.includes(state.demo.activeDemo)) {
          updatedCompletedDemos = updatedCompletedDemos.concat(state.demo.activeDemo)
        }
      }
      return {
        ...state,
        demo: {
          ...state.demo,
          activeDemo: null,
          activeStep: null,
          preferableDemo: null,
          completedDemos: updatedCompletedDemos,
          demoModal: updatedCompletedDemos.length === DEMO_COUNTS ?
            UserDemoModal.Outro :
            UserDemoModal.Choose,
          hintElements: {
          },
        },
      }
    }
    case ActionType.SetDemoStep: {
      return {
        ...state,
        demo: {
          ...state.demo,
          activeStep: action.payload.step,
          hintElements: action.payload.hintElements,
        },
      }
    }
    case ActionType.CancelDemo: {
      return {
        ...state,
        demo: {
          ...state.demo,
          isActive: false,
          activeDemo: null,
          activeStep: null,
          hintElements: {
          },
        },
      }
    }

    case ActionType.HideHintElement: {
      return {
        ...state,
        demo: {
          ...state.demo,
          hintElements: {
            ...state.demo.hintElements,
            [action.payload]: null,
          },
        },
      }
    }

    case ActionType.CompleteDemoIntro: {
      return {
        ...state,
        demo: {
          ...state.demo,
          demoModal: UserDemoModal.Choose,
        },
      }
    }

    default: {
      return state
    }
  }
}

export default user
