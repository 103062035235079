import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import {
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Box,
  CircularProgress,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { SuccessAutoHideWithDismissAction } from 'ui/custom-snackbar-provider'
import { Country, provinceOptions, stateOptions } from 'utils/constants/province-lookup-options'
import classes from 'features/build-report/report-controls/dwelling-edit-dialog/dwelling-edit-dialog.module.scss'
import { DwellingForReport } from 'state-mngt/models/dwelling'

import PhoneInput from 'ui/phone-input'
import EmailInput from 'ui/email-input'
import PostalCodeInput from 'ui/postal-code-input'
import { setDwellingForReport } from 'state-mngt/actions/report-actions'
import { theme } from 'theme'
import { useMixPanel } from 'features/analytics/mixpanel-provider'
import { useAppDispatch } from 'utils/hooks/reduxTypes'

export const DwellingEditDialog = (props: { dwelling: DwellingForReport, open: boolean, setOpen: any }) => {
  const { dwelling, open, setOpen } = props

  const dispatch = useAppDispatch()
  const { mixpanel } = useMixPanel()
  const { enqueueSnackbar } = useSnackbar()

  const [first_name, setFirstName] = useState<string>(dwelling.first_name ? dwelling.first_name : '') // required
  const [last_name, setLastName] = useState<string>(dwelling.last_name ? dwelling.last_name : '') // required
  const [email, setEmail] = useState<string>(dwelling.email) // required
  const [phone, setPhone] = useState<string>(dwelling.phone ? dwelling.phone : '')
  const [street_1, setStreet1] = useState<string>(dwelling.street_1) // required
  const [street_2, setStreet2] = useState<string>(dwelling.street_2)
  const [city, setCity] = useState<string>(dwelling.city) // required
  const [province, setProvince] = useState<string>(dwelling.province) // required
  const [country, setCountry] = useState<string>(dwelling.country) // required
  const [postalCode, setPostalCode] = useState<string>(dwelling.postal_code) // required

  const [hasPhoneError, setHasPhoneError] = useState<boolean>(false)
  const [hasEmailError, setHasEmailError] = useState<boolean>(dwelling.email === '')
  const [hasPostalCodeError, setHasPostalCodeError] = useState<boolean>(dwelling.postal_code === '')
  const [generalRequiredError, setGeneralRequiredError] = useState<boolean>(false)

  const [updating, setUpdating] = useState<boolean>(false)

  /**
     * Closes modal when clicking 'cancel' button
     */
  const handleCancel = () => {
    if (mixpanel) {
      mixpanel.track('pp_reportPage_cancelDwellingEditButton')
    }
    setOpen(false)
  }

  /**
     * Save updated details
     * call API update method with dwelling id and details
     * call API update method with admin id and details
     */
  const handleSave = () => {
    if (mixpanel) {
      mixpanel.track('pp_reportPage_saveDwellingEditButton')
    }
    const updatedDwellingDetails: DwellingForReport = {
      first_name,
      last_name,
      email,
      phone,
      street_1,
      street_2,
      city,
      province,
      country,
      postal_code: postalCode,
    }

    updateDwelling(updatedDwellingDetails)
  }

  /**
     * update dwelling info (for report) in store
     * @param updatedDwelling - DwellingForReport
     */
  const updateDwelling = async (updatedDwelling: DwellingForReport) => {
    setUpdating(true)
    dispatch(setDwellingForReport(updatedDwelling))
    enqueueSnackbar('Dwelling details updated successfully', SuccessAutoHideWithDismissAction())

    setUpdating(false)
    setOpen(false)
  }

  /**
     * Change province/state value options when user toggle countries
     * Note since in db there are values that are not valid countries, they will appear as option but not trigger any selection changes
     * @param event - ChangeEvent
     */
  const onCountrySelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (event.target.value) {
      setCountry(event.target.value as string)
      if (event.target.value === Country.CANADA) {
        setProvince(provinceOptions[0].label)
      }
      if (event.target.value === Country.AMERICA) {
        setProvince(stateOptions[0].label)
      }
      selectStateOptions(event.target.value as string)
      // validatePostalCode(event.target.value as string, postalCode);
    }
  }

  /**
     * Change province/state options based on 'country' selection
     * @param countryParam - string
     */
  const selectStateOptions = (countryParam: string): string[] => {
    if (!countryParam) {
      return []
    }

    if (countryParam === Country.CANADA) {
      return provinceOptions.map(p => p.label)
    }
    if (countryParam === Country.AMERICA) {
      return stateOptions.map(s => s.label)
    }
    return []
  }

  /**
     * Handle province selection click
     * Set province value selected
     * @param event - ChangeEvent
     * @param value - string
     */
  const handleProvinceChange = (event: React.ChangeEvent<{}>, value: string | null) => {
    if (value) {
      setProvince(value)
      setGeneralRequiredError(value === 'Select')
    }
  }

  return (
    <Dialog aria-labelledby="Company Edit" open={open} >
      { !updating && (
        <>
          <DialogContent className={classes.dialogContainer}>
            <form>
              <Grid container spacing={3}>
                <Box display="flex" flexDirection="column" m={2}>
                  <Typography color="primary" variant="h5" gutterBottom>Edit your customer&apos;s details</Typography>
                  <Typography color="primary" variant="body2">
                    * Any changes made will only show up on this report
                  </Typography>
                </Box>
                <Grid item sm={6}>
                  <TextField id="admin-first-name"
                    fullWidth
                    label="First name"
                    required
                    defaultValue={dwelling.first_name}
                    error={first_name === ''}
                    helperText={first_name === '' ? 'First name is required' : ''}
                    onChange={e => {
                      setFirstName(e.target.value)
                      setGeneralRequiredError(e.target.value === '')
                    }}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField id="admin-last-name"
                    fullWidth
                    label="Last name"
                    required
                    defaultValue={dwelling.last_name}
                    error={last_name === ''}
                    helperText={last_name === '' ? 'Last name is required' : ''}
                    onChange={e => {
                      setLastName(e.target.value)
                      setGeneralRequiredError(e.target.value === '')
                    }}
                  />
                </Grid>
                <Grid item sm={12}>
                  <PhoneInput
                    name="admin-phone"
                    id="admin-phone"
                    inputValue={phone}
                    fullWidth={true}
                    touched={true}
                    onPhoneValueChange={setPhone}
                    onPhoneErrorChange={setHasPhoneError}
                  />
                </Grid>
                <Grid item sm={12}>
                  <EmailInput
                    name="admin-email"
                    id="admin-email"
                    inputValue={email}
                    label="Email address"
                    touched={true}
                    fullWidth={true}
                    onEmailErrorChange={setHasEmailError}
                    onEmailValueChange={setEmail}
                  />
                </Grid>
                <Grid item sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="company-country-label">Country</InputLabel>
                    <Select
                      labelId="company-country-label"
                      id="company-country"
                      value={country}
                      onChange={onCountrySelect}>
                      <MenuItem value={Country.CANADA}>{Country.CANADA}</MenuItem>
                      <MenuItem value={Country.AMERICA}>{Country.AMERICA}</MenuItem>
                      {country !== Country.CANADA && country !== Country.AMERICA &&
                                                <MenuItem value={country}>{country}</MenuItem>
                      }
                    </Select>
                    {country !== Country.CANADA && country !== Country.AMERICA &&
                                            <span style={{
                                              color: theme.palette.error.main,
                                            }}>Not default country, select from top two options</span>
                    }
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  {/* <TextField id="dwelling-zipcode"
                                        fullWidth
                                        label={country !== Country.AMERICA ? 'Postal Code' : 'Zip'}
                                        required
                                        defaultValue={dwelling.postal_code}
                                        onChange={handlePostalCodeChange}
                                        error={postalCodeError !== ''}
                                        helperText={postalCodeError}
                                    /> */}
                  {country && (
                    <PostalCodeInput
                      name="dwelling-zipcode"
                      id="dwelling-zipcode"
                      label={country !== Country.AMERICA ? 'Postal Code' : 'Zip'}
                      touched={true}
                      fullWidth={true}
                      country={country}
                      inputValue={postalCode}
                      onPostalCodeValueChange={setPostalCode}
                      onPostalCodeErrorChange={setHasPostalCodeError}
                    />
                  )}
                </Grid>
                <Grid item sm={6}>
                  <TextField id="company-city"
                    fullWidth
                    label="City"
                    required
                    defaultValue={dwelling.city}
                    error={city === ''}
                    helperText={city === '' ? 'City is required' : ''}
                    onChange={e => {
                      setCity(e.target.value)
                      setGeneralRequiredError(e.target.value === '')
                    }}
                  />
                </Grid>
                <Grid item sm={6}>
                  <Autocomplete
                    id="company-province"
                    options={selectStateOptions(country)}
                    style={{
                      transform: 'translateY(-8px)',
                    }}
                    value={province}
                    size="small"
                    onChange={(handleProvinceChange)}
                    renderInput={(params) => (
                      <TextField {...params}
                        label={country !== Country.AMERICA ? 'Province' : 'State'}
                        required
                        margin="normal"
                        variant="outlined"
                        error={province === 'Select'}
                        helperText={province === 'Select' ? 'Selection is required' : ''}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={12}>
                  <TextField id="dwelling-address-1"
                    fullWidth
                    label="Address Line 1"
                    required
                    defaultValue={dwelling.street_1}
                    error={street_1 === ''}
                    helperText={street_1 === '' ? 'Address Line 1 is required' : ''}
                    onChange={e => {
                      setStreet1(e.target.value)
                      setGeneralRequiredError(e.target.value === '')
                    }}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField id="dwelling-address-2"
                    fullWidth
                    label="Address Line 2"
                    defaultValue={dwelling.street_2}
                    onChange={e => setStreet2(e.target.value)}
                  />
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={handleCancel}>Cancel</Button>
            <Button
              color="secondary"
              disabled={generalRequiredError || hasPhoneError || hasEmailError || hasPostalCodeError}
              variant="contained"
              onClick={handleSave}
              autoFocus
              style={{
                margin: '1rem',
              }}>
                            SAVE
            </Button>
          </DialogActions>
        </>
      )}
      { updating &&
                <DialogContent className={classes.dialogContainer}>
                  <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
                    <CircularProgress />
                  </Box>
                </DialogContent>
      }
    </Dialog>
  )
}
