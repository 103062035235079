import React, { useState } from 'react'
import MaterialTable, { Options } from 'material-table'
import {
  Menu, MenuItem, ListItemIcon, Typography, Select, FormControl, Button,
} from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import ClearIcon from '@material-ui/icons/Clear'
import RefreshIcon from '@material-ui/icons/Refresh'
import EditIcon from '@material-ui/icons/Edit'
import { TeamMember } from 'state-mngt/models/user'
import {
  MemberStatus, MemberTeam, MEMBER_TEAM_NAMES, MEMBER_STATUS_NAMES, MEMBER_TEAM_OPTIONS,
} from 'utils/constants/member-enums'
import tableIcons from 'features/customers-list/table-icons'
import TeamListToolbar from 'features/preferences/team-mngt/team-list/components/team-list-table/components/team-list-toolbar'
import { useMixPanel } from 'features/analytics/mixpanel-provider'

interface Props {
  items: TeamMember[];
  onSave: (members: TeamMember[]) => Promise<void>;
  onStartResend: (member: TeamMember) => void;
  onStartRemove: (member: TeamMember) => void;
}

export const tableOptions: Options<any> = {
  sorting: true,
  rowStyle: (_, rowIndex) => {
    if (rowIndex % 2) {
      return {
        backgroundColor: '#F6FBFE',
      }
    }
    return {

    }
  },
  headerStyle: {
    fontWeight: 'bold',
  },
  actionsColumnIndex: -1,
  draggable: false,
  search: false,
  paging: false,
}

const localization = {
  header: {
    actions: '',
  },
}

const TeamListTable: React.FC<Props> = ({
  items, onSave, onStartResend, onStartRemove,
}) => {
  const { mixpanel } = useMixPanel()

  const [editRowIds, setEditRowIds] = useState<number[]>([])
  const [isSaveDisabled, setSaveDisabled] = useState(true)
  const [isSaving, setSaving] = useState(false)
  const [anchorPosition, setAnchorPosition] = useState<null | { top: number; left: number }>(null)
  const [menuRow, setMenuRow] = useState<null | TeamMember>(null)
  const [updatedItems, setUpdatedItems] = useState<TeamMember[]>([])
  const isEditing = editRowIds.length > 0

  const openRowMenu = (event: React.MouseEvent<HTMLElement>, row: TeamMember | TeamMember[]) => {
    if (!Array.isArray(row)) {
      setAnchorPosition({
        left: event.clientX,
        top: event.clientY,
      })
      setMenuRow(row)
    }
  }

  const closeRowMenu = () => {
    setAnchorPosition(null)
    setMenuRow(null)
  }

  const startEdit = () => {
    if(mixpanel) {
      mixpanel.track('pp_teamMgmtPage_editTeamButton')
    }

    if (menuRow) {
      setEditRowIds((rowIds) => rowIds.concat(menuRow.id))
    }
    closeRowMenu()
  }

  const cancelEdit = () => {
    setEditRowIds([])
    setSaveDisabled(true)
    setUpdatedItems([])
  }

  const saveEdit = () => {
    setSaving(true)
    onSave(updatedItems)
      .then(() => {
        setSaving(false)
        cancelEdit()
      })
      .catch(() => {
        setSaving(false)
      })
  }

  const onChangeRole = (event: React.ChangeEvent<{ value: unknown }>, row: TeamMember) => {
    setSaveDisabled(false)
    setUpdatedItems((updatedItems) => {
      return updatedItems.some((item) => item.id === row.id) ?
        updatedItems.map((item) => item.id === row.id ? ({
          ...item,
          team: event.target.value as MemberTeam,
        }) : item) :
        updatedItems.concat({
          ...row,
          team: event.target.value as MemberTeam,
        })
    })
  }

  const startResend = () => {
    if (menuRow) {
      onStartResend(menuRow)
    }
    closeRowMenu()
  }

  const startRemove = () => {
    if (menuRow) {
      onStartRemove(menuRow)
    }
    closeRowMenu()
  }

  return (
    <>
      <MaterialTable
        icons={tableIcons}
        localization={localization}
        options={tableOptions}
        columns={[
          {
            title: 'First Name',
            emptyValue: 'Pending',
            field: 'first_name',
            searchable: false,
            defaultSort: 'asc',
            customSort: (data1, data2) => {
              // @ts-ignore
              return data1.first_name.localeCompare(data2.first_name, 'en', {
                sensitivity: 'base',
              })
            },
            cellStyle: (_, row) => {
              const styles = {
                height: '32px',
                color: 'inherit',
              }
              if ((row.status === MemberStatus.PENDING) || (row.status === MemberStatus.EXPIRED))  {
                styles.color = '#96A7AF'
              }
              return styles
            },
            render: (row) => (row.first_name ? row.first_name : 'First Name'),
          },
          {
            title: 'Last Name',
            emptyValue: 'Pending',
            field: 'last_name',
            searchable: false,
            customSort: (data1, data2) => {
              // @ts-ignore
              return data1.last_name.localeCompare(data2.last_name, 'en', {
                sensitivity: 'base',
              })
            },
            cellStyle: (_, row) => {
              const styles = {
                height: '32px',
                color: 'inherit',
              }
              if ((row.status === MemberStatus.PENDING) || (row.status === MemberStatus.EXPIRED)) {
                styles.color = '#96A7AF'
              }
              return styles
            },
            render: (row) => (row.last_name ? row.last_name : 'Last Name'),
          },
          {
            title: 'Email',
            field: 'email',
            searchable: false,
            customSort: (data1, data2) => {
              return data1.email.localeCompare(data2.email, 'en', {
                sensitivity: 'base',
              })
            },
            cellStyle: {
              height: '32px',
            },
          },
          {
            title: 'Team',
            field: 'role',
            searchable: false,
            customSort: (data1, data2) => {
              return data1.team.localeCompare(data2.team, 'en', {
                sensitivity: 'base',
              })
            },
            cellStyle: {
              height: '32px',
            },
            render: (row: TeamMember) => {
              if (!editRowIds.includes(row.id)) {
                return MEMBER_TEAM_NAMES[row.team]
              }
              const updatedRow = updatedItems.find((item) => row.id === item.id)
              const rowData = updatedRow ? updatedRow : row
              return (
                <FormControl variant="filled" size="small">
                  <Select
                    variant="outlined"
                    value={rowData.team}
                    onChange={(option) => onChangeRole(option, rowData)}
                  >
                    {MEMBER_TEAM_OPTIONS.map(({ name, label }) => (
                      <MenuItem key={name} value={name}>{label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )
            },
          },
          {
            title: 'Status',
            field: 'status',
            searchable: false,
            customSort: (data1, data2) => {
              return data1.status.localeCompare(data2.status, 'en', {
                sensitivity: 'base',
              })
            },
            cellStyle: {
              height: '32px',
            },
            render: (row: TeamMember) => MEMBER_STATUS_NAMES[row.status],
          },
          {
            cellStyle: {
              height: '32px',
            },
            render: (row => (
              <>
                {
                  row.status === MemberStatus.EXPIRED &&
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => onStartResend(row)}
                    >
                      Resend Invitation
                    </Button>
                }
              </>
            )),
          },
        ]}
        actions={[
          (row: TeamMember) => ({
            icon: () => <MoreHorizIcon />,
            hidden: editRowIds.includes(row.id),
            tooltip: '',
            onClick: (event, row) => openRowMenu(event, row),
          }),
        ]}
        data={items}
        title=""
        components={{
          Container: ({ children }) => (
            <div>
              {children}
            </div>
          ),
          Toolbar: () => (
            <TeamListToolbar
              isEditing={isEditing}
              isSaveDisabled={isSaveDisabled}
              cancelEdit={cancelEdit}
              saveEdit={saveEdit}
              isSaving={isSaving}
            />
          ),
        }}
      />
      <Menu
        id="actions-menu"
        anchorPosition={anchorPosition !== null ? anchorPosition : undefined}
        anchorReference="anchorPosition"
        open={Boolean(anchorPosition)}
        onClose={closeRowMenu}
      >
        {menuRow?.status === MemberStatus.ACTIVE && (
          <MenuItem onClick={startEdit}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Edit team</Typography>
          </MenuItem>
        )}
        {(menuRow?.status === MemberStatus.PENDING || menuRow?.status === MemberStatus.EXPIRED) && (
          <MenuItem onClick={startResend}>
            <ListItemIcon>
              <RefreshIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Resend invitation</Typography>
          </MenuItem>
        )}
        <MenuItem onClick={startRemove}>
          <ListItemIcon>
            <ClearIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Remove</Typography>
        </MenuItem>
      </Menu>
    </>
  )
}

export default TeamListTable
