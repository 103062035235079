import React, { useEffect, useState } from 'react'
import {
  Dialog, makeStyles, Typography, Box, DialogContent, DialogActions, Button, DialogTitle,
} from '@material-ui/core'
import { useMixPanel } from 'features/analytics/mixpanel-provider'
import { theme } from 'theme'

const useStyles = makeStyles({
  dialogTitle: {
    margin: 0,
    paddingTop: theme.spacing(4),
    paddingBottom: 0,
    textAlign: 'left',
  },
  dialogContent: {
    display: 'flex',
    width: '350px',
    justifyContent: 'left',
    alignItems: 'center',
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    alignContent: 'stretch',
  },
})

export interface ExitTourDialogProps {
  isOpen: boolean;
  onContinueTour?: () => void;
  onExitTour?: () => void;
}

export const ExitTourDialog = (props: ExitTourDialogProps) => {
  const classes = useStyles()
  const { isOpen = true, onContinueTour, onExitTour } = props
  const { mixpanel } = useMixPanel()

  const handleContinueTour = () => {
    if (mixpanel) {
      mixpanel.track('pp_productTour_exitTourDialog_continueButton')
    }

    if (onContinueTour) {
      onContinueTour()
    }
  }

  const handleExitTour = () => {
    if (mixpanel) {
      mixpanel.track('pp_productTour_exitTourDialog_exitButton')
    }

    if (onExitTour) {
      onExitTour()
    }
  }

  useEffect(() => {
    if (mixpanel && isOpen) {
      mixpanel.track('pp_productTour_exitTourDialog_open')
    }
  }, [mixpanel, isOpen])


  return (
    <Dialog
      aria-labelledby="exit-tour-dialog-title"
      open={isOpen}
      onClose={ (event: object, reason: string) => {
        if (reason !== 'backdropClick') {
          handleExitTour()
        }
      }}>
      <DialogTitle id="exit-tour-dialog-title" disableTypography className={classes.dialogTitle}>
        <Typography variant="h5" color="textPrimary">Exit the tour?</Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="body1" color="textPrimary">
          The progress will be lost.<br/><br/>
          You can restart the tour by <br/>clicking the "Quick Guide" button.
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="contained"
          onClick={handleExitTour}
          style={{
            flex: 1,
            margin: '1rem',
            color: theme.palette.error.main,
            border: '1px solid ' + theme.palette.error.main,
          }}>
            EXIT ANYWAY
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleContinueTour}
          style={{
            flex: 1,
            margin: '1rem',
          }}>
            CONTINUE
        </Button>
      </DialogActions>
    </Dialog>
  )
}
