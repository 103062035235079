import React from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

const PmIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0784 5.08084C10.0784 3.93162 10.9958 3 12.1275 3C13.2591 3 14.1765 3.93162 14.1765 5.08084C14.1765 6.23005 13.2591 7.16167 12.1275 7.16167C10.9958 7.16167 10.0784 6.23005 10.0784 5.08084ZM12.6863 5.08084C12.6863 4.76741 12.4361 4.51334 12.1275 4.51334C11.8188 4.51334 11.5686 4.76741 11.5686 5.08084C11.5686 5.39426 11.8188 5.64834 12.1275 5.64834C12.4361 5.64834 12.6863 5.39426 12.6863 5.08084ZM18.7168 6.15234L14.941 12.0002C14.7178 12.346 14.2517 12.4434 13.9 12.2178C13.5753 12.0096 13.4616 11.599 13.6216 11.2651L13.6674 11.1834L17.4431 5.33551C17.6664 4.98974 18.1325 4.8923 18.4842 5.11786C18.8088 5.32607 18.9226 5.73673 18.7625 6.07062L18.7168 6.15234ZM16.753 15.3881C16.3998 14.6306 15.5088 14.2985 14.7629 14.6463L11.7241 16.0633C10.9782 16.4111 10.6599 17.3072 11.0131 18.0646L11.9724 20.122C12.3257 20.8795 13.2167 21.2116 13.9626 20.8637L17.0014 19.4467C17.7473 19.0989 18.0656 18.2029 17.7124 17.4454L16.753 15.3881ZM12.3637 17.4349L15.4025 16.0179L16.3618 18.0752L13.323 19.4922L12.3637 17.4349ZM19.951 12.08C18.8193 12.08 17.902 13.0116 17.902 14.1608C17.902 15.3101 18.8193 16.2417 19.951 16.2417C21.0826 16.2417 22 15.3101 22 14.1608C22 13.0116 21.0826 12.08 19.951 12.08ZM19.951 13.5933C20.2596 13.5933 20.5098 13.8474 20.5098 14.1608C20.5098 14.4743 20.2596 14.7283 19.951 14.7283C19.6424 14.7283 19.3922 14.4743 19.3922 14.1608C19.3922 13.8474 19.6424 13.5933 19.951 13.5933ZM6.91176 8.29667C4.75136 8.29667 3 10.0752 3 12.2692C3 14.4631 4.75136 16.2417 6.91176 16.2417C9.07217 16.2417 10.8235 14.4631 10.8235 12.2692C10.8235 10.0752 9.07217 8.29667 6.91176 8.29667ZM6.91176 9.81001C8.24916 9.81001 9.33333 10.911 9.33333 12.2692C9.33333 13.6273 8.24916 14.7283 6.91176 14.7283C5.57437 14.7283 4.4902 13.6273 4.4902 12.2692C4.4902 10.911 5.57437 9.81001 6.91176 9.81001Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default PmIcon
