import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  Typography,
  FormControl,
  FormControlLabel,
  Paper,
  RadioGroup,
  Radio,
  Divider,
} from '@material-ui/core'
import { User } from 'state-mngt/models/user'
import 'features/preferences/settings/settings.scss'
import { useMixPanel } from 'features/analytics/mixpanel-provider'
import { ErrorAutoHideWithDismissAction, SuccessAutoHideWithDismissAction } from 'ui/custom-snackbar-provider'
import PrimarySwitch from 'ui/primary-switch'
import { UserPreferencesEnum } from 'utils/constants/preference-enums'
import { updateUser } from 'state-mngt/actions/user-actions'
import PreferencesSideMenuLayout from 'features/preferences/preferences-side-menu-layout'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxTypes'
import { usePageTitle } from 'utils/hooks/router'
import InfoTooltip from 'ui/info-tooltip'
import InfoIcon from '@material-ui/icons/Info'
import { selectIsLoadingUser, selectUser } from 'state-mngt/selectors/user-selectors'

const Settings = () => {
  const { mixpanel } = useMixPanel()
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const user = useAppSelector(selectUser)
  const isLoadingUser = useAppSelector(selectIsLoadingUser)

  const [updatedUser, setUpdatedUser] = useState<User>()

  usePageTitle('Settings')

  useEffect(() => {
    if (user) {
      setUpdatedUser(user)
    }
  }, [user])

  useEffect(() => {
    if (mixpanel) {
      mixpanel.track('pp_settingsPage_open')
    }
  }, [mixpanel])

  const saveChanges = async (user?: User) => {
    if (!user || user.id === undefined) {
      return
    }

    const wasUserUpdated = await dispatch(updateUser(user.id, user, true))

    if (wasUserUpdated)
      enqueueSnackbar('User information updated successfully!', SuccessAutoHideWithDismissAction())
    else
      enqueueSnackbar('User information updated failed!', ErrorAutoHideWithDismissAction())
  }

  /**
   * callback for any preference toggle clicks, set state based on the change
   * @param event - ChangeEvent<HTMLInputElement>
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newUser = updatedUser ? {
      ...updatedUser,
      [event.target.name]: event.target.value,
    } : undefined
    setUpdatedUser(newUser)
    saveChanges(newUser)
  }

  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newUser = updatedUser ? {
      ...updatedUser,
      [event.target.name]: event.target.checked,
    } : undefined
    setUpdatedUser(newUser)
    saveChanges(newUser)
  }

  if (isLoadingUser) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <PreferencesSideMenuLayout>
      <Box px={4} py={2} display="flex">
        <Box width={420}>
          <Box pr={6}>
            <Box mb={2}>
              <Typography variant="h5">Data units</Typography>
            </Box>
            {updatedUser && (
              <>
                <Box mb={4}>
                  <Paper
                    elevation={3}
                    style={{
                      borderRadius: 4,
                    }}
                  >
                    <Box px={3} py={2}>
                      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                        <Typography variant="h6">Temperature</Typography>
                        <InfoTooltip
                          placement="bottom-start"
                          arrow
                          interactive
                          title={
                            <>
                              <Typography variant="caption" color="secondary">
                                <strong>Celcius (°C)</strong> - The unit of measuring temperature commonly used in
                                Canada (the ol’ homeland of HAVEN!) and the rest of the world.
                                <br />
                                <strong>Fahrenheit (°F)</strong> - The unit of measuring temperature commonly used in
                                the USA (sorry guys, you’re pretty much on your own with this bizarre temperature
                                scale!)
                              </Typography>
                            </>
                          }
                        >
                          <InfoIcon
                            color="primary"
                            style={{
                              marginLeft: 8,
                            }}
                          />
                        </InfoTooltip>
                      </Box>
                      <Box>
                        <FormControl component="fieldset">
                          <RadioGroup
                            name="temperature_unit"
                            value={updatedUser.temperature_unit}
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              value={UserPreferencesEnum.CELSIUS}
                              control={<Radio color="primary" />}
                              label="Celcius (°C)"
                            />
                            <FormControlLabel
                              value={UserPreferencesEnum.FAHRENHEIT}
                              control={<Radio color="primary" />}
                              label="Fahrenheit (°F)"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </Box>
                  </Paper>
                </Box>
                <Box mb={4}>
                  <Paper
                    elevation={3}
                    style={{
                      borderRadius: 4,
                    }}
                  >
                    <Box px={3} py={2}>
                      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                        <Typography variant="h6">PM</Typography>
                        <InfoTooltip
                          placement="bottom-start"
                          arrow
                          interactive
                          title={
                            <>
                              <Typography variant="caption" color="secondary" gutterBottom>
                                <strong>
                                  μg/m<sup>3</sup>
                                </strong>{' '}
                                - Micrograms per cubic meter refers to the average concentration of 2.5 micron (PM2.5)
                                particles detected in a cubic meter of air.
                                <br />
                                <strong>
                                  count/cm<sup>3</sup>
                                </strong>{' '}
                                - This is the average number of inhalable particle counted per cubic meter of air that
                                is sampled.
                              </Typography>
                              <br />
                              <Typography variant="caption" color="secondary">
                                Micrograms per cubic meter is the standard measurement used the EPA for producing the
                                Air Quality Index (AQI).
                              </Typography>
                            </>
                          }
                        >
                          <InfoIcon
                            color="primary"
                            style={{
                              marginLeft: 8,
                            }}
                          />
                        </InfoTooltip>
                      </Box>
                      <Box>
                        <FormControl component="fieldset">
                          <RadioGroup name="pm_unit" value={updatedUser.pm_unit} onChange={handleChange}>
                            <FormControlLabel
                              value={UserPreferencesEnum.PM_MC}
                              control={<Radio color="primary" />}
                              label="μg/m³"
                            />
                            <FormControlLabel
                              value={UserPreferencesEnum.PM_COUNT}
                              control={<Radio color="primary" />}
                              label="count/cm³"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </Box>
                  </Paper>
                </Box>
                <Box mb={4}>
                  <Paper
                    elevation={3}
                    style={{
                      borderRadius: 4,
                    }}
                  >
                    <Box px={3} py={2}>
                      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                        <Typography variant="h6">tVOC</Typography>
                        <InfoTooltip
                          placement="bottom-start"
                          arrow
                          interactive
                          title={
                            <>
                              <Typography variant="caption" color="secondary">
                                <strong>ppb</strong> - This number represents the average parts per billion (ppb) of
                                volatile organic compounds (VOC) detected in a sample of air.
                                <br />
                                <strong>μg/meter³</strong> - Micrograms per cubic meter refers to the concentration of
                                volatile organic compounds (VOC) detected in a sample of air.
                              </Typography>
                            </>
                          }
                        >
                          <InfoIcon
                            color="primary"
                            style={{
                              marginLeft: 8,
                            }}
                          />
                        </InfoTooltip>
                      </Box>
                      <Box>
                        <FormControl component="fieldset">
                          <RadioGroup name="voc_unit" value={updatedUser.voc_unit} onChange={handleChange}>
                            <FormControlLabel
                              value={UserPreferencesEnum.VOC_COUNT}
                              control={<Radio color="primary" />}
                              label="ppb"
                            />
                            <FormControlLabel
                              value={UserPreferencesEnum.VOC_MC}
                              control={<Radio color="primary" />}
                              label="μg/m³"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </Box>
                  </Paper>
                </Box>
              </>
            )}
          </Box>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box display="flex" flexDirection="row">

          {/* Get Started navigation-bar button settings. */}
          <Box pl={6} width={420}>
            <Box display="flex" alignItems="center" justifyContent="left" mb={2}>
              <Typography variant="h5">Get Started</Typography>
              <InfoTooltip
                placement="bottom-start"
                arrow
                interactive
                title={
                  <>
                    <Typography variant="caption" color="secondary" gutterBottom>
                      It contains two modules:
                      <ul style={{
                        marginTop: 0,
                        marginLeft: '1.2rem',
                      }}>
                        <li>
                          <b>Pro Portal </b>Tour will provide a quick overview of how to get the most value from
                          Pro Portal features and resources.
                        </li>
                        <li>
                          Demo Accounts Tour illustrates different IAQ issues and how those can be resolved using
                          HAVEN Monitor and Controller integrations.
                        </li>
                      </ul>
                    </Typography>

                  </>
                }
              >
                <InfoIcon
                  color="primary"
                  style={{
                    marginLeft: 8,
                  }}
                />
              </InfoTooltip>
            </Box>
            {updatedUser && (
              <Box mb={4}>
                <Paper
                  elevation={3}
                  style={{
                    borderRadius: 4,
                  }}
                >
                  <Box px={3} py={2}>
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                      <Typography variant="h6">Show Get Started</Typography>
                      <PrimarySwitch
                        color="primary"
                        checked={updatedUser.pro_portal_tour_enabled}
                        onChange={handleChecked}
                        name="pro_portal_tour_enabled"
                      />
                    </Box>
                    <Box>
                      <Typography variant="body2">
                        Show or hide the “Get Started” button that allows you to start Pro Portal tour and Demo Accounts tour.
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            )}
          </Box>

          {/* Demo accounts settings. */}
          <Box pl={6} width={420}>
            <Box display="flex" alignItems="center" justifyContent="left" mb={2}>
              <Typography variant="h5">Demo Accounts</Typography>
              <InfoTooltip
                placement="bottom-start"
                arrow
                interactive
                title={
                  <>
                    <Typography variant="caption" color="secondary" gutterBottom>
                      These are 3 fictional customer accounts (or demo accounts as we call them) that illustrate
                      different IAQ issues and how those can be resolved using HAVEN Monitor and Controller integrations.
                    </Typography>
                  </>
                }
              >
                <InfoIcon
                  color="primary"
                  style={{
                    marginLeft: 8,
                  }}
                />
              </InfoTooltip>
            </Box>
            {updatedUser && (
              <Box mb={4}>
                <Paper
                  elevation={3}
                  style={{
                    borderRadius: 4,
                  }}
                >
                  <Box px={3} py={2}>
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                      <Typography variant="h6">Show Demo Accounts</Typography>
                      <PrimarySwitch
                        color="primary"
                        checked={updatedUser.pro_portal_demo_enabled}
                        onChange={handleChecked}
                        name="pro_portal_demo_enabled"
                      />
                    </Box>
                    <Box>
                      <Typography variant="body2">
                        Show or hide demo customers accounts on the Customers page.
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </PreferencesSideMenuLayout>
  )
}

export default Settings
