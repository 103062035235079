import { useState, useEffect } from 'react'
import { createRequestsAdapter } from 'utils/http-utils'
import { RequestsAdapter } from 'state-mngt/models/http'

/**
 * Hook that creates a single request adapter for sending HTTP
 */
export const useRequestsAdapter = () => {
  const [requestsAdapter, setRequestsAdapter] = useState<RequestsAdapter>(createRequestsAdapter())

  useEffect(() => {
    if (!requestsAdapter) {
      setRequestsAdapter(createRequestsAdapter())
    }

    // Abort all requests under this Request adapter during cleanup.
    return () => {
      requestsAdapter?.abortAll()
    }
  }, [requestsAdapter])

  return requestsAdapter
}
