import { Box, Button, Typography, withStyles } from '@material-ui/core'
import * as React from 'react'
import ButtonSpinner from './button-spinner'

const ColorButton = withStyles((theme) => ({
    root: {
        color: theme.palette.error.main,
        '&:hover': {
            color: theme.palette.error.dark,
        },
    },
}))(Button)

function EntityHeader({ loading, handleDelete, children }) {
    return (
        <Box
            display='flex'
            width='100%'
            alignItems='center'
            justifyContent='space-between'
        >
            <Typography variant='subtitle1'>
                {children}
            </Typography>
            <ColorButton
                disabled={loading}
                onClick={handleDelete}
            >{loading ? <ButtonSpinner /> : <>Delete</>}</ColorButton>
        </Box>
    )
}

export default EntityHeader
