import React, {
  useState, useCallback, useMemo, useEffect,
} from 'react'
import {
  Box, Breadcrumbs, Typography, Divider, Grid, Container, Button,
} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ROUTE_PATHS } from 'routes/routes'
import { MemberTeam, MemberStatus } from 'utils/constants/member-enums'
import { TeamMember, UserServiceCompanyInvite } from 'state-mngt/models/user'
import { isEmailValid } from 'utils/form-utils'
import TeamAddGroup from 'features/preferences/team-mngt/team-add/components/team-add-group'
import TeamConfirmationDialog from 'features/preferences/team-mngt/team-confirmation-dialog'
import { inviteUserToJoinCompany } from 'state-mngt/actions/company-actions'
import { selectCompanyId } from 'state-mngt/selectors/company-selectors'
import { ServiceCompanyInviteResult } from 'state-mngt/models/serviceCompany'
import { useSnackbar } from 'notistack'
import {
  ErrorPersistWithDismissAction, SuccessPersistWithDismissAction,
} from 'ui/custom-snackbar-provider'
import { HavenApiErrorCode } from 'state-mngt/models/http'
import { useMixPanel } from 'features/analytics/mixpanel-provider'

const TEAM_GROUPS = [
  MemberTeam.Management,
  MemberTeam.Sales,
  MemberTeam.Service,
]

interface FormItem {
  value: string;
  isTouched: boolean;
  isValid: boolean;
}

interface TeamToFormItem {
  [key: string]: FormItem[],
}

const useAddForm = (groups: MemberTeam[]) => {
  const [valuesMap, setValues] = useState<TeamToFormItem>(
    groups.reduce((acc, team) => (
      {
        ...acc,
        [team]: [
          {
            value: '',
            isValid: false,
            isTouched: false,
          },
        ],
      }
    ), {
    },
    ),
  )

  const addItem = useCallback((team: MemberTeam, initialValue = '') => {
    setValues((valuesMap) => ({
      ...valuesMap,
      [team]: valuesMap[team].concat({
        value: initialValue,
        isValid: false,
        isTouched: false,
      }),
    }))
  }, [setValues])

  const updateItem = useCallback((team: MemberTeam, updatedItem: Partial<FormItem>, updatedIndex: number) => {
    setValues((valuesMap) => ({
      ...valuesMap,
      [team]: valuesMap[team].map((item, index) => index === updatedIndex ? ({
        ...item,
        isValid: isEmailValid(updatedItem.value !== undefined ? updatedItem.value : item.value),
        ...updatedItem,
      }) : item),
    }))
  }, [setValues])

  const removeItem = useCallback((team: MemberTeam, removedIndex: number) => {
    setValues((valuesMap) => ({
      ...valuesMap,
      [team]: valuesMap[team].filter((item, index) => index !== removedIndex),
    }))
  }, [setValues])

  const isFormValid = useMemo(() => {
    const valuesArray = Object.values(valuesMap)

    if (valuesArray.some((group) => group.some((item) => item.value))) {
      return !valuesArray.some((group) => group.some((item) => item.value && !item.isValid))
    }
    return false
  }, [valuesMap])

  return {
    valuesMap,
    addItem,
    updateItem,
    removeItem,
    isFormValid,
  }
}

interface InviteResultPromise {
  invite: UserServiceCompanyInvite,
  inviteResult: ServiceCompanyInviteResult,
}

const TeamAdd = () => {
  const {
    valuesMap, addItem, updateItem, removeItem, isFormValid,
  } = useAddForm(TEAM_GROUPS)

  const [isConfirmationDialog, setConfirmationDialog] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const companyId = useSelector(selectCompanyId)
  const { enqueueSnackbar } = useSnackbar()
  const { mixpanel } = useMixPanel()

  useEffect(() => {
    if(mixpanel) {
      mixpanel.track('pp_teamMgmtPage_addMembersPage_Open')
    }
  }, [mixpanel])

  const handleErrorMessage = (error: ServiceCompanyInviteResult) => {
    if (error.code == HavenApiErrorCode.INVALID_PARAMETER.valueOf()) {
      return (
        <>
          <b>
            • Uhoh, {error.invite.email} couldn’t be added to the {error.invite.team} team at this time.
            &nbsp;Please try again later or contact&nbsp;
            <a href="mailto:prosupport@haveniaq.com">prosupport@haveniaq.com.</a> [Error code {error.code}]
          </b><br/>
        </>
      )
    } else if (error.code == HavenApiErrorCode.INVALID_PERMISSION.valueOf()) {
      return (
        <>
          <b>
            • Hmm, it doesn’t look like you have the right permissions to add new users to this service company.&nbsp;
            Please ask your HAVEN Pro admin user for assistance or contact&nbsp;
            <a href="mailto:prosupport@haveniaq.com">prosupport@haveniaq.com.</a> [Error code {error.code}]
          </b><br/>
        </>
      )
    } else if (error.code == HavenApiErrorCode.DUPLICATE.valueOf()) {
      return (
        <>
          <b>
            • An invitation to {error.invite.email} has already been sent. [Error code {error.code}]
          </b><br/>
        </>
      )
    } else if (error.code == HavenApiErrorCode.CONFLICT.valueOf()) {
      return (
        <>
          <b>
            • A user with this email address {error.invite.email} has already been added to a service company.&nbsp;
            [Error code {error.code}]
          </b><br/>
        </>
      )
    } else if (error.code == HavenApiErrorCode.NOT_FOUND.valueOf()) {
      return (
        <>
          <b>
            • Sorry! We’re having some technical issues right now. Please try again later or contact&nbsp;
            <a href="mailto:prosupport@haveniaq.com">prosupport@haveniaq.com.</a> [Error code {error.code}]
          </b><br/>
        </>
      )
    } else {
      return (
        <>
          <b>
            • Sorry! We’re having some technical issues right now. Please try again later or contact&nbsp;
            <a href="mailto:prosupport@haveniaq.com">prosupport@haveniaq.com.</a> [Error code {error.code}]
          </b><br/>
        </>
      )
    }
  }

  const teamMembers = useMemo(() => {
    return Object
      .entries(valuesMap)
      .reduce((acc: TeamMember[], [team, items]) => {
        const teamMembersInGroup = items
          .filter(({ value }) => value.trim().length > 0)
          .map(({ value }) => ({
            email: value,
            team: team as MemberTeam,
            id: -1,
            status: MemberStatus.PENDING,
          }))
        return acc.concat(teamMembersInGroup)
      }, [])
  }, [valuesMap])

  const onCancelSend = useCallback(() => {
    setConfirmationDialog(false)
  }, [setConfirmationDialog])

  const onStartSend = useCallback((event) => {
    if(mixpanel) {
      mixpanel.track('pp_teamMgmtPage_addMembersPage_sendInviteOpenDialog')
    }

    event.preventDefault()
    setConfirmationDialog(true)
  }, [setConfirmationDialog])

  const onConfirmSend = useCallback(async (items) => {
    const promisesDispatch: Promise<InviteResultPromise>[] = []

    for (const currentItem of items) {
      if (companyId) {
        promisesDispatch.push(
          // @ts-ignore
          (dispatch(
            inviteUserToJoinCompany(
              {
                service_company_id: companyId, team: currentItem.team, email: currentItem.email,
              },
            ),
          ) as Promise<ServiceCompanyInviteResult>),
        )
      }
    }

    (Promise as any).allSettled(promisesDispatch)
      .then((results: Object[]) => {
        const allRejected = results.filter(currentElement => {
          // @ts-ignore
          return currentElement.status === 'rejected'
        })

        const allFulfilled = results.filter(currentElement => {
          // @ts-ignore
          return currentElement.status === 'fulfilled'
        })

        if (allRejected && allRejected.length > 0) {
          const errorMessages: JSX.Element[] = []

          allRejected.forEach((rejected => {
            errorMessages.push(
              // @ts-ignore
              handleErrorMessage(rejected!.reason),
            )
          }))

          enqueueSnackbar(
            <div>
              We’re having problems sending {allRejected.length}
              {allRejected.length > 1 ? ' invitations' : ' invitation'}. Please review the notes below:<br/>
              {errorMessages}
            </div>,
            ErrorPersistWithDismissAction(),
          )
        }

        if (allFulfilled && allFulfilled.length > 0) {
          enqueueSnackbar(
            <div>
              {allFulfilled.length} out of {results.length} {allFulfilled.length > 1 ? ' invitations' : ' invitation'}
              &nbsp;sent successfully!
            </div>,
            SuccessPersistWithDismissAction(),
          )
        }

        setConfirmationDialog(false)
        navigate(ROUTE_PATHS.teamMngt.root.absolute)
      })
  }, [companyId, setConfirmationDialog, enqueueSnackbar, navigate])

  return (
    <Box p={2}>
      <Box px={1} mb={1}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            to={ROUTE_PATHS.teamMngt.root.absolute}
            style={{
              color: '#888',
            }}
          >
            Team
          </Link>
          <Typography color="textPrimary">
            Add new members
          </Typography>
        </Breadcrumbs>
      </Box>
      <Divider />
      <form onSubmit={onStartSend}>
        <Box p={1}>
          <Typography variant="h5" gutterBottom>
            Add new members
          </Typography>
          <Typography variant="body1">
            {'Simply add in your team-mngt members’ email addresses. When they create their HAVEN Pro accounts, ' +
              'they’ll be prompted to complete their profiles!'}
          </Typography>
          <Box my={4}>
            <Container maxWidth="lg">
              <Grid spacing={8} container>
                {TEAM_GROUPS.map((team) => (
                  <Grid key={team} item xs={4}>
                    <TeamAddGroup
                      team={team}
                      items={valuesMap[team]}
                      addItem={addItem}
                      updateItem={updateItem}
                      removeItem={removeItem}
                    />
                  </Grid>
                ))}
              </Grid>
            </Container>
          </Box>
          <Container maxWidth="lg">
            <Grid container spacing={4}>
              <Grid xs={6} item>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={() => {
                    if(mixpanel) {
                      mixpanel.track('pp_teamMgmtPage_addMembersPage_cancelButton')
                    }

                    navigate(-1)
                  }}
                  fullWidth
                >
                  Cancel
                </Button>
              </Grid>
              <Grid xs={6} item>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  endIcon={<EmailIcon />}
                  disabled={!isFormValid}
                  onClick={onStartSend}
                  fullWidth
                >
                  Invite new members
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </form>
      {isConfirmationDialog && (
        <TeamConfirmationDialog
          type="send"
          items={teamMembers}
          onCancel={onCancelSend}
          onConfirm={onConfirmSend}
        />
      )}
    </Box>
  )
}

export default TeamAdd
