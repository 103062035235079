import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { ROUTE_PATHS } from 'routes/routes'
import Settings from 'features/preferences/settings'
import Account from 'features/preferences/account'
import Company from 'features/preferences/company'

export const Preferences = () => {
  return (
    <>
      {/* Descendant Routes, MUST USE RELATIVE PATHS TO MATCH THE ROUTES. */}
      <Routes>
        <Route index element={<Settings />} />
        <Route path={ROUTE_PATHS.preferences.settings.relative} element={<Settings />} />
        <Route path={ROUTE_PATHS.preferences.account.relative} element={<Account />} />
        <Route path={ROUTE_PATHS.preferences.company.relative} element={<Company />} />
      </Routes>
    </>
  )
}
