import { DeviceTriggerType } from 'state-mngt/models/automation'
import { Status } from 'state-mngt/models/status'

export enum DeviceType {
  Cam = 'cam',
  Cac = 'cac',
}

export enum DeviceInstallType {
  NotInstalled = 'not_installed',
  PersonalUseProgram = 'pup',
  HomeownerSale = 'homeowner_sale',
  TemporaryAssessment = 'temporary_assessment',
  MaintenanceContract = 'maintenance_contract',
}

export interface Device {
  id: number;
  type: 'cac' | 'cam';
  name: string;
  dwelling_id: number;
  install_timestamp: string | null;
  hub_id: string;
  hub_device_id: string;
  plastic_serial_number: string; // number on exterior of the box
  pcb_serial_number: string; // pcb board inside
  zone: string;
  equipment_id: number | null;
  installed_on_equipment_name?: string; // e.g: Ventilating Dehumidifier; Air handler.
  /*
  Used only when associated monitors with zones to keep track of monitors that belong to the same zone.
  If a zone includes only one monitor then sameZoneIndex is 0.
  If a zone includes more than one monitor, then each monitor will have an incremental sameZoneIndex, starting from 1. 
  The sameZoneIndex will be used to append to the zone name later when the monitors are separated by zone.
  */
  sameZoneIndex?: number;
}

export interface DeviceContent {
  cam_device_id: number | null;
  dwelling_id: number;
  equipment_id: number | null;
  hub_device_id: string;
  hub_id: string;
  id: number;
  install_timestamp: string | null;
  install_type: DeviceInstallType;
  name: string;
  pcb_serial_number: string;
  plastic_serial_number: string;
  type: DeviceType;
  zone: string;
  zone_size: number;
}

export interface DeviceTelemetry {
  timestamp: string;
  airflow: number;
  voc_mc: number;
  voc_count: number;
  pm_count: number;
  pm_mc: number;
  temperature: number;
  humidity: number;
  voc_status: string;
  pm_status: string;
  combined_status: string;
  highest_factor: string;
}

export interface DeviceTelemetryQuality {
  // The total time in seconds for a specific date/time window that the measurements were taken.
  total_time: number;
  // The total number in seconds which the measurements had data points within the total_time above.
  data_time: number;
  // The measurements bellow are the total time in seconds spent in its specific threshold (poor, fair, good).
  pm_good_time: number;
  pm_fair_time: number;
  pm_poor_time: number;
  voc_good_time: number;
  voc_fair_time: number;
  voc_poor_time: number;
  humidity_low_poor_time: number;
  humidity_low_fair_time: number;
  humidity_good_time: number;
  humidity_high_fair_time: number;
  humidity_high_poor_time: number;
}

export interface CacChannel {
  id: number;
  index: number;
  active: boolean;
  equipment_id: number;
  active_high: boolean;
  trigger_type: DeviceTriggerType;
  override_timeout: null
  scheduler_disable_timestamp: null
  scheduler_active: boolean;
  iaq_controls_active: boolean;
  start_timestamp: string;
  end_timestamp: string;
}

export interface DeviceCacChannels {
  deviceId: number;
  channels: CacChannel[];
}

interface DeviceAutomation {
  triggerTypes: DeviceTriggerType[];
  equipmentName: string;
}

export interface DeviceEntityContent {
  id: number;
  type: DeviceType,
  status: Status;
  isAllLoaded: boolean;
  installType: DeviceInstallType;
  associatedWith?: string;
  automations: DeviceAutomation[];
}

export interface CamHardwareStatus {
  airflow_ok: boolean;
  bme_ok: boolean;
  faceplate_ok: boolean;
  humidity_ok: boolean;
  pm_ok: boolean;
  telemetry_received: boolean;
  temperature_ok: boolean;
  voc_ok: boolean;
}

interface CacHardwareStatus {
  telemetry_received: boolean;
}

export type DeviceHardwareStatus = CacHardwareStatus | CamHardwareStatus

export interface DeviceAirflow {
  airflow_duration: number;
  timestamp: string;
}

export interface CacDeviceTelemetry {
  channel0: boolean;
  channel1: boolean;
  timestamp: string;
}
