import React from 'react'
import { MemberRole } from 'utils/constants/member-enums'
import {
  Box,
  Divider,
  Typography,
} from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import SettingsIcon from '@material-ui/icons/Settings'
import { ROUTE_PATHS } from 'routes/routes'
import SideMenuLayout from 'ui/side-menu-layout'
import { SideMenuItem } from 'ui/side-menu-layout/side-menu-layout'
import companyImageUrl from 'features/preferences/preferences-side-menu-layout/company-building.svg'


interface Props {
  children: React.ReactElement,
}

const menuItems: Array<SideMenuItem> = [
  {
    to: ROUTE_PATHS.preferences.settings.absolute,
    icon: <SettingsIcon />,
    label: 'Settings',
  },
  {
    to: ROUTE_PATHS.preferences.account.absolute,
    icon: <PersonIcon />,
    label: 'Account',
  },
  {
    to: ROUTE_PATHS.preferences.company.absolute,
    icon:
      <img
        alt="Company Building Icon"
        src={companyImageUrl}
        width="24"
        height="24"
      />,
    label: 'Company',
  },
]

const PreferencesSideMenuLayout: React.FC<Props> = ({ children }) => {
  return (
    <>
      <Box height={80} display="flex" alignItems="center" pl={3} pr={3}>
        <Typography variant="h2" color="primary">Settings</Typography>
      </Box>
      <Divider />
      <SideMenuLayout
        isCollapsable
        menuItems={menuItems}
        height="calc(100vh - 81px - 64px)"
        name="preferences"
      >
        {children}
      </SideMenuLayout>
    </>
  )
}

export default PreferencesSideMenuLayout
