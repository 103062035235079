import * as React from 'react'
import { Box, Typography } from '@material-ui/core'
import { AutomationOutput } from 'types'
import useEquipmentAutomation from 'utils/hooks/useEquipmentAutomation'
import Stack from 'ui/stack'
import { findEquipSrc } from './static-input'
import { parseEquipmentType } from 'utils/string-utils'
import Dot from './dot'
import Muted from './muted'

/**
 * Display automation equipment statically
 */
const EquipmentSection = ({ outputs }: { outputs: AutomationOutput[] }) => {
    const automation = useEquipmentAutomation()
    const interlocks = automation?.interlocks || []
    const allOutputs = automation?.outputs || []

    const isInterlocked = outputId =>
        interlocks
            .filter(x => x.enabled)
            .find(x => x.primary_output === outputId)

    const renderInterlock = outputId => {
        const interlock = isInterlocked(outputId)
        const interlockedWith = interlock ? allOutputs.find(y => interlock.secondary_output === y.id)?.equipment?.type : null

        if (!interlockedWith) return null

        return (
            <>
                {interlock ? (
                    <Muted>
                        interlocked with {parseEquipmentType(interlockedWith)}
                    </Muted>
                ) : null}
            </>
        )
    }

    return (
        <>
            {
                outputs.filter(x => x.equipment).map(x => (
                    <Stack
                        key={x.id}
                        direction='row' >
                        <Box display='flex' gridGap='8px'>
                            <img src={findEquipSrc(x.equipment?.type)} />
                            <Box>
                                <Typography variant='body1'>
                                    {parseEquipmentType(x.equipment?.type)}
                                </Typography>
                                {renderInterlock(x.id)}
                            </Box>
                        </Box>
                        <Dot active={Boolean(x.currently_triggered)} />
                    </Stack>
                ))
            }
        </>
    )
}

export default EquipmentSection
