import { Action, AnyAction } from 'redux'
import { ThunkAction } from 'redux-thunk'
import {
  Dwelling,
  DwellingPermission,
  DwellingContent,
} from 'state-mngt/models/dwelling'
import dwellingService from 'state-mngt/services/dwelling-service'
import { RootState } from 'state-mngt/store'
import { ActionPayload } from 'state-mngt/models/redux'
import { RequestsAdapter } from 'state-mngt/models/http'

export enum ActionType {
  GetCustomerDetails = 'customer/GET_CUSTOMER_DETAILS',
  SetDwellingPermission = 'customer/SET_DWELLING_PERMISSION',
  LoadDwellingContentPending = 'customer/LOAD_DWELLING_CONTENT_PENDING',
  LoadDwellingContentResolve = 'customer/LOAD_DWELLING_CONTENT_RESOLVE',
  LoadDwellingContentReject = 'customer/LOAD_DWELLING_CONTENT_REJECT',
  CleanCustomers = 'customer/CLEAN_CUSTOMERS',
  CleanCustomerDetails = 'customer/CLEAN_CUSTOMER_DETAILS',
}

export type SetCustomerDetailsAction = ActionPayload<ActionType.GetCustomerDetails, Dwelling>;
export type SetDwellingPermissionAction = ActionPayload<ActionType.SetDwellingPermission, DwellingPermission[]>;
export type LoadDwellingContentPendingAction = ActionPayload<ActionType.LoadDwellingContentPending, number>;
export type LoadDwellingContentResolveAction = ActionPayload<
  ActionType.LoadDwellingContentResolve,
  { id: number; value: DwellingContent }
>;
export type LoadDwellingContentRejectAction = ActionPayload<ActionType.LoadDwellingContentReject, number>;
export type CleanCustomersAction = Action<ActionType.CleanCustomers>;
export type CleanCustomerDetailsAction = Action<ActionType.CleanCustomerDetails>;

export const setDwelling = (customerDetails: Dwelling): SetCustomerDetailsAction => ({
  type: ActionType.GetCustomerDetails,
  payload: customerDetails,
})

export const setDwellingPermission = (dwellingPermission: DwellingPermission[]): SetDwellingPermissionAction => ({
  type: ActionType.SetDwellingPermission,
  payload: dwellingPermission,
})

export const cleanCustomers = (): CleanCustomersAction => ({
  type: ActionType.CleanCustomers,
})

export const cleanCustomerDetails = (): CleanCustomerDetailsAction => ({
  type: ActionType.CleanCustomerDetails,
})

type ThunkResult<R = void> = ThunkAction<R, RootState, undefined, AnyAction>;

/**
 * gets dwelling from API when providing dwelling id
 * if id doesn't exist, redirects to not found page
 * @param dwellingId - number
 */
export const getDwelling =
  (dwellingId: number, requestsAdapter: RequestsAdapter): ThunkResult =>
    async (dispatch) => {
      try {
        let dwelling = await requestsAdapter
          .make(dwellingService.getDwelling(dwellingId))
          .catch(requestsAdapter.throwOnAbort)

        if (dwelling) {
          dwelling = {
            ...dwelling,
            address: dwelling.street_1 + ', ' + dwelling.city,
          }

          dispatch(setDwelling(dwelling))
          return dwelling
        }
      } catch {
        // aborted
      }
    }

/**
 * Thunk function that calls API for getting all dwelling information.
 * @param dwellingId - The id of the dwelling to retrieve its content (all devices and equipments).
 * @param requestsAdapter - request adapter that will send API requests for retrieving data.
 */
export const loadAllDwellingInfo =
  (dwellingId: number, requestsAdapter: RequestsAdapter): ThunkResult =>
    async (dispatch) => {
      try {
        // Get specific dwelling information for the dwelling id.
        dispatch(getDwelling(dwellingId, requestsAdapter))
        dispatch(getDwellingPermission(dwellingId))

      } catch {
        // aborted
      }
    }

/**
 * Call API to get dwelling permissions in order to get admin id
 * Call API with admin id to get admin details
 * @param dwellingId - number
 */
export const getDwellingPermission =
  (dwellingId: number): ThunkResult =>
    async (dispatch) => {
      const dwellingPermissions = await dwellingService.getDwellingPermission(dwellingId).catch(() => null)

      if (dwellingPermissions) {
        dispatch(setDwellingPermission(dwellingPermissions))
      } else {
        dispatch(setDwellingPermission([]))
      }
    }
