export enum MemberRole {
    ADMIN = 0,
    TECHNICIAN = 1,
    ANALYST = 2,
}

export enum MemberTeam {
  Management = 'management',
  Sales = 'sales',
  Service = 'service',
  None = 'none',
}

export enum MemberStatus {
    ACTIVE = 'accepted',
    PENDING = 'sent',
    EXPIRED = 'expired',
}

export const userRoleNames = ['Manager', 'Technician', 'Analyst']

export const MEMBER_STATUS_NAMES = {
  [MemberStatus.ACTIVE]: 'Active',
  [MemberStatus.PENDING]: 'Pending',
  [MemberStatus.EXPIRED]: 'Expired',
}

export const MEMBER_TEAM_NAMES = {
  [MemberTeam.Management]: 'Management',
  [MemberTeam.Sales]: 'Sales',
  [MemberTeam.Service]: 'Service & Install',
  [MemberTeam.None]: 'None',
}

export const MEMBER_TEAM_OPTIONS = [
  {
    name: MemberTeam.Management,
    label: MEMBER_TEAM_NAMES[MemberTeam.Management],
  },
  {
    name: MemberTeam.Sales,
    label: MEMBER_TEAM_NAMES[MemberTeam.Sales],
  },
  {
    name: MemberTeam.Service,
    label: MEMBER_TEAM_NAMES[MemberTeam.Service],
  },
]


