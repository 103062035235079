import { UserDemoName } from 'state-mngt/models/user'

export const DEMO_STEPS = {
  [UserDemoName.Demo1]: 4,
  [UserDemoName.Demo2]: 4,
  [UserDemoName.Demo3]: 4,
}

export const DEMO_CUSTOMER_IDS = {
  [UserDemoName.Demo1]: -1,
  [UserDemoName.Demo2]: -2,
  [UserDemoName.Demo3]: -3,
}

export const DEMO_COUNTS = Object.keys(UserDemoName).length
