import React, { useEffect } from 'react'
import {
  Dialog, makeStyles, Typography, DialogContent, DialogActions, Button, DialogTitle, IconButton,
} from '@material-ui/core'
import { useMixPanel } from 'features/analytics/mixpanel-provider'
import { theme } from 'theme'
import CloseIcon from '@material-ui/icons/Close'
import { ArrowForward } from '@material-ui/icons'
import ProPortalIntroImage from 'features/product-tour/pro-portal-intro.png'

const useStyles = makeStyles({
  dialogTitle: {
    margin: 0,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  dialogContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    alignContent: 'stretch',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

export interface WelcomeDialogProps {
  isOpen: boolean;
  onSkipTheTour?: () => void;
  onShowMeAround?: () => void;
  onClose?: () => void;
}


export const WelcomeProPortalDialog = (props: WelcomeDialogProps) => {
  const classes = useStyles()
  const {
    isOpen = true, onSkipTheTour, onShowMeAround, onClose,
  } = props
  const { mixpanel } = useMixPanel()


  const handleSkipTheTour = () => {
    if (mixpanel) {
      mixpanel.track('pp_productTour_welcomeProPortalDialog_skipTheTourButton')
    }

    if (onSkipTheTour) {
      onSkipTheTour()
    }
  }

  const handleContinue = () => {
    if (mixpanel) {
      mixpanel.track('pp_productTour_welcomeProPortalDialog_showMeAroundButton')
    }

    if (onShowMeAround) {
      onShowMeAround()
    }
  }

  const handleCloseDialog = () => {
    if (mixpanel) {
      mixpanel.track('pp_productTour_welcomeProPortalDialog_closeButton')
    }

    if (onClose) {
      onClose()
    }
  }

  useEffect(() => {
    if (mixpanel && isOpen) {
      mixpanel.track('pp_productTour_welcomeProPortalDialog_open')
    }
  }, [mixpanel, isOpen])


  return (
    <Dialog
      aria-labelledby="welcome-dialog-title"
      open={isOpen}
      onClose={ (event: object, reason: string) => {
        if (reason !== 'backdropClick') {
          handleCloseDialog()
        }
      }} >
      <DialogTitle id="welcome-dialog-title" disableTypography className={classes.dialogTitle}>
        <Typography variant="h3" color="secondary">Welcome to the HAVEN Pro Portal!</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseDialog}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <img
          src={ProPortalIntroImage}
          alt="Pro Portal Intro Image"
          style={{
            marginRight: '15px',
          }}
        />
        <Typography variant="body1" color="textPrimary">
          Remotely manage your customers in a whole new way with access to HAVEN data and
          insights. We call it Active Air Management!<br/><br/>
          You get access to:
          <ul style={{
            marginTop: 0, marginLeft: '2rem',
          }}>
            <li>Customer leads management</li>
            <li>Remote diagnostic tools</li>
            <li>Sales & Marketing resources</li>
          </ul>
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="contained"
          onClick={handleSkipTheTour}
          style={{
            flex: 1,
            margin: '1rem',
            color: theme.palette.error.main,
            border: '1px solid ' + theme.palette.error.main,
          }}>
          SKIP THE TOUR
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleContinue}
          style={{
            flex: 1,
            margin: '1rem',
            minWidth: '220px',
          }}>
          <Typography variant="button">SHOW ME AROUND</Typography>
          <ArrowForward style={{
            marginLeft: '7px',
            width: '17px',
            height: '17px',
          }}/>
        </Button>
      </DialogActions>
    </Dialog>
  )
}
