import React, { Fragment } from 'react'
import { Close } from '@material-ui/icons'
import { useEffect } from 'react'
import Stack from 'ui/stack'
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import Muted from './muted'
import { parseEquipmentType } from 'utils/string-utils'
import AddNew from './install/add-new'
import { selectTempRule, setTemporaryRule, useRulesStore } from './store'
import { dedupe } from './useGetData'
import RuleTitle from './ruleTitle'

/**
 * equipment needs to be divided into two if it's venitlating dehumidifier
 */
const ScheduleEquipment = ({
  outputs,
  availableOutputs,
  removeSelected,
  setSelectedOutputs,
  ruleId,
}) => {
  const tempRule = useRulesStore(selectTempRule(ruleId))
  const selectedOutputs = tempRule.outputs.filter(x => x.equipment)

  useEffect(() => {
    const selectedOutputIds = [
      ...tempRule.outputs.map(x => x.id),
      ...tempRule.outputs.flatMap(x => x.interlocked_blowers),
      ...tempRule.outputs.flatMap(x => x.interlocked_non_blowers),
    ]

    const outputsToRemove = selectedOutputs.map(x => x.id).filter(x => selectedOutputIds.includes(x))
    if (outputsToRemove.length) removeSelected(outputsToRemove)

    setSelectedOutputs(selectedOutputIds)
  }, [JSON.stringify(selectedOutputs)])

  const handleChangeEquipment = index => event => {
    const { value } = event.target
    const outputId = parseInt(value)
    const selection = outputs.find(x => x.id === outputId)

    const newOutputs = tempRule.outputs.map((x, i) => i === index ? selection : x)
    const oldOutput = tempRule.outputs[index]

    const update = {
      outputs: newOutputs,
      trigger: {
        ...tempRule.trigger,
        outputs: newOutputs.map(x => x.id),
      },
    }

    removeSelected([oldOutput.id])
    setTemporaryRule(ruleId, update)
  }

  const onClickMoreEquipment = () => {
    if (!availableOutputs.length) return
    const nextInLine = availableOutputs[0]
    const outputs = [...tempRule.outputs, nextInLine]

    const update = {
      outputs,
      trigger: {
        ...tempRule.trigger,
        outputs: outputs.map(x => x.id)
      }
    }

    setTemporaryRule(ruleId, update)
  }

  const onClickRemoveEquipment = (outputId) => () => {
    const newOutputs = tempRule.outputs.filter(x => x.id !== outputId)
    const newOutputIds = newOutputs.map(x => x.id)

    const outputsToRemove = [
      outputId,
      ...(outputs.find(x => x.id === outputId)?.interlocked_blowers || []),
    ]

    const update = {
      outputs: newOutputs,
      trigger: {
        ...tempRule.trigger,
        outputs: newOutputIds,
      },
    }

    setTemporaryRule(ruleId, update)
    removeSelected(outputsToRemove)
  }

  const menuItemIsDisabled = (parentOutput, output) => {
    if (parentOutput.interlocked_blowers.includes(output.id)) return false
    if (parentOutput.interlocked_non_blowers.includes(output.id)) return false
    return !availableOutputs.some(x => x.id === output.id)
  }

  const interlockedBlowers = output => dedupe(output.interlocked_blowers)

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        {selectedOutputs.map((output, index) => (
          <Fragment key={output.id}>
            <Box
              display='flex'
              alignItems='center'
            >
              <FormControl
                id={`equipment_select_${output.id}`}
                size='small'
                variant='outlined'
                style={{
                  width: '100%',
                }}
              >
                <InputLabel
                  id={`equipment_select_${output.id}`}>Equipment</InputLabel>
                <Select
                  id={`equipment_select_${output.id}`}
                  name={`equipment_select`}
                  labelId={`equipment_select`}
                  label='Equipment'
                  value={output.id || ''}
                  onChange={handleChangeEquipment(index)}
                >
                  <MenuItem
                    disabled
                    value={``}
                  >
                    Select...
                  </MenuItem>
                  {outputs.filter(x => x.equipment).map(x => (
                    <MenuItem
                      key={x.id}
                      disabled={menuItemIsDisabled(output, x)}
                      value={`${x.id}`}
                    >
                      <Box display='flex' flexDirection='column'>
                        {parseEquipmentType(x.equipment.type)}
                        <Muted style={{ fontSize: '14px' }}>{x.equipment.brand}</Muted>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box ml={1}>
                <IconButton
                  onClick={onClickRemoveEquipment(output.id)}
                  disabled={selectedOutputs.length < 1}
                  size='small'
                >
                  <Close color='error' />
                </IconButton>
              </Box>
            </Box>
            {
              (interlockedBlowers(output) || [])?.length ?
                (interlockedBlowers(output) || []).map(id => (
                  <Fragment key={id}>
                    <Box ml={2} my={2}>
                      <RuleTitle>
                        interlocked with
                      </RuleTitle>
                    </Box>
                    <FormControl
                      id={`equipment_select_${id}`}
                      size='small'
                      variant='outlined'
                      disabled
                      style={{
                        width: '100%',
                      }}
                    >
                      <InputLabel
                        id={`equipment_select_${id}`}>Equipment</InputLabel>
                      <Select
                        id={`equipment_select_${id}`}
                        name={`equipment_select`}
                        labelId={`equipment_select`}
                        label='Equipment'
                        value={id || ''}
                        onChange={handleChangeEquipment(index)}
                      >
                        <MenuItem
                          key={id}
                          disabled
                          value={`${id}`}
                        >
                          <Box display='flex' flexDirection='column'>
                            {parseEquipmentType(outputs.find(x => x.id === id)?.equipment?.type) || ''}
                            <span style={{ fontSize: '14px' }}>{outputs.find(x => x.id === id)?.equipment?.brand}</span>
                          </Box>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Fragment>
                )) :
                null}
          </Fragment>
        ))}
        {!availableOutputs.length ? (
          null
        ) : (
          <Box mb={2}>
            <AddNew
              onClick={onClickMoreEquipment}
            >
              Add equipment
            </AddNew>
          </Box>
        )}
      </Stack>
    </Stack>
  )
}

export default ScheduleEquipment
