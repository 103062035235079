import { useEffect, useState, useRef } from 'react'

export const useEffectOnce = (callback: (cb: () => void) => void, deps: unknown[] = []) => {
  const [isCalled, setCalled] = useState<boolean>(false)

  useEffect(() => {
    if (!isCalled) {
      callback(() => setCalled(true))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}

/**
 * Custom hook for persisting a mutable value without causing re-renders. The value will not be updated.
 * The returned value will persist for the full lifetime of the component that calls this hook.
 * This is useful for keeping track of a component state/props changes and act accordingly.
 * @param value - Any value that should be persisted
 */
export const usePrevious = <T>(value: T): T | undefined => {
  // useRef is like a “box” that can hold a mutable value in its ".current" property.
  // The “ref” object is a generic container whose current property is mutable and can hold any value, similar to an
  // instance property on a class.
  const ref = useRef<T>()

  useEffect(() => {
    ref.current = value
  }, [value])

  // Return previous value (it happens before update in useEffect above)
  return ref.current
}
