import { useAuth0 } from '@auth0/auth0-react'
import {
  Box, Button, Card, Grid, TextField, Typography,
} from '@material-ui/core'
import 'features/onboarding/onboarding.scss'
import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ROUTE_PATHS } from 'routes/routes'
import companyService from 'state-mngt/services/company-service'
import OnboardingLinearProgress from 'ui/liner-progress'
import { MemberStatus } from 'utils/constants/member-enums'
import { useEffectOnce } from 'utils/hooks/lifecycle'
import { usePageTitle } from 'utils/hooks/router'
import useDecodedSearchParams from 'utils/hooks/useDecodedSearchParams'

/**
 * It allows the user to enter an invite code to join a service company. The invite code is either entered manually by
 * the user or automatically (programmatically) if this page is open from an email invite which contains extra
 * information in the URL.
 * @constructor
 */
export const JoinServiceCompany = () => {
  usePageTitle('Join Service Company')

  const navigate = useNavigate()
  const [searchParams] = useDecodedSearchParams()
  const { loginWithRedirect } = useAuth0()

  // Extra information that is passed to the URL if this page was loaded from an email invite.
  const tempInviteCode = (searchParams.get('invite') || '')
  const companyName = searchParams.get('company')
  const teamName = searchParams.get('team')

  const [inviteCode, setInviteCode] = useState(tempInviteCode)
  const [displayInviteCodeError, setDisplayInviteCodeError] = useState(false)
  const [displayInviteCodeCaption, setDisplayInviteCodeCaption] = useState(false)
  const [enableContinueButton, setEnableContinueButton] = useState(false)

  useEffectOnce((markAsCalled) => {
    markAsCalled()
    if (inviteCode) {
      if (inviteCode.trim().length > 0 ) {
        setEnableContinueButton(true)
      }
    }
  }, [inviteCode])

  /**
   * Callback for the invite code text input value change.
   * @param e - The event source of the callback.
   */
  const handleInviteCodeInputChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const input = e.target.value as string
    setInviteCode(input.trim())

    if (input.trim().length > 0 ) {
      setDisplayInviteCodeCaption(true)
      setEnableContinueButton(true)
    }
    else {
      setDisplayInviteCodeCaption(false)
      setEnableContinueButton(false)
    }

    if (displayInviteCodeError) {
      setDisplayInviteCodeError(false)
    }
  }

  /**
   * Callback for the continue button.
   * It handles the next step.
   */
  const handleContinueClick = async () => {
    try {
      setEnableContinueButton(false)
      const result = await companyService.getCompanyInvite(inviteCode!)

      if (result.status === MemberStatus.EXPIRED) {
        navigate(ROUTE_PATHS.joinServiceCompany.error.inviteCodeExpired.absolute, {
          replace: true,
        })
      } else if (result.status === MemberStatus.ACTIVE) {
        navigate(ROUTE_PATHS.joinServiceCompany.error.inviteCodeUsed.absolute, {
          replace: true,
        })
      } else if (result.status === MemberStatus.PENDING) {
        loginWithRedirect({
          // Custom parameters that will be appended to the URL.
          invite: encodeURIComponent(inviteCode as string),
          company: (companyName ? companyName : result.service_company_name),
          team: (teamName ? teamName : result.team),
          // Default Property used to store the state before doing the redirect. It chooses where to redirect after
          // users login-redirect/sign up.
          appState: {
            returnTo: ROUTE_PATHS.joinServiceCompany.onboarding.absolute,
          },
        })
      } else {
        navigate(ROUTE_PATHS.notFound.absolute, {
          replace: true,
        })
      }
    } catch(error) {
      setDisplayInviteCodeError(true)
      setEnableContinueButton(true)
    }
  }

  return(
    <>
      <Box id="onboarding-container" display="flex" flexDirection="column">
        <Grid container className="home-page">
          <Grid item sm={2} />
          <Grid item sm={10}>
            <Box mt="30vh" mb={4}>
              <Typography variant="h1" gutterBottom>Joining a service company?</Typography>
              <Typography variant="h5" gutterBottom>Copy and paste the invite code below.</Typography>
            </Box>
            <Card
              elevation={3}
              style={{
                width: '560px',
                padding: '2rem',
                borderRadius: 20,
              }}
            >
              <Box display="flex" mb={4}>
                <Box display="flex" mt={3}>
                  <Typography id="label-input-invite-code">My code is</Typography>
                </Box>
                <Box display="column" ml={2} flex="auto">
                  <Typography
                    variant="caption"
                    style={{
                      visibility: displayInviteCodeCaption ? 'visible' : 'hidden',
                    }}
                  >
                    Invite code
                  </Typography>
                  <TextField
                    id="join-invite-code-input"
                    fullWidth
                    size="small"
                    placeholder="Paste code here"
                    error={ displayInviteCodeError }
                    helperText={ displayInviteCodeError && 'Invalid code! Please try again.' }
                    value={ inviteCode }
                    onChange={ handleInviteCodeInputChange }
                  />
                </Box>
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  fullWidth
                  disabled={ !enableContinueButton }
                  onClick={ handleContinueClick }
                >
                  Continue
                </Button>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box
        style={{
          backgroundColor: '#EEE',
        }}
        width="100%"
        padding="2rem 1rem 1rem"
      >
        <OnboardingLinearProgress
          value={10}
          style={{
            width: '50%',
            margin: '0 auto',
          }}
        />
      </Box>
    </>
  )
}
