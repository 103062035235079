/**
 * Videos links used for Sales And Marketing page static content
 */
export enum VideoLink {
    INSTALLATION_CENTRAL_AIR_MONITOR ='https://www.youtube.com/watch?v=RrO6I8ePrWk&ab_channel=HAVEN',
    INSTALLATION_CENTRAL_AIR_CONTROLLER ='https://www.youtube.com/watch?v=iCosqgWva_Q&ab_channel=HAVEN',
    INSTALLATION_EMBED = 'https://www.youtube.com/embed/coRvho_Rox4',
    MAINTENANCE ='https://youtu.be/CvPtjWytaD8',
    RESET ='https://youtu.be/1g6fgICcuh4',
    HARDWARE ='https://youtu.be/O-ZZ5ruX_vI',
    HARDWARE_EMBED = 'https://www.youtube.com/embed/O-ZZ5ruX_vI',
    INTRODUCTION_WATCH ='https://www.youtube.com/watch?v=qSsyDgGRX8s',
    INTRODUCTION_EMBED = 'https://www.youtube.com/embed/NH-CAHTealg',
    INTRODUCTION_CONTROLLER = 'https://www.youtube.com/watch?v=jI82nn2Ekng&ab_channel=HAVEN',
    INTRODUCTION_MONITOR = 'https://www.youtube.com/watch?v=AG-68FG_VY4&ab_channel=HAVEN',
    INTRODUCTION_TO_PERSONAL_USE_PROGRAM = 'https://youtu.be/W3TbbyZYw28',
}

interface Video {
    title: string;
    url: VideoLink;
    date: string; // label
}

export const camVideos: Video[] = [
  {
    title: 'Intro to the Central Air Monitor',
    url: VideoLink.INTRODUCTION_MONITOR,
    date: 'Feb 14, 2024',
  },
  {
    title: 'Introducing HAVEN',
    url: VideoLink.INTRODUCTION_WATCH,
    date: 'Aug 12, 2022',
  },
  {
    title: 'Installing the Central Air Monitor',
    url: VideoLink.INSTALLATION_CENTRAL_AIR_MONITOR,
    date: 'Aug 12, 2022',
  },
  {
    title: 'HAVEN Central Air Monitor Hardware Introduction',
    url: VideoLink.HARDWARE,
    date: 'Nov 09, 2020',
  },
  {
    title: 'HAVEN Central Air Monitor Soft & Hard Reset',
    url: VideoLink.RESET,
    date: 'Nov 09, 2020',
  },
  {
    title: 'HAVEN Central Air Monitor Maintenance',
    url: VideoLink.MAINTENANCE,
    date: 'Nov 09, 2020',
  },

]

export const cacVideos: Video[] = [
  {
    title: 'Intro to the Central Air Controller',
    url: VideoLink.INTRODUCTION_CONTROLLER,
    date: 'Feb 14,, 2024',
  },
  {
    title: 'Installing the Central Air Controller',
    url: VideoLink.INSTALLATION_CENTRAL_AIR_CONTROLLER,
    date: 'Aug 12, 2022',
  },
]

export const personalUseProgramVideos: Video[] = [
  {
    title: 'Intro to HAVEN for Pros',
    url: VideoLink.INTRODUCTION_TO_PERSONAL_USE_PROGRAM,
    date: 'Aug 12, 2022',
  },
]
