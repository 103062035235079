/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card, Box, Typography, TextField, Button, IconButton, MenuItem, Select,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Country } from 'utils/constants/province-lookup-options'
import { OnboardingProfile } from 'state-mngt/models/onboarding-profile'
import InfoTooltip from 'ui/info-tooltip'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { selectOnboardingStateOptions } from 'utils/onboarding-utils'
import PostalCodeInput from 'ui/postal-code-input'
import EmailInput from 'ui/email-input'
import PhoneInput from 'ui/phone-input'
import { useMixPanel } from 'features/analytics/mixpanel-provider'

export const FormThree = (props: {
  profile: OnboardingProfile;
  onProfileChange: (profile: OnboardingProfile) => void;
  onNextValueChange: (value: number) => void;
  onIsValidValueChange: (formNum: number, isValid: boolean) => void;
  isParentFormValid: boolean;
}) => {
  const { mixpanel } = useMixPanel()
  const {
    profile, onProfileChange, isParentFormValid, onNextValueChange, onIsValidValueChange,
  } = props
  const [country, setCountry] = useState<string>(profile.country)
  const [postal_code, setPostalCode] = useState<string>('') // required
  const [city, setCity] = useState<string>(' ') // required
  const [province, setProvince] = useState<string>(profile.country === Country.AMERICA ? 'AL' : 'AB') // required, pre-fill based on country
  const [street_1, setStreet1] = useState<string>(' ') // required
  const [street_2, setStreet2] = useState<string>('')
  const [email, setEmail] = useState<string>('') // required
  const [phone, setPhone] = useState<string>('(   )    -    ') // required, with mask
  const [provinceList, setProvinceList] = useState<string[]>(selectOnboardingStateOptions(profile.country))

  const [hasPhoneError, setHasPhoneError] = useState<boolean>(true)
  const [hasEmailError, setHasEmailError] = useState<boolean>(true)
  const [hasPostalCodeError, setHasPostalCodeError] = useState<boolean>(false)

  const [btnVisible, setBtnVisible] = useState<boolean>(false)
  const [activeCard, setActiveCard] = useState<boolean>(true)

  useEffect(() => {
    if (country !== profile.country) {
      setProvinceList(selectOnboardingStateOptions(profile.country))
      setProvince(selectOnboardingStateOptions(profile.country)[0])
      setCountry(profile.country)
    }
  }, [profile, postal_code, country])

  useEffect(() => {
    changeProfile()
  }, [])

  useEffect(() => {
    if (!hasPostalCodeError) {
      const updatedProfile: OnboardingProfile = {
        ...profile,
        postal_code: postal_code,
      }
      onProfileChange(updatedProfile)
    }
  }, [hasPostalCodeError, postal_code])

  useEffect(() => {
    if (!hasEmailError) {
      const updatedProfile: OnboardingProfile = {
        ...profile,
        email: email,
      }
      onProfileChange(updatedProfile)
    }
  }, [hasEmailError, email])

  useEffect(() => {
    if (!hasPhoneError) {
      const updatedProfile: OnboardingProfile = {
        ...profile,
        phone: phone,
      }
      onProfileChange(updatedProfile)
    }
  }, [hasPhoneError, phone])

  useEffect(() => {
    if (!hasPostalCodeError && city.trim() !== '' && street_1.trim() !== '' && !hasEmailError && !hasPhoneError) {
      setBtnVisible(true)
      onIsValidValueChange(3, true)
    } else {
      onIsValidValueChange(3, false)
    }
  }, [hasPostalCodeError, city, street_1, hasEmailError, hasPhoneError, onIsValidValueChange])

  /**
   * callback fn for 'Next' click
   * set btn visibility
   * send progress change to parent component
   * send profile to parent component
   */
  const handleNext = () => {
    if (mixpanel) {
      mixpanel.track('pp_onboardingPage_cardThree_nextButton')
    }
    changeProfile()
    onNextValueChange(50)
    setBtnVisible(false)
    setActiveCard(false)
  }

  const changeProfile = () => {
    const updatedProfile: OnboardingProfile = {
      ...profile,
      postal_code,
      city,
      province,
      street_1,
      street_2,
      email,
      phone,
    }

    onProfileChange(updatedProfile)
  }

  /**
   * Handle province selection click
   * Set province value selected
   * @param event - ChangeEvent
   * @param value - string
   */
  const handleProvinceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string
    setProvince(value)

    const updatedProfile: OnboardingProfile = {
      ...profile,
      province: value,
    }

    onProfileChange(updatedProfile)
  }

  const handleCityChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const input = e.target.value as string
    setCity(input)

    const updatedProfile: OnboardingProfile = {
      ...profile,
      city: input,
    }

    onProfileChange(updatedProfile)
  }

  const handleStreetOneChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const input = e.target.value as string
    setStreet1(input)

    const updatedProfile: OnboardingProfile = {
      ...profile,
      street_1: input,
    }

    onProfileChange(updatedProfile)
  }

  const handleStreetTwoChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const input = e.target.value as string
    setStreet2(input)

    const updatedProfile: OnboardingProfile = {
      ...profile,
      street_2: input,
    }

    onProfileChange(updatedProfile)
  }

  return (
    <Card
      elevation={3}
      style={{
        width: '570px',
        padding: '2rem',
        margin: '2rem 0',
      }}
      className={activeCard ? 'onboarding-card' : 'onboarding-fade'}
    >
      {profile && (
        <Box display="column">
          <Box>
            <Box display="flex" justifyContent="space-between">
              <Typography>Company address and contact info</Typography>
              <InfoTooltip
                placement="right-start"
                arrow
                interactive
                title="This address and contact info will be visible to your future HAVEN IAQ customers in the HAVEN IAQ app."
              >
                <IconButton style={{
                  padding: 0,
                }}>
                  <InfoOutlinedIcon style={{
                    margin: '.4rem',
                  }} fontSize="small" />
                </IconButton>
              </InfoTooltip>
            </Box>
            <Box mt={1} mr={1}>
              {country && (
                <PostalCodeInput
                  name="onboarding-postal-code"
                  id="onboarding-postal-code"
                  placeholder={country !== Country.AMERICA ? 'Postal Code*' : 'Zip*'}
                  fullWidth={false}
                  country={country}
                  inputValue=""
                  touched={false}
                  onPostalCodeValueChange={setPostalCode}
                  onPostalCodeErrorChange={setHasPostalCodeError}
                />
              )}
            </Box>
            <Box mt={1} display="flex" alignItems="flex-start">
              <TextField
                id="onboarding-city"
                required
                size="medium"
                placeholder="City*"
                defaultValue=""
                error={city === ''}
                helperText={city === '' ? 'City is required' : ''}
                onChange={handleCityChange}
              />
              &nbsp;&nbsp;
              {provinceList && province && (
                <Select id="onboarding-province" value={province} onChange={handleProvinceChange}>
                  {provinceList.map((p, i) => {
                    return (
                      <MenuItem key={p + i} value={p}>
                        {p}
                      </MenuItem>
                    )
                  })}
                </Select>
              )}
            </Box>
            <Box mt={1}>
              <TextField
                id="onboarding-address-1"
                fullWidth
                placeholder="Address Line 1*"
                required
                defaultValue=""
                error={street_1 === ''}
                helperText={street_1 === '' ? 'Address Line 1 is required' : ''}
                onChange={handleStreetOneChange}
              />
            </Box>
            <Box mt={1}>
              <TextField
                id="onboarding-address-2"
                fullWidth
                placeholder="Address Line 2"
                defaultValue=""
                onChange={handleStreetTwoChange}
              />
            </Box>
            <Box mt={1}>
              <EmailInput
                name="onboarding-company-email"
                id="onboarding-company-email"
                fullWidth={true}
                placeholder="Office email address*"
                inputValue={email}
                touched={false}
                onEmailErrorChange={setHasEmailError}
                onEmailValueChange={setEmail}
              />
            </Box>
            <Box my={1}>
              <PhoneInput
                name="onboarding-phone"
                id="onboarding-phone"
                inputValue={phone}
                touched={false}
                fullWidth={true}
                onPhoneValueChange={setPhone}
                onPhoneErrorChange={setHasPhoneError}
              />
            </Box>
          </Box>
        </Box>
      )}
      {btnVisible && activeCard && (
        <Box display="flex" justifyContent="flex-end">
          <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleNext}
            disabled={
              !isParentFormValid ||
              hasPostalCodeError ||
              city.trim() === '' ||
              street_1.trim() === '' ||
              hasEmailError ||
              hasPhoneError
            }
          >
            Next
          </Button>
        </Box>
      )}
    </Card>
  )
}
