import {
  createStyles, Tab, Tabs, Theme, withStyles,
} from '@material-ui/core'
import React from 'react'
import { theme } from 'theme'

interface StyledTabProps {
  label: JSX.Element | string;
}

export const ReportPageTabs = withStyles({
  root: {
  },
  indicator: {
    width: 48,
    height: 48,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 5,
    backgroundColor: 'transparent',
    left: '.5rem',
    bottom: 0,
    marginTop: '.25rem',
    '&::before': {
      content: '""',
      position: 'absolute',
      background: `linear-gradient(-135deg, #FFFFFF 50%, ${theme.palette.primary.main} 50%)`,
      display: 'block',
      width: 16,
      height: 16,
      right: -2,
      top: -2,
    },
  },
})(Tabs)

export const ReportPageTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      width: 48,
      minWidth: 48,
      fontWeight: 'normal',
      border: `2px solid ${theme.palette.grey[300]}`,
      borderRadius: 5,
      marginLeft: '0.5em',
      color: theme.palette.grey[300],
      ...theme.typography.h4,
    },
    selected: {
    },
  }),
)((props: StyledTabProps) => <Tab disableRipple {...props} />)
