import httpService from 'state-mngt/services/data/http-service'
import {
  NewServiceCompany,
  ServiceCompany,
  ServiceCompanyInviteResult,
  ServiceCompanyInvites, ServiceCompanyInviteDeleteInfo, ServiceCompanyInvite,
} from 'state-mngt/models/serviceCompany'
import { User, UserRole, UserServiceCompanyInvite } from 'state-mngt/models/user'
import { CompanyProfile } from 'state-mngt/models/company-profile'
import { HavenApiPaginationQuery } from 'state-mngt/models/http'
import { DwellingsPaginated } from 'state-mngt/models/dwelling'

/**
 * Get service company details from '/service_company/{id}' endpoint
 */
class CompanyService {
  /**
   * calls /service_company/{id} endpoint
   * @param companyId number
   */
  getServiceCompany = async (companyId: number): Promise<ServiceCompany> => {
    return httpService.get('/service_company/' + companyId)
  }

  /**
   * calls /service_company/{id} endpoint
   * @param companyId number
   */
  updateServiceCompany = async (companyId: number, companyDetails: ServiceCompany): Promise<void> => {
    return httpService.post('/service_company/' + companyId, companyDetails)
  }

  /**
   * calls /service_company/{id} endpoint
   * @param companyId number
   */
  updateJourney = async (companyId: number, state: string): Promise<void> => {
    return httpService.post(`/service_company/${companyId}/journey`,
      {
        state, 
      })
  }

  /**
   * calls (DELETE) /service_company_role endpoint
   */
  deleteServiceCompanyRole = async (memberRole: UserRole): Promise<void> => {
    return httpService.delete('/service_company_role', memberRole)
  }

  /**
   * calls /service_company/{id}/employees endpoint given company id
   * @param companyId - number
   */
  getCompanyMembers = async (companyId: number): Promise<User[]> => {
    return httpService.get('/service_company/' + companyId + '/employees')
  }

  /**
   * calls /service_company endpoint given company details
   * @param company - NewServiceCompany
   */
  createServiceCompany = async (company: NewServiceCompany): Promise<{ id: number }> => {
    return httpService.post('/service_company', company)
  }
  /**
   * calls /service_company/{id}/profile endpoint given company profile and id
   * @param companyProfile - CompanyProfile
   * @param companyId -number
   */
  createCompanyProfile = async (companyProfile: CompanyProfile, companyId: number): Promise<void> => {
    return httpService.post('/service_company/' + companyId + '/profile', companyProfile)
  }

  /**
   * calls /service_company/{id}/invites endpoint given company id
   * @param companyId - The company id that will have its invites retrieved from.
   */
  getCompanyInvites = async (companyId: number): Promise<ServiceCompanyInvites> => {
    return httpService.get('/service_company/' + companyId + '/invites')
  }

  /**
   * calls /service_company/{id}/invite endpoint for inviting a user to join a service company.
   * @param invite - The object that identify which company to invite to and the user's information.
   */
  inviteUserToServiceCompany = async (invite: UserServiceCompanyInvite):
    Promise<ServiceCompanyInviteResult> => {
    return httpService.post('/service_company/invite', invite)
  }

  /**
   * calls /service_company/invite/{inviteCode}/resend endpoint for resending an invitation for a user to join a
   * service company.
   * @param inviteCode - The invite code that will be resent.
   */
  resendInviteForUserToServiceCompany = async (inviteCode: string):
    Promise<ServiceCompanyInviteResult> => {
    return httpService.post('/service_company/invite/' + inviteCode + '/resend', null)
  }

  /**
   * calls /service_company/{id}/invite/{inviteCode} endpoint given the company id and the invite information.
   * @param inviteDeleteInfo - Information that identifies the invite to be removed, that is the company id and the
   * invite code
   */
  deleteInviteForUserToJoinCompany = async (inviteDeleteInfo: ServiceCompanyInviteDeleteInfo)
    : Promise<ServiceCompanyInviteResult> => {
    return httpService.delete(
      '/service_company/invite/' + inviteDeleteInfo.invite_code, inviteDeleteInfo,
    )
  }

  /**
   * calls /service_company/{id}/invite/{inviteCode} endpoint for retrieving an invite information
   * @param invite - The invite code for retrieving its information.
   */
  getCompanyInvite = async (invite: string)
    : Promise<ServiceCompanyInvite> => {
    return httpService.get('/service_company/invite/' + invite)
  }

  /**
   * Get a summary list of dwellings where the service company is the preferred service company.
   * The dwelling list is just summary information about the dwelling. To get more detailed information use the
   * /dwelling/{id} GET API endpoint. If the user does not have permission to see the dwellings for this service company
   * the INVALID_PERMISSION error is returned.
   * The valid query order parameters for this API endpoint are 'id', 'create_timestamp', 'customer_name', 'address',
   * 'total_poor_percentage', 'device_status_good' and 'filter_status_good'.
   * @param companyId The service company which will have its dwellings summary list requested.
   * @param query The query parameters to request the paginated data. offset parameter is included in the return list.
   */
  getDwellingsPaginated = async (companyId: number, query?: HavenApiPaginationQuery)
    : Promise<DwellingsPaginated> => {
    // TODO-lsantos: This is ugly I know. Refactor this to use a more elegant solution for passing HTTP Queries whenever
    // we refactor the HTTP module.
    let paramUri = ''

    if (query) {
      paramUri = Object.entries(query).map(e => e.join('=')).join('&')
      paramUri = '/?' + paramUri
    }

    return httpService.get('/service_company/' + companyId + '/dwellings' + paramUri)
  }
}

const companyService = new CompanyService()

export default companyService
