import React, { useEffect, useState } from 'react'
import { HavenLinks } from 'utils/constants/resources/links'
import { Link, useNavigate } from 'react-router-dom'
import { useMixPanel } from 'features/analytics/mixpanel-provider'
import {
  Button,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  CircularProgress,
} from '@material-ui/core'
import { Country } from 'utils/constants/province-lookup-options'
import stripeService, { InitPaymentResponse } from 'state-mngt/services/stripe-service'
import { useStripe } from '@stripe/react-stripe-js'
import { Alert } from '@material-ui/lab'
import { theme } from 'theme'
import InboxIcon from '@material-ui/icons/Inbox'
import CancelIcon from '@material-ui/icons/Cancel'
import bundleImageUrl from 'features/how-to-buy/bundle.svg'
import { useAppSelector } from 'utils/hooks/reduxTypes'
import { selectCompanyDetails } from 'state-mngt/selectors/company-selectors'
import { selectUserRole } from 'state-mngt/selectors/user-selectors'
import { usePageTitle } from 'utils/hooks/router'
import { ROUTE_PATHS } from 'routes/routes'
import { renderPrice } from './payment-form'

export const PersonalUseProgram = () => {
  const [quantity, setQuantity] = useState(1)
  const [loading, setLoading] = useState(false)
  const [paymentDetails, setPaymentDetails] = useState<InitPaymentResponse>({
    client_secret: '',
    unit_amount: 0,
    currency: '',
    payment_intent_id: '',
    taxes: [],
  })

  const navigate = useNavigate()

  const stripe = useStripe()
  const { mixpanel } = useMixPanel()

  const companyDetails = useAppSelector(selectCompanyDetails)
  const userRole = useAppSelector(selectUserRole)

  usePageTitle('Personal Use Program')

  useEffect(() => {
    setLoading(true)
    if (!quantity) return
    if (!companyDetails?.country) return

    const createPaymentIntent = async () => {
      try {
        /**
         * start the stripe payment process
         */
        const details = await stripeService.initPayment({
          quantity, country: companyDetails.country, 
        })
        setPaymentDetails(details)
        setLoading(false)
      } catch (e) {
        console.error(e)
      }
    }

    createPaymentIntent()
  }, [companyDetails?.country, quantity])

  useEffect(() => {
    if (mixpanel) {
      mixpanel.track('pp_personalUseProgramPage_open')
    }
  }, [mixpanel])

  const handleBuy = async () => {
    setLoading(true)
    navigate(
      `${ROUTE_PATHS.howToBuy.personalUseProgram.payment.absolute}`, {
        state: {
          ...paymentDetails,
          quantity,
        },
      })
  }

  return (
    <>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Box py={4} px={2}>
          <Typography variant="h3" color="primary">
            Get to know the HAVEN ecosystem for yourself
          </Typography>
        </Box>


        <Box textAlign="center">
          <Typography variant="body1" gutterBottom>
            Buy <strong>up to 3</strong> HAVEN Bundles (at a discount!) so you can evaluate the end-to-end experience.
            <br />
            This includes everything from installing the hardware to viewing the data in the Pro Portal and App before
            you bring it to your customers.
            <br />
          </Typography>

          <Typography variant="body1">
            <strong>What’s in the bundle:</strong> 1 Central Air Monitor and 1 Central Air Controller
          </Typography>

          <img
            alt="Haven CAM product shot"
            src={bundleImageUrl}
            width="300"
            height="300"
          />
        </Box>

        {companyDetails && userRole && (
          <Box>
            <Box display="flex" width="340px" margin="0 auto">
              <Typography>
                <b>How many would you like?</b> (3 max.)
              </Typography>
              <FormControl size="small">
                <Select
                  labelId="buy-pup-select-label"
                  id="buy-pup-select"
                  style={{
                    marginLeft: '1rem',
                  }}
                  value={quantity}
                  onChange={(e) => setQuantity(parseInt(e.target.value as string))}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <br />

            <Alert severity="success" icon={<InboxIcon />} variant="outlined">
              <Typography color="textPrimary" variant="body2">
                To save you a bookkeeping headache, you’ll receive a receipt emailed to you a couple minutes after
                your purchase has been completed.
              </Typography>
            </Alert>
          </Box>
        )}

        {(!companyDetails || !userRole ||
          (companyDetails.country !== Country.AMERICA && companyDetails.country !== Country.CANADA)) && (
          <Box my={2} width="100%">
            {!userRole?.manager && (
              <Alert severity="error" variant="outlined" icon={<CancelIcon />}>
                <Typography color="textPrimary" variant="body2">
                    Your service company profile is incomplete. Please contact the{' '}
                  <a
                    style={{
                      color: theme.palette.primary.main,
                    }}
                    href={HavenLinks.CUSTOMER_SUPPORT}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                      HAVEN Pro Support team
                  </a>{' '}
                    to help you complete the service company details before completing your personal use program
                    (PUP) device purchase.
                </Typography>
              </Alert>
            )}
            {userRole?.manager && (
              <Alert severity="error" variant="outlined" icon={<CancelIcon />}>
                <Typography color="textPrimary" variant="body2">
                    Your service company profile is incomplete. Please go to{' '}
                  <Link
                    style={{
                      color: theme.palette.primary.main,
                    }}
                    to="/company"
                    target="_blank"
                  >
                      Company page
                  </Link>{' '}
                    and complete the service company details before completing your PUP device purchase.
                </Typography>
              </Alert>
            )}
          </Box>
        )}

        {companyDetails && stripe && (
          <Button
            id="buy-a-pup-buy-btn"
            color="primary"
            variant="contained"
            onClick={handleBuy}
            size="large"
            style={{
              margin: '1rem',
            }}
            disabled={
              loading ||
              !userRole ||
              (companyDetails.country !== Country.AMERICA && companyDetails.country !== Country.CANADA)
            }
            endIcon={loading ? <CircularProgress size={20} /> : <></>}
          >
            Buy for{' '}
            {paymentDetails.unit_amount ? renderPrice(paymentDetails.unit_amount * quantity) : '...'}
          </Button>
        )}
      </Box>
    </>
  )
}