import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import { useMixPanel } from 'features/analytics'
import React, { useEffect, useRef, useState } from 'react'
import { theme } from 'theme'
import Error from './error'
import { useSkipFirstRender } from './util'

const useStyles = makeStyles({
  dialogContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialogActions: {
    marginBottom: '8px',
    marginRight: '16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'stretch',
  },
  input: {
    width: '100%',
  },
})

export const AddEmailDialog = ({ setEmail, onClickYes, isOpen }) => {
  const classes = useStyles()
  const { mixpanel } = useMixPanel()
  const inputRef = useRef<HTMLDivElement>(null)
  const [value, setValue] = useState('')
  const [error, setError] = useState('')
  const [disabled, setDisblaed] = useState(true)

  const _onClickYes = () => {
    const regex = new RegExp(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$/i)
    if (value.match(regex) !== null) {
      setError('')
      setEmail(value)
      onClickYes()
    } else {
      setError('Invalid email')
    }
  }

  useSkipFirstRender(() => {
    const regex = new RegExp(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$/i)
    if (value.match(regex) !== null) {
      setError('')
      setDisblaed(false)
    } else {
      setDisblaed(true)
    }
  }, [value])

  useEffect(() => {
    if (mixpanel && isOpen) {
      mixpanel.track('alerts - open email dialog')
    }
  }, [mixpanel, isOpen])

  return (
    <Dialog
      aria-labelledby="delete-dialog-title"
      open={isOpen}>
      <DialogTitle id="delete-dialog-title" style={{
        paddingBottom: '0',
      }} disableTypography>
        <Typography variant="h6" style={{
          color: theme.palette.grey[500],
        }}>An email is required to send alerts</Typography>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          className={classes.input}
          ref={inputRef}
          variant='outlined'
          value={value}
          onChange={e => setValue(e.target.value)}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {error && (<Error>{error}</Error>)}
        <Button
          variant="outlined"
          disabled={disabled}
          color="primary"
          onClick={_onClickYes}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
