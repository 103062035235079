/**
 * returns a boolean value indicating if the file type is allowed
 * @param file - File
 */
export const validateFileType = (file: File): boolean => {

  switch(file.type) {
    case 'image/jpeg':
      return true
    case 'image/png':
      return true
    case 'image/gif':
      return true
    default:
      return false
  }
}