import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import React, {
  useEffect,
  useState,
} from 'react'
import { theme } from 'theme'
import { InputControlProps } from './input'
import {
  selectTempInput,
  setTemporaryRuleInput,
  useRulesStore,
} from './store'
import {
  start_set_point,
} from './util'
import Stack from 'ui/stack'
import Muted from './muted'
import Emphasis from './emphasis'

function OutdoorAir({
  ruleId,
  inputId,
}: InputControlProps) {
  const [selectedFactor, setSelectedFactor] = useState('2')
  const input = useRulesStore(selectTempInput(ruleId, inputId))

  useEffect(() => {
    if (!input?.measurement || !start_set_point) return
    setSelectedFactor(`${input?.start_set_point}`)
  }, [input?.measurement, input?.start_set_point])

  if (!input) return null

  const handleChange = (event, value) => {
    setSelectedFactor(value)
    if (value === 'custom') return
    return setTemporaryRuleInput(ruleId, input.id, {
      start_set_point: parseInt(value),
      stop_set_point: parseInt(value),
    })
  }

  return (
    <Stack>
      <Typography
        variant='body1'
      >
        <Muted>
          <Emphasis>
            Outdoor AQI {` `}
          </Emphasis>
          is within
        </Muted>
      </Typography>
      <RadioGroup
        value={selectedFactor}
        aria-label={input.measurement}
        name={input.measurement}
        onChange={handleChange}>
        <FormControlLabel value='2' control={<Radio />} label={
          <Typography>
            1 - 50
            <Muted> (good range)</Muted>
          </Typography>
        } />
        <FormControlLabel value='3' control={<Radio />} label={
          <Typography>
            50 - 100
            <Muted> (fair range)</Muted>
          </Typography>
        } />
      </RadioGroup>
    </Stack>
  )
}

export default OutdoorAir
