import {
    selectAutomation,
    selectRule,
    setAutomation,
    setTemporaryRule,
    useAutomationsStore,
    useRulesStore,
} from "features/customer-drill-down/equipment/store"
import { findAlertType } from "features/customer-drill-down/equipment/util"
import mixpanel from "mixpanel-browser"
import { useCallback, useState } from "react"
import httpService from "state-mngt/services/data/http-service"
import { AutomationResponse } from "types"

function useRuleById(id) {
    return useRulesStore(selectRule(id))
}

function useToggleEnabledState(ruleId: number) {
    const [loading, setLoading] = useState<boolean>()
    const rule = useRuleById(ruleId)
    const automation = useAutomationsStore(selectAutomation(rule.automationId))

    // toggle enabled flag on the automation
    const toggle = useCallback(() => {
        const isTempalte = rule?.trigger?.isTemplate

        if (!rule) return
        if (!automation) return

        if (isTempalte) {
            // format the automation for the API
            const updatedAutomation = {
                ...automation,
                id: null,
                rules: automation.rules
                    .map(({ isTemplate, id, enabled, ...rest }) =>
                        id === ruleId ?
                            ({ ...rest, id, enabled: !enabled }) :
                            ({ ...rest, id, enabled })),
            }

            // create a new automation then save it on state
            const create = async () => {
                setLoading(true)
                try {
                    const { id } = await httpService.post(`/automation`, updatedAutomation)
                    const savedAutomation = await httpService.get<AutomationResponse>(`/automation/${id}`)
                    if (mixpanel) mixpanel.track('New alert created', {
                        alertType: findAlertType(rule),
                        automation: id,
                        dwelling: rule.dwellingId,
                    })
                    setAutomation(id, savedAutomation)
                } catch (e) {
                    console.error(e)
                } finally {
                    setLoading(false)
                }
            }

            create()
        } else {
            const updatedAutomation = {
                ...automation,
                rules: automation.rules
                    .map(({ id, enabled, ...rest }) =>
                        id === ruleId ?
                            ({ ...rest, id, enabled: !enabled }) :
                            ({ ...rest, id, enabled })),
            }

            const update = async () => {
                setLoading(true)
                try {
                    // setAutomation(automation.id, updatedAutomation)
                    await httpService.post(`/automation/${automation.id}`, updatedAutomation)
                    const savedAutomation = await httpService.get<AutomationResponse>(`/automation/${automation.id}`)
                    setTemporaryRule(ruleId, { ...rule, trigger: { ...rule.trigger, enabled: !rule.trigger.enabled } })
                    setAutomation(automation.id, savedAutomation)
                } catch (e) {
                    console.error(e)
                } finally {
                    setLoading(false)
                }
            }
            update()
            if (mixpanel) mixpanel.track(`Alert toggled`, {
                toggled: rule.trigger.enabled ? 'off' : 'on',
                alertType: findAlertType(rule),
                automation: rule.automationId,
                dwelling: rule.dwellingId,
            })
        }
    }, [
        loading,
        JSON.stringify(rule),
        JSON.stringify(automation),
    ])

    return { loading, toggle }
}

export default useToggleEnabledState
