import { Automation, AutomationResponse, AutomationRule } from "types"
import {
  selectAutomation,
  selectRule,
  tempKey,
  useAutomationsStore,
  useRulesStore,
} from "./store"
import httpService from "state-mngt/services/data/http-service"
import { tempId } from "./util"

/**
 * saving an automation requires creating new inputs
 * with the changes and saving those to the automation
 */
const mergeRule = (tempRule: AutomationRule, automation: Automation): Automation => {
  // create new inputs for each input in the rule
  const ruleId = tempRule.id
  const touchedInputs = tempRule.inputs.filter(x => x.touched)
  const newInputs = touchedInputs.map(x => ({ ...x, id: tempId() }))
  const inputsToRemove = touchedInputs.map(x => x.id)

  // replace automation outputs with temp outputs
  const newOutputs = automation.outputs.map(x => {
    const match = tempRule.outputs.find(y => y.id === x.id)
    if (match) return match
    return x
  })

  const ruleOutputs = tempRule.outputs.map(x => x.id)

  return {
    ...automation,
    rules: automation.rules.map(rule => {
      if (rule.id === ruleId) return {
        ...rule,
        inputs: [
          ...rule.inputs.filter(x => !inputsToRemove.includes(x)),
          ...newInputs.map(x => x.id),
        ],
        outputs: ruleOutputs,
      }
      return rule
    }),
    outputs: newOutputs,
    inputs: [...automation.inputs, ...newInputs],
  }
}

function useMergeTemporaryRuleIntoAutomation(ruleId) {
  const tempRule = useRulesStore(selectRule(tempKey(ruleId)))
  const automation = useAutomationsStore(selectAutomation(tempRule?.automationId))
  if (!tempRule || !automation) return null
  return mergeRule(tempRule, automation)
}

async function save(automation) {
  try {
    await httpService.post(`/automation/${automation.id}`, automation)
    const r = await httpService.get<AutomationResponse>(`/automation/${automation.id}`)
    return { ...r, id: automation.id }
  } catch (e) {
    console.error(e)
  }
}

/**
 * used for persisting an automation that has been
 * changed via a temporary rule. merges the temporary
 * rule into the automation, and then saves the changes
 */
function useSaveTemporaryRuleToAutomation(ruleId?: number) {
  if (!ruleId) return () => Promise.resolve()
  const mergedAutomation = useMergeTemporaryRuleIntoAutomation(ruleId)
  if (!mergedAutomation) return () => Promise.resolve()
  return () => save(mergedAutomation)
}

export {
  useMergeTemporaryRuleIntoAutomation,
}

export default useSaveTemporaryRuleToAutomation
