import { tempKey, useRulesStore } from "./store"

const select = id => store => store[id]

function useIsTouched(ruleId, keys = [] as string[]) {
  const rule = useRulesStore(select(ruleId))
  const tempRule = useRulesStore(select(tempKey(ruleId)))

  if (!rule) return false
  if (!tempRule) return false

  const _rule = keys.length ?
    {
      ...rule,
      inputs: rule.inputs.map(input => Object.keys(input).reduce((prev, curr) => {
        if (keys.includes(curr)) return {
          ...prev,
          [curr]: input[curr],
        }
        return prev
      }, {
      })),
    } : rule

  const _tempRule = keys.length ?
    {
      ...tempRule,
      inputs: tempRule.inputs.map(input => Object.keys(input).reduce((prev, curr) => {
        if (keys.includes(curr)) return {
          ...prev,
          [curr]: input[curr],
        }
        return prev
      }, {
      })),
    } : tempRule

  return (JSON.stringify(_rule) !== JSON.stringify(_tempRule))
}

export default useIsTouched
