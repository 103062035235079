import useCurrentDwellingContents from "./useCurrentDwellingContents"
import { dedupe } from "features/customer-drill-down/equipment/useGetData"

const parseZonesFromContents = (contents) =>
  dedupe([
    ...contents.devices.map(x => x.zone),
    ...contents.ecosense_devices.map(x => x.zone),
    ...contents.equipment.map(x => x.zone),
  ].filter(x => x)) as string[]

export {
  parseZonesFromContents,
}

export default () => {
  const contents = useCurrentDwellingContents()
  if (!contents) return []
  return parseZonesFromContents(contents)
}
