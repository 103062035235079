import { StepType } from '@reactour/tour'
import {
  CloseStepCallbackFn,
  NextStepCallbackFn,
  PreviousStepCallbackFn,
  TourStepContent,
} from 'ui/tour-step-content'
import { Avatar, Box, Typography } from '@material-ui/core'
import { getTourProviderStyle } from 'features/product-tour/tour-provider-with-custom-options'
import { theme } from 'theme'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import React from 'react'


/**
 * CSS selectors of DOM elements to be highlighted during the product tour.
 */
export const CUSTOMERS_PAGE_TOUR_SELECTORS = {
  DEMO_ACCOUNTS_TABLE: 'demo-accounts-table',
  CUSTOMERS_ACCOUNTS_TABLE: 'customers-accounts-table',
  DEMO_ACCOUNTS_TABLE_PRIORITY_COLUMN: 'demo-accounts-table-priority-column',
  DEMO_ACCOUNTS_TABLE_ISSUE_TYPE_COLUMN: 'demo-accounts-table-issue-type-column',
  DEMO_ACCOUNTS_TABLE_FILTER_STATUS_COLUMN: 'demo-accounts-table-filter-status-column',
}

/**
 * Get an array of steps used with "@reactour/tour" library for the customers page.
 * Each step contains elements that will be highlighted using the CSS selectors and popovers, a custom popup with an
 * explanation about the current step and buttons for going back or forward between the steps.
 * @param previousStepHandler PreviousStepCallbackFn function for handling the click of the previous button.
 * @param nextStepHandler NextStepCallbackFn function for handling the click of the next button.
 * @param closeStepHandler CloseStepCallbackFn function for handling the click of the close icon button.
 */
export const getCustomersPageTourSteps = (previousStepHandler: PreviousStepCallbackFn,
  nextStepHandler: NextStepCallbackFn, closeStepHandler: CloseStepCallbackFn) : StepType[] => {

  const boxesStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'stretch',
  }

  const stepContentIconsStyles: React.CSSProperties = {
    marginRight: '.5rem',
    marginBottom: '.5rem',
  }

  const issueTypesStyle: React.CSSProperties = {
    width: '1.5rem',
    height: '1.5rem',
    marginRight: '.5rem',
    marginBottom: '.5rem',
    backgroundColor: theme.palette.grey[300],
  }

  const tourStepName = 'customersPageTour'

  return [
    {
      selector: `#${CUSTOMERS_PAGE_TOUR_SELECTORS.DEMO_ACCOUNTS_TABLE}`,
      content: (props: any) => (
        <TourStepContent
          title="Demo Data Customers"
          tourStepName={tourStepName}
          content={
            <>
              <Typography variant="body1" gutterBottom>
                At the top are 3 demo customer stories for you to check out (we’ll get to that, don’t worry!).
              </Typography>
              <br/>
              <Typography variant="body1">
                Each of these dwellings has a different type of issue that HAVEN is uniquely able to detect and help
                solve.
              </Typography>
            </>
          }
          currentStep={props.currentStep + 1}
          totalSteps={props.steps.length}
          previousStepText="Previous"
          nextStepText="Next"
          onPreviousStep={previousStepHandler}
          onNextStep={nextStepHandler}
          onCloseStep={closeStepHandler}
        />
      ),
    },
    {
      selector: `[data-tour="${CUSTOMERS_PAGE_TOUR_SELECTORS.CUSTOMERS_ACCOUNTS_TABLE}"]`,
      content: (props: any) => (
        <TourStepContent
          title="Customers"
          tourStepName={tourStepName}
          content={
            <>
              <Typography variant="body1" gutterBottom>
                When you have real HAVEN customers, their dwellings will appear below the demo accounts, prioritized by
                issue type and severity.
              </Typography>
            </>
          }
          currentStep={props.currentStep + 1}
          totalSteps={props.steps.length}
          previousStepText="Previous"
          nextStepText="Next"
          onPreviousStep={previousStepHandler}
          onNextStep={nextStepHandler}
          onCloseStep={closeStepHandler}
        />
      ),
      styles: {
        ...getTourProviderStyle(),
        // The background/backdrop area that is NOT highlighted.
        maskWrapper: (base: any) => {
          return {
            ...getTourProviderStyle().maskWrapper(base),
            // Somehow if this is none this step continues capturing mouse events on the elements under the mask.
            // The user should not be able to interact with anything other than the popover on this step.
            pointerEvents: 'stroke',
          }
        },
        // The highlighted area used in the selector
        maskArea: (base: any, state: any) => {
          const { x, height } = state

          return {
            ...base,
            width: `calc(100vw - ${x}px)`,
            height: height + 140,
            rx: 10,
          }
        },
        // I am not sure why, but if maskArea is overridden it won't work properly unless clickArea is also overridden
        // with at least the width set.
        clickArea: (base: any, state: any) => {
          const { x } = state

          return {
            ...base,
            width: `calc(100vw - ${x}px)`,
          }
        },
      },
    },
    {
      selector: 'dummy-selector-because-it-cannot-be-empty',
      highlightedSelectors: [
        `[data-tour="${CUSTOMERS_PAGE_TOUR_SELECTORS.DEMO_ACCOUNTS_TABLE_PRIORITY_COLUMN}"]`,
        `[data-tour="${CUSTOMERS_PAGE_TOUR_SELECTORS.DEMO_ACCOUNTS_TABLE_PRIORITY_COLUMN}-id:-1"]`,
        `[data-tour="${CUSTOMERS_PAGE_TOUR_SELECTORS.DEMO_ACCOUNTS_TABLE_PRIORITY_COLUMN}-id:-2"]`,
        `[data-tour="${CUSTOMERS_PAGE_TOUR_SELECTORS.DEMO_ACCOUNTS_TABLE_PRIORITY_COLUMN}-id:-3"]`,
      ],
      content: (props: any) => (
        <TourStepContent
          title="Prioritization"
          tourStepName={tourStepName}
          content={
            <>
              <Typography variant="body1" gutterBottom>
                The priority column sorts customers based on the percentage of time in the last month their air quality
                spent in a “Poor” threshold.
              </Typography>
              <br/>
              <Typography variant="body1" gutterBottom>
                This time is limited to when airflow was active, as that’s when the Monitor can best detect the air
                quality within the entire home.
              </Typography>
              <br/>
              <Box style={boxesStyle}>
                <ArrowUpwardIcon style={{
                  color: theme.palette.error.main,
                  border: `1px solid ${theme.palette.error.main}`,
                  ...stepContentIconsStyles,
                  borderRadius: '50%',
                }} />
                High priority: &gt;15%
              </Box>
              <Box style={boxesStyle}>
                <ArrowUpwardIcon style={{
                  ...stepContentIconsStyles,
                  color: theme.palette.error.main,
                }} />
                Medium priority: 5-15%
              </Box>
              <Box style={boxesStyle}>
                <KeyboardArrowUpIcon style={{
                  ...stepContentIconsStyles,
                  color: theme.palette.warning.main,
                }} />
                Low priority: 2-5%
              </Box>
              <Box style={boxesStyle}>
                <KeyboardArrowDownIcon style={{
                  ...stepContentIconsStyles,
                  color: theme.palette.grey[300],
                }} />
                No priority: &lt;2%
              </Box>
            </>
          }
          currentStep={props.currentStep + 1}
          totalSteps={props.steps.length}
          previousStepText="Previous"
          nextStepText="Next"
          onPreviousStep={previousStepHandler}
          onNextStep={nextStepHandler}
          onCloseStep={closeStepHandler}
        />
      ),
    },
    {
      selector: 'dummy-selector-because-it-cannot-be-empty',
      highlightedSelectors: [
        `[data-tour="${CUSTOMERS_PAGE_TOUR_SELECTORS.DEMO_ACCOUNTS_TABLE_ISSUE_TYPE_COLUMN}"]`,
        `[data-tour="${CUSTOMERS_PAGE_TOUR_SELECTORS.DEMO_ACCOUNTS_TABLE_ISSUE_TYPE_COLUMN}-id:-1"]`,
        `[data-tour="${CUSTOMERS_PAGE_TOUR_SELECTORS.DEMO_ACCOUNTS_TABLE_ISSUE_TYPE_COLUMN}-id:-2"]`,
        `[data-tour="${CUSTOMERS_PAGE_TOUR_SELECTORS.DEMO_ACCOUNTS_TABLE_ISSUE_TYPE_COLUMN}-id:-3"]`,
      ],
      content: (props: any) => (
        <TourStepContent
          title="Issue types"
          tourStepName={tourStepName}
          content={
            <>
              <Typography variant="body1" gutterBottom>
                Issue Type indicates which parameter(s) are causing this particular customer to be flagged as a
                "Priority".
              </Typography>
              <br/>
              <Box style={boxesStyle}>
                <Avatar style={issueTypesStyle}> P </Avatar>
                PM2.5 = Fine particulates
              </Box>
              <Box style={boxesStyle}>
                <Avatar style={issueTypesStyle}> V </Avatar>
                tVOC = total Volatile Organic   Compounds
              </Box>
              <Box style={boxesStyle}>
                <Avatar style={issueTypesStyle}> H </Avatar>
                RH = Relative humidity
              </Box>
            </>
          }
          currentStep={props.currentStep + 1}
          totalSteps={props.steps.length}
          previousStepText="Previous"
          nextStepText="Next"
          onPreviousStep={previousStepHandler}
          onNextStep={nextStepHandler}
          onCloseStep={closeStepHandler}
        />
      ),
    },
    {
      selector: 'dummy-selector-because-it-cannot-be-empty',
      highlightedSelectors: [
        `[data-tour="${CUSTOMERS_PAGE_TOUR_SELECTORS.DEMO_ACCOUNTS_TABLE_FILTER_STATUS_COLUMN}"]`,
        `[data-tour="${CUSTOMERS_PAGE_TOUR_SELECTORS.DEMO_ACCOUNTS_TABLE_FILTER_STATUS_COLUMN}-id:-1"]`,
        `[data-tour="${CUSTOMERS_PAGE_TOUR_SELECTORS.DEMO_ACCOUNTS_TABLE_FILTER_STATUS_COLUMN}-id:-2"]`,
        `[data-tour="${CUSTOMERS_PAGE_TOUR_SELECTORS.DEMO_ACCOUNTS_TABLE_FILTER_STATUS_COLUMN}-id:-3"]`,
      ],
      content: (props: any) => (
        <TourStepContent
          title="Filter status"
          tourStepName={tourStepName}
          content={
            <>
              <Typography variant="body1" gutterBottom>
                “Filter status” shows you when this dwelling’s filter needs to be replaced.
              </Typography>
              <br/>
              <Box style={boxesStyle}>
                <WarningIcon
                  style={{
                    ...stepContentIconsStyles,
                    color: theme.palette.error.main,
                  }}
                />
                Time for a filter change
              </Box>
              <Box style={boxesStyle}>
                <CheckCircleOutlineOutlinedIcon style={stepContentIconsStyles}/>
                Filter is ok
              </Box>
            </>
          }
          currentStep={props.currentStep + 1}
          totalSteps={props.steps.length}
          previousStepText="Previous"
          nextStepText="Continue Tour"
          onPreviousStep={previousStepHandler}
          onNextStep={nextStepHandler}
          onCloseStep={closeStepHandler}
        />
      ),
    },
  ]
}
