import { Reducer } from 'redux'
import { ActionType, SetErrorAction } from 'state-mngt/actions/error-actions'
import { ErrorType } from 'state-mngt/models/error'

interface ErrorState {
  type: ErrorType | null;
}

const initState: ErrorState = {
  type: null,
}

type ReducerAction = SetErrorAction;

const reducer: Reducer<ErrorState, ReducerAction> = (state = initState, action) => {
  switch (action.type) {
    case ActionType.SetError: {
      return {
        ...state,
        type: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
