import * as React from 'react'
import Stack from "ui/stack";
import AutomationRuleCard from "./automation-rule-card";
import RuleTitle from "./ruleTitle";
import Override from "./override";
import { selectRule, useRulesStore } from './store';
import { Box, Button } from '@material-ui/core';
import StaticInput from './static-input';
import EquipmentSection from './equipment-section';

function StaticRule({
    ruleId,
    renderFeedback,
    handleClickEdit,
}) {
    const rule = useRulesStore(selectRule(ruleId))
    const outputs = rule.outputs.filter(x => x.equipment)

    return (
        <AutomationRuleCard>
            <Stack direction='row'>
                <RuleTitle>
                    Activate
                </RuleTitle>
                <Override
                    ruleId={ruleId}
                />
            </Stack>
            <EquipmentSection outputs={outputs} />
            {rule.inputs.map((input, index) => (
                <Stack
                    style={{ marginTop: '8px' }}
                    key={input.id}
                    spacing={1}
                >
                    <RuleTitle>
                        {(index > 0) ? 'And' : 'When'}
                    </RuleTitle>
                    <StaticInput ruleId={rule.id} inputId={input.id} />
                </Stack>
            ))}
            <Box
                width='100%'
                display='flex'
                mt={2}
                justifyContent='flex-end'
            >
                {renderFeedback()}
                <Button
                    color='primary'
                    size='small'
                    onClick={handleClickEdit}
                    variant='outlined'>
                    Edit
                </Button>
            </Box>
        </AutomationRuleCard>
    )
}

export default StaticRule
