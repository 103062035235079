import { ClickAwayListener, IconButton } from '@material-ui/core'
import React from 'react'
import InfoTooltip from 'ui/info-tooltip'
import InfoIcon from '@material-ui/icons/Info'

/**
 * used for tooltips containing interactive element such as links
 * @param props - { content: JSX.Element; open: boolean; setOpen: (value: boolean)=>void }
 * @returns JSX.Element
 */
const InteractiveInfoTooltip = (props: { content: JSX.Element; open: boolean; setOpen: (value: boolean) => void }) => {
  const { content, open, setOpen } = props

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <InfoTooltip
          placement="right-start"
          arrow
          onClose={() => setOpen(false)}
          open={open}
          interactive
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={content}
        >
          <IconButton
            onClick={() => setOpen(true)}
            style={{
              padding: 0,
            }}
          >
            <InfoIcon style={{
              margin: '.4rem',
            }} fontSize="small" />
          </IconButton>
        </InfoTooltip>
      </div>
    </ClickAwayListener>
  )
}

export default InteractiveInfoTooltip
