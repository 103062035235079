import React from 'react'
import { Box, CircularProgress, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  spinner: {
    width: '18px !important',
    height: '18px !important',
  },
}))

function ButtonSpinner() {
  const classes = useStyles()
  return (
    <Box display='flex'>
      <CircularProgress className={classes.spinner} color='primary' />
    </Box>
  )
}

export default ButtonSpinner
