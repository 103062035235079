export enum EmployeeCount {
  ONE_TO_TEN = '1-10',
  ELEVEN_TO_FIFTY = '11-50',
  FIFTY_ONE_TO_ONE_HUNDRED = '51-100',
  MORE_THAN_HUNDRED = '100+',
}

export const initCompanyProfile = {
  first_name: '',
  last_name: '',
  user_email: '',
  company_name: '',
  website: '',
  country: '',
  city: '',
  province: '',
  postal_code: '',
  street_1: '',
  street_2: '',
  email: '', // for company
  phone: '', // for company
  services: [],
  focuses: [],
  distribution: [],
  employeeCount: '',
  hearAboutUs: [],
  has_residential_focus: false,
  has_commercial_focus: false,
  does_perform_building_science_projects: false,
  wantBeta: false,
  wantDemo: false,
  hasSalesTeam: false,
  comments: '',
}

export const companyServicesOptions =[
  {
    label: 'Equipment installation',
    value: 'equipment_installation',
    info: 'Installing new equipment and/or replacing old equipment',
  },
  {
    label: 'Service',
    value: 'service',
    info: 'Inspecting, maintaining and repairing equipment and systems',
  },
  {
    label: 'Maintenance plans',
    value: 'maintenance_plans',
    info: 'Service maintenance contracts for scheduling regular visits (sometimes based on season) to maintain efficiency and reliability of equipment',
  },
]

export const companyBuildingScienceServicesOptions = [
  {
    label: 'Home comfort & performance consultation',
    value: 'home_comfort',
    info: 'Comfort Consult Info Content Assessing your customers’ heating and cooling expectations, alongside the evaluation and testing of the equipment, insulation and ductwork. Typically followed by providing recommendations that can improve the home’s overall health, comfort and energy efficiency.',
  },
  {
    label: 'IAQ consultation',
    value: 'iaq_consultation',
    info: 'Efforts to help customers understand how HVAC system and home construction can improve indoor air quality',
  },
  {
    label: 'Smart home',
    value: 'smart_homes',
    info: 'Selling and installing connected, smart home equipment (e.g. smart thermostats)',
  },
  {
    label: 'Energy audits',
    value: 'energy_audits',
    info: 'Building energy auditing & providing reports for combustion safety, building envelope, HVAC system sizing & efficiency, moisture problems (may also include thermal imaging, water efficiency, appliances, dryer vents, energy bills)',
  },
]

export const companyResidentialFocusesOptions = [
  {
    label: 'Single Family Residential',
    value: 'single_family_residential',
    info: 'Detached single family homes',
  },
  {
    label: 'Multi Family Residential',
    value: 'multi_family_residential',
    info: 'Condos, apartment buildings',
  },
  {
    label: 'High-end homes',
    value: 'high_end_residential',
    info: 'Consulting on and providing top of the line, end-to-end solutions (retrofitting and/or new)',
  },
  {
    label: 'New construction',
    value: 'new_construction_residential',
    info: 'Working with homeowners and/or developers to outfitting new homes with ductwork, AC, IAQ, exhaust & fresh air, de-/humidifiers, thermostats, air exchange systems, etc.',
  },
]

export const companyCommercialFocusesOptions = [
  {
    label: 'Light Commercial',
    value: 'light_commercial',
    info: 'Smaller-scale business construction for offices, free-standing retail, restaurants, small medical facilities, banks, etc.). Usually under four stories and less than 25,000 square feet.',
  },
  {
    label: 'Heavy Commercial',
    value: 'heavy_commercial',
    info: 'Buildings above 4 stories, more than 25,000 square feet. ',
  },
  {
    label: 'Industrial',
    value: 'industrial',
    info: 'Warehouses, manufacturing facilities, etc.',
  },
]

export const distributionOptions = [
  {
    label: 'Crescent Parts',
    value: 'crescent_parts',
  },
  {
    label: 'East Coast Metals',
    value: 'east_coast_metals',
  },
  {
    label: 'Ferguson',
    value: 'ferguson',
  },
  {
    label: 'Goodman',
    value: 'goodman',
  },
  {
    label: 'Johnstone Supply',
    value: 'johnstone_supply',
  },
  {
    label: 'Steven’s Equipment',
    value: 'stevens_equipment',
  },
  {
    label: 'TruTech Tools',
    value: 'trutech_tools',
  },
  {
    label: 'Wolseley Canada Inc.',
    value: 'wolseley_canada',
  },
  {
    label: 'Others',
    value: 'others',
  },
]

export const hearAboutUsOptions = [
  {
    label: 'LinkedIn',
    value: 'linked_in',
  },
  {
    label: 'Facebook',
    value: 'facebook',
  },
  {
    label: 'Customer',
    value: 'customer',
  },
  {
    label: 'Sales Rep',
    value: 'sales_rep',
  },
  {
    label: 'Colleague',
    value: 'colleague',
  },
  {
    label: 'Industry Event',
    value: 'industry_event',
  },
  {
    label: 'Search Engine',
    value: 'search_engine',
  },
  {
    label: 'Publication',
    value: 'publication',
  },
  {
    label: 'Podcast',
    value: 'pod_cast',
  },
  {
    label: 'Others',
    value: 'others',
  },
]
