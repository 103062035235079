import React, { createContext, PropsWithChildren, useContext } from 'react'
import mixpanel, { Mixpanel } from 'mixpanel-browser'


export interface MixPanelContextType {
  mixpanel?: Mixpanel;
}

export const MixPanelContext = createContext<MixPanelContextType>({
})

export const useMixPanel = () => useContext(MixPanelContext)

export const MixPanelProvider = ({ children }: PropsWithChildren<{}>) => (
  <MixPanelContext.Provider value={{
    mixpanel,
  }}>
    {children}
  </MixPanelContext.Provider>
)
