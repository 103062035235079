import { DeviceTelemetry, CacDeviceTelemetry } from 'state-mngt/models/device'

export enum GanttSeriesType {
  Airflow,
  CacChannel,
}

interface GanttSeriesOptionsBase {
  title: string;
  color: string;
  channelNumber?: number;
}

interface AirflowGanttSeriesOptions extends GanttSeriesOptionsBase {
  seriesType: GanttSeriesType.Airflow;
  data: DeviceTelemetry[];
}

interface CacChannelGanttSeriesOptions extends GanttSeriesOptionsBase {
  seriesType: GanttSeriesType.CacChannel;
  data: CacDeviceTelemetry[];
  channelNumber: number;
}

export type GanttSeriesOptions = AirflowGanttSeriesOptions | CacChannelGanttSeriesOptions;

export const SERIES_COLORS = [
  '#479BD0',
  '#79EED2',
  '#8871A9',
  '#FBE57F',
  '#FF7A7E',
]

/**
 * An annotation interface which serves as a container for items like labels or
 * shapes. Created items are positioned on the chart either by linking them to
 * existing points or created mock points.
 */
export interface Annotation {
  getLabels: (series: any) => Array<{
    point: {
      xAxis: number;
      yAxis: number;
      x: number;
      y: number;
    },
    text: string;
  }>
  getShapes: (series: any) => Array<{
    point: {
      xAxis: number;
      yAxis: number;
      x: number;
      y: number;
    },
    type: 'circle',
  }>
}
