import { AutomationOutput, Equipment } from "types"

const findEquipmentType = (equipment: string) => {
    if (equipment.includes('ventilating_dehumidifier')) return 'ventilating_dehumidifier'
    return equipment
}

const findEquipmentComponent = (equipment: string) => {
    if (equipment.includes('ventilating_dehumidifier_fan')) return 1
    if (equipment.includes('ventilating_dehumidifier_compressor')) return 0
    return 0
}

const findCompressor = (outputs: Partial<AutomationOutput>[], equipment?: Equipment) => {
    if (!equipment) return false
    const output = outputs.find(x => x.equipment_id === equipment.id && x.equipment_component === 0)
    return Boolean(output) && equipment
}

const findFan = (outputs: Partial<AutomationOutput>[], equipment?: Equipment) => {
    if (!equipment) return false
    const output = outputs.find(x => x.equipment_id === equipment.id && x.equipment_component === 1)
    return Boolean(output) && equipment
}

export {
    findEquipmentType,
    findEquipmentComponent,
    findCompressor,
    findFan,
}
