import { Box, Button, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import rocketImageUrl from 'features/payment-redirect/rocket.svg'
import { Link } from 'react-router-dom'
import { useAppSelector } from 'utils/hooks/reduxTypes'
import { selectCompanyDetails } from 'state-mngt/selectors/company-selectors'
import companyService from 'state-mngt/services/company-service'
import { Elements, useStripe } from '@stripe/react-stripe-js'
import { ROUTE_PATHS } from 'routes/routes'
import { PaymentIntent, loadStripe } from '@stripe/stripe-js'
import config from 'config'

const stripePromise = loadStripe(config.stripePublicKey)

const PaymentRedirect = () => {
  const [status, setStatus] = useState<PaymentIntent.Status | null>(null)
  const stripe = useStripe()
  const companyDetails = useAppSelector(selectCompanyDetails)

  useEffect(() => {
    if (!stripe) return
    if (!companyDetails?.id) return

    const clientSecretParam = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret",
    )
    if (!clientSecretParam) return

    const observePayment = async () => {
      const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecretParam)
      if (!paymentIntent) return

      setStatus(paymentIntent.status)

      if (paymentIntent.status === 'succeeded') {
        await companyService.updateJourney(companyDetails.id, 'pup_purchased')
      }
    }

    observePayment()
  }, [stripe, companyDetails?.id])

  const renderMessaging = () => {
    switch (status) {
      case 'succeeded': {
        return (
          <>
            <Typography color="secondary" variant="h4" gutterBottom>Payment Successful</Typography>
            <br />
            <img
              alt="Haven CAM product shot"
              src={rocketImageUrl}
              width="196"
              height="196"
            />
            <Box width="600px" m="60px auto">
              <Typography>
                {'You should receive an email with your invoice and purchase details shortly. We\'ll be shipping your new evaluation charts to you ASAP!'}
              </Typography>
            </Box>
          </>
        )
      }
      case 'canceled':
      case 'requires_payment_method': {
        return (
          <>
            <Typography color="secondary" variant="h4" gutterBottom>Payment Failed</Typography>
            <br />
            <Box width="600px" m="60px auto">
              <Typography>
                We couldn't process your payment.
              </Typography>
              <br />
              <Button id="buy-a-pup-buy-btn"
                color="primary"
                variant="contained"
                component={Link}
                to={ROUTE_PATHS.howToBuy.personalUseProgram.absolute}
                style={{
                  margin: '1rem',
                }}>
                Try again
              </Button>
            </Box>
          </>
        )

      }
      default: {
        return <p>Loading...</p>
      }
    }
  }

  return (
    <Box textAlign="center" p={3} mt="15vh">
      {renderMessaging()}
    </Box>
  )
}

export default () => <Elements stripe={stripePromise}><PaymentRedirect /></Elements>
