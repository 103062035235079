import { useEffect, useState } from "react"
import { getUserPreferences } from "state-mngt/selectors/user-selectors"
import { Measurement } from "types"
import { useAppSelector } from "./reduxTypes"
import { noop } from "./useConvertTemperatureUnits"

const pmMassToCount = () => value => Math.round(value / 0.5726)
const pmCountToMass = () => value => Math.round(value * 0.5726)
const vocPpbToMass = () => value => Math.round(value * 2.35849)
const vocMassToPpb = () => value => Math.round(value / 2.35849)

function useConvertParticulateUnit(measurement: Measurement, unit) {
  const [convertedUnit, setConvertedUnit] = useState<string | null>(null)
  const [display, setDisplay] = useState(noop)
  const [format, setFormat] = useState(noop)
  const preferences = useAppSelector(getUserPreferences)

  useEffect(() => {
    if (!measurement) return
    if (!preferences) return

    const { pm_isMc, voc_isMc } = preferences

    setConvertedUnit(
      measurement === 'pm25' ?
        (pm_isMc ? 'ugm3' : 'count') :
        (voc_isMc ? 'ugm3' : 'ppb'),
    )

    if (measurement === 'pm25') {
      if (!pm_isMc) {
        setDisplay(pmMassToCount)
        setFormat(pmCountToMass)
      }
    }

    if (measurement === 'voc') {
      if (voc_isMc) {
        setDisplay(vocPpbToMass)
        setFormat(vocMassToPpb)
      }
    }

  }, [
    measurement,
    JSON.stringify(preferences),
  ])

  return {
    convertedUnit: convertedUnit || unit,
    display,
    format,
  }
}

export default useConvertParticulateUnit
