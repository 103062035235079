import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'
import React, { memo, useEffect, useState } from 'react'
import { InputControlProps } from './input'
import Emphasis from './emphasis'
import {
  selectTempInput,
  setTemporaryRuleInput,
  useRulesStore,
} from './store'
import {
  displayRange,
  humanizeMeasurement,
  start_set_point,
  stop_set_point,
  useSkipFirstRender,
  findUnit,
  parseUnit,
  airflowPhrasingFromFactor,
  isWithinFive,
} from './util'
import Stack from 'ui/stack'
import Muted from './muted'
import useConvertParticulateUnit from 'utils/hooks/useConvertParticulateUnit'

const useClasses = makeStyles(theme => ({
  textField: {
    marginLeft: '32px',
    maxWidth: '164px',
  },
}))

const stopPoint = (startPoint: number) => {
  return startPoint * 0.9
}

const startPointToFactor = measurement => unit => (startPoint: number) => {
  if (!measurement) return ''
  if (!unit) return ''
  const startPoints = start_set_point[measurement][unit]
  return Object.keys(startPoints).find(factor => isWithinFive(startPoints[factor], startPoint)) || 'custom'
}

function AirFlow({
  ruleId,
  inputId,
}: InputControlProps) {
  const [customSetPoint, setCustomSetPoint] = useState(0) // store it locally just so it updates visually on change without rendering the whole tree
  const [selectedFactor, setSelectedFactor] = useState('fair')
  const [initialSetComplete, setInitialSetComplete] = useState(false)

  const input = useRulesStore(selectTempInput(ruleId, inputId))
  const classes = useClasses()
  const unit = findUnit(input?.measurement)
  const { convertedUnit, display, format } = useConvertParticulateUnit(input?.measurement, unit)

  useSkipFirstRender(() => {
    if (!customSetPoint) return
    if (selectedFactor !== 'custom') return

    if (input?.start_set_point === customSetPoint) return
    if (unit !== convertedUnit) {
      if (input?.start_set_point === format(customSetPoint)) return
    }

    if (input?.start_set_point) setTemporaryRuleInput(ruleId, input.id, {
      start_set_point: format(customSetPoint),
      stop_set_point: stopPoint(format(customSetPoint)),
    })
  }, [customSetPoint, format])

  useEffect(() => {
    if (!input) return
    if (!convertedUnit) return
    if (initialSetComplete) return

    const good = start_set_point[input.measurement][convertedUnit]['good']
    const fair = start_set_point[input.measurement][convertedUnit]['fair']

    const isGood = isWithinFive(display(input?.start_set_point), good)
    const isFair = isWithinFive(display(input?.start_set_point), fair)

    const isCustom = !isGood && !isFair

    if (isCustom) {
      setSelectedFactor('custom')
      const setPoint = display(input.start_set_point)
      setCustomSetPoint(setPoint)
      setInitialSetComplete(true)
    }

  }, [
    input?.start_set_point,
    display,
    convertedUnit,
    initialSetComplete,
  ])

  useEffect(() => {
    /**
     * a bit hacky but a converted unit can change
     * at any time so it must be stateful. when it
     * updates we need to reevaluate the initial
     * custom set point
     */
    if (unit !== convertedUnit) setInitialSetComplete(false)
  }, [unit, convertedUnit])

  const handleChange = (event, value) => {
    setSelectedFactor(value)

    if (value === 'custom') return
    if (!input) return

    const start = start_set_point[input.measurement][unit][value]
    const stop = stop_set_point[input.measurement][unit][value]

    return setTemporaryRuleInput(ruleId, input?.id, {
      start_set_point: start,
      stop_set_point: stop,
    })
  }

  const onChangeCustomSetPoint = (event) => {
    const { value } = event.target
    let _value = parseInt(value) || 0
    if (_value > 9999) _value = 9999
    setCustomSetPoint(_value)
  }

  if (!input) return null

  const triggerAction = () => {
    if (!input) return null
    const parsedUnit = parseUnit(convertedUnit)

    if (selectedFactor === 'custom') {
      return `worse than ${customSetPoint} ${parsedUnit}`
    }

    return `${airflowPhrasingFromFactor[input.measurement][convertedUnit][selectedFactor]}`
  }

  const humanizedMeasurement = humanizeMeasurement(input.type, input.measurement)

  return (
    <Stack>
      <Typography
        variant='body1'
      >
        <Emphasis>
          {humanizedMeasurement} <Muted>levels are</Muted>
          {` `}{triggerAction()}{` `}
        </Emphasis>
      </Typography>
      <RadioGroup
        value={selectedFactor}
        aria-label={input.measurement}
        name={input.measurement}
        onChange={handleChange}>
        <FormControlLabel value='good' control={<Radio />} label={
          <Typography>
            Good
            <Muted>{` `}{displayRange[input.measurement][convertedUnit]['good']}</Muted>
          </Typography>
        } />
        <FormControlLabel value='fair' control={<Radio />} label={
          <Typography>
            Fair
            <Muted>{` `}{displayRange[input.measurement][convertedUnit]['fair']}</Muted>
          </Typography>
        } />
        <FormControlLabel value='custom' control={<Radio />} label={
          <Typography>
            Custom
          </Typography>
        } />
        {(selectedFactor === 'custom') ? (
          <TextField
            autoFocus
            className={classes.textField}
            value={customSetPoint}
            onChange={onChangeCustomSetPoint}
          />
        ) : null}
      </RadioGroup>
    </Stack>
  )
}

export {
  startPointToFactor,
}

export default memo(AirFlow)
