import React from 'react'
import { TelemetryDataType } from 'utils/constants/telemetry-data-type'
import { TimeInterval } from 'utils/constants/time-interval'
import { DeviceTelemetryQuality } from 'state-mngt/models/device'
import { ReportOptions } from 'state-mngt/models/report'
import { UserPreference } from 'state-mngt/models/user'
import { AllDeviceQuality } from 'features/customer-drill-down/charts/customer-history/historyPieCharts'
import { PieChartData } from 'ui/pie-chart/pie-chart'
import { humanize } from './string-utils'
import { HumidityQuality, Quality } from 'stores/quality'

// encode UTF8 for btoa()
export const b64EncodeUnicode = (str: string) => {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
    return String.fromCharCode(parseInt(p1, 16))
  }))
}

// Decode base64 to UTF8
export const b64DecodeUnicode = (str: string) => {
  return decodeURIComponent(str)
}

export const formatForPieChart = (data: Quality | null): PieChartData[] => {
  if (!data) return []
  const statuses = ['good', 'fair', 'poor', 'no_data']
  return statuses.map(status => ({
    name: humanize(status), y: data[status],
  }))
}

export const formatHumidityForPieChart = (data: HumidityQuality | null): PieChartData[] => {
  if (!data) return []
  const statuses = Object.keys(data)
  return statuses.map(status => ({
    name: humanize(status), y: data[status],
  }))
}

export const qualityToPieChartData = (type: TelemetryDataType, quality: AllDeviceQuality, id?: string) => {
  if (id) return formatForPieChart(quality.ecosense[id])

  switch (type) {
    case TelemetryDataType.PM:
    case TelemetryDataType.VOC:
      return formatForPieChart(quality.pm_status)
    default:
      return formatHumidityForPieChart(quality.humidity_status)
  }
}

const qualityKey = (type) => {
  const keys = ['pm_status', 'voc_status', 'humidity_status', 'ecosense', 'dew_point_status']

  if (type === TelemetryDataType.PM) return keys[0]
  if (type === TelemetryDataType.VOC) return keys[1]
  if (type === TelemetryDataType.HUMIDITY) return keys[2]
  if (type === TelemetryDataType.ECOSENSE_RADON) return keys[3]
  if (type === TelemetryDataType.INDOOR_DEW_POINT) return keys[4]

  console.warn('[qualityKeys]', 'no key for device quality present: ', type)

  return ''
}

const pluckQuality = (quality, type, id = '') => {
  const value = quality[qualityKey(type)]
  if (id) return value[id]
  return value
}

/**
 * get device poor time/percentages based on type selected
 * @param type - TelemetryDataType
 * @param deviceQuality - DeviceTelemetryQuality
 * @param abovePoorForHumidity - This parameter is ONLY considered for humidity data type. If true, calculate for 
 * humidity above poor time. Otherwise, bellow poor time.
 */
export const getLegendStat = (
  startTime: Date,
  endTime: Date,
  value: number,
) => {

  let daysInThreshold = 0
  let percentageDaysInThreshold = 0

  if (!value) return `0`

  const diff = endTime.getTime() - startTime.getTime()
  const frac = value / 100

  const time = diff * frac
  daysInThreshold = time / (1000 * 60 * 60 * 24)
  percentageDaysInThreshold = value

  return `${daysInThreshold.toFixed(1)} days (${percentageDaysInThreshold.toFixed(1)}%)`
}

/**
 * get device fair time/percentages based on type selected
 * @param type - TelemetryDataType
 * @param deviceQuality - DeviceTelemetryQuality
 * @param aboveFairForHumidity - This parameter is ONLY considered for humidity data type. If true, calculate for 
 * humidity above fair time. Otherwise, bellow fair time.
 */
export const getFairStat = (type: TelemetryDataType, deviceQuality: DeviceTelemetryQuality,
  aboveFairForHumidity = false) => {
  const {
    pm_fair_time, voc_fair_time, humidity_high_fair_time, humidity_low_fair_time, total_time,
  } = deviceQuality

  // Since DeviceTelemetryQuality is in seconds we will need to convert from milliseconds to seconds, then from seconds to days.
  const secondsInOneDay = TimeInterval.DAY / 1000

  let daysInThreshold = 0
  let percentageDaysInThreshold = 0

  switch (type) {
    case TelemetryDataType.PM:
      daysInThreshold = pm_fair_time / secondsInOneDay
      percentageDaysInThreshold = (pm_fair_time / total_time) * 100
      break
    case TelemetryDataType.VOC:
      daysInThreshold = voc_fair_time / secondsInOneDay
      percentageDaysInThreshold = (voc_fair_time / total_time) * 100
      break
    case TelemetryDataType.HUMIDITY:
      const humidityToUse = (aboveFairForHumidity ? humidity_high_fair_time : humidity_low_fair_time)
      daysInThreshold = humidityToUse / secondsInOneDay
      percentageDaysInThreshold = (humidityToUse / total_time) * 100
      break
    default:
      daysInThreshold = pm_fair_time / secondsInOneDay
      percentageDaysInThreshold = (pm_fair_time / total_time) * 100
  }

  return `${daysInThreshold.toFixed(1)} days (${percentageDaysInThreshold.toFixed(1)}%)`
}

/**
 * get device good time/percentages based on type selected
 * @param type - TelemetryDataType
 * @param deviceQuality - DeviceTelemetryQuality
 */
export const getGoodStat = (type: TelemetryDataType, deviceQuality: DeviceTelemetryQuality) => {
  const {
    pm_good_time, voc_good_time, humidity_good_time, total_time,
  } = deviceQuality

  // Since DeviceTelemetryQuality is in seconds we will need to convert from milliseconds to seconds, then from seconds to days.
  const secondsInOneDay = TimeInterval.DAY / 1000

  let daysInThreshold = 0
  let percentageDaysInThreshold = 0

  switch (type) {
    case TelemetryDataType.PM:
      daysInThreshold = pm_good_time / secondsInOneDay
      percentageDaysInThreshold = (pm_good_time / total_time) * 100
      break
    case TelemetryDataType.VOC:
      daysInThreshold = voc_good_time / secondsInOneDay
      percentageDaysInThreshold = (voc_good_time / total_time) * 100
      break
    case TelemetryDataType.HUMIDITY:
      daysInThreshold = humidity_good_time / secondsInOneDay
      percentageDaysInThreshold = (humidity_good_time / total_time) * 100
      break
    default:
      daysInThreshold = pm_good_time / secondsInOneDay
      percentageDaysInThreshold = (pm_good_time / total_time) * 100
  }

  return `${daysInThreshold.toFixed(1)} days (${percentageDaysInThreshold.toFixed(1)}%)`
}

/**
 * get device no-data time/percentages based on device quality returned from api
 * @param type - TelemetryDataType
 * @param deviceQuality - DeviceTelemetryQuality
 */
export const getNoDataStat = (type: TelemetryDataType, deviceQuality: DeviceTelemetryQuality) => {
  const {
    pm_good_time, pm_fair_time, pm_poor_time,
    voc_good_time, voc_fair_time, voc_poor_time,
    humidity_low_poor_time, humidity_low_fair_time, humidity_good_time,
    humidity_high_fair_time, humidity_high_poor_time,
    total_time,
  } = deviceQuality

  // Since DeviceTelemetryQuality is in seconds we will need to convert from milliseconds to seconds, then from seconds to days.
  const secondsInOneDay = TimeInterval.DAY / 1000
  let daysInThreshold = 0
  let percentageDaysInThreshold = 0
  let netTotalTime = 0

  switch (type) {
    case TelemetryDataType.PM:
      netTotalTime = total_time - (pm_poor_time + pm_fair_time + pm_good_time)
      daysInThreshold = netTotalTime / secondsInOneDay
      percentageDaysInThreshold = (netTotalTime / total_time) * 100
      break
    case TelemetryDataType.VOC:
      netTotalTime = total_time - (voc_good_time + voc_fair_time + voc_poor_time)
      daysInThreshold = netTotalTime / secondsInOneDay
      percentageDaysInThreshold = (netTotalTime / total_time) * 100
      break
    case TelemetryDataType.HUMIDITY:
      netTotalTime = (total_time - (
        humidity_low_poor_time + humidity_low_fair_time + humidity_good_time +
        humidity_high_fair_time + humidity_high_poor_time)
      )
      daysInThreshold = netTotalTime / secondsInOneDay
      percentageDaysInThreshold = (netTotalTime / total_time) * 100
      break
    default:
      netTotalTime = total_time - (pm_poor_time + pm_fair_time + pm_good_time)
      daysInThreshold = netTotalTime / secondsInOneDay
      percentageDaysInThreshold = (netTotalTime / total_time) * 100
  }

  return `${daysInThreshold.toFixed(1)} days (${percentageDaysInThreshold.toFixed(1)}%)`
}

/**
 * get thresholds based on userPreferences and data type selected
 * @param option - ReportOptions
 * @param index - number
 * @param userPreferences - UserPreference
 */
export const getThreshold = (option: ReportOptions, index: number, userPreferences: UserPreference) => {
  switch (option.type) {
    case TelemetryDataType.PM:
      return (<small style={{
        display: 'block', marginLeft: '12px', padding: '4px',
      }}>{userPreferences.pm_isMc ? option.thresholdMg![index] : option.thresholdCount![index]}</small>)
    case TelemetryDataType.VOC:
      return (<small style={{
        display: 'block', marginLeft: '12px', padding: '4px',
      }}>{userPreferences.voc_isMc ? option.thresholdMg![index] : option.thresholdPPB![index]}</small>)
    case TelemetryDataType.HUMIDITY:
      return (<small style={{
        display: 'block', marginLeft: '12px', padding: '4px',
      }}>{option.thresholdMg![index]}</small>)
    default:
      return <></>
  }
}

export {
  pluckQuality,
}
