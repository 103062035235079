import { useCallback, useEffect, useState } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import useDecodedSearchParams from './useDecodedSearchParams'

/**
 * A custom hook that provides a function callback for adding, replacing or deleting URL query parameters to the browser's
 * current URL. It also pushes or replace the newly created URL in the browser's navigation history.
 */
export const useSetQueryToNavigationHistory = () => {

  const navigate = useNavigate()

  let toUpdate: Record<string, string>[] = []
  let timerId: number | undefined

  /**
   * A callback function for adding, replacing or deleting the URL query parameters passed to this function.
   * It also pushes or replace the newly created URL in the browser's navigation history.
   * @param params - An object with key/value pair as strings to be pushed or replaced in the URL query parameters.
   * If the value is an empty string then the query parameter will be removed from the URL.
   * @param replace - A boolean indicating if the parameter should be added or replaced in the navigation history.
   */
  return (params: Record<string, string>, replace = false) => {

    // queue when few query parameters value are being set/replaced together
    if (timerId !== undefined) {
      window.cancelAnimationFrame(timerId)
    }

    toUpdate.push(params)

    timerId = window.requestAnimationFrame(() => {

      // It uses window.location instead of hook useLocation because this is a callback function and the hook does not
      // provide the latest information when this function is called.
      const currentSearchParams = createSearchParams(window.location.search)

      // Loop through all query parameters within array.
      toUpdate.forEach((chunkParams) => {
        // Loop through all the key/value pairs of each query parameter.
        Object.entries(chunkParams).forEach(([key, value]) => {
          // Either create/update query parameter if there is a value or delete if there is none.
          if (value) {
            currentSearchParams.set(key, encodeURIComponent(value))
          } else {
            currentSearchParams.delete(key)
          }
        })
      })

      // Add to browser history, thus user can go back and forward within browser buttons.
      navigate({
        pathname: window.location.pathname,
        search: currentSearchParams.toString(),
      }, {
        replace: replace,
      })

      toUpdate = []
      timerId = undefined
    })
  }
}

interface SyncStateParams<T> {
  key: string;
  initialValue: T;
  fromQuery?: (queryValue: string) => T;
  toQuery?: (stateValue: T) => string;
  replace?: boolean;
  initializeInTheUrl?: boolean;
}

/**
 * A custom hook that synchronize components state with the URL as query parameters.
 * @param params - A SyncStateParams object containing four parameters.
 * <br/>
 * {
 * <br/> key: The name of the query parameter state that will be saved and appended/replaced in the URL. <br/>
 * <br/> initialValue: The initial value of the query parameter state.
 * <br/> fromQuery: Function for preserving the query parameter data type when extracted from the URL.
 * <br/> toQuery: Function for converting the query parameter data type to string, so it can be placed in the URL.
 * <br/> replace: A boolean indicating if the parameter should be added or replaced in the navigation history.
 * <br/> initializeInTheUrl: A boolean indicating if the initialValue should be added or replaced in the URL when
 * initializing this hook. Otherwise, the change in the URL will only happen when the setValue of this hook is called.
 * <br/> }
 */
export const useStateInURLQueryParameter = <T = string>(params: SyncStateParams<T>)
  : [T, (value: T, replace?: boolean) => void] => {
  const {
    key,
    initialValue,
    fromQuery = (queryValue: string) => queryValue,
    toQuery = (stateValue: T) => stateValue + '',
    replace = false,
    initializeInTheUrl = false,
  } = params

  const [searchParams] = useDecodedSearchParams()
  const setQuery = useSetQueryToNavigationHistory()

  // Either initialize the query parameter state with the initialValue argument passed to this hook or a pre-existent
  // query parameter value.
  const [value, setValue] = useState(() => {
    const queryValue = searchParams.get(key)

    if (queryValue) {
      // We don't need to check for initializeInTheUrl since the query is already in the URL.
      return fromQuery(queryValue) as T
    }

    if (initializeInTheUrl) {
      setQuery({
        [key]: toQuery(initialValue),
      }, replace)
    }

    return initialValue
  })

  /**
   * Callback function for updating the query parameter state and URL.It will be called by this hook consumers.
   */
  const onSetValue = useCallback((newValue: T, shouldReplace: boolean = replace) => {
    setQuery({
      [key]: toQuery(newValue),
    }, shouldReplace)
    setValue(newValue)
  }, [key, toQuery])

  return [value, onSetValue]
}

export const usePageTitle = (title?: string) => {
  useEffect(() => {
    document.title = title ? `${title} | Haven Pro` : 'Haven Pro'
  }, [title])
}
