import {
    Box,
    Typography,
} from '@material-ui/core'
import * as React from 'react'
import Automations from '../automations'
import { useParams } from 'react-router-dom'

function AutomationStep() {
    const { dwellingId } = useParams()
    if (!dwellingId) return null

    return (
        <>
            <Typography variant='h3'>
                Set up automations
            </Typography>
            <Box pt={4} mt={4}>
                <Automations
                    hideTitle
                    dwellingId={parseInt(dwellingId)}
                />
            </Box>
        </>
    )
}

export default AutomationStep
