import React from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

const RhIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0004 3.59977L14.8486 3.96281C14.5335 4.69839 14.1441 5.50333 13.5827 6.60072L12.4933 8.72041C10.8954 11.8692 10.4272 13.1197 10.4272 14.5311C10.4272 17.5515 12.874 20 15.8922 20C18.9104 20 21.3571 17.5515 21.3571 14.5311L21.3532 14.3111C21.3019 12.8869 20.7316 11.5356 18.9117 7.99976L18.3343 6.88165C17.6056 5.46449 17.1466 4.50508 16.7862 3.6053C16.4641 2.80092 15.3275 2.7974 15.0004 3.59977ZM15.9254 5.63844L15.9942 5.5L16.0672 5.64506C16.201 5.91153 16.3445 6.19214 16.4993 6.49068L17.0602 7.55964L17.6202 8.6209C19.4408 12.0945 20 13.446 20 14.5883C20 16.7487 18.2092 18.5 16 18.5C13.7908 18.5 12 16.7487 12 14.5883L12.0051 14.3987C12.0638 13.3093 12.6365 11.9417 14.2564 8.83354L15.4906 6.48625C15.6466 6.18604 15.7909 5.90481 15.9254 5.63844ZM6.24935 8.30114H5.46577V9.08531C5.46577 9.55503 5.08527 9.93582 4.6159 9.93582C4.14653 9.93582 3.76603 9.55503 3.76603 9.08531V8.30114H2.84987C2.3805 8.30114 2 7.92035 2 7.45063C2 6.98091 2.3805 6.60012 2.84987 6.60012H3.76603V5.68327C3.76603 5.21355 4.14653 4.83277 4.6159 4.83277C5.08527 4.83277 5.46577 5.21355 5.46577 5.68327V6.60012H6.24935C6.71873 6.60012 7.09923 6.98091 7.09923 7.45063C7.09923 7.92035 6.71873 8.30114 6.24935 8.30114ZM6.24935 15.1052H2.84987C2.3805 15.1052 2 14.7244 2 14.2547C2 13.785 2.3805 13.4042 2.84987 13.4042H6.24935C6.71873 13.4042 7.09923 13.785 7.09923 14.2547C7.09923 14.7244 6.71873 15.1052 6.24935 15.1052Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default RhIcon
