import React, { FC, Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { OptionsObject, SnackbarProvider, useSnackbar } from 'notistack'
import { theme } from 'theme'
import { Button } from '@material-ui/core'


const useStyles = makeStyles({
  root: {
    width: '90%',
  },
  variantSuccess: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.grey['500'],
  },
  variantError: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.grey['500'],
  },
  // variantInfo: {
  //   backgroundColor: 'color here',
  // },
  // variantWarning: {
  //   backgroundColor: 'color here',
  // },
})

/**
 * Properties that can be passed as `options` parameter of the enqueueSnackbar function.
 */
export const SuccessPersistWithDismissAction = (): OptionsObject => (
  {
    variant: 'success',
    persist: true,
    action: SnackBarDismissAction,
  }
)

/**
 * Properties that can be passed as `options` parameter of the enqueueSnackbar function.
 */
export const SuccessAutoHideWithDismissAction = (): OptionsObject => (
  {
    variant: 'success',
    autoHideDuration: 4000,
    action: SnackBarDismissAction,
  }
)

/**
 * Properties that can be passed as `options` parameter of the enqueueSnackbar function.
 */
export const ErrorPersistWithDismissAction = (): OptionsObject => (
  {
    variant: 'error',
    persist: true,
    action: SnackBarDismissAction,
  }
)

/**
 * Properties that can be passed as `options` parameter of the enqueueSnackbar function.
 */
export const ErrorAutoHideWithDismissAction = (): OptionsObject => (
  {
    variant: 'error',
    autoHideDuration: 4000,
    action: SnackBarDismissAction,
  }
)


/**
 * It represents a button (functional component) that is displayed in a Snackbar.
 * It's used as an action for the snackbar OptionsObject.
 * @param key
 * @constructor
 */
export const SnackBarDismissAction = (key: string | number) => {
  const { closeSnackbar } = useSnackbar()

  return (
    <Fragment>
      <Button
        size='small'
        onClick={() => closeSnackbar(key)}
        style={{
          color: theme.palette.text.primary,
        }}
      >
        Dismiss
      </Button>
    </Fragment>
  )
}


/**
 * SnackbarProvider component that must wrap the app component.
 * Note: If you're using material-ui ThemeProvider, make sure this component is a child of it.
 * For using this snackbar simply use the "useSnackbar" hook in a functional component.
 * Ex:
 *    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
 *    enqueueSnackbar('Your message', OptionalOptionsObject);
 *
 * For the optional parameter OptionsObject in the enqueueSnackbar function, there are a few options defined above.
 * @param children
 * @constructor
 */
export const CustomSnackbarProvider: FC = ({ children }) => {
  const classes = useStyles()

  return (
    <SnackbarProvider
      maxSnack={2}
      classes={classes}
      children={children}
      anchorOrigin={{
        vertical: 'bottom', horizontal: 'center',
      }}
      style={{
        // border: '1px solid red',
      }}
    />
  )
}
