import React from 'react'
import {
  Box, Button, Grid, Typography,
} from '@material-ui/core'
import { HavenLinks } from 'utils/constants/resources/links'
import { usePageTitle } from 'utils/hooks/router'


/**
 * Error page that is displayed whenever a user try to join a service company using an invitation code that has already
 * been used.
 * @constructor
 */
export const InviteCodeUsed = () => {
  usePageTitle('Invite Code Has Been Used')

  return(
    <>
      <Grid container className="home-page">
        <Grid item sm={2} />
        <Grid item sm={3}>
          <Box display="flex" alignItems="center" height="100%">
            <Box>
              <Typography variant="h1" gutterBottom>Shoot! Looks like that link has been used already.</Typography>
              <Box id="sub-text">
                <Typography variant="h5" className="home-heading" gutterBottom>
                  To request a new invitation, please contact the admin for this service company or get in touch with
                  HAVEN Customer Support.
                </Typography>
              </Box>
              <Box mt={3}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  href={ HavenLinks.MEMBERSHIP_SUPPORT }
                >
                  Contact Customer Support
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item sm={7} />
      </Grid>
    </>
  )
}
