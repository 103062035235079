import { useCallback, useEffect } from "react"
import httpService from "state-mngt/services/data/http-service"
import { MonitorTelemetry } from "types"
import {
  selectTelemetry,
  useTelemetryStore,
  set,
  selectAllTelemetry,
} from "./store"
import { getApiTimeInterval } from "utils/http-utils"
import { adjustStartAndEndTimes } from "utils/hooks/useEcosenseTelemetry"
import useSWR from "swr"

const usePoll = (cb, time) => {
  useEffect(() => {
    const to = setInterval(cb, time)
    return () => clearInterval(to)
  }, [cb, time])
}

const usePollRecentTelemetry = (deviceId, dataMin, dataMax) => {
  const _set = set(deviceId)
  const interval = getApiTimeInterval(dataMin, dataMax)

  const allDeviceTelemetry = useTelemetryStore(selectAllTelemetry({
    deviceId, interval,
  }))

  const callback = () => {
    const get = async (start: Date, end: Date) => {
      try {
        const data = await httpService.getOnce<MonitorTelemetry[]>(
          `/device/${deviceId}/telemetry_range?start_time=${start.toISOString()}&end_time=${end.toISOString()}&interval=${interval}`,
        )
        if (data) _set({
          interval, data,
        })
      } catch (e) {
        console.error(e)
      }
    }

    const { startTime, endTime } = adjustStartAndEndTimes(allDeviceTelemetry, dataMin, dataMax)
    if (startTime && endTime) get(startTime, endTime)
  }

  usePoll(callback, 10 * 1000)
}

const useDeviceTelemetry = ({
  deviceId, dataMin, dataMax, setLoading,
}: {
  deviceId?: number,
  dataMin: Date,
  dataMax: Date,
  setLoading: any,
}) => {
  const _set = set(deviceId)
  const interval = getApiTimeInterval(dataMin, dataMax)

  const allDeviceTelemetry = useTelemetryStore(selectAllTelemetry({
    deviceId, interval,
  }))

  const data = useTelemetryStore(selectTelemetry({
    deviceId,
    dataMin,
    dataMax,
    interval,
  }))

  useEffect(() => {
    if (!dataMin || !dataMax) return
    if (!deviceId) return

    const get = async (start: Date, end: Date) => {
      try {
        const data = await httpService.getOnce<MonitorTelemetry[]>(
          `/device/${deviceId}/telemetry_range?start_time=${start.toISOString()}&end_time=${end.toISOString()}&interval=${interval}`,
        )
        if (data) _set({
          interval, data,
        })
      } catch (e) {
        console.error(e)
      }

      setLoading(false)
    }

    const { startTime, endTime } = adjustStartAndEndTimes(allDeviceTelemetry, dataMin, dataMax)

    if (startTime && endTime) get(startTime, endTime)
    if (!startTime && !endTime) setLoading(false)
  }, [
    dataMin?.getTime(),
    dataMax?.getTime(),
    deviceId,
    allDeviceTelemetry?.length,
  ])

  return data
}

export default useDeviceTelemetry
