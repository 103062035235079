import { useAuth0 } from '@auth0/auth0-react'
import config from 'config'
import { useMixPanel } from 'features/analytics/mixpanel-provider'
import { isAllowed } from 'features/protect-route/access-control'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, RouteProps, useLocation, useSearchParams } from 'react-router-dom'
import { ROUTE_PATHS } from 'routes/routes'
import { selectUser, selectUserRole } from 'state-mngt/selectors/user-selectors'
import { MemberRole } from 'utils/constants/member-enums'

type Props = {
  // The member roles the route under protection will be limited to.
  roles?: MemberRole[];
  // A URL path to redirect the user if the route under protection is not allowed to be rendered.
  notAllowedRedirectPath?: string;
  children: any
} & RouteProps

/**
 * Prevents user from seeing content unless authenticated and have specific user permissions.
 * This component must be used as a wrapper element around children components or children routes to protect access to them.
 * e.g.:
 * 1 - Direct protected child component:
 * <Route path={protectedPath} element={<ProtectedRoute><Onboarding /></ProtectedRoute>} />
 *
 * 2 - No direct child but with children routes:
 * <Route element={<ProtectedRoute />}>
 *   <Route path={protectedPath} element={<SalesAndMarketing />} />
 *   <Route path={protectedPath2} element={<SalesAndMarketing />} />
 * </Route>
 *
 * Use 1 if the protected component has a descendant routes (another <Route> element with deep routes) or if there is
 * only a single match for a single protected component.
 * Use 2 if there are multiple routes to multiple components under the same route path and there is no need to descend
 * that in deep Routes.
 * @param {RouteProps} param0 - RouteProps object
 */
const ProtectedRoute = ({ children, roles, notAllowedRedirectPath }: Props) => {
  const { isLoading, isAuthenticated } = useAuth0()
  const { mixpanel } = useMixPanel()

  const user = useSelector(selectUser)
  const companyRole = useSelector(selectUserRole)
  const location = useLocation()
  const [searchParams] = useSearchParams()

  const pathname = `?pathname=${location.pathname}&${searchParams.toString()}`

  useEffect(() => {
    if (mixpanel) {
      mixpanel.init(config.mixpanelId, {
      }, '')

      if (user && user.id) {
        mixpanel.identify(user.id.toString())
      }
    }
  }, [mixpanel, user])

  if (!isLoading && isAuthenticated) {
    if (isAllowed(companyRole, roles)) {
      // This way it will allow to render either option 1 or 2 as per this function component comment section.
      return children ? (<>{children}</>) : <Outlet />
    } else {
      return <Navigate to={notAllowedRedirectPath || ROUTE_PATHS.root} replace />
    }
  }

  return <Navigate to={`${ROUTE_PATHS.root}${pathname}`} replace />
}

export default ProtectedRoute
