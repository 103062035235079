import useCurrentZone from 'utils/hooks/useCurrentZone'
import useCurrentDwellingContents from 'utils/hooks/useCurrentDwellingContents'

const useEcosenseDevicesInCurrentDwellingZone = () => {
  const currentZone = useCurrentZone()
  const dwellingContents = useCurrentDwellingContents()
  const ecosenseDevices = (dwellingContents?.ecosense_devices || [])
  const devices = ecosenseDevices.filter(x => x.zone.toLowerCase() === currentZone.toLowerCase())

  return devices
}

export default useEcosenseDevicesInCurrentDwellingZone
