import { createStyles, makeStyles, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import { theme } from 'theme'

const useStyles = makeStyles(() =>
  createStyles({
    helperText: {
      '&:after': {
        borderBottomColor: theme.palette.secondary.main + ' !important',
      },
      '& .MuiInput-underline.Mui-error:after': {
        borderBottomColor: theme.palette.secondary.main + ' !important',
      },
      '& .MuiFormHelperText-root': {
        color: theme.palette.secondary.main,
      },
    },
  }),
)

const EmailInput = (props: {
    inputValue: string,
    name?: string,
    id: string,
    label?: string,
    placeholder?: string,
    touched: boolean,
    fullWidth: boolean,
    onEmailValueChange: (value: string) => void,
    onEmailErrorChange: (err: boolean) => void
}) => {
  const classes = useStyles()

  const {
    inputValue, onEmailValueChange, onEmailErrorChange, name, id, label, placeholder, touched, fullWidth,
  } = props

  const [emailError, setEmailError] = useState<string>(inputValue === '' && touched ? 'Email is required' : '')


  /**
     * Validate email field
     * Set value if valid
     * Set error message if format is not valid or empty
     * @param event - ChangeEvent
     */
  const handleEmailChange = (event: React.ChangeEvent<{ value: unknown }>) => {

    const value = event.target.value as string

    if (value === '') {
      setEmailError('Email is required')
      onEmailErrorChange(true)
      return
    }

    if (value) {
      const regex = new RegExp(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$/i)

      if (value.match(regex) !== null) {
        setEmailError('')
        onEmailErrorChange(false)
        onEmailValueChange(value)
      } else {
        setEmailError('Email format: hvac@haveniaq.com')
        onEmailErrorChange(true)
      }
    }
  }

  return (
    <TextField
      id={id}
      name={name}
      label={label}
      fullWidth={fullWidth}
      placeholder={placeholder}
      defaultValue={inputValue}
      onChange={handleEmailChange}
      error={emailError !== ''}
      className={classes.helperText}
      helperText={emailError}
    />
  )
}

export default EmailInput
