import { DeviceTelemetry, DeviceTelemetryQuality } from 'state-mngt/models/device'
import { AnyAction } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { ActionPayload } from 'state-mngt/models/redux'
import { TimeUnit } from 'utils/constants/time-interval'
import { APIInterval } from 'utils/constants/time-interval-api'
import {
  DwellingContent,
  CacChannelTelemetry,
  MonitorByZone,
  CacChannelInfo,
} from 'state-mngt/models/dwelling'
import dwellingService from 'state-mngt/services/dwelling-service'
import { RootState } from 'state-mngt/store'
// import { addStyleToPDF, b64DecodeUnicode } from 'utils/report-utils';
import { setDeviceCurrentTelemetry } from 'state-mngt/actions/device-actions'
import { DeviceCacChannels } from 'state-mngt/models/device'
import { RequestsAdapter } from 'state-mngt/models/http'
import equipmentService from 'state-mngt/services/equipment-service'
import { convertToTimeUnit } from 'utils/time-utils'


export enum ActionType {
  SET_DWELLING_CONTENT = 'dwelling/SET_DWELLING_CONTENT',
  CLEAN_DWELLING_CONTENT = 'dwelling/CLEAN_DWELLING_CONTENT',
  SET_DWELLING_CAC_CHANNELS = 'dwelling/SET_DWELLING_CAC_CHANNELS',
  SET_MAPPED_CAC_IDS = 'dwelling/SET_MAPPED_CAC_IDS',
  SET_CONTROLLERS_CHANNELS_INFO = 'dwelling/SET_CONTROLLERS_CHANNELS_INFO',
  SET_MONITORS_BY_ZONE = 'dwelling/SET_CAMS_BY_ZONE'
}


export interface CleanDwellingContentAction {
  type: ActionType.CLEAN_DWELLING_CONTENT;
}

export type SetDwellingContentAction = ActionPayload<ActionType.SET_DWELLING_CONTENT, DwellingContent>;
export type SetDwellingCacChannelsAction = ActionPayload<ActionType.SET_DWELLING_CAC_CHANNELS, DeviceCacChannels>;
export type SetMappedCacIds = ActionPayload<ActionType.SET_MAPPED_CAC_IDS, {
  id: number;
  ids: number[];
}>;
export type SetMonitorsByZoneAction = ActionPayload<ActionType.SET_MONITORS_BY_ZONE, MonitorByZone[]>;
export type SetControllersChannelInfoAction = ActionPayload<ActionType.SET_CONTROLLERS_CHANNELS_INFO, CacChannelInfo[]>;

export const setDwellingContent = (dwellingContent: DwellingContent): SetDwellingContentAction => ({
  type: ActionType.SET_DWELLING_CONTENT,
  payload: dwellingContent,
})

export const cleanDwellingContent = (): CleanDwellingContentAction => ({
  type: ActionType.CLEAN_DWELLING_CONTENT,
})

export const setDwellingCacChannels = (deviceCacChannels: DeviceCacChannels): SetDwellingCacChannelsAction => ({
  type: ActionType.SET_DWELLING_CAC_CHANNELS,
  payload: deviceCacChannels,
})

export const setAssociatedCacIdsForCam = (id: number, cacIds: number[]): SetMappedCacIds => ({
  type: ActionType.SET_MAPPED_CAC_IDS,
  payload: {
    id,
    ids: cacIds,
  },
})

export const setControllersChannelInfo = (cacChannelsInfo: CacChannelInfo[]): SetControllersChannelInfoAction => ({
  type: ActionType.SET_CONTROLLERS_CHANNELS_INFO,
  payload: cacChannelsInfo,
})

export const setMonitorsByZone = (monitorsByZone: MonitorByZone[]): SetMonitorsByZoneAction => ({
  type: ActionType.SET_MONITORS_BY_ZONE,
  payload: monitorsByZone,
})

type ThunkResult<R = void> = ThunkAction<R, RootState, undefined, AnyAction>;

/**
 * calls API to get telemetry quality data for the pie chart
 * @param deviceId - number
 * @param startTime - Date
 * @param endTime - Date
 * @param requestsAdapter - RequestsAdapter
 */
export const getDeviceQuality = (
  deviceId: number,
  startTime: Date,
  endTime: Date,
  requestsAdapter: RequestsAdapter,
): ThunkResult<Promise<DeviceTelemetryQuality | undefined>> => async () => {
  const quality = await requestsAdapter.make(
    dwellingService.getDevicePerformance(deviceId, startTime, endTime),
  ).catch(requestsAdapter.throwOnAbort)

  if (quality) {
    return quality
  } else {
    return undefined
  }
}

export const getDeviceCacChannels = (
  cacDeviceId: number,
  requestsAdapter: RequestsAdapter,
): ThunkResult<Promise<DeviceCacChannels | null>> => (
  async (dispatch) => {
    const channels = await requestsAdapter.make(
      equipmentService.getDeviceCacChannels(cacDeviceId),
    ).catch(requestsAdapter.throwOnAbort)
    if (channels) {
      const result = {
        deviceId: cacDeviceId,
        channels: channels,
      }
      dispatch(setDwellingCacChannels(result))
      return result
    } else {
      return null
    }
  }
)

/**
 * calls API to get telemetry range data for the line chart
 * @param deviceId - number
 * @param startTime - Date included in the search, meaning it will be >= the startTime.
 * @param endTime - Date excluded from the search, meaning it will be < the endTime.
 * @param interval - APIInterval
 * @param requestsAdapter - RequestsAdapter
 */
export const getRangeData = (deviceId: number, startTime: Date, endTime: Date, interval: APIInterval,
  requestsAdapter: RequestsAdapter): ThunkResult<Promise<DeviceTelemetry[] | undefined>> => async () => {

  let rangeData: DeviceTelemetry[] | undefined = []
  const thirtyDaysInMs = convertToTimeUnit(30, TimeUnit.Days, TimeUnit.Milliseconds) // 2592000000
  let differenceInMilliseconds = endTime.getTime() - startTime.getTime()

  // If requested for more than 30 days data, let's divide the requests of up to 30 days max per request.
  // The requests will be sent in order asynchronously and returned once all the requests return.
  if (differenceInMilliseconds > thirtyDaysInMs) {
    // Store in milliseconds because we will do date operations using this time unit.
    let currentStartTime: number = startTime.getTime()
    let currentEndTime: number = endTime.getTime()
    // Since we previously checked that there are more than 30 days of data being requested, let's add the maximum of
    // 30 days to the first request.
    let timeToBeAdded: number = thirtyDaysInMs
    // The array of requests that will be sent to the server.
    const requestPromises: any[] = []

    while (timeToBeAdded > 0) {
      currentEndTime = currentStartTime + timeToBeAdded

      requestPromises.push(
        requestsAdapter.make(
          dwellingService.getTelemetryRange(deviceId, new Date(currentStartTime), new Date(currentEndTime), interval),
        ).catch(requestsAdapter.throwOnAbort),
      )

      currentStartTime = currentEndTime
      // The difference in milliseconds keeps decreasing as we continue adding time after every chunk.
      differenceInMilliseconds = differenceInMilliseconds - timeToBeAdded

      // If more than 30 days difference, again, cap the time to be added at a maximum of 30 days.
      // Otherwise, it will be the remaining difference.
      if (differenceInMilliseconds > thirtyDaysInMs)
        timeToBeAdded = thirtyDaysInMs
      else
        timeToBeAdded = differenceInMilliseconds
    }

    await Promise.all(requestPromises).then((result) => {
      result.map((value) => {
        if (rangeData) {
          rangeData = [...rangeData, ...value]
        }
      })
    }).catch((reason) => {
      rangeData = undefined
    })
  } else {
    rangeData = await requestsAdapter.make(
      dwellingService.getTelemetryRange(deviceId, startTime, endTime, interval),
    ).catch(requestsAdapter.throwOnAbort)
  }

  return rangeData
}

export const getCacRangeData = (
  deviceId: number,
  startTime: Date,
  endTime: Date,
  interval: APIInterval,
  requestsAdapter: RequestsAdapter,
): ThunkResult<Promise<CacChannelTelemetry | undefined>> => async () => {
  const rangeData = await requestsAdapter.make(
    dwellingService.getCacTelemetryRange(deviceId, startTime, endTime, interval),
  ).catch(requestsAdapter.throwOnAbort)

  if (rangeData) {
    return {
      id: deviceId,
      data: rangeData,
    }
  } else {
    return undefined
  }
}

/**
 * Call get Telemetry API endpoint,
 * dispatch current telemetry to store
 * @param {number} deviceId - number
 * @param requestsAdapter - RequestsAdapter
 */
export const getCurrentData = (
  deviceId: number,
  requestsAdapter: RequestsAdapter,
): ThunkResult<Promise<DeviceTelemetry | undefined>> => async dispatch => {
  const currentData = await requestsAdapter.make(dwellingService.getTelemetryCurrent(deviceId))
    .catch(requestsAdapter.throwOnAbort)

  if (currentData) {
    dispatch(setDeviceCurrentTelemetry(currentData))
    return currentData
  } else {
    return undefined
  }
}

/**
 * Call API to generate PDF
 * @param elementId - string of id, with '#' in the string
 * @param fileName  - file name of the pdf
 * @returns - void
 */
// export const generatePDF = (elementId: string, fileName: string): ThunkResult => async () => {
//   const response = await dwellingService.generatePDF(addStyleToPDF(elementId))
//     .catch(() => null);

//   if (response && response.pdf) {
//     const blob = new Blob([new Uint8Array(
//       atob(b64DecodeUnicode(response.pdf))
//         .split('')
//         .map(function (c) {
//           return c.charCodeAt(0);
//         })
//     )]);

//     const link = document.createElement('a');
//     link.href = window.URL.createObjectURL(blob);
//     link.download = fileName + '.pdf';
//     link.click();
//   }
// };
