import { Box } from '@material-ui/core'
import { useMixPanel } from 'features/analytics/mixpanel-provider'
import { BuildReportTemplate } from 'features/build-report'
import { CustomerLayout } from 'features/customer-drill-down'
import Charts from 'features/customer-drill-down/charts'
import Equipment from 'features/customer-drill-down/equipment'
import Alerts from 'features/customer-drill-down/equipment/alerts'
import Automations from 'features/customer-drill-down/equipment/automations'
import Interlocks from 'features/customer-drill-down/equipment/interlocks'
import Schedules from 'features/customer-drill-down/equipment/schedules'
import useGetData from 'features/customer-drill-down/equipment/useGetData'
import Profile from 'features/customer-drill-down/profile'
import React, { useEffect } from 'react'
import {
  Route,
  Routes,
  useParams,
} from 'react-router-dom'
import { ROUTE_PATHS } from 'routes/routes'
import { theme } from 'theme'
import { HISTORY_QUERY_KEYS } from 'utils/constants/customer'
import { useStateInURLQueryParameter } from 'utils/hooks/router'
import useAllZones from 'utils/hooks/useAllZones'
import useTieOutputToEquipment from 'utils/hooks/useTieOutputToEquipment'
import useSyncAutomationWithRules from 'utils/hooks/useSyncAutomationWithRules'

/**
 * Render customer drill down page
 */
export const CustomerDrillDown = () => {
  const { mixpanel } = useMixPanel()
  const { dwellingId } = useParams()

  // these just has to be called once somewhere
  useGetData(dwellingId)
  useTieOutputToEquipment()
  useSyncAutomationWithRules()

  const allZones = useAllZones()

  useEffect(() => {
    if (mixpanel) {
      mixpanel.track('pp_customerDrillDownPage_open')
    }
  }, [mixpanel])

  const [zone, setZone] = useStateInURLQueryParameter({
    initialValue: '',
    key: HISTORY_QUERY_KEYS.zone,
  })

  // Initially set the zone to the URL if isn't set yet. Do it only once.
  useEffect(() => {
    if (!zone && allZones.length > 0) {
      setZone(allZones[0], true)
    }
  }, [
    JSON.stringify(allZones),
    zone,
    setZone,
  ])

  return (
    <>
      {/* Descendant Routes, MUST USE RELATIVE PATHS TO MATCH THE ROUTES. */}
      <Routes>
        <Route
          path={'/'}
          element={<CustomerLayout zone={zone} setZone={setZone} />}
        >
          <Route index element={<Charts />} />
          <Route path={`/automations`} element={<Automations dwellingId={parseInt(dwellingId || '')} />} />
          <Route path={`/schedules`} element={<Schedules dwellingId={parseInt(dwellingId || '')} />} />
          <Route path={`/interlocks`} element={<Interlocks dwellingId={parseInt(dwellingId || '')} />} />
          <Route path={`/alerts`} element={<Alerts dwellingId={parseInt(dwellingId || '')} />} />
          <Route path={`/equipment`} element={<Equipment setZone={setZone} dwellingId={parseInt(dwellingId || '')} />} />
          <Route path={`/profile`} element={<Profile />} />
        </Route>
        <Route path={ROUTE_PATHS.customers.details.report.relative} element={<BuildReportTemplate />} />
      </Routes>
    </>
  )
}
