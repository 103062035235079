import React, { useEffect } from 'react'
import SalesAndMarketing from 'features/resources/sales-and-marketing'
import { Route, Routes } from 'react-router-dom'
import { useMixPanel } from 'features/analytics/mixpanel-provider'
import { ROUTE_PATHS } from 'routes/routes'
import { usePageTitle } from 'utils/hooks/router'

/**
 * Displays resources page (with static content temporarily)
 */

export const Resources = () => {
  const { mixpanel } = useMixPanel()


  usePageTitle('Resources')

  useEffect(() => {
    if (mixpanel) {
      mixpanel.track('pp_resourcePage_open')
    }
  })

  return (
    <>
      {/* Descendant Routes, MUST USE RELATIVE PATHS TO MATCH THE ROUTES. */}
      <Routes>
        <Route index element={<SalesAndMarketing />} />
        <Route path={ROUTE_PATHS.resources.salesAndMarketing.root.relative} element={<SalesAndMarketing />} />
        <Route path={ROUTE_PATHS.resources.salesAndMarketing.tab.relative} element={<SalesAndMarketing />} />
      </Routes>
    </>
  )
}
