import { useEffect } from "react"
import { MonitorTelemetry } from "types"
import useMonitorInCurrentZone from "./useMonitorInCurrentZone"
import useQualityStore, {
  DewPointQuality, HumidityQuality, Quality, QualityData, QualityStore, initialData, 
} from "stores/quality"
import { selectTelemetry, useTelemetryStore } from "./store"
import { getApiTimeInterval } from "utils/http-utils"
import { padDates } from "./chart-utils"
import { TimeInterval } from "utils/constants/time-interval"

// turn interval into ms
export const getMS = (interval: string) => {
  let ms = TimeInterval.DAY
  if (interval === '5m') ms = TimeInterval.MINUTE
  if (interval === '1h') ms = TimeInterval.HOUR
  return ms
}

interface HumidityCounts {
    humidity: HumidityQuality
    dew_point: DewPointQuality
}

// Clausius-Clapeyron equation
const calculateDewPoint = (temperatureCelsius: number, relativeHumidity: number) => {
  const a = 17.08 // constant specific to water vapor
  const b = 234.18 // constant specific to water vapor
  const temperatureKelvin = temperatureCelsius + 273.15
  const es = 6.112 * Math.exp((a * temperatureKelvin) / (b + temperatureKelvin)) // saturation vapor pressure
  const ea = (relativeHumidity * es) / 100 // vapour pressure
  const dewPoint = (b * Math.log(ea / 6.11)) / (a - Math.log(ea / 6.11))
  return dewPoint
}

const tooHumid = (level: number) => level > 70
const humid = (level: number) => (level < 70) && (level > 60)
const good = (level: number) => (level < 60) && (level > 30)
const dry = (level: number) => (level < 30) && (level > 25)
const tooDry = (level: number) => level < 25

const dryAndComfy = (dewPoint: number) => dewPoint < 13
const sticky = (dewPoint: number) => (dewPoint < 16) && (dewPoint > 12)
const dewPointHumid = (dewPoint: number) => dewPoint > 15

const isNonZeroFalsy = value => (value === null) || (value === undefined)

const computeHumidityLevels = (data: MonitorTelemetry[], interval: string, totalTime: number) => {
  const counts: HumidityCounts = {
    humidity: {
      too_humid: 0,
      humid: 0,
      good: 0,
      dry: 0,
      too_dry: 0,
      no_data: 0,
    },
    dew_point: {
      dry_and_comfy: 0,
      humid: 0,
      sticky: 0,
      no_data: 0,
    },
  }

  const percentages: HumidityCounts = {
    humidity: {
      too_humid: 0,
      humid: 0,
      good: 0,
      dry: 0,
      too_dry: 0,
      no_data: 0,
    },
    dew_point: {
      dry_and_comfy: 0,
      humid: 0,
      sticky: 0,
      no_data: 0,
    },
  }


  const humidityStatusChecks = [
    {
      check: tooHumid, name: 'too_humid', 
    },
    {
      check: humid, name: 'humid', 
    },
    {
      check: good, name: 'good', 
    },
    {
      check: dry, name: 'dry', 
    },
    {
      check: tooDry, name: 'too_dry', 
    },
  ]

  const dewPointStatusChecks = [
    {
      check: dryAndComfy, name: 'dry_and_comfy', 
    },
    {
      check: dewPointHumid, name: 'humid', 
    },
    {
      check: sticky, name: 'sticky', 
    },
  ]

  const ms = getMS(interval) // ms per data point

  data.forEach((telem) => {
    if (isNonZeroFalsy(telem.humidity)) return
    const passedHumidityCheck = humidityStatusChecks.find(status => status.check(telem.humidity))
    if (isNonZeroFalsy(telem.temperature)) return
    const passedDewPointCheck = dewPointStatusChecks
      .find(status =>
        status.check(calculateDewPoint(telem.humidity, telem.temperature)))
    if (passedHumidityCheck) counts.humidity[passedHumidityCheck.name]++
    if (passedDewPointCheck) counts.dew_point[passedDewPointCheck.name]++
  })

  Object.keys(counts.humidity).forEach(key => {
    percentages.humidity[key] = Math.round(((counts.humidity[key] * ms) / totalTime) * 100)
  })

  Object.keys(counts.dew_point).forEach(key => {
    percentages.dew_point[key] = Math.round(((counts.dew_point[key] * ms) / totalTime) * 100)
  })

  percentages.humidity['no_data'] = Math.max(100 - Object.values(percentages.humidity).reduce((prev, curr) => prev + curr, 0), 0)
  percentages.dew_point['no_data'] = Math.max(100 - Object.values(percentages.dew_point).reduce((prev, curr) => prev + curr, 0), 0)

  return percentages
}

const computeQuality = (data: MonitorTelemetry[], interval: string, totalTime: number): QualityData => {
  const properties = ['pm_status', 'voc_status']

  const statusPercentages = {
    'pm_status': {
    },
    'voc_status': {
    },
    'humidity_status': {
    },
    'dew_point_status': {
    },
  }

  // short circuit if there's no data
  if (!data.length) {
    return {
      'pm_status': {
        good: 0,
        fair: 0,
        poor: 0,
        no_data: 100,
      },
      'voc_status': {
        good: 0,
        fair: 0,
        poor: 0,
        no_data: 100,
      },
      'humidity_status': {
        good: 0,
        humid: 0,
        dry: 0,
        too_dry: 0,
        too_humid: 0,
        no_data: 100,

      },
      'dew_point_status': {
        dry_and_comfy: 0,
        sticky: 0,
        humid: 0,
        no_data: 100,
      },
    }
  }

  const ms = getMS(interval) // ms per data point

  properties.forEach(property => {
    const counts = data.reduce((prev, curr) => {
      prev[curr[property]] = (prev[curr[property]] || 0) + 1
      return prev
    }, {
    })

    const percentages: Quality = {
      good: 0,
      fair: 0,
      poor: 0,
      no_data: 0,
    }

    Object.keys(counts).forEach(key => {
      percentages[key] = Math.round(((counts[key] * ms) / totalTime) * 100)
    })

    percentages['no_data'] = Math.max(100 - Object.values(percentages).reduce((prev, curr) => prev + curr, 0), 0)
    statusPercentages[property] = percentages
  })

  const humidityStatuses = computeHumidityLevels(data, interval, totalTime)

  statusPercentages['humidity_status'] = humidityStatuses.humidity
  statusPercentages['dew_point_status'] = humidityStatuses.dew_point

  return statusPercentages as QualityData
}

const select = (monitorId?: number) => (store: QualityStore): QualityData =>
  (monitorId && store[monitorId]) ? store[monitorId] : initialData

function useMonitorQuality({ startTime, endTime }) {
  const set = useQualityStore.setState

  const monitor = useMonitorInCurrentZone()

  // even though we use unpadded dates to calculate
  // the quality, we want to pad the dates here to get
  // the correct interval for selecting data from the store.
  const [dataMin, dataMax] = padDates(startTime, endTime)
  const interval = getApiTimeInterval(dataMin, dataMax)

  const data = useTelemetryStore(selectTelemetry({
    deviceId: monitor?.id,
    dataMin: startTime,
    dataMax: endTime,
    interval: interval,
  }))

  const monitorQuality = useQualityStore(select(monitor?.id))

  useEffect(() => {
    if (!monitor) return
    const totalTime = endTime.getTime() - startTime.getTime()
    set({
      [monitor.id]: computeQuality(data, interval, totalTime), 
    })
  }, [
    interval,
    startTime?.getTime(),
    monitor?.id,
    JSON.stringify(data),
  ])

  return monitorQuality
}

export default useMonitorQuality
