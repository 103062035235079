import React, { useState } from 'react'
import { Typography, Box, Button } from '@material-ui/core'
import InteractiveInfoTooltip from 'ui/interactive-info-tooltip'
import { useMixPanel } from 'features/analytics/mixpanel-provider'

interface Props {
  isEditing: boolean;
  isSaveDisabled: boolean;
  isSaving: boolean;
  cancelEdit: () => void;
  saveEdit: () => void;
}

const TeamListToolbar: React.FC<Props> = ({
  isEditing, cancelEdit, saveEdit, isSaveDisabled, isSaving,
}) => {
  const { mixpanel } = useMixPanel()
  const [openTooltip, setOpenTooltip] = useState<boolean>(false)

  const infoTooltip = (
    <InteractiveInfoTooltip
      content={
        <>
          <p>An overview of any Active or Pending HAVEN Pro team members — all in one place.</p>
          <b>What can you do here?</b>
          <ul
            style={{
              marginTop: 0,
              paddingLeft: 20,
            }}
          >
            <li>Add new members to your Management, Sales and Technician teams</li>
            <li>Resend invitation emails to “Pending” or “Expired” members</li>
            <li>Manage the teams to customize the content your members receive from HAVEN</li>
            <li>Remove members to revoke access to your HAVEN Pro service company</li>
          </ul>
        </>
      }
      open={openTooltip}
      setOpen={setOpenTooltip} />
  )

  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex">
        <Typography variant="h6">Service Company Members</Typography>
        {infoTooltip}
      </Box>
      {isEditing && (
        <Box display="flex">
          <Box width={156}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                if(mixpanel) {
                  mixpanel.track('pp_teamMgmtPage_cancelEditTeamButton')
                }
                cancelEdit()
              }}
              disabled={isSaving}
              fullWidth
            >
              Cancel
            </Button>
          </Box>
          <Box marginLeft={1} width={156}>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                if(mixpanel) {
                  mixpanel.track('pp_teamMgmtPage_saveEditTeamButton')
                }
                saveEdit()
              }}
              disabled={isSaveDisabled || isSaving}
              fullWidth
            >
              {isSaving ? 'Saving...' : 'Save'}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default TeamListToolbar
