import { DeviceTriggerType, VentilatingDehumidifierAutomationActivation } from 'state-mngt/models/automation'
import { Status } from 'state-mngt/models/status'

export interface Equipment {
  id: number;
  make: string;
  name: string;
  type: EquipmentType;
  dwelling_id: number;
  install_timestamp: Date;
  model: string;
  serial_number: string;
  equipment_id: number;
  zone: string;
  // Ordered by install timestamp DESC (Last one first).
  filters: number[];
}

export enum DuctType {
  Return = 'Return',
  Supply = 'Supply',
}

export enum EquipmentType {
  Cah = 'central_air_handler',
  Erv = 'mechanical_ventilation',
  Humidifier = 'humidifier',
  DeHumidifier = 'dehumidifier',
  Other = 'other',
  Ventilating_dehumidifier = 'ventilating_dehumidifier',
  Outdoor_air_damper = 'outdoor_air_damper',
}

export interface EquipmentContent {
  id: number;
  duct_height: number;
  duct_material: string;
  duct_shape: string;
  duct_type: DuctType;
  duct_width: number;
  dwelling_id: number;
  install_timestamp: string;
  name: string;
  type: EquipmentType;
  zone: string;
}

export enum BlowerStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

// Equipment information used to display equipment cards on the equipment tab from the customer drill down page.
export interface EquipmentEntityContent {
  id: number;
  type: EquipmentType;
  isAllLoaded: boolean;
  ductType: DuctType;
  isBlower: boolean;
  // For Central Air Handler
  blowerPowerStatus?: BlowerStatus;
  lastActivated?: string;
  averageDailyRuntime?: number;
  controlledBy: DeviceTriggerType[];
  status?: Status;
  ventilatingDehumidifierAutomation?: VentilatingDehumidifierAutomationActivation;
}
