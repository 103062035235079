import React, { useCallback, ChangeEvent } from 'react'
import {
  Typography, makeStyles, Box, TextField, Button, InputAdornment, IconButton,
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import { MemberTeam, MEMBER_TEAM_NAMES } from 'utils/constants/member-enums'
import InfoTooltip from 'ui/info-tooltip'
import managementTeamImage from 'features/preferences/team-mngt/team-add/components/team-add-group/images/management-team.svg'
import salesTeamImage from 'features/preferences/team-mngt/team-add/components/team-add-group/images/sales-team.svg'
import serviceTeamImage from 'features/preferences/team-mngt/team-add/components/team-add-group/images/service-team.svg'
import { theme } from 'theme'
import { useMixPanel } from 'features/analytics/mixpanel-provider'

const useStyles = makeStyles((theme) => ({
  validField: {
    '& input ~ fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: 2,
    },
  },
  addButton: {
    borderStyle: 'dashed',
  },
}))

interface Props {
  team: MemberTeam;
  items: Array<{ value: string; isValid: boolean; isTouched: boolean }>;
  addItem: (team: MemberTeam) => void;
  updateItem: (team: MemberTeam, item: { value?: string; isTouched?: boolean }, index: number) => void;
  removeItem: (team: MemberTeam, index: number) => void;
}

const TeamAddGroup: React.FC<Props> = ({
  team, items, addItem, updateItem, removeItem,
}) => {
  const classes = useStyles()
  const { mixpanel } = useMixPanel()

  let imageUrl = managementTeamImage
  let tooltipContent = 'Management team-mngt is anyone who’s doing work back at the office. Operations manager, service manager, office manager. You get the picture.'

  if (team === MemberTeam.Sales) {
    imageUrl = salesTeamImage
    tooltipContent = 'These are the smooth-talking folks that are helping your customers understand what kinds of service or equipment they may need.'
  } else if (team === MemberTeam.Service) {
    imageUrl = serviceTeamImage
    tooltipContent = 'These are the folks who are pounding the pavement in the trucks, providing the hands-on installation and equipment maintenance services to your customers.'
  }

  const onAddItem = useCallback(() => {
    if(mixpanel) {
      if (team === MemberTeam.Management) {
        mixpanel.track('pp_teamMgmtPage_addMembersPage_addMoreToMgmtButton')
      } else if (team === MemberTeam.Sales) {
        mixpanel.track('pp_teamMgmtPage_addMembersPage_addMoreToSalesButton')
      } else if (team === MemberTeam.Service) {
        mixpanel.track('pp_teamMgmtPage_addMembersPage_addMoreToServiceButton')
      } else {
        mixpanel.track('pp_teamMgmtPage_addMembersPage_addMoreButton')
      }
    }

    addItem(team)
  }, [addItem, team])

  const onRemoveItem = useCallback((index) => {
    removeItem(team, index)
    if (items.length === 1) {
      addItem(team)
    }
  }, [removeItem, team, items, addItem])

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    updateItem(team, {
      value: event.target.value,
    }, index)
  }, [team, updateItem])

  const onBlur = useCallback((index: number) => {
    updateItem(team, {
      isTouched: true,
    }, index)
  }, [team, updateItem])

  return (
    <Box textAlign="center">
      <Typography variant="h6">
        Your {MEMBER_TEAM_NAMES[team]} team
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="center" mb={4}>
        <Typography variant="body2">Who is your &quot;{MEMBER_TEAM_NAMES[team]}&quot;?</Typography>
        <InfoTooltip
          placement="right-start"
          arrow
          title={tooltipContent}
        >
          <InfoIcon
            htmlColor={theme.palette.grey['500']}
            style={{
              margin: '.4rem',
            }}
            fontSize="small"
          />
        </InfoTooltip>
      </Box>
      <Box mb={2}>
        <img
          src={imageUrl}
          width={178}
          height={178}
          alt=""
        />
      </Box>
      {items.map(({ value, isValid, isTouched }, index, array) => (
        <Box key={index} mb={2} className={isTouched && isValid ? classes.validField : ''}>
          <TextField
            name={`new-member-${team}-${index}`}
            variant="outlined"
            placeholder="Email address"
            type="email"
            fullWidth
            autoComplete="off"
            value={value}
            onChange={(event) => onChange(event, index)}
            onBlur={() => onBlur(index)}
            InputProps={{
              endAdornment: value ? (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={() => onRemoveItem(index)}>
                    <DeleteIcon />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
            helperText={value && !isValid && 'This email doesn’t look right. Please follow the format: email@something.com'}
            error={(value && !isValid) ? true : false}
          />
        </Box>
      ))}
      <Button
        classes={{
          root: classes.addButton,
        }}
        size="large"
        variant="outlined"
        color="primary"
        endIcon={<AddIcon />}
        onClick={onAddItem}
        fullWidth
      >
        Add more
      </Button>
    </Box>
  )
}

export default TeamAddGroup
