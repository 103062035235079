import React, { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  createStyles,
  makeStyles,
  InputAdornment,
} from '@material-ui/core'
import { DateTimePicker } from '@material-ui/pickers'
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder'
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { Timeframe } from 'utils/constants/time-interval'
import { theme } from 'theme'
import TourTooltip from 'ui/tour-tooltip'
import { DemoHintElementName } from 'state-mngt/models/user'
import { hideHintElement } from 'state-mngt/actions/user-actions'
import { getHintElement } from 'state-mngt/selectors/user-selectors'
import { RootState } from 'state-mngt/store'
import { useAppSelector } from 'utils/hooks/reduxTypes'

const useStyles = makeStyles(() =>
  createStyles({
    errorArea: {
      borderColor: theme.palette.error.light,
      borderStyle: '1px solid',
    },
    errorMsg: {
      position: 'absolute',
      color: theme.palette.error.main,
      top: '100px',
      fontSize: '10px',
    },
  }),
)

interface BaseProps {
  onChangeTimeframe: any,
  startTime: Date,
  endTime: Date,
  timeframe: string,
  setStart: any,
  setEnd: any,
}

const HistoryTimeControl = (props: BaseProps) => {
  const classes = useStyles()
  const {
    setEnd,
    endTime,
    setStart,
    startTime,
    timeframe,
    onChangeTimeframe,
  } = props

  const timeframeHint = useAppSelector((state: RootState) => getHintElement(state, DemoHintElementName.Timeframe))

  const [inputError, setInputError] = useState<boolean>(false)
  const [inputValidationMsg, setInputValidationMsg] = useState<string>('')

  useEffect(() => {
    if (endTime.getTime() < startTime.getTime()) {
      setInputError(true)
      setInputValidationMsg('Start date should be before end date.')
    } else {
      setInputError(false)
      setInputValidationMsg('')
    }
  }, [endTime, startTime])

  /**
   * callback function to save changed start time input value to state
   * @param date - MaterialUiPickersDate
   */
  const handleStartChange = (date: MaterialUiPickersDate) => {
    if (date) {
      setStart(date.toDate()) // set time in parent component
    }
  }

  /**
   * callback function to save changed end time input value in state
   * @param date - MaterialUiPickersDate
   */
  const handleEndChange = (date: MaterialUiPickersDate) => {
    if (date) {
      setEnd(date.toDate()) // set time in parent component
    }
  }

  return (
    <>
      <Box
        display="flex"
        flex='1 1'
        justifyContent="space-between"
        alignItems='center'
      >
        <Box display="flex" flexDirection="column">
          <TourTooltip
            title={timeframeHint ? timeframeHint.text : ''}
            placement="top-end"
            onClose={() => hideHintElement(DemoHintElementName.Timeframe)}
            arrow
            open={!!timeframeHint}
          >
            <FormControl
              style={{
                width: '188px',
              }}
              variant="outlined"
              size="small"
            >
              <InputLabel
                id="timeFrame-select-label"
                style={{
                  backgroundColor: 'white',
                  padding: '0 .5rem',
                }}
              >
                Quick-select
              </InputLabel>
              <Select
                labelId="timeFrame-select-label"
                id="timeFrame-select"
                value={timeframe}
                onChange={e =>
                  onChangeTimeframe(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <QueryBuilderIcon
                      color="primary"
                      style={{
                      }}
                    />
                  </InputAdornment>
                }
              >
                <MenuItem value='none' disabled>Select...</MenuItem>
                <MenuItem value={Timeframe.Hour}>Last hour</MenuItem>
                <MenuItem value={Timeframe.Day}>Last 24h</MenuItem>
                <MenuItem value={Timeframe.Week}>Last week</MenuItem>
                <MenuItem value={Timeframe.Weeks2}>Last 2 weeks</MenuItem>
                <MenuItem value={Timeframe.Month}>Last month</MenuItem>
                <MenuItem value={Timeframe.Months3}>Last 3 months</MenuItem>
                <MenuItem value={Timeframe.Months6}>Last 6 months</MenuItem>
                <MenuItem value={Timeframe.Year}>Last year</MenuItem>
              </Select>
            </FormControl>
          </TourTooltip>
        </Box>
        <Typography
          variant="body1"
          style={{
            margin: '0 1em',
          }}
        >
          OR
        </Typography>
        <Box
          display="flex"
          className={inputError ? classes.errorArea : ''}
        >
          <Box display="flex" flexDirection="column">
            <Box>
            </Box>
            <DateTimePicker
              style={{
                width: '164px',
                marginRight: '1em',
              }}
              id="datetime-local-start"
              maxDate={endTime}
              maxDateMessage=""
              label="Start"
              size="small"
              format='MMM D, YYYY'
              inputVariant="outlined"
              value={startTime}
              onChange={(date) => handleStartChange(date)}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment:
                  <VerticalAlignBottomIcon
                    color="primary"
                    style={{
                      transform: 'rotate(-90deg)',
                      marginRight: '0.5em',
                    }}
                  />,
              }}
              showTodayButton
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column">
            <Box>
            </Box>
            <DateTimePicker
              style={{
                width: '164px',
              }}
              id="datetime-local-end"
              minDate={startTime}
              minDateMessage=""
              label="End"
              size="small"
              inputVariant="outlined"
              value={endTime}
              format='MMM D, YYYY'
              onChange={(date) => handleEndChange(date)}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment:
                  <VerticalAlignBottomIcon
                    color="primary"
                    style={{
                      transform: 'rotate(90deg)',
                      marginRight: '0.5em',
                    }}
                  />,
              }}
              showTodayButton
            />
          </Box>
          {inputError && <span className={classes.errorMsg}>{inputValidationMsg}</span>}
        </Box>
      </Box>
    </>
  )
}

export default HistoryTimeControl
