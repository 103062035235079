import { UserPreference } from 'state-mngt/models/user'
import { DeviceTelemetry } from 'state-mngt/models/device'
import {
  AnnotationsOptions,
  SeriesZonesOptionsObject,
  XAxisPlotBandsOptions,
  YAxisPlotLinesOptions,
} from 'highcharts'
import { theme } from 'theme'
import { TELEMETRY_THRESHOLD } from 'utils/constants/telemetry-threshold'
import { dataTypeSelections, TelemetryDataType } from 'utils/constants/telemetry-data-type'
import tempService from 'utils/temp-converter'
import { UserPreferencesEnum } from 'utils/constants/preference-enums'
import { TimeInterval } from 'utils/constants/time-interval'
import { HavenLinks } from 'utils/constants/resources/links'
import { CHART_DATA_TYPE_LABELS } from 'features/customer-drill-down/charts/device-chart/constants'
import * as Highcharts from 'highcharts'
import { getNormalizedTelemetry } from 'features/customer-drill-down/charts/device-chart/chart-utils'
import { getApiTimeInterval } from 'utils/http-utils'
import { OutdoorAir } from 'state-mngt/models/outdoor-air'
import { EcosenseTelemetry } from 'stores/ecosense'

export const ANNOTATIONS_FOR_DEMO: AnnotationsOptions = {
  draggable: '',
  labelOptions: {
    backgroundColor: theme.brand.contrast.main,
    borderWidth: 0,
    borderRadius: 4,
    align: 'left',
    padding: 8,
    style: {
      fontSize: '20px',
      fontWeight: '300',
      color: theme.palette.text.primary,
    },
    distance: 32,
    allowOverlap: false,
  },
  labels: [],
  shapeOptions: {
    fill: 'transparent',
    stroke: theme.palette.primary.main,
    strokeWidth: 3,
    r: 20,
  },
  shapes: [],
}

// export const drawCustomResetZoomButton = (chart: Highcharts.Chart,
//   resetZoomCallbackFunction: Highcharts.EventCallbackFunction<any>): Highcharts.SVGElement => {

//   const customResetZoomButtonNormalState: Highcharts.SVGAttributes = {
//     stroke: '#008A93',
//     'stroke-width': 2,
//     fill: '#FFFFFF',
//     r: 4,
//     style: {
//       color: '#008A93',
//       fontFamily: theme.typography.fontFamily,
//       fontSize: '0.875rem',
//       fontWeight: '500'
//     }
//   };

//   const customResetZoomButtonHoverState = {
//     ...customResetZoomButtonNormalState,
//     fill: '#008A93',
//     style: {
//       color: '#FFFFFF',
//     }
//   };

//   const customResetZoomButtonWidth = 110;

//   // Access the renderer layer associated with the chart in order to draw a customized reset zoom button.
//   const customResetZoomButton = chart.renderer.button('RESET ZOOM', chart.plotWidth - 50, 5,
//     resetZoomCallbackFunction, customResetZoomButtonNormalState, customResetZoomButtonHoverState)
//     .addClass('linechart-custom-reset-zoom-button')
//     .attr({
//       width: customResetZoomButtonWidth,
//       zIndex: 3 // To stay on top of all the other elements on the chart area.
//     });

//   // Draw/put an image on the left of the button.
//   chart.renderer.image(`${HavenLinks.S3}reset-zoom-darker-blew.png`, 0, 0, 25, 25)
//     .align({
//       align: 'left',
//       x: customResetZoomButtonWidth - 15,
//       y: 3
//     }, false, 'linechart-custom-reset-zoom-button')
//     .add(customResetZoomButton);

//   return customResetZoomButton;
// };

/**
 * Gets a data type object and converts it to a string label to be used as text.
 * @param dataType - The telemetry data type to be converted to a string label.
 * @returns A string label representation for the data type.
 */
export const convertChartDataTypeToLabel = (dataType: TelemetryDataType): string => {
  if (!dataType) {
    return ''
  }

  const selectedDataType = dataTypeSelections.find(currentItem => currentItem.type === dataType)

  if (!selectedDataType) {
    return 'Radon'
  }

  return selectedDataType.label.toUpperCase()
}

/**
 * for creating dotted line for line chart
 * @param data - (number|null)[][]
 * @param type - TelemetryDataType
 * @returns SeriesZonesOptionsObject[]
 */
export const buildZones = (data: (number | null)[][], type: TelemetryDataType): SeriesZonesOptionsObject[] => {

  if (data.length === 0) {
    return []
  }

  const zones: SeriesZonesOptionsObject[] = []
  let i = -1
  let current
  let previous
  let dashStyle: '' | 'Solid' | 'Dot'
  let value

  while (data[++i][1] === null);

  zones.push({
    value: data[i][0]!,
  })

  while (++i < data.length) {
    previous = data[i - 1][1]
    current = data[i][1]
    dashStyle = ''

    if (previous !== null && current === null) { // if it's start of the null point
      dashStyle = 'Solid'
      value = data[i - 1][0]!
    } else if (previous === null && current !== null) { // if it's the end of the null point
      dashStyle = 'Dot'
      value = data[i][0]!
    }

    if (dashStyle) {
      zones.push({
        dashStyle,
        value,
      })
    }
  }

  return zones
}

function idp_f(tmp: any, rh: any) {
  //console.log('tmp is ' + tmp);
  //console.log('rh is ' + rh);

  //Example:  RH=10%, T=25°C  -> Dew point  =  -8.77°C RH=90%, T=50°C -> Dew point  =  47.90°C

  const H = (Math.log10(rh) - 2) / 0.4343 + (17.62 * tmp) / (243.12 + tmp)
  const dp = 243.12 * H / (17.62 - H)
  return dp
}

export const round = (n: number) => Math.round(n * 10) / 10

/**
 * Convert telemetry data for chart. If data type is temperature it may be converted between imperial and metric system.
 * @param dataType - The telemetry data type of the current set of data (device telemetry).
 * @param deviceTelemetry - The set of data. It's an array of DeviceTelemetry[].
 * @param outdoorAir - The set of data. It's an array of OutdoorAir[].
 * @param userPreferences - Pro Portal user's preference, such as: demo data, system of units (SI) etc.UserPreference
 * @returns A list of arrays with two values ([][v1, v2]) where v1 is the timestamp and v2 the actual  The values are
 * converted to absolute numbers.
 */

export const convertLineChartData = (
  dataType: TelemetryDataType | '' | undefined,
  deviceTelemetry: DeviceTelemetry[],
  outdoorAir: OutdoorAir[],
  ecosenseTelemetry: { [key: string]: EcosenseTelemetry[] },
  userPreferences: UserPreference,
): (number | null)[][] => {

  if (!dataType) return []

  let data: (number | null)[][]

  if (dataType === TelemetryDataType.OUTDOOR_TEMPERATURE || dataType === TelemetryDataType.OUTDOOR_DEW_POINT ||
    dataType === TelemetryDataType.OUTDOOR_AQI || dataType === TelemetryDataType.OUTDOOR_HUMIDITY) {
    if (outdoorAir.length === 0) return []
  }

  if (dataType === TelemetryDataType.OUTDOOR_TEMPERATURE ||
    dataType === TelemetryDataType.OUTDOOR_DEW_POINT ||
    dataType === TelemetryDataType.OUTDOOR_AQI ||
    dataType === TelemetryDataType.OUTDOOR_HUMIDITY ||
    dataType === TelemetryDataType.INDOOR_DEW_POINT ||
    dataType === TelemetryDataType.TEMPERATURE ||
    dataType === TelemetryDataType.PM ||
    dataType === TelemetryDataType.VOC ||
    dataType === TelemetryDataType.HUMIDITY) {

    const actualDataType = (dataType as (
      TelemetryDataType.OUTDOOR_TEMPERATURE |
      TelemetryDataType.TEMPERATURE |
      TelemetryDataType.OUTDOOR_DEW_POINT |
      TelemetryDataType.INDOOR_DEW_POINT |
      TelemetryDataType.OUTDOOR_AQI |
      TelemetryDataType.OUTDOOR_HUMIDITY |
      TelemetryDataType.PM |
      TelemetryDataType.VOC |
      TelemetryDataType.HUMIDITY))

    switch (actualDataType) {
      case TelemetryDataType.OUTDOOR_TEMPERATURE:
      case TelemetryDataType.OUTDOOR_DEW_POINT: {
        const keyName = (actualDataType === TelemetryDataType.OUTDOOR_TEMPERATURE) ? 'temperature' : 'dew_point'

        if (userPreferences?.temperature_isFahrenheit) {
          data = outdoorAir.map(d => [new Date(d.timestamp).getTime(), tempService.convertToFahrenheit(d[keyName])])
        } else {
          data = outdoorAir.map(d => [new Date(d.timestamp).getTime(), d[keyName]])
        }

        break
      }

      case TelemetryDataType.TEMPERATURE: {
        const keyName = 'temperature'

        if (userPreferences?.temperature_isFahrenheit) {
          data = deviceTelemetry.map(d => [new Date(d.timestamp).getTime(), tempService.convertToFahrenheit(d[keyName])])
        } else {
          data = deviceTelemetry.map(d => [new Date(d.timestamp).getTime(), d[keyName]])
        }

        break
      }
      case TelemetryDataType.HUMIDITY: {
        data = deviceTelemetry.map(d => [new Date(d.timestamp).getTime(), Math.abs(d['humidity'])])
        break
      }
      case TelemetryDataType.INDOOR_DEW_POINT: { //fix me
        //const keyName = 'indoor_temperature';
        const keyName1 = 'temperature' // indoor temp
        const keyName2 = 'humidity'

        if (userPreferences?.temperature_isFahrenheit) {
          data = deviceTelemetry.map(d => [new Date(d.timestamp).getTime(), tempService.convertToFahrenheit(idp_f(d[keyName1], d[keyName2]))])
        } else {
          data = deviceTelemetry.map(d => [new Date(d.timestamp).getTime(), idp_f(d[keyName1], d[keyName2])])
        }

        break
      }
      case TelemetryDataType.OUTDOOR_AQI: {
        data = outdoorAir.map(d => [new Date(d.timestamp).getTime(), Math.abs(d['aqi'])])
        break
      }
      case TelemetryDataType.OUTDOOR_HUMIDITY: {
        data = outdoorAir.map(d => [new Date(d.timestamp).getTime(), Math.abs(d['humidity'])])
        break
      }
      case TelemetryDataType.TEMPERATURE: {
        if (userPreferences?.temperature_isFahrenheit) {
          data = deviceTelemetry.map(
            d => [new Date(d.timestamp).getTime(), round(tempService.convertToFahrenheit(d[actualDataType]))],
          )
        } else {
          data = deviceTelemetry.map(d => [new Date(d.timestamp).getTime(), d[actualDataType]])
        }
        break
      }
      case TelemetryDataType.PM: {
        const pmType = userPreferences.pm_isMc ? UserPreferencesEnum.PM_MC : UserPreferencesEnum.PM_COUNT
        data = deviceTelemetry.map(d => [new Date(d.timestamp).getTime(), round(Math.abs(d[pmType]))])
        break
      }
      case TelemetryDataType.VOC: {
        const vocType = userPreferences.voc_isMc ? UserPreferencesEnum.VOC_MC : UserPreferencesEnum.VOC_COUNT
        data = deviceTelemetry.map(d => [new Date(d.timestamp).getTime(), Math.abs(d[vocType])])
        break
      }
      default: {
        data = outdoorAir.map(d => [new Date(d.timestamp).getTime(), Math.abs(d[actualDataType])])
      }
    }
    // if the dataType is not of any known type
    // we assume it's a device serial number for now
    // in the future we'll have to allow for selecting
    // different devices with different data types
  } else {
    if (Object.keys(ecosenseTelemetry).length === 0) return []
    // console.log('converting ecosense', ecosenseTelemetry, dataType)
    data = ecosenseTelemetry[dataType].map(x =>
      [new Date(x.timestamp).getTime(), x.radon])
  }

  const result = [...data]

  // add null points to cloned array, to be used for breaks on chart line
  if (data.length > 1) {
    let interval = 0
    // find out what the interval is between data points
    if (data[1][0] && data[0][0]) {
      interval = data[1][0]! - data[0][0]!
    }
    // if larger than interval, insert a null data point
    for (let i = 1; i < data.length; i++) {
      if (data[i][0]! - data[i - 1][0]! > interval) {
        const nullPoint = [data[i][0]! - interval, null]
        const index = result.findIndex(r => r[0] === data[i][0])
        result.splice(index, 0, nullPoint)
      }
    }
  }

  return result
}


/**
 * Generate one or two series (set of data) depending on whether it's a dual axis chart or not.
 * All data plotted on a chart comes from the series object.
 * The actual data is represented as an array by the data attribute with a list of arrays with two values ([][v1, v2])
 * where v1 is the timestamp and v2 the actual value.
 * @param deviceTelemetry - DeviceTelemetry[]
 * @param outdoorAir - OutdoorAir[]
 * @param dataType - TelemetryDataType
 * @param secondDataType - TelemetryDataType | ''
 * @param userPreferences - Pro Portal user's preference, such as: demo data, system of units (SI) etc.UserPreference
 */

export const generateLineChartSeries = (
  deviceTelemetry: DeviceTelemetry[],
  outdoorAir: OutdoorAir[],
  ecosenseTelemetry: { [key: string]: EcosenseTelemetry[] },
  dataType: TelemetryDataType,
  secondDataType: TelemetryDataType | '',
  userPreferences: UserPreference)
  : Highcharts.SeriesOptionsType[] => {

  const convertedFirstData = convertLineChartData(
    dataType,
    deviceTelemetry,
    outdoorAir,
    ecosenseTelemetry,
    userPreferences,
  )

  // Primary data.
  const series: Highcharts.SeriesOptionsType[] = [
    {
      type: 'line',
      showInLegend: false,
      data: convertedFirstData, // [number, number] type is needed for chart
      name: convertChartDataTypeToLabel(dataType), // needed for tooltip data type label,
      zones: buildZones(convertedFirstData, dataType), // Sections of the series that is displayed differently.
      zoneAxis: 'x',
      connectNulls: true,
    },
  ]

  // If second data type exist add secondary data.
  if (secondDataType) {
    const convertedSecondData = convertLineChartData(
      secondDataType,
      deviceTelemetry,
      outdoorAir,
      ecosenseTelemetry,
      userPreferences,
    )

    series.push({
      type: 'line',
      data: convertedSecondData,
      color: '#943AB7',
      yAxis: 1, // for the right tick marks to show
      name: convertChartDataTypeToLabel(secondDataType), // needed for tooltip label display
      showInLegend: false,
      zones: buildZones(convertedSecondData, secondDataType), // Sections of the series that is displayed differently.
      zoneAxis: 'x',
      connectNulls: true,
    })
  }

  return series
}


/**
 * Plot lines stretch across the plot area, marking a specific value on one of the axes.
 * These plot lines represent thresholds that indicate the IAQ statuses fair and poor.
 * @param alignLeft - Boolean indicating wether to align the plot line on the primary (left) or secondary axis (right).
 * @param userPreferences - Pro Portal user's preference, such as: demo data, system of units (SI) etc.
 * @returns YAxisPlotLinesOptions[] - An array of plot lines indicating the IAQ statuses fair and poor.
 */
const getLineChartYAxisPmPlotLine = (alignLeft = true, userPreferences: UserPreference)
  : YAxisPlotLinesOptions[] => {
  return [
    {
      color: theme.palette.warning.main,
      dashStyle: 'Dash',
      label: {
        text: 'Fair',
        // useHTML: true, // required for adding 'dot' to the label
        style: {
          backgroundColor: 'white',
          borderWidth: '1px',
          borderColor: theme.palette.warning.main,
          borderStyle: 'solid',
          transform: alignLeft ?
            'translateX(-12px)' :
            'translateX(40px)', // move label to cover on yAxis label completely
          color: theme.palette.warning.main,
          padding: '0',
        },
        align: alignLeft ? 'left' : 'right',
        x: -16,
        y: 3,
      },
      value: userPreferences.pm_isMc ? 11.7 : 20.4,
      width: 2,
      zIndex: 1,
    },
    {
      color: theme.palette.error.main,
      dashStyle: 'Dash',
      className: 'poor',
      label: {
        text: 'Poor',
        // useHTML: true, // required for adding 'dot' to the label
        style: {
          backgroundColor: 'white',
          borderWidth: '1px',
          borderColor: theme.palette.error.main,
          borderStyle: 'solid',
          transform: alignLeft ?
            'translateX(-12px)' :
            'translateX(40px)', // move label to cover on yAxis label completely
          color: theme.palette.error.main,
          padding: '0',
        },
        align: alignLeft ? 'left' : 'right',
        x: -16,
        y: 3,
      },
      value: userPreferences.pm_isMc ? 33.2 : 58,
      width: 2,
      zIndex: 1,
    },
  ]
}

const goodPlotLine = (alignLeft, value) => ({
  color: theme.palette.success.main,
  dashStyle: 'Dash' as const,
  label: {
    text: 'Good',
    // useHTML: true, // required for adding 'dot' to the label
    style: {
      backgroundColor: 'white',
      borderWidth: '1px',
      borderColor: theme.palette.success.main,
      borderStyle: 'solid',
      color: theme.palette.warning.main,
      transform: alignLeft ? 'translateX(-33px)' : 'translateX(40px)', // move label to cover on yAxis label completely
      padding: '0 6px',
    },
    align: alignLeft ? 'left' as const : 'right' as const,
    x: -4,
    y: 3,
  },
  value,
  width: 2,
  zIndex: 1,
})

const fairPlotLine = (alignLeft, value) => ({
  color: theme.palette.warning.main,
  dashStyle: 'Dash' as const,
  label: {
    text: 'Fair',
    // useHTML: true, // required for adding 'dot' to the label
    style: {
      backgroundColor: 'white',
      borderWidth: '1px',
      borderColor: theme.palette.warning.main,
      borderStyle: 'solid',
      color: theme.palette.warning.main,
      transform: alignLeft ? 'translateX(-12px)' : 'translateX(40px)', // move label to cover on yAxis label completely
      padding: '0 6px',
    },
    align: alignLeft ? 'left' as const : 'right' as const,
    x: -4,
    y: 3,
  },
  value,
  width: 2,
  zIndex: 1,
})

const poorPlotLine = (alignLeft, value) => ({
  color: theme.palette.error.main,
  dashStyle: 'Dash' as const,
  className: 'poor',
  label: {
    text: 'Poor',
    // useHTML: true, // required for adding 'dot' to the label
    style: {
      backgroundColor: 'white',
      borderWidth: '1px',
      borderColor: theme.palette.error.main,
      borderStyle: 'solid',
      color: theme.palette.error.main,
      transform: alignLeft ? 'translateX(-12px)' : 'translateX(40px)', // move label to cover on yAxis label completely
      padding: '0 6px',
    },
    align: alignLeft ? 'left' as const : 'right' as const,
    x: -4,
    y: 3,
  },
  value,
  width: 2,
  zIndex: 1,
})

/**
 * plot line setting for VOC
 */
const getLineChartYAxisVocPlotLine = (alignLeft: boolean, userPreferences: UserPreference)
  : YAxisPlotLinesOptions[] => {
  return [
    fairPlotLine(alignLeft, userPreferences.voc_isMc ? 500 : 211),
    poorPlotLine(alignLeft, userPreferences.voc_isMc ? 2000 : 849),
  ]
}

const getYAxisPlotBands = (dataType: TelemetryDataType, alignLeft) => {
  switch (dataType) {
    case TelemetryDataType.HUMIDITY: {
      return [{
        label: {
          text: 'Good RH',
          style: {
            color: '#39DD7B',
            padding: '0 8px',
            // transform: alignLeft ? 'translateX(-33px)' : 'translateX(40px)', // move label to cover on yAxis label completely
          },
          align: alignLeft ? 'left' as const : 'right' as const,
        },
        color: '#E5FFF0',
        from: 30,
        to: 60,
      }]
    }
    default: return []
  }
}

/**
 * get the plotline based on data type change
 * @param dataType - string
 * @param isPrimaryAxis - boolean
 * @param userPreferences - Pro Portal user's preference, such as: demo data, system of units (SI) etc.UserPreference
 * @param overlayOptions - string[]
 * @returns plotline - YAxisPlotLinesOptions[]
 */
export const getLineChartYAxisPlotLine = (dataType: TelemetryDataType | '' | undefined, isPrimaryAxis = true,
  userPreferences: UserPreference, selectedOverlays: string[]): YAxisPlotLinesOptions[] => {
  let plotline: YAxisPlotLinesOptions[] = []

  if (!dataType || selectedOverlays.indexOf('Thresholds') === -1) {
    return plotline
  }

  switch (dataType) {
    case TelemetryDataType.PM:
      plotline = getLineChartYAxisPmPlotLine(isPrimaryAxis, userPreferences)
      break
    case TelemetryDataType.VOC:
      plotline = getLineChartYAxisVocPlotLine(isPrimaryAxis, userPreferences)
      break
    case TelemetryDataType.INDOOR_DEW_POINT:
      plotline = [
        fairPlotLine(isPrimaryAxis, userPreferences.temperature_isFahrenheit ? 55 : 13),
        poorPlotLine(isPrimaryAxis, userPreferences.temperature_isFahrenheit ? 65 : 15),
      ]
      break
    default:
      plotline = []
  }

  return plotline
}


/**
 * Get the line chart yAxis title based on data type and user preferences.
 * @param dataType - Enum for field names of data type for IAQ measurements.
 * @param userPreferences - Pro Portal user's preference, such as: demo data, system of units (SI) etc.
 * @returns title - A text.
 */
export const getLineChartYAxisTitle = (dataType: TelemetryDataType | '' | undefined, userPreferences: UserPreference) => {
  let title = ''

  if (!dataType) {
    return title
  }

  switch (dataType) {
    case TelemetryDataType.PM:
      title = userPreferences?.pm_isMc ? CHART_DATA_TYPE_LABELS.PM_MC : CHART_DATA_TYPE_LABELS.PM_COUNT
      break
    case TelemetryDataType.VOC:
      title = userPreferences?.voc_isMc ? CHART_DATA_TYPE_LABELS.VOC_MC : CHART_DATA_TYPE_LABELS.VOC_COUNT
      break
    case TelemetryDataType.HUMIDITY:
      title = CHART_DATA_TYPE_LABELS.HUMIDITY
      break
    case TelemetryDataType.TEMPERATURE:
      title = userPreferences?.temperature_isFahrenheit ?
        CHART_DATA_TYPE_LABELS.TEMPERATURE_F :
        CHART_DATA_TYPE_LABELS.TEMPERATURE_C
      break
    case TelemetryDataType.OUTDOOR_AQI:
      title = CHART_DATA_TYPE_LABELS.OUTDOOR_AQI
      break
    case TelemetryDataType.OUTDOOR_DEW_POINT:
      title = userPreferences?.temperature_isFahrenheit ?
        CHART_DATA_TYPE_LABELS.OUTDOOR_DEW_POINT_F :
        CHART_DATA_TYPE_LABELS.OUTDOOR_DEW_POINT_C
      break
    case TelemetryDataType.INDOOR_DEW_POINT:
      title = userPreferences?.temperature_isFahrenheit ?
        CHART_DATA_TYPE_LABELS.INDOOR_DEW_POINT_F :
        CHART_DATA_TYPE_LABELS.INDOOR_DEW_POINT_C
      break
    case TelemetryDataType.OUTDOOR_HUMIDITY:
      title = CHART_DATA_TYPE_LABELS.OUTDOOR_HUMIDITY
      break
    case TelemetryDataType.OUTDOOR_TEMPERATURE:
      title = userPreferences?.temperature_isFahrenheit ?
        CHART_DATA_TYPE_LABELS.OUTDOOR_TEMPERATURE_F :
        CHART_DATA_TYPE_LABELS.OUTDOOR_TEMPERATURE_C
      break
    default:
      title = 'Radon'
  }

  return title
}

/**
 * Create inactive intervals for chart data from telemetry data to use for plotbands
 * @param deviceTelemetry - DeviceTelemetry[]
 * @returns data for gantt chart - XrangePointOptionsObject
 */
export const getPlotBands = (
  (deviceTelemetry: DeviceTelemetry[], startTime: Date | null, endTime: Date | null): XAxisPlotBandsOptions[] => {
    if (!startTime || !endTime) return []
    // return empty array if input is empty array
    if (deviceTelemetry.length === 0) {
      return []
    }

    const modifiedArr = getNormalizedTelemetry<DeviceTelemetry>(deviceTelemetry, {
      timestamp: '',
      airflow: 0,
      voc_mc: 0,
      voc_count: 0,
      pm_count: 0,
      pm_mc: 0,
      humidity: 0,
      temperature: 0,
      voc_status: '',
      pm_status: '',
      combined_status: '',
      highest_factor: '',
    })

    const autoInterval = getApiTimeInterval(startTime, endTime)
    let intervalInMilliSeconds = TimeInterval.MINUTE * 5

    if (autoInterval === '1d') {
      intervalInMilliSeconds = TimeInterval.DAY
    } else if (autoInterval === '1h') {
      intervalInMilliSeconds = TimeInterval.HOUR
    }

    const dataArray: XAxisPlotBandsOptions[] = []
    let dataPoint: XAxisPlotBandsOptions = {
    }

    let prevAirflow = false
    let currentAirflow = Math.abs(modifiedArr[0].airflow) >= TELEMETRY_THRESHOLD.airflow
    const arrayStartsAfterStartTime = new Date(modifiedArr[0].timestamp).getTime() > startTime.getTime()
    const arrayEndsBeforeEndTime = (
      endTime.getTime() - new Date(deviceTelemetry[deviceTelemetry.length - 1].timestamp).getTime() > intervalInMilliSeconds
    )

    // if no data from start time to start of array
    if (arrayStartsAfterStartTime) {
      dataPoint = {
        from: startTime.getTime(),
        color: theme.palette.grey[200],
      }
      if (currentAirflow) {
        dataPoint.to = new Date(modifiedArr[0].timestamp).getTime()
        dataArray.push(dataPoint)
      }
    } else {
      if (!currentAirflow) {
        dataPoint = {
          from: new Date(modifiedArr[0].timestamp).getTime(),
          color: theme.palette.grey[200],
        }
      }
    }

    // loop through the remaining data points (excluding index 0), push data points to data array
    for (let i = 1; i < modifiedArr.length; i++) {
      prevAirflow = Math.abs(modifiedArr[i - 1].airflow) >= TELEMETRY_THRESHOLD.airflow
      currentAirflow = Math.abs(modifiedArr[i].airflow) >= TELEMETRY_THRESHOLD.airflow

      if (currentAirflow !== prevAirflow) {
        if (!currentAirflow) {
          dataPoint = {
            from: new Date(modifiedArr[i - 1].timestamp).getTime(),
            color: theme.palette.grey[200],
          }
        } else {
          dataPoint.to = new Date(modifiedArr[i].timestamp).getTime()
          dataArray.push(dataPoint)
        }
      }
    }

    // check last point
    if (!currentAirflow) {
      if (arrayEndsBeforeEndTime) {
        dataPoint.to = endTime.getTime()
      } else {
        dataPoint.to = new Date(modifiedArr[modifiedArr.length - 1].timestamp).getTime()
      }
      dataArray.push(dataPoint)
    } else {
      if (arrayEndsBeforeEndTime) {
        dataPoint = {
          from: new Date(modifiedArr[modifiedArr.length - 1].timestamp).getTime(),
          color: theme.palette.grey[200],
        }
        dataPoint.to = endTime.getTime()
        dataArray.push(dataPoint)
      }
    }
    return dataArray
  }
)


/**
 * get the min for y axis if only one data type is supplied and it is either temperature or humidity
 * @param dataType - TelemetryDataType
 * @param data - DeviceTelemetry[]
 * @param userPreferences - Pro Portal user's preference, such as: demo data, system of units (SI) etc.UserPreference
 * @returns number
 */
const getYAxisMin = (dataType: TelemetryDataType, data: DeviceTelemetry[], userPreferences: UserPreference): number => {
  if (!data || data.length === 0) {
    return 0
  }

  const dataArr = [...data]
  let min = 0

  if (dataType === TelemetryDataType.HUMIDITY) {
    dataArr.sort((a, b) => a.humidity - b.humidity)
    min = dataArr[0].humidity - 10
  } else if (dataType === TelemetryDataType.TEMPERATURE) {
    dataArr.sort((a, b) => a.temperature - b.temperature)
    min = userPreferences.temperature_isFahrenheit ?
      tempService.convertToFahrenheit(dataArr[0].temperature) - 10 :
      dataArr[0].temperature - 5
  } else {
    min = 0
  }

  return min
}

/**
 * get the max for y axis if only one data type is supplied and it is either temperature or humidity
 * @param dataType - TelemetryDataType
 * @param data - DeviceTelemetry[]
 * @param userPreferences - Pro Portal user's preference, such as: demo data, system of units (SI) etc.UserPreference
 * @returns number
 */
const getYAxisMax = (dataType: TelemetryDataType, data: DeviceTelemetry[], userPreferences: UserPreference) => {
  if (!data || data.length === 0) {
    return null
  }

  const dataArr = [...data]
  let max

  if (dataType === TelemetryDataType.HUMIDITY) {
    dataArr.sort((a, b) => a.humidity - b.humidity)
    max = dataArr[dataArr.length - 1].humidity + 10
  } else if (dataType === TelemetryDataType.TEMPERATURE) {
    dataArr.sort((a, b) => a.temperature - b.temperature)
    max = userPreferences.temperature_isFahrenheit ?
      tempService.convertToFahrenheit(dataArr[dataArr.length - 1].temperature) + 10 :
      dataArr[dataArr.length - 1].temperature + 5
  } else if (dataType === TelemetryDataType.PM) {
    const isPmMc = userPreferences.pm_isMc
    const dataKey = isPmMc ? 'pm_mc' : 'pm_count'
    dataArr.sort((a, b) => a[dataKey] - b[dataKey])
    const maxValue = dataArr[dataArr.length - 1][dataKey]
    max = maxValue + maxValue * 0.25
  } else {
    max = null
  }

  return max
}

/**
 * Generate one or two yAxis for line chart depending on whether it's a dual axis chart or not.
 * @param data - TelemetryDataType
 * @param dataType - TelemetryDataType
 * @param secondDataType -TelemetryDataType | ''
 * @param overlayOptions - string[]
 * @param userPreferences - Pro Portal user's preference, such as: demo data, system of units (SI) etc.UserPreference
 */
export const generateLineChartYAxis = (data: DeviceTelemetry[], dataType: TelemetryDataType,
  secondDataType: TelemetryDataType | '', userPreferences: UserPreference,
  overlayOptions: string[]): Highcharts.YAxisOptions[] => {

  const yMax1 = getYAxisMax(dataType, data, userPreferences)
  const yMin1 = getYAxisMin(dataType, data, userPreferences)

  // Primary yAxis.
  const yAxis: Highcharts.YAxisOptions[] = [{
    plotLines: getLineChartYAxisPlotLine(dataType, true, userPreferences, overlayOptions),
    plotBands: getYAxisPlotBands(dataType, true),
    gridLineWidth: 1,
    gridLineDashStyle: 'LongDash',
    className: 'line-y-axis',
    title: {
      text: getLineChartYAxisTitle(dataType, userPreferences),
      useHTML: !!secondDataType,
      align: 'middle',
      style: {
        color: theme.palette.secondary.main,
      },
    },
    // max: yMax1,
    // min: yMin1,
  }]

  // If second data type exist add secondary yAxis
  if (secondDataType) {
    const bothTemperature = (secondDataType === 'temperature' || secondDataType === 'outdoor_temperature') &&
      (dataType === 'temperature' || dataType === 'outdoor_temperature')

    let yMax2 = getYAxisMax(secondDataType, data, userPreferences)
    let yMin2 = getYAxisMin(secondDataType, data, userPreferences)

    // make sure x ranges are the same if the units are the same
    if (bothTemperature) {
      const globalMax = Math.max(yMax1, yMax2)
      const globalMin = Math.min(yMin1, yMin2)

      yAxis[0].max = globalMax
      yAxis[0].min = globalMin
      yMax2 = globalMax
      yMin2 = globalMin
    }

    yAxis.push({
      plotLines: getLineChartYAxisPlotLine(secondDataType, false, userPreferences, overlayOptions),
      plotBands: getYAxisPlotBands(secondDataType, false),
      gridLineWidth: 1,
      gridLineDashStyle: 'LongDash',
      className: 'line-y-axis',
      title: {
        text: getLineChartYAxisTitle(secondDataType, userPreferences),
        useHTML: true,
        align: 'middle',
        style: {
          color: '#EE84E8',
        },
      },
      max: yMax2,
      min: yMin2,
      opposite: true,
    })
  }

  return yAxis
}


/**
 * returns Xaxis options for line chart
 * @param data - DeviceTelemetry[]
 * @param startTime - Date
 * @param endTime - Date
 * @param overlayOptions - string[]
 * @param setExtremesHandler - callback
 * @param afterSetExtremesHandler - callback
 */
export const generateLineChartXAxis = (
  data: DeviceTelemetry[],
  startTime: Date | null,
  endTime: Date | null,
  selectedOverlays: string[],
  events?: Highcharts.XAxisEventsOptions,
  labels: Highcharts.XAxisLabelsOptions = {
    enabled: false,
  },
): Highcharts.XAxisOptions => {
  return {
    type: 'datetime',
    maxPadding: 0,
    plotBands: (selectedOverlays || []).indexOf('Airflow inactive') === -1 ? [] : getPlotBands(data, startTime, endTime),
    events,
    gridLineWidth: 1,
    gridLineDashStyle: 'LongDash',
    lineWidth: 0,
    labels,
    tickLength: 0,
  }
}

/**
 * Define basic options for line chart
 * @param interactive
 * @param marginLeft
 * @param height
 */
export const getLineChartDefaultOptions = (
  interactive: boolean,
  height: number,
)
  : Highcharts.Options => {
  return {
    title: {
      text: '',
    },
    time: {
      useUTC: false,
    },
    boost: {
      useGPUTranslations: true,
      seriesThreshold: 1,
      pixelRatio: 0,
    },
    chart: {
      backgroundColor: 'transparent',
      panning: {
        enabled: interactive,
        type: 'x',
      },
      ignoreHiddenSeries: false, // added to display xAxis when there are no telemetry data in selected time frame,
      resetZoomButton: { // style zoom button, currently rendering an image as button
        theme: {
          style: {
            display: 'none', // Hide default zoom button since we will be customizing and adding it programmatically.
          },
        },
      },
      height,
      width: interactive ? null : 840,
      marginRight: interactive ? 56 : 32,
      marginLeft: interactive ? 64 : 64,
      reflow: true,
      style: {
        fontFamily: 'inherit',
        zIndex: 1,
        cursor: interactive ? 'grab' : 'default',
      },
    },
    lang: {
      noData: `<img
        width=${interactive ? 480 : 360}
        src="${HavenLinks.S3}no-data.png"
        alt="graphics of a person with questions"
      >`,
    },
    credits: {
      enabled: false, // hide default message 'created by Highcharts'
    },
    noData: {
      useHTML: true, // need to set to true for image
      position: {
        verticalAlign: 'top',
      },
    },
    tooltip: interactive ? {
      shared: true, // combine tooltip for both y axis
      valueDecimals: 1,
      backgroundColor: '#FFFFFF',
      shadow: false,
      useHTML: true,
    } : {
      enabled: false,
    },
    plotOptions: {
      line: {
        color: theme.palette.secondary.main,
      },
      series: {
        marker: {
          enabled: false, // hide plot dots when data isn't dense
        },
        lineWidth: 1,
        states: {
          hover: interactive ? {
            lineWidth: 1, // prevent line thickness increase when mouse is close to line
          } : {
            enabled: false,
          },
        },
      },
    },
  }
}
