import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogContent, CircularProgress } from '@material-ui/core'
import { UserDemoModal } from 'state-mngt/models/user'
import { disableDemoMode, completeDemoIntroModal } from 'state-mngt/actions/user-actions'
import { isDemoModalOpen, getDemoModal, isUserCompletedDemosLoaded } from 'state-mngt/selectors/user-selectors'
import { useMixPanel } from 'features/analytics/mixpanel-provider'
import DemoDialog, { DialogType } from 'features/demo-data/demo-dialog'

const DemoIntroDialog = () => {
  const dispatch = useDispatch()
  const { mixpanel } = useMixPanel()

  const isOpen = useSelector(isDemoModalOpen)
  const type = useSelector(getDemoModal)
  const isCompletedDemosLoaded = useSelector(isUserCompletedDemosLoaded)

  const onClose = useCallback(() => {
    if (mixpanel) {
      mixpanel.track('pp_customersPage_' + (type || '') + 'DataDialog_closeButton')
    }

    dispatch(disableDemoMode())
  }, [dispatch])

  const onNext = useCallback(() => {
    if (mixpanel) {
      mixpanel.track('pp_customersPage_' + (type || '')  + 'DataDialog_nextButton')
    }

    if (type && type === UserDemoModal.Intro) {
      dispatch(completeDemoIntroModal())
    }
  }, [dispatch, type])

  useEffect(() => {
    if (mixpanel && isOpen) {
      mixpanel.track('pp_customersPage_' + (type || '')  + 'DataDialog_open')
    }
  }, [mixpanel, isOpen])

  if (isOpen && !isCompletedDemosLoaded) {
    return (
      <Dialog open>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <>
      <DemoDialog
        isOpen={isOpen && type === UserDemoModal.Intro}
        type={DialogType.Intro}
        onClose={onClose}
        onNext={onNext}
      />
      <DemoDialog
        isOpen={isOpen && type === UserDemoModal.Choose}
        type={DialogType.Choose}
        onClose={onClose}
        onNext={onNext}
      />
      <DemoDialog
        isOpen={isOpen && type === UserDemoModal.Outro}
        type={DialogType.Outro}
        onClose={onClose}
        onNext={onNext}
      />
    </>
  )
}

export default DemoIntroDialog
