import * as React from 'react'
import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core'
import { useParams } from 'react-router-dom'
import Combobox from 'ui/combobox'
import useStore, { InstallStore, setIsValid } from 'stores/install'
import useAllZones from 'utils/hooks/useAllZones'
import { dedupe } from '../useGetData'
import { capitalize } from 'utils/string-utils'

const setInstallDetails = (details: Partial<{ zone: string, zoneSize: number, dwellingId: number }>) =>
    useStore.setState(details)

const ZONES = [
    { title: 'Upstairs' },
    { title: 'Downstairs' },
    { title: 'Whole home' },
]

const select = ({ zone, zoneSize }: InstallStore) => ({ zone, zoneSize })

function DwellingStep({ setZoneOnUrl, installIsOpen }) {
    const { dwellingId } = useParams()
    const [zones, setZones] = React.useState(ZONES)
    const { zone, zoneSize } = useStore(select)

    const setZoneOnStateAndUrl = zone => {
        setInstallDetails({ zone })
        setZoneOnUrl(zone)
    }

    const setZoneSize = zoneSize => setInstallDetails({ zoneSize })

    const existingZones = useAllZones()

    React.useEffect(() => {
        if (!existingZones.length) return
        const dedupedZones = dedupe([...ZONES.map(x => x.title), ...existingZones].map(x => x.toLowerCase()))
        const newZones = dedupedZones.map(x => ({ title: capitalize(x) }))
        setZones(newZones)
    }, [existingZones.join('')])

    React.useEffect(() => {
        if (!installIsOpen) return // only set the zone on URL if the install is actually in progress
        if (!zone) setZoneOnStateAndUrl(zones[0].title)
    }, [installIsOpen, zone, zones.map(x => x.title).join('')])

    React.useEffect(() => {
        setInstallDetails({ dwellingId: parseInt(dwellingId || '') })
        setIsValid(0)(Boolean(dwellingId && zone && zoneSize))
    }, [dwellingId, zone, zoneSize])

    return (
        <>
            <Typography variant='h3'>
                New install
            </Typography>
            <Box
                mt={4}
                maxWidth='400px'
                bgcolor='white'
                borderRadius='11px'
                padding={2}
            >
                <div>
                    <Box
                        display='flex'
                        flexDirection='column'
                        gridGap='16px'
                    >
                        <FormControl
                            size='small'
                            variant='outlined'
                            fullWidth
                        >
                            <InputLabel>
                                Install type
                            </InputLabel>
                            <Select
                                id={`insallType`}
                                name={`insallType`}
                                labelId={`insallType`}
                                label='Install type'
                                defaultValue='residential'
                            >
                                <MenuItem value='residential'>
                                    Residential
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl
                            size='small'
                            variant='outlined'
                            fullWidth
                        >
                            <InputLabel>
                                Deployment type
                            </InputLabel>
                            <Select
                                id={`deploymentType`}
                                name={`deploymentType`}
                                labelId={`deploymentType`}
                                label='Deployment type'
                                defaultValue='sale-to-homeowner'
                            >
                                <MenuItem value='sale-to-homeowner'>
                                    Sale to homeowner
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box
                        display='flex'
                        flexDirection='column'
                        gridGap='16px'
                    >
                        <Box>
                            <Box mt={3} mb={2}>
                                <p>Some description about what a zone is and why it's important</p>
                            </Box>
                            <Combobox
                                value={zone}
                                options={zones}
                                onChange={setZoneOnStateAndUrl}
                                label='Install zone'
                            />
                        </Box>
                        <Box>
                            <TextField
                                size='small'
                                fullWidth
                                variant='outlined'
                                value={zoneSize}
                                type='number'
                                onChange={e => setZoneSize(parseInt(e.target.value))}
                                label='Zone size (sq ft.)'
                            />
                        </Box>
                    </Box>
                </div>
            </Box>
        </>
    )
}

export default DwellingStep
