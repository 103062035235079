import { LoadEntity } from 'state-mngt/models/redux'

export const startLoadEntity = (): LoadEntity<null> => ({
  isLoading: true,
  isLoaded: false,
  value: null,
})

export const resolveLoadEntity = <TValue,>(value: TValue): LoadEntity<TValue | null> => ({
  isLoading: false,
  isLoaded: true,
  value,
})

export const rejectLoadEntity = (): LoadEntity<null> => ({
  isLoading: false,
  isLoaded: false,
  value: null,
})


