import useCurrentDwellingContents from "utils/hooks/useCurrentDwellingContents"
import useCurrentZone from "utils/hooks/useCurrentZone"

const useMonitorInCurrentZone = () => {
    const contents = useCurrentDwellingContents()
    const zone = useCurrentZone()
    const monitor = contents?.devices.find(x =>
        (x.zone.toLowerCase().trim() === zone.toLowerCase().trim()) && (x.type === 'cam'))
    return monitor
}

export default useMonitorInCurrentZone
