import {
    Box,
    Button,
    Typography,
} from '@material-ui/core'
import * as React from 'react'
import { isBlower } from './new-controller'
import useStore, {
    selectOutputs,
    selectEquipments,
    selectInterlocks,
    setInterlocks,
} from 'stores/install'
import { Interlock } from 'types'
import { tempId } from '../util'
import AutomationRuleCard from '../automation-rule-card'
import { Add } from '@material-ui/icons'
import SingleInterlock from './single-interlock'

const defaultInterlock = (primary_output, secondary_output): Interlock => ({
    primary_output,
    secondary_output,
    automation_id: 0,
    enabled: true,
    id: tempId(),
})

function InterlockStep() {
    const equipments = useStore(selectEquipments)
    const outputs = useStore(selectOutputs)
    const interlocks = useStore(selectInterlocks)

    const ventilatingDehumidifierFan = equipments
        .find(x =>
            x.type?.includes('ventilating_dehumidifier') &&
            (outputs.find(y => y.equipment_id === x.id)?.equipment_component === 1))

    const blowers = [
        ...equipments.filter(x => (x && isBlower(x.type))),
        ...(ventilatingDehumidifierFan ? [ventilatingDehumidifierFan] : []),
    ]

    const nonBlowers = equipments.filter(x => !(blowers.map(y => y.id).includes(x.id)))

    const findEquipmentIds = (outputsIds: number[]) => {
        const equipmentIds = outputs
            .filter(x => x.id && outputsIds.includes(x.id))
            .map(x => equipments.find(y => y.id === x.equipment_id))
            .filter(x => x)
            .map(x => x?.id)

        return {
            equipment1: equipmentIds[0],
            equipment2: equipmentIds[1],
        }
    }

    const findEquipmentId = (outputId: number) => {
        return (outputs || []).find(x => x?.id && x.id === outputId)?.equipment_id
    }

    const findOutput = (equipmentId: number) =>
        outputs.find(x => x.equipment_id === equipmentId)?.id || console.warn('[findOutput] not found')

    const createNewInterlock = (nonBlower = 0, blower = 0) => {
        /**
         * NOTE: use new logic from interlock equipment tab
         */
        const primary_output = findOutput(nonBlower)
        const secondary_output = findOutput(blower)

        setInterlocks([...interlocks, defaultInterlock(primary_output, secondary_output)])
    }

    React.useEffect(() => {
        if (!blowers?.length) return
        if (!nonBlowers?.length) return

        let newInterlocks: { primary_output: number, secondary_output: number }[] = []
        const secondary_output = findOutput(blowers[0].id || 0)

        for (const nonBlower of nonBlowers) {
            // create default interlocks for each nonBlower
            const primary_output = findOutput(nonBlower.id || 0)
            if (secondary_output && primary_output) newInterlocks = [...newInterlocks, { primary_output, secondary_output }]
        }

        setInterlocks(newInterlocks.map(x => defaultInterlock(x.primary_output, x.secondary_output)))
    }, [
        blowers.map(x => x.id).join(''),
        nonBlowers.map(x => x.id).join(''),
    ])

    // non blowers can only be used once
    const usedNonBlowers = nonBlowers.filter(x => interlocks.find(interlock => {
        const equips = findEquipmentIds([interlock.primary_output, interlock.secondary_output])
        return (equips.equipment1 === x.id) || (equips.equipment2 === x.id)
    }))

    const setNonBlower = index => nonBlowerId => {
        const newInterlocks = interlocks.map((x, i) => {
            if (index === i) {
                return {
                    ...x,
                    primary_output: findOutput(nonBlowerId) || 0,
                }
            }
            return x
        })

        setInterlocks(newInterlocks)
    }

    const setBlower = index => blowerId => {
        const newInterlocks = interlocks.map((x, i) => {
            if (index === i) {
                return {
                    ...x,
                    secondary_output: findOutput(blowerId) || 0,
                }
            }
            return x
        })

        setInterlocks(newInterlocks)
    }

    const handleDelete = index => () => {

    }

    return (
        <>
            <Typography variant='h3'>
                Set up equipment interlocks
            </Typography>
            <Typography variant='subtitle1'>
                Interlocking ensures non-blower equipment is never activated without a blower
            </Typography>
            <Box
                mt={4}
                display='grid'
                gridGap='16px'
                width='100%'
                gridTemplateColumns={[
                    '1fr',
                    '1fr 1fr',
                    '400px 400px 400px',
                ]}
                alignItems='start'
            >
                {interlocks.map(({ primary_output, secondary_output }, index) => (
                    <SingleInterlock
                        key={`${primary_output}${secondary_output}`}
                        nonBlowerId={findEquipmentId(primary_output)}
                        blowerId={findEquipmentId(secondary_output)}
                        setBlowerId={setBlower(index)}
                        setNonBlowerId={setNonBlower(index)}
                        blowers={blowers}
                        nonBlowers={nonBlowers}
                        handleDelete={handleDelete(index)}
                    />
                ))}
                {usedNonBlowers.length !== nonBlowers.length && (
                    <AutomationRuleCard style={{
                        display: 'flex',
                        justifyContent: 'center',
                        paddingTop: 16,
                    }}>
                        <Button
                            onClick={() => createNewInterlock(0, 0)}
                            startIcon={<Add />}
                        >New interlock</Button>
                    </AutomationRuleCard>
                )}
            </Box>
        </>
    )
}

export default InterlockStep
