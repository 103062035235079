import {
  Card, Box, Typography, TextField, Button,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { OnboardingProfile } from 'state-mngt/models/onboarding-profile'
import { useMixPanel } from 'features/analytics/mixpanel-provider'

export const FormOne = (props: {
  profile: OnboardingProfile;
  onProfileChange: (profile: OnboardingProfile) => void;
  onNextValueChange: (value: number) => void;
  onIsValidValueChange: (formNum: number, isValid: boolean) => void;
  isParentFormValid: boolean;
}) => {
  const { mixpanel } = useMixPanel()
  const {
    profile, onProfileChange, isParentFormValid, onNextValueChange, onIsValidValueChange,
  } = props
  const [first_name, setFirstName] = useState<string>(profile.first_name) // required
  const [last_name, setLastName] = useState<string>(profile.last_name) // required
  const [firstNameRequiredError, setFirstNameRequiredError] = useState<boolean>(false)
  const [lastNameRequiredError, setLastNameRequiredError] = useState<boolean>(false)
  const [btnVisible, setBtnVisible] = useState<boolean>(false)
  const [activeCard, setActiveCard] = useState<boolean>(true)

  useEffect(() => {
    if (!firstNameRequiredError && !lastNameRequiredError) {
      onIsValidValueChange(1, true)
    } else {
      onIsValidValueChange(1, false)
    }
  }, [firstNameRequiredError, lastNameRequiredError, onIsValidValueChange])

  useEffect(() => {
    if(first_name.trim() === '' || last_name.trim() === ''){
      setBtnVisible(false)
    } else {
      setBtnVisible(true)
    }
  }, [first_name, last_name])

  /**
   * callback fn for 'Next' btn click
   */
  const handleNext = () => {
    if (mixpanel) {
      mixpanel.track('pp_onboardingPage_cardOne_nextButton')
    }
    if (first_name && last_name) {
      const updatedProfile: OnboardingProfile = {
        ...profile,
        first_name,
        last_name,
      }
      onProfileChange(updatedProfile)
      onNextValueChange(15)
      setBtnVisible(false)
      setActiveCard(false)
    }
  }

  /**
   * callback fn for first name value change
   * sets first name state
   * @param e - ChangeEvent
   */
  const handleFirstNameChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const input = e.target.value as string
    setFirstName(input)
    setFirstNameRequiredError(input.trim() === '' ? true : false)

    const updatedProfile: OnboardingProfile = {
      ...profile,
      first_name: input,
    }

    onProfileChange(updatedProfile)
  }

  /**
   * callback fn for last name value change
   * sets last name state
   * @param e - ChangeEvent
   */
  const handleLastNameChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const input = e.target.value as string
    setLastName(input)
    setLastNameRequiredError(input.trim() === '' ? true : false)

    const updatedProfile: OnboardingProfile = {
      ...profile,
      last_name: input,
    }

    onProfileChange(updatedProfile)
  }

  return (
    <Card
      elevation={3}
      style={{
        width: '570px',
        padding: '2rem',
      }}
      className={activeCard ? 'onboarding-card' : 'onboarding-fade'}
    >
      {profile && (
        <Box display="flex" mb={4}>
          <Typography>My name is</Typography>
          <Box display="flex" ml={2}>
            <TextField
              id="onboarding-first-name"
              required
              size="small"
              placeholder="First Name*"
              defaultValue={first_name}
              error={firstNameRequiredError}
              style={{
                marginRight: '1rem',
              }}
              helperText={firstNameRequiredError ? 'First name is required' : ''}
              onChange={handleFirstNameChange}
            />
            <TextField
              id="onboarding-last-name"
              required
              size="small"
              placeholder="Last Name*"
              defaultValue={last_name}
              error={lastNameRequiredError}
              helperText={lastNameRequiredError ? 'Last name is required' : ''}
              onChange={handleLastNameChange}
            />
          </Box>
        </Box>
      )}
      {profile && profile.user_email && (
        <Box display="flex" mb={4}>
          <Typography>My email is</Typography>
          <Box display="flex" ml={2} flex="auto">
            <TextField id="onboarding-user-email" fullWidth disabled size="small" defaultValue={profile.user_email} />
          </Box>
        </Box>
      )}
      {btnVisible && activeCard && (
        <Box display="flex" justifyContent="flex-end">
          <Button type="submit" color="primary" variant="contained" onClick={handleNext} disabled={!isParentFormValid}>
            Next
          </Button>
        </Box>
      )}
    </Card>
  )
}
