import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from 'serviceWorker'
import { theme } from 'theme'
import { Provider } from 'react-redux'
import store from 'state-mngt/store'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import 'index.scss'
import package_json from '../package.json'
import App from 'app/app'
import { ThemeProvider, CssBaseline } from '@material-ui/core'
import { BrowserRouter } from 'react-router-dom'
import { MixPanelProvider } from 'features/analytics/mixpanel-provider'
import Auth0ProviderWithHistory from 'features/authentication/auth0-provider-with-history'
import { CustomSnackbarProvider } from 'ui/custom-snackbar-provider'
import { TourProviderWithCustomOptions } from 'features/product-tour'


ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <BrowserRouter>
            <MixPanelProvider>
              <Auth0ProviderWithHistory>
                <CustomSnackbarProvider>
                  <TourProviderWithCustomOptions>
                    <App />
                  </TourProviderWithCustomOptions>
                </CustomSnackbarProvider>
              </Auth0ProviderWithHistory>
            </MixPanelProvider>
          </BrowserRouter>
        </MuiPickersUtilsProvider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
  ,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// Added version number in console
console.log('Front-end app version: ' + package_json.version)
