import React, { useState } from 'react'
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Link,
  InputAdornment,
} from '@material-ui/core'
import { DateTimePicker } from '@material-ui/pickers'
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { HISTORY_QUERY_KEYS } from 'utils/constants/customer'
import { Timeframe } from 'utils/constants/time-interval'
import { getMillisecondsForTimeFrame } from 'utils/time-utils'
import { useStateInURLQueryParameter } from 'utils/hooks/router'

export const ReportTimeControl = (props: {
  startTime: Date;
  endTime: Date;
  setStart: any;
  setEnd: any;
}) => {
  const {
    setStart,
    setEnd,
    startTime,
    endTime,
  } = props

  const [controlStartTime, setStartTime] = useState<Date>(startTime)
  const [controlEndTime, setEndTime] = useState<Date>(endTime)
  const [timeFrame, setTimeFrame] = useStateInURLQueryParameter<string>({
    key: HISTORY_QUERY_KEYS.timeFrame,
    initialValue: '1m',
  })
  const [isCustomTime, setIsCustomTime] = useStateInURLQueryParameter<boolean>({
    key: HISTORY_QUERY_KEYS.customTime,
    initialValue: false,
    fromQuery: (queryValue: string) => queryValue === 'true',
    toQuery: (stateValue: boolean) => (stateValue ? stateValue.toString() : ''),
  })

  const [endTimeInputError, setEndTimeInputError] = useState<boolean>(false)
  const [endTimeInputValidationMsg, setEndTimeInputValidationMsg] = useState<string>('')
  const [startTimeInputError, setStartTimeInputError] = useState<boolean>(false)
  const [startTimeInputValidationMsg, setStartTimeInputValidationMsg] = useState<string>('')

  /**
   * callback function to save changed start time input value to state
   * @param date - MaterialUiPickersDate
   */
  const handleStartChange = (date: MaterialUiPickersDate) => {
    if (date) {
      if (controlEndTime.getTime() < date.toDate().getTime()) {
        setStartTimeInputError(true)
        setStartTimeInputValidationMsg('Start date should be before end date.')
      } else {
        setStartTimeInputError(false)
        setStartTimeInputValidationMsg('')
        setEndTimeInputError(false)
        setEndTimeInputValidationMsg('')
      }
      setStartTime(date.toDate())
      setStart(date.toDate()) // set time in parent component
    }
  }

  /**
   * callback function to save changed end time input value in state
   * @param date - MaterialUiPickersDate
   */
  const handleEndChange = (date: MaterialUiPickersDate) => {
    if (date) {
      if (date.toDate().getTime() < controlStartTime.getTime()) {
        setEndTimeInputError(true)
        setEndTimeInputValidationMsg('End date should be after start date.')
      } else {
        setStartTimeInputError(false)
        setStartTimeInputValidationMsg('')
        setEndTimeInputError(false)
        setEndTimeInputValidationMsg('')
      }
      setEndTime(date.toDate())
      setEnd(date.toDate()) // set time in parent component
    }
  }

  /**
   * callback function to save changed time frame selector value in state and dispatch to store before refreshing range data with API call
   * @param event change event for time frame selector
   */
  const handleTimeFrameChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    const startValue = event.target.value as Timeframe
    const startMillisecond = getMillisecondsForTimeFrame(startValue)
    const startTime = new Date(startMillisecond)
    const endTime = new Date()

    setStartTime(startTime)
    setEndTime(endTime)

    setTimeFrame(startValue)
    setStart(startTime) // set time in parent component
    setEnd(endTime) // set time in parent component
  }

  /**
   * callback fn when toggling between pre-defined timeframe or custom start and end times
   */
  const toggleTimeControl = () => {
    setIsCustomTime(!isCustomTime)
  }

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row">
          {!isCustomTime && (
            <FormControl
              style={{
                width: '190px',
                marginTop: '1rem',
              }}
              variant="outlined"
              size="small"
            >
              <InputLabel
                id="timeFrame-select-label"
                style={{
                  backgroundColor: 'white',
                  padding: '0 .5rem',
                }}
              >
                Quick-select
              </InputLabel>
              <Select
                labelId="timeFrame-select-label"
                id="timeFrame-select"
                value={timeFrame}
                onChange={handleTimeFrameChange}
                startAdornment={
                  <InputAdornment position="start">
                    <QueryBuilderIcon
                      color="primary"
                    />
                  </InputAdornment>
                }
              >
                <MenuItem value={Timeframe.Hour}>Last hour</MenuItem>
                <MenuItem value={Timeframe.Day}>Last 24h</MenuItem>
                <MenuItem value={Timeframe.Week}>Last week</MenuItem>
                <MenuItem value={Timeframe.Weeks2}>Last 2 weeks</MenuItem>
                <MenuItem value={Timeframe.Month}>Last month</MenuItem>
                <MenuItem value={Timeframe.Months3}>Last 3 months</MenuItem>
                <MenuItem value={Timeframe.Months6}>Last 6 months</MenuItem>
                <MenuItem value={Timeframe.Year}>Last year</MenuItem>
              </Select>
            </FormControl>
          )}
          {isCustomTime && (
            <Box mt={1}>
              <DateTimePicker
                style={{
                  width: '220px',
                  marginTop: '.5rem',
                  marginBottom: '.5rem',
                }}
                id="datetime-local-start"
                maxDate={controlEndTime}
                error={startTimeInputError}
                label="Custom Start"
                size="small"
                inputVariant="outlined"
                value={controlStartTime}
                onChange={(date) => handleStartChange(date)}
                InputLabelProps={{
                  shrink: true,
                }}
                showTodayButton
                helperText={startTimeInputValidationMsg}
              />
              <DateTimePicker
                style={{
                  width: '220px',
                  marginTop: '.5rem',
                  marginRight: '.5rem',
                }}
                id="datetime-local-end"
                minDate={controlStartTime}
                error={endTimeInputError}
                label="Custom End"
                size="small"
                inputVariant="outlined"
                value={controlEndTime}
                showTodayButton
                onChange={(date) => handleEndChange(date)}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={endTimeInputValidationMsg}
              />
            </Box>
          )}
        </Box>
        <Link
          type="button"
          color="primary"
          variant="body2"
          onClick={toggleTimeControl}
          style={{
            margin: '.5rem 0 0 0',
          }}
        >
          OR CHOOSE {isCustomTime ? 'FROM QUICK-SELECT TIMEFRAMES' : 'CUSTOM TIMEFRAME'}
        </Link>
      </Box>
    </>
  )
}
