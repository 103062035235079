import { Options } from 'material-table'

export const CustomerTablePageSizeOptions = [10, 20, 30]

export const customerTableOptions: Options<any> = {
  sorting: true,
  // Do not set this search option to true because we use a custom bar.
  search: false,
  paging: true,
  pageSize: 10,
  pageSizeOptions: CustomerTablePageSizeOptions,
  paginationType: 'normal',
  loadingType: 'overlay',
  emptyRowsWhenPaging: false,
  headerStyle: {
    fontWeight: 'bold',
  },
  actionsColumnIndex: -1,
  draggable: false,
  rowStyle: (_, rowIndex) => {
    if (rowIndex % 2) {
      return {
        backgroundColor: '#F7FBFD',
      }
    }
    return {
      backgroundColor: '#FFFFFF',
    }
  },
}

export const demoCustomerTableOptions: Options<any> = {
  sorting: false,
  search: false,
  paging: false,
  headerStyle: {
    fontWeight: 'bold',
  },
  actionsColumnIndex: -1,
  draggable: false,
  maxBodyHeight: 252,
  minBodyHeight: 224,
  rowStyle: (_, rowIndex) => {
    if (rowIndex % 2) {
      return {
        backgroundColor: '#F7FBFD',
      }
    }
    return {
      backgroundColor: '#FFFFFF',
    }
  },
}
