import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { ROUTE_PATHS } from 'routes/routes'
import { PersonalUseProgram } from 'features/how-to-buy'
import { PaymentForm } from 'features/how-to-buy'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import config from 'config'

const stripePromise = loadStripe(config.stripePublicKey)

export const HowToBuy = () => {
  return (
    <>
      {/* Descendant Routes, MUST USE RELATIVE PATHS TO MATCH THE ROUTES. */}
      <Routes>
        <Route 
          index 
          element={<Elements stripe={stripePromise}><PersonalUseProgram /></Elements>} />
        <Route 
          path={ROUTE_PATHS.howToBuy.personalUseProgram.relative} 
          element={<Elements stripe={stripePromise}><PersonalUseProgram /></Elements>} />
        <Route 
          path={ROUTE_PATHS.howToBuy.personalUseProgram.payment.relative} 
          element={<PaymentForm />} />
      </Routes>
    </>
  )
}
