import { useSearchParams } from 'react-router-dom';

function useDecodedSearchParams() {
  const [params] = useSearchParams()
  const decodedParams = new URLSearchParams()
  params.forEach((value, key) => {
    decodedParams.append(key, decodeURIComponent(value))
  })
  return [decodedParams]
}

export default useDecodedSearchParams
