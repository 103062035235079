import {
  Box, Button, createStyles, List, ListItem, ListItemText, makeStyles, Typography,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import React, { useState } from 'react'
import { ServiceCompany, ServiceCompanyRole } from 'state-mngt/models/serviceCompany'
import { theme } from 'theme'
import 'features/preferences/company/company-details/company-details.scss'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import AddIcon from '@material-ui/icons/Add'
import store from 'state-mngt/store'
import EditIcon from '@material-ui/icons/Edit'
import { CompanyLogoDialog } from 'features/preferences/company/company-logo-dialog'
import { userRoleNames } from 'utils/constants/member-enums'
import { certificationTooltip, roleTooltip } from 'utils/constants/tooltip-content'
import { useMixPanel } from 'features/analytics/mixpanel-provider'

const useStyles = makeStyles(() =>
  createStyles({
    formValue: {
    },
    certifiedBadge: {
      color: theme.palette.success.main,
    },
    logoField: {
      borderWidth: '1px',
      borderColor: theme.palette.secondary.main,
      borderStyle: 'dashed',
      borderRadius: '1rem',
      height: '100px',
      width: '100px',
    },
  }),
)

const CompanyDetails = (props: { companyDetails: ServiceCompany | undefined }) => {
  const classes = useStyles()
  const { mixpanel } = useMixPanel()
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const { companyDetails } = props
  const { role } = store.getState().user

  if (!companyDetails) {
    return (
      <Alert severity="error">
        <AlertTitle>Company details not available at this time</AlertTitle>
        Please try again later.
      </Alert>
    )
  }

  /**
   * Open logo edit modal
   */
  const handleLogoEdit = () => {
    if (mixpanel) {
      mixpanel.track('pp_companyPage_editLogoButton')
    }
    setOpenDialog(true)
  }

  /**
   * Returns the label for user role
   * @param role - ServiceCompanyRole
   */
  const roleLabel = (role: ServiceCompanyRole) => {
    let label = ''
    if (role.manager) {
      label = userRoleNames[0]
    } else if (role.technician) {
      label = userRoleNames[1]
    } else {
      label = userRoleNames[2]
    }
    return label
  }


  return (
    <Box display="flex" justifyContent="space-between">
      <List aria-label="Company detail list" className="company-list">
        <ListItem>
          <ListItemText className="company-form-label" primary="Service Company" />
          <span className={classes.formValue + ' company-form-value'}>{companyDetails?.name}</span>
        </ListItem>
        <ListItem>
          <ListItemText className="company-form-label" primary="Service Company Logo" />
          {!companyDetails.logo_url && role?.manager &&
            <ListItemText
              className={classes.formValue + ' company-form-value'}
              primary={
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  className={classes.formValue + ' ' + classes.logoField + ' company-form-value'}
                  id="company-logo_upload"
                  onClick={handleLogoEdit}
                >
                  <AddIcon color="secondary" />
                  <Typography color="secondary">Add Logo</Typography>
                </Box>
              }
            />
          }
          {!companyDetails.logo_url && !role?.manager &&
            <ListItemText
              className={classes.formValue + ' company-form-value'}
              primary={
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  className={classes.formValue + ' ' + classes.logoField + ' company-form-value'}
                >
                  <Typography
                    color="secondary"
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    No Logo uploaded
                  </Typography>
                </Box>
              }
            />
          }
          {companyDetails.logo_url &&
            <>
              <ListItemText
                className={classes.formValue + ' company-form-value'}
                primary={
                  <Box
                    id="company-logo-display"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    className={classes.formValue + ' ' + classes.logoField + ' company-form-value'}
                  >
                    <img alt="company logo" src={companyDetails.logo_url} key={Date.now().toString()} />
                  </Box>
                }
              />
              {role?.manager &&
                <Button
                  id="logo_edit"
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={handleLogoEdit}
                  style={{
                    bottom: 0,
                    right: 0,
                    left: 10,
                    position: 'relative',
                  }}
                >
                  <EditIcon fontSize="small"/>
                </Button>
              }
            </>
          }
          {companyDetails &&
            <CompanyLogoDialog open={openDialog} companyDetails={companyDetails} setOpen={setOpenDialog} />
          }
        </ListItem>
        <ListItem>
          <ListItemText className="company-form-label" primary="Address Line 1" />
          <ListItemText className={classes.formValue + ' company-form-value'} primary={companyDetails?.street_1} />
        </ListItem>
        <ListItem>
          <ListItemText className="company-form-label" primary="Address Line 2" />
          <ListItemText className={classes.formValue + ' company-form-value'} primary={companyDetails?.street_2} />
        </ListItem>
        <ListItem>
          <ListItemText className="company-form-label" primary="City" />
          <ListItemText className={classes.formValue + ' company-form-value'} primary={companyDetails?.city} />
        </ListItem>
        <ListItem>
          <ListItemText className="company-form-label" primary="State/Province" />
          <ListItemText className={classes.formValue + ' company-form-value'} primary={companyDetails?.province} />
        </ListItem>
        <ListItem>
          <ListItemText className="company-form-label" primary="Country" />
          <ListItemText className={classes.formValue + ' company-form-value'} primary={companyDetails?.country} />
        </ListItem>
        <ListItem>
          <ListItemText className="company-form-label" primary="Zip / Postal Code" />
          <ListItemText className={classes.formValue + ' company-form-value'} primary={companyDetails?.postal_code} />
        </ListItem>
        <ListItem>
          <ListItemText className="company-form-label" primary="Office Email" />
          <ListItemText className={classes.formValue + ' company-form-value'} primary={companyDetails?.email} />
        </ListItem>
        <ListItem>
          <ListItemText className="company-form-label" primary="Office Phone" />
          <ListItemText className={classes.formValue + ' company-form-value'} primary={companyDetails?.phone} />
        </ListItem>
        <ListItem>
          <ListItemText
            className="company-form-label"
            primary={
              <>
                <span>Certification Status</span>
                {certificationTooltip}
              </>
            }
          />
          <ListItemText
            className={classes.formValue + ' company-form-value'}
            primary={
              <Box display="flex">
                <span style={{
                  marginTop: '.25rem',
                }}>Certified&nbsp;&nbsp;</span>
                <CheckCircleIcon className={classes.certifiedBadge} />
              </Box>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            className="company-form-label"
            primary={
              <>
                <span>My Role</span>
                {roleTooltip}
              </>
            }
          />
          <ListItemText className={classes.formValue + ' company-form-value'} primary={roleLabel(role!)} />
        </ListItem>
      </List>
    </Box>
  )
}

export default CompanyDetails
