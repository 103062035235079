import { useEffect } from "react"
import {
  Automation,
  AutomationInput,
  AutomationLog,
  AutomationOutput,
  AutomationPillar,
  AutomationResponse,
  AutomationRule,
  AutomationScheduleRule,
  AutomationTrigger,
  Device,
  Equipment,
} from "types"
import { create } from "zustand"
import { dedupe } from "./useGetData"

export type EquipmentStore = { [key: number]: Equipment }
export type AutomationStore = { [key: number]: Automation }
export type RulesStore = { [key: number]: AutomationRule }
export type LogsStore = { [key: number]: AutomationLog }
export type DevicesStore = { [key: number]: Device }
export type DwellingAutomationsStore = { [dwellingId: number]: number[] }

const tempKey = (id: string | number) => `_temp_${id}`

const useEquipmentStore = create<EquipmentStore>(set => ({}))
const useRulesStore = create<RulesStore>(set => ({}))
const useAutomationsStore = create<AutomationStore>(set => ({}))
const useDwellingAutomationsStore = create<DwellingAutomationsStore | null>(set => null)
const useLogsStore = create<LogsStore | null>(set => null)
const useDevicesStore = create<DevicesStore | null>(set => null)

const toDict = (arr: any[]) => arr.reduce((prev, curr) => ({
  ...prev,
  [curr.id]: curr,
}), {})

/**
 * @deprecated
 * can be removed - temp rules are created each time automations
 * are synced with rules. see useSyncAutomationWithRules hook
 */
const useInitTemporaryRule = (ruleId: number) => {
  useEffect(() => {
    if (!ruleId) return

    const create = () => {
      const tempRule = useRulesStore.getState()[tempKey(ruleId)]
      if (tempRule) return

      useRulesStore.setState(prev => ({
        ...prev,
        [tempKey(ruleId)]: prev[ruleId],
      }))
    }

    create()
  }, [ruleId])
}

const setTemporaryRuleInput = (ruleId: number, inputId: number, data) => {
  const key = tempKey(ruleId)
  useRulesStore.setState(prev => ({
    ...prev,
    [key]: {
      ...prev[key],
      inputs: prev[key].inputs.map(x => {
        if (x.id === inputId) {
          return {
            ...x,
            ...data,
            touched: true,
          }
        }
        return x
      }),
    },
  }))
}

/**
 * shallow merge an update only to a temp rule.
 * used to maintain canonical rule state so a
 * user can revert changes
 */
const setTemporaryRule = (ruleId: number, data: Partial<AutomationRule>) => {
  const key = tempKey(ruleId)
  useRulesStore.setState(prev => ({
    ...prev,
    [key]: {
      ...(prev[key] || {}),
      ...data,
    },
  }))
}

/**
 * set both the canonical rule and the
 * temp rule simultaneously. apply the
 * same update to both.
 */
const setTemporaryAndCanonRule = (ruleId: number, data: Partial<AutomationRule>) => {
  const _tempKey = tempKey(ruleId)
  useRulesStore.setState(prev => ({
    ...prev,
    [ruleId]: {
      ...(prev[ruleId] || {}),
      ...data,
    },
    [_tempKey]: {
      ...(prev[_tempKey] || {}),
      ...data,
    },
  }))

}

const resetTemporaryRule = (ruleId) => {
  const tempKey = `_temp_${ruleId}`
  useRulesStore.setState(prev => ({
    ...prev,
    [tempKey]: prev[ruleId],
  }))
}

// deletes canon and temp rules
const deleteRule = (ruleId: number) => {
  useRulesStore.setState(prevState => {
    return Object.fromEntries(
      Object.entries(prevState)
        .filter(([x, y]) =>
          (parseInt(x) !== ruleId))
        .filter(([x, y]) =>
          (x !== tempKey(ruleId)))
    )

    // const update = Object.fromEntries(
    //   Object.entries(prevState)
    //     .filter(([x, y]) =>
    //       (parseInt(x) !== ruleId))
    //     .filter(([x, y]) =>
    //       (x !== tempKey(ruleId)))
    // )

    // return update
  }, true)
}

/**
 * copies the contents of a temporary rule
 * into the canon rule state.
 */
const persistTemporaryRule = (ruleId) => {
  const tempKey = `_temp_${ruleId}`
  useRulesStore.setState(prev => ({
    ...prev,
    [ruleId]: prev[tempKey],
  }))
}

const selectAutomation = (id: number) => (store: AutomationStore) => store[id]

const selectAutomationByZone = (dwellingId?: number) => (zone: string) =>
  (store: AutomationStore) =>
    (dwellingId && zone) ?
      Object.values(store)
        .filter(automation =>
          (automation.zone.toLowerCase() === zone.toLowerCase()) &&
          automation.dwelling_id === dwellingId)[0] :
      null

const selectEquipmentAutomations = (dwellingId?: number, zone?: string) => (store: AutomationStore) =>
  (dwellingId && zone) ?
    Object.values(store)
      .filter(automation =>
        (automation.zone.toLowerCase() === zone.toLowerCase()) &&
        (automation.outputs.some(x => x.type === 'cac')) &&
        automation.dwelling_id === dwellingId) :
    null

const selectFirstEquipmentAutomation = (dwellingId?: number, zone?: string) => (store: AutomationStore) =>
  (dwellingId && zone) ?
    Object.values(store)
      .filter(automation =>
        (automation.zone.toLowerCase() === zone.toLowerCase()) &&
        (automation.outputs.some(x => x.type === 'cac')) &&
        automation.dwelling_id === dwellingId)[0] :
    null

const selectOutputsByIds = (outputIds: number[]) => (store: AutomationStore) =>
  outputIds?.length ?
    outputIds.map(id =>
      Object.values(store)
        .flatMap(automation => automation.outputs)
        .find(output => output.id === id)) :
    []

const selectAllOutputs = (store: AutomationStore) =>
  Object.values(store).flatMap(automation => automation.outputs)

const selectAllEquipmentAutomationOutputs = (dwellingId: number) => (store: AutomationStore) =>
  Object.values(store)
    .filter(x => x.dwelling_id === dwellingId)
    .filter(x => isEquipmentAutomation(x.outputs))
    .flatMap(x => x.outputs)

const selectEmailAutomations = (store: AutomationStore): AutomationStore =>
  toDict(Object.values(store).filter(x => x.outputs.some(y => y.type === 'email')))

// add a new automation or update an existing
const setAutomation = (automationId: number, data: Partial<Automation | AutomationResponse> = {}) =>
  useAutomationsStore.setState(prev => ({
    ...prev,
    [automationId]: {
      // @ts-ignore
      id: automationId,
      ...(prev[automationId] || {}),
      ...data,
    },
  }))

// replace automation data
const overwriteAutomation = (automationId: number, data: Automation | AutomationResponse) =>
  useAutomationsStore.setState(prev => ({
    ...prev,
    [automationId]: {
      // @ts-ignore
      id: automationId,
      ...data,
    },
  }))

// create or overwrite automations from a list
const setAutomations = (automations: { [key: number]: Automation }) =>
  useAutomationsStore.setState(prev => ({
    ...prev,
    ...automations,
  }))

const selectEmailRules = (dwellingId: number, zone: string) => (store: RulesStore): RulesStore | null => {
  const filteredRules = toDict(Object.keys(store)
    .filter(key => !key.includes('_temp_'))
    .filter(key => store[key]?.dwellingId === dwellingId)
    .filter(key => store[key]?.zone.toLowerCase().trim() === zone.toLowerCase().trim())
    .filter(key => store[key]?.outputs.some(output => output.type === 'email'))
    .map(key => store[key])
  )
  return (dwellingId && zone) ?
    filteredRules : null
}

const selectInput = (ruleId, inputId) => (store: RulesStore) =>
  store[ruleId]?.inputs.find(x => x.id === inputId)

const selectTempInput = <T = AutomationInput>(ruleId, inputId) => (store: RulesStore) => {
  const input = store[tempKey(ruleId)]?.inputs.find(x => x.id === inputId)
  if (!input) console.warn(`[selectTempInput] No temporary input. Did you forget to create it? ${ruleId}`)
  return input as T
}

const selectTempRule = (ruleId: number) => (store: RulesStore): AutomationRule => {
  return store[tempKey(ruleId)]
}

const selectOutputsByRule = (ruleId) => (store: RulesStore) => store[ruleId]?.outputs
const selectEquipmentById = (id: number) => (store: EquipmentStore) => store[id]
const selectEquipmentByZone = ({ dwellingId, zone }) => (store: EquipmentStore) =>
  Object.values(store)
    .filter(x => x.dwelling_id === dwellingId)
    .filter(x => x.zone === zone)

const isEquipmentAutomation = (outputs: AutomationOutput[]) => outputs.some(x => x.type.toLowerCase() === 'cac')
const isSchedule = (inputs: AutomationInput[]) => inputs.some(x => x.type === 'schedule')
const isEnabled = (trigger: AutomationTrigger) => trigger.enabled

const selectRulesByIds = (rules: string[]) => (store: RulesStore) =>
  Object.keys(store)
    .filter(key => rules.includes(key))
    .filter(key => isEnabled(store[key].trigger))
    .reduce((prev, curr) => ({
      ...prev,
      [curr]: store[curr],
    }), {} as RulesStore)

const selectRulesByAutomationIds = (automationIds: number[]) => (store: RulesStore) =>
  Object.keys(store)
    .filter(key => automationIds.includes(store[key]?.automationId))
    .filter(key => isEnabled(store[key]?.trigger))
    .reduce((prev, curr) => ({
      ...prev,
      [curr]: store[curr],
    }), {} as RulesStore)

const selectEquipmentRulesByPillar = (dwellingId: number) =>
  (pillar: AutomationPillar) =>
    (store: RulesStore): AutomationRule[] =>
      Object.keys(store)
        .filter(key => !key.includes('_temp_'))
        .filter(key => store[key].dwellingId === dwellingId)
        .filter(key => isEquipmentAutomation(store[key].outputs))
        .filter(key => !isSchedule(store[key].inputs))
        .filter(key => store[key].trigger[pillar])
        .filter(key => store[key].trigger.enabled)
        .map(key => store[key])

const selectPillar = (dwellingId: number) => (pillar: AutomationPillar) => (store: RulesStore): AutomationRule[] =>
  Object.keys(store)
    .filter(key => !key.includes('_temp_'))
    .filter(key => store[key].dwellingId === dwellingId)
    .filter(key => !isSchedule(store[key].inputs))
    .filter(key => isEnabled(store[key].trigger))
    .filter(key => store[key].trigger[pillar])
    .map(key => store[key])

const selectScheduleRules = (dwellingId: number, zone: string) => (store: RulesStore): AutomationScheduleRule[] =>
  Object.keys(store)
    .filter(key => !key.includes('_temp_'))
    .filter(key => store[key].dwellingId === dwellingId)
    .filter(key => store[key].zone.toLowerCase() === zone.toLowerCase())
    .filter(key => isSchedule(store[key].inputs))
    .filter(key => isEnabled(store[key].trigger))
    .map(key => store[key])

const selectTempScheduleRules = (dwellingId: number, zone: string) => (store: RulesStore): AutomationScheduleRule[] =>
  Object.keys(store)
    .filter(key => key.includes('_temp_'))
    .filter(key => store[key].dwellingId === dwellingId)
    .filter(key => store[key].zone.toLowerCase() === zone.toLowerCase())
    .filter(key => isSchedule(store[key].inputs))
    .filter(key => isEnabled(store[key].trigger))
    .map(key => store[key])

const selectTemplate = (automationId: number) => (store: AutomationStore) => {
  return store[automationId]?.template
}

const genericPluck = <T>(ids: number[]) => (store: { [key: number]: T }) => {
  return Object.keys(store)
    .filter(x => ids.includes(parseInt(x)))
    .reduce((prev, curr) => ({
      ...prev, [curr]: store[curr],
    }), {} as { [key: number]: T })
}

const selectEquipments = (equipmentIds: number[]) => (store: EquipmentStore): EquipmentStore => {
  return Object.keys(store)
    .filter(x => equipmentIds.includes(parseInt(x)))
    .reduce((prev, curr) => ({
      ...prev, [curr]: store[curr],
    }), {})
}

const selectDevices = (deviceIds: number[]) => (store: DevicesStore | null): DevicesStore => {
  return Object.keys(store || {})
    .filter(x => deviceIds.includes(parseInt(x)))
    .reduce((prev, curr) => ({
      ...prev, [curr]: store?.[curr],
    }), {})
}

const selectDevicesByZone = (dwellingId: number) => (zone: string) => (store: DevicesStore | null): DevicesStore => {
  if (!zone || !store) return {}
  return Object.keys(store || {})
    .filter(key => parseInt(store[key].dwelling_id) === dwellingId)
    .filter(key => store[key].zone.toLowerCase() === zone.toLowerCase())
    .reduce((prev, curr) => ({
      ...prev, [curr]: store?.[curr],
    }), {})
}

const selectMonitorByZone = (dwellingId: number) => (zone: string) => (store: DevicesStore | null) => {
  if (!zone || !store) return null
  return Object
    .values(store || {})
    .filter(device => device.dwelling_id === dwellingId)
    .filter(device => device.zone.toLowerCase() === zone.toLowerCase())
    .find(x => x.type === 'cam')
}

const selectAllZones = (dwellingId: number) => (store: DevicesStore | null) => {
  if (!dwellingId) return []
  if (!store) return []
  return dedupe(Object.values(store).filter(x => x.dwelling_id === dwellingId).map(device => device.zone))
}

const selectLogs = (logIds: number[]) => (store: LogsStore | null): LogsStore => {
  return Object.keys(store || {})
    .filter(x => logIds.includes(parseInt(x)))
    .reduce((prev, curr) => ({
      ...prev, [curr]: store?.[curr],
    }), {})
}

const selectLogsByAutomationId = (automationId: number) => (store: LogsStore | null): AutomationLog[] => {
  if (!store) return []
  return Object.values(store)
    .filter(log => parseInt(log.automation_id) === automationId)
}

const selectLogsByOutputIds = (outputIds: number[]) => (store: LogsStore | null): AutomationLog[] => {
  if (!store) return []
  return Object.values(store)
    .filter(log => log.rules.some(rule => outputIds.some(id => rule.outputs.includes(id))))
}

const selectAutomationsByDwellingId = (dwellingId?: number) => (store: AutomationStore) => {
  if (!dwellingId) return []
  return Object.values(store).filter(x => x.dwelling_id === dwellingId)
}

const selectRule = (ruleId: number | string) => (store: RulesStore): AutomationRule => {
  return store[ruleId]
}

const selectAutomationZone = (automationId: number) => (store: AutomationStore) => {
  if (!automationId) return ''
  return store[automationId]?.zone
}

const selectInterlocks = (dwellingId: number) => (store: AutomationStore) => {
  return Object.values(store)
    .filter(x => x.dwelling_id === dwellingId)
    .filter(x => isEquipmentAutomation(x.outputs))
    .flatMap(x => x.interlocks)
}

const formatForStore = <T extends { id: number }>(data: T[]) =>
  data.reduce((prev, curr) => ({
    ...prev,
    [curr.id]: curr,
  }), {} as { [key: number]: T })

const updateAutomation = automationId => (data: Partial<Automation>) => useAutomationsStore.setState(prev => ({
  ...prev,
  [automationId]: {
    ...prev[automationId],
    ...data,
  },
}))

/**
 * update a canon rule and optionally
 * apply the same update to the temp rule
 */
const updateRule = (ruleId: number) =>
  ({ data, syncTempRule = false }: { data: Partial<AutomationRule>, syncTempRule: boolean }) =>
    useRulesStore.setState(store => ({
      ...store,
      [ruleId]: { ...store[ruleId], ...data },
      ...(syncTempRule ? {
        [tempKey(ruleId)]: { ...store[ruleId], ...data }
      } : {})
    }))

const updateRuleOutputs = ruleId => (data: Partial<AutomationOutput>) => useRulesStore.setState(prev => ({
  ...prev,
  [ruleId]: {
    ...prev[ruleId],
    outputs: prev[ruleId].outputs.map(x => ({
      ...x,
      ...data,
    })),
  },
  [tempKey(ruleId)]: {
    ...prev[tempKey(ruleId)],
    outputs: prev[tempKey(ruleId)].outputs.map(x => ({
      ...x,
      ...data,
    })),
  },
}))

const setEquipment = (equipment: { [key: number]: Equipment }) =>
  useEquipmentStore.setState(prev => ({ ...prev, ...equipment }))

export {
  toDict,
  tempKey,
  useEquipmentStore,
  useRulesStore,
  useAutomationsStore,
  useDwellingAutomationsStore,
  useLogsStore,
  useDevicesStore,
  useInitTemporaryRule,
  persistTemporaryRule,
  resetTemporaryRule,
  setTemporaryRuleInput,
  setTemporaryRule,
  setTemporaryAndCanonRule,
  deleteRule,
  setAutomation,
  setAutomations,
  setEquipment,
  overwriteAutomation,
  selectAutomation,
  selectAutomationsByDwellingId,
  selectOutputsByIds,
  selectAllOutputs,
  selectInput,
  selectTempInput,
  selectTempRule,
  selectScheduleRules,
  selectTempScheduleRules,
  selectEquipmentById,
  selectEquipmentByZone,
  selectOutputsByRule,
  selectEmailAutomations,
  selectPillar,
  selectTemplate,
  selectEquipments,
  selectRule,
  selectRulesByIds,
  selectEmailRules,
  selectAutomationZone,
  selectDevices,
  selectDevicesByZone,
  selectLogs,
  selectLogsByAutomationId,
  selectLogsByOutputIds,
  selectRulesByAutomationIds,
  selectMonitorByZone,
  selectAllZones,
  selectAutomationByZone,
  selectEquipmentRulesByPillar,
  selectEquipmentAutomations,
  selectInterlocks,
  selectAllEquipmentAutomationOutputs,
  selectFirstEquipmentAutomation,
  formatForStore,
  updateAutomation,
  updateRuleOutputs,
  updateRule,
  genericPluck,
}
