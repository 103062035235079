import { Country, provinceOptions, stateOptions } from 'utils/constants/province-lookup-options'

/**
 * remove 'http://' from the website entered
 * @param str - string
 * @returns string
 */
export const removeHttp = (str: string) => {
  if(str === ''){
    return ''
  }

  let trimmed = ''

  if(str.endsWith('/')){
    trimmed = str.slice(0, str.length - 1)
  } else {
    trimmed = str
  }

  if(trimmed.startsWith('http://')){
    return trimmed.substr(7, str.length)
  } else if (trimmed.startsWith('https://')){
    return trimmed.substr(8, str.length)
  } else {
    return trimmed
  }
}

/**
 * return labels for the option selected
 * @param options - {value: string, label: string}[]
 * @param valueArr - string[]
 */
export  const getSelectedLabel = (options: {value: string, label: string}[], valueArr: string[]) => {
  const filteredOptions =  options.filter(o => valueArr.includes(o.value))
  return filteredOptions.map((option, i) => i !== valueArr.length - 1 ? option.label + ', ' : option.label)
}


/**
 * Change province/state options based on 'country' selection
 * @param countryParam - string
 */
export const selectOnboardingStateOptions = (countryParam: string): string[] => {
  if (!countryParam) {
    return []
  }

  if (countryParam === Country.CANADA) {
    return provinceOptions.filter(a => a.label !== 'Select').map(p => p.label)
  }
  if (countryParam === Country.AMERICA) {
    return stateOptions.filter(a => a.label !== 'Select').map(s => s.label)
  }
  return []
}
