import React from 'react'
import {
  Box, Button, Grid, Typography,
} from '@material-ui/core'
import { HavenLinks } from 'utils/constants/resources/links'
import { usePageTitle } from 'utils/hooks/router'


/**
 * Error page that is displayed whenever a user try to join a service company that does not exist.
 * @constructor
 */
export const ServiceCompanyDoesNotExist = () => {
  usePageTitle('Service Company Does Not Exist')

  return(
    <>
      <Grid container className="home-page">
        <Grid item sm={2} />
        <Grid item sm={3}>
          <Box display="flex" alignItems="center" height="100%">
            <Box>
              <Typography variant="h1" gutterBottom>
                The service company you are trying to join does not exist.
              </Typography>
              <Box mt={3}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                >
                  Create New Service Company
                </Button>
              </Box>
              <Box mt={2}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  href={ HavenLinks.MEMBERSHIP_SUPPORT }
                >
                  Contact Customer Support
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item sm={7} />
      </Grid>
    </>
  )
}
