import { AutomationTrigger, Measurement } from "types"
import { hourToSecond, tempId } from "../util"

const thresholds = {
    pmGoodToFair: 11.7,
    pmFairToGood: 10,
    pmFairToPoor: 33.2,
    pmPoorToFair: 30,
    vocGoodToFair: 212,
    vocFairToGood: 200,
    vocFairToPoor: 850,
    vocPoorToFair: 800,
}

const defaultSetPoints = (measurementType: Measurement, setPointUnit: 'fahrenheit' | 'celcius' = 'celcius') => {
    let start_set_point
    let stop_set_point

    if (measurementType === 'pm25') {
        start_set_point = thresholds.pmGoodToFair
        stop_set_point = thresholds.pmFairToGood
    }

    if (measurementType === 'voc') {
        start_set_point = thresholds.vocGoodToFair
        stop_set_point = thresholds.vocFairToGood
    }

    if (measurementType === 'humidity') {
        start_set_point = 50
        stop_set_point = 0
    }

    if (measurementType === 'dew_point') {
        if (setPointUnit === 'fahrenheit') {
            start_set_point = 52
        } else {
            start_set_point = 11
        }
        stop_set_point = 0
    }

    return {
        start_set_point,
        stop_set_point,
    }
}

const defaultOutdoorAirInput = () => {
    return {
        id: tempId(),
        type: 'outdoor_air',
        device_id: null,
        currently_triggered: false,
        detection_duration: hourToSecond(12),
        measurement: 'pm25' as const,
        rising: true,
        ...defaultSetPoints('pm25'),
    }
}

const defaultFiltration = (output) => {
    const inputId = tempId()
    const ruleId = tempId()

    const inputs = [{
        id: inputId,
        type: 'cam_threshold' as const,
        device_id: null,
        currently_triggered: false,
        detection_duration: hourToSecond(12),
        measurement: 'pm25' as const,
        rising: true,
        ...defaultSetPoints('pm25')
    }]

    const outputs = [output]

    const rules = [{
        id: ruleId,
        enabled: false,
        inputs: [inputId],
        outputs: [output.id],
        filtration: true,
        ventilation: false,
        humidity: false,
    }]

    return { inputs, rules, outputs }
}

const defaultAutomation = ({ dwelling_id, zone }) => ({
    id: tempId(),
    dwelling_id,
    status: '',
    active: true,
    template: 'none' as const,
    create_timestamp: '',
    // filtration_override_on: false,
    // filtration_override_timeout: null,
    // ventilation_override_on: false,
    // ventilation_override_timeout: null,
    // humidity_override_on: false,
    // humidity_override_timeout: null,
    zone,
    outputs: [],
    inputs: [],
    rules: [] as AutomationTrigger[],
    interlocks: [],
})

const defaultVentilation = (output) => {
    const inputId = tempId()
    const ruleId = tempId()

    const outdoorAirInput = defaultOutdoorAirInput()

    const inputs = [
        outdoorAirInput,
        {
            id: inputId,
            type: 'cam_threshold' as const,
            device_id: null,
            rising: true,
            currently_triggered: false,
            detection_duration: hourToSecond(4),
            measurement: 'voc' as const,
            ...defaultSetPoints('voc'),
        }]

    const outputs = [output]

    const rules = [{
        id: ruleId,
        enabled: false,
        inputs: [inputId],
        outputs: [output.id],
        filtration: false,
        ventilation: true,
        humidity: false,
    }]

    return { inputs, outputs, rules }
}

export type DefaultAutomation = ReturnType<typeof defaultVentilation>

const defaultHighHumidity = (output) => {
    const inputId = tempId()
    const ruleId = tempId()

    const inputs = [{
        id: inputId,
        type: 'cam_threshold',
        device_id: null,
        rising: true,
        currently_triggered: false,
        detection_duration: hourToSecond(4),
        measurement: 'dew_point',
        ...defaultSetPoints('dew_point'),
    }]

    const outputs = [{}]

    const rules: AutomationTrigger[] = [{
        id: ruleId,
        enabled: false,
        inputs: [inputId],
        outputs: [output.id],
        filtration: false,
        ventilation: false,
        humidity: true,
        override_on: false,
        override_timeout: '',
    }]

    return { inputs, outputs, rules }
}

const defaultLowHumidity = (output) => {
    const inputId = tempId()
    const ruleId = tempId()

    const inputs = [{
        id: inputId,
        type: 'cam_threshold' as const,
        device_id: null,
        rising: false,
        currently_triggered: false,
        detection_duration: hourToSecond(4),
        measurement: 'dew_point' as const,
        ...defaultSetPoints('dew_point'),
    }]

    const outputs = [{}]

    const rules: AutomationTrigger[] = [{
        id: ruleId,
        enabled: false,
        inputs: [inputId],
        outputs: [output.id],
        filtration: false,
        ventilation: false,
        humidity: true,
        override_on: false,
        override_timeout: '',
    }]

    return { inputs, outputs, rules }
}

const brands = [
    'Aldes',
    'Amana',
    'American Standard',
    'Aprilaire',
    'Bosch',
    'Broan-Nutone',
    'Bryant',
    'Carrier',
    'Coleman',
    'Daikin',
    'Fantech',
    'Fujitsu',
    'Goodman',
    'Greenheck',
    'Heil',
    'Hitachi',
    'Honeywell',
    'Keeprite',
    'Lennox',
    'LG',
    'Lifebreath',
    'Midea',
    'Mitsubishi Electric',
    'Napoleon',
    'Nortek (+ Frigidaire, Gibson)',
    'Panasonic',
    'Pioneer',
    'Renewaire',
    'Rheem',
    'Ruud',
    'Samsung',
    'Sanyo',
    'Santa Fe',
    'Sharp',
    'SystemAir',
    'S&P Advancing Ventilation',
    'Tappan',
    'Tempstar',
    'Toshiba',
    'Trane',
    'Ultra-Aire',
    'United Enertech',
    'Venmar',
    'Whirlpool',
    'York (Johnson Controls)',
    'Zehnder',
]

export {
    defaultOutdoorAirInput,
    defaultFiltration,
    defaultVentilation,
    defaultHighHumidity,
    defaultLowHumidity,
    defaultAutomation,
    brands,
}
