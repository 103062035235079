/**
 * Enum for field names of type data returned from API
 */
export enum TelemetryDataType {
  PM = 'pm_mc',
  VOC = 'voc_mc',
  INDOOR_DEW_POINT = 'indoor_dew_point',
  HUMIDITY = 'humidity', //indoor RH
  TEMPERATURE = 'temperature', // indoor temp
  AIRFLOW = 'airflow',
  OUTDOOR_AQI = 'aqi',
  OUTDOOR_DEW_POINT = 'dew_point',
  OUTDOOR_HUMIDITY = 'outdoor_humidity', // outdoor RH 
  OUTDOOR_TEMPERATURE = 'outdoor_temperature',
  ECOSENSE_RADON = 'ecosense_radon',
}

export interface TypeSelection {
  type: TelemetryDataType | string;
  label: string;
}

export const dataTypeSelections: TypeSelection[] = [{
  type: TelemetryDataType.PM,
  label: 'PM2.5',
}, {
  type: TelemetryDataType.VOC,
  label: 'tVOC',
}, {
  type: TelemetryDataType.INDOOR_DEW_POINT,
  label: 'Indoor Dew Point',
}, {
  type: TelemetryDataType.HUMIDITY,
  label: 'Indoor RH', // Relative Humidity
}, {
  type: TelemetryDataType.TEMPERATURE,
  label: 'Indoor Temperature',
}, {
  type: TelemetryDataType.OUTDOOR_AQI,
  label: 'Outdoor AQI',
}, {
  type: TelemetryDataType.OUTDOOR_DEW_POINT,
  label: 'Outdoor Dew Point',
}, {
  type: TelemetryDataType.OUTDOOR_HUMIDITY,
  label: 'Outdoor RH',
}, {
  type: TelemetryDataType.OUTDOOR_TEMPERATURE,
  label: 'Outdoor Temperature',
}]
