import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Button,
  Chip,
  makeStyles,
  Paper,
  Divider,
} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email'
import ClearIcon from '@material-ui/icons/Clear'
import { TeamMember } from 'state-mngt/models/user'
import { MEMBER_TEAM_NAMES } from 'utils/constants/member-enums'
import { useMixPanel } from 'features/analytics/mixpanel-provider'

const useStyles = makeStyles((theme) => ({
  paper: {
    color: 'inherit',
    padding: theme.spacing(2),
  },
  infoPaper: {
    color: 'inherit',
  },
  list: {
    listStyle: 'disc',
    paddingLeft: 24,
    margin: 0,
  },
  member: {
    '&:not(:last-child)': {
      borderBottom: '1px solid #E0E0E0',
    },
    '&:nth-child(even)': {
      backgroundColor: '#F6FBFE',
    },
  },
  email: {
    width: '100%',
    wordBreak: 'break-all',
    whiteSpace: 'pre-wrap',
  },
}))

interface Props {
  type: 'resend' | 'send' | 'remove'
  items: TeamMember[];
  onCancel: () => void,
  onConfirm: (members: TeamMember[]) => void;
}

const TeamConfirmationDialog: React.FC<Props> = ({
  type, items, onCancel, onConfirm,
}) => {
  const classes = useStyles()
  const { mixpanel } = useMixPanel()
  const [isSaving, setSaving] = useState(false)
  const texts = {
    title: 'Resend invitation?',
    subtitle: 'This will serve as an invitation reminder for your new team-mngt member.',
    cancelText: 'Cancel',
    loading: 'Sending...',
    confirmText: 'Yes, Resend invitation!',
    confirmIcon: <EmailIcon />,
  }
  if (type === 'remove') {
    texts.title = 'Remove member?'
    texts.subtitle = ''
    texts.loading = 'Removing...'
    texts.confirmText = 'Remove member'
    texts.confirmIcon = <ClearIcon />
  } else if (type === 'send') {
    const isMultiple = items.length > 1
    texts.title = `Nice! You’re about to invite new member${isMultiple ? 's' : ''}.`
    if (isMultiple) {
      texts.subtitle = 'Please confirm that the emails and teams below are correct:'
    } else {
      texts.subtitle = 'Please confirm that the email and team-mngt below are correct:'
    }
    texts.loading = 'Sending...'
    texts.cancelText = 'Go back & edit'
    texts.confirmText = `Send invitation${isMultiple ? 's' : ''}!`
  }

  return (
    <Dialog
      maxWidth="md"
      aria-labelledby="Resend invitation"
      classes={{
        paper: classes.paper,
      }}
      open
    >
      <DialogTitle disableTypography	>
        <Typography variant="h6">{texts.title}</Typography>
        <Typography variant="body1">
          {texts.subtitle && texts.subtitle}
          {type === 'remove' && (
            <>
              This will remove them from your HAVEN Pro Service Company. They will no longer be able to:
              <ul className={classes.list}>
                <li>View your company’s HAVEN data OR</li>
                <li>Install HAVEN devices on behalf of your company.</li>
              </ul>
            </>
          )}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Paper
          variant="outlined"
          classes={{
            root: classes.infoPaper,
          }}
        >
          {items.map(({ email, team }, index) => (
            <Box display="flex" key={index} className={classes.member}>
              <Box px={2} py={1} flex="calc(100% - 184px)">
                <Typography
                  variant="h6"
                  classes={{
                    root: classes.email,
                  }}
                >
                  {email}
                </Typography>
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box px={2} py={1} width={180}>
                <Chip
                  color="secondary"
                  label={`${MEMBER_TEAM_NAMES[team]} team`}
                />
              </Box>
            </Box>
          ))}
        </Paper>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" width="100%" p={2}>
          <Box width="calc(50% - 12px)">
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                if(mixpanel) {
                  if (type == 'send') {
                    mixpanel.track('pp_teamMgmtPage_sendInviteDialog_cancel')
                  } else if (type === 'resend') {
                    mixpanel.track('pp_teamMgmtPage_resendInviteDialog_cancel')
                  } else if (type === 'remove') {
                    mixpanel.track('pp_teamMgmtPage_removeMemberDialog_cancel')
                  } else  {
                    mixpanel.track('pp_teamMgmtPage_dialog_cancel')
                  }
                }
                onCancel()
              }}
              fullWidth
              disabled={isSaving}
            >
              {texts.cancelText}
            </Button>
          </Box>
          <Box width="calc(50% - 12px)">
            <Button
              variant="contained"
              color="primary"
              endIcon={texts.confirmIcon}
              onClick={() => {
                if(mixpanel) {
                  if (type == 'send') {
                    mixpanel.track('pp_teamMgmtPage_sendInviteDialog_confirm')
                  } else if (type === 'resend') {
                    mixpanel.track('pp_teamMgmtPage_resendInviteDialog_confirm')
                  } else if (type === 'remove') {
                    mixpanel.track('pp_teamMgmtPage_removeMemberDialog_confirm')
                  } else  {
                    mixpanel.track('pp_teamMgmtPage_dialog_confirm')
                  }
                }
                setSaving(true)
                onConfirm(items)
              }}
              disabled={isSaving}
              fullWidth
            >
              {isSaving ? texts.loading : texts.confirmText}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default TeamConfirmationDialog
