import {
  Box, Divider, Grid, GridSpacing, makeStyles, 
} from "@material-ui/core"
import React from "react"

const useClasses = makeStyles(theme => ({
  divider: {
    marginTop: 16,
    marginBottom: 0,
    backgroundColor: theme.palette.charcoalLightTint,
  },
}))

const Stack = ({
  children,
  spacing = 2 as GridSpacing,
  direction = 'column' as 'column' | 'row',
  align = 'center' as 'center' | 'flex-end' | 'flex-start',
  justify = 'space-between' as 'center' | 'space-between' | 'flex-start',
  wrap = 'wrap' as 'nowrap' | 'wrap',
  divider = false,
  itemStyles = [{
  }],
  style = {
  },
  ...rest
}) => {
  const containerStyle: React.CSSProperties = {
    flexDirection: direction,
    alignItems: align,
    justifyContent: justify,
    flexWrap: wrap,
    ...style,
  }

  const itemStyle = (index): React.CSSProperties => ({
    ...(itemStyles[index] || itemStyles[0]), // default to the first one
    ...(direction === 'column' ? {
      width: '100%', 
    } : {
    }),
  })

  const classes = useClasses()
  const _children = children || [].filter(x => x)

  return (
    <Grid
      container
      spacing={spacing}
      style={containerStyle}
      {...rest}
    >
      {React.Children.map(_children, (child, i) => {
        const showDivider = divider && (i !== _children.length - 1)
        if (showDivider) return (
          <Grid item style={{
            ...itemStyle(i),
            paddingTop: 8,
            paddingBottom: 8,
          }}>
            <Box>
              {child}
            </Box>
            <Divider className={classes.divider} />
          </Grid>
        )

        return (
          <Grid item style={{
            ...itemStyle(i),
          }}>
            {child}
          </Grid>
        )
      })}
    </Grid>
  )
}

export default Stack
