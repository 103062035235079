import { UserDemoName } from 'state-mngt/models/user'
import { Dwelling, DwellingContent } from 'state-mngt/models/dwelling'
import { Device, DeviceTelemetry, DeviceTelemetryQuality } from 'state-mngt/models/device'
import { Equipment } from 'state-mngt/models/equipment'
import { DEMO_CUSTOMER_IDS } from 'features/demo-data/demo-data'
import { getTodayMidnightDate } from 'utils/time-utils'
import { APIInterval } from 'utils/constants/time-interval-api'
import DemoDeviceService from 'state-mngt/services/data/demo-service/services/demo-dwelling-service/services/demo-device-service'

interface DemoDwelling {
  dwellingId: number;
  dwelling: Dwelling,
  dwellingContent: DwellingContent;
  devices: Device[],
  equipment: Equipment[],
}

class DemoDwellingService {
  constructor(private demoDeviceService: DemoDeviceService) { }

  public getDwelling(demoName: UserDemoName): DemoDwelling {
    const dwellingId = DEMO_CUSTOMER_IDS[demoName]
    const devices = this.demoDeviceService.getDevices(demoName, dwellingId)
    const equipment = this.demoDeviceService.getEquipment(demoName, dwellingId)

    const dwellingContent = {
      devices,
      equipment,
      ecosense_devices: [],
    }

    const dwelling: Dwelling = {
      id: dwellingId,
      name: '',
      create_timestamp: getTodayMidnightDate().toISOString(),
      admin_id: null,
      customer_name: null,
      first_name: '',
      last_name: '',
      address: '',
      street_1: '',
      street_2: '',
      city: '',
      province: '',
      postal_code: '',
      country: '',
      cam_ids: DemoDeviceService.getCamIds(devices),
      cac_ids: [],
      poor_average_time_percentage: 0,
      poor_pm_time_percentage: 0,
      poor_voc_time_percentage: 0,
      poor_humidity_time_percentage: 0,
      device_status_good: true,
      filter_status_good: true,
      isDemo: true,
    }

    if (demoName === UserDemoName.Demo1) {
      dwelling.name = 'Particle Problems'
      dwelling.first_name = 'Pat'
      dwelling.last_name = 'Parker'
      dwelling.address = '3439 Terra Vita pl'
      dwelling.poor_average_time_percentage = 0.9
      dwelling.poor_pm_time_percentage = 0.9
    }
    if (demoName === UserDemoName.Demo2) {
      dwelling.name = 'Relative Humidity Horrors'
      dwelling.first_name = 'Hubert'
      dwelling.last_name = 'Hernàndez'
      dwelling.address = '3863 west 11'
      dwelling.poor_average_time_percentage = 0.9
      dwelling.poor_humidity_time_percentage = 0.9
    }
    if (demoName === UserDemoName.Demo3) {
      dwelling.name = 'VOC vexations'
      dwelling.first_name = 'Vida'
      dwelling.last_name = 'Valdes'
      dwelling.address = '4205 Dollar Road'
      dwelling.poor_average_time_percentage = 0.1
      dwelling.poor_voc_time_percentage = 0.1
    }

    return {
      dwelling,
      dwellingId,
      dwellingContent,
      devices,
      equipment,
    }
  }

  public getDeviceTelemetryCurrent(demoName: UserDemoName): DeviceTelemetry {
    const result: DeviceTelemetry = {
      timestamp: new Date().toISOString(),
      airflow: 1,
      voc_mc: 0,
      voc_count: 0,
      pm_count: 150,
      pm_mc: 0,
      temperature: 0,
      humidity: 0,
      voc_status: '',
      pm_status: 'poor',
      combined_status: '',
      highest_factor: '',
    }
    if (demoName === UserDemoName.Demo1) {
      result.pm_count = 150
      result.pm_mc = 15
      result.pm_status = 'poor'
      result.voc_count = 164
      result.voc_mc = 16.4
      result.voc_status = 'good'
      result.humidity = 51.1
      result.temperature = 25.3
    }
    if (demoName === UserDemoName.Demo2) {
      result.pm_count = 150
      result.pm_mc = 15
      result.pm_status = 'poor'
      result.voc_count = 164
      result.voc_mc = 16.4
      result.voc_status = 'good'
      result.humidity = 51.1
      result.temperature = 25.3
    }
    if (demoName === UserDemoName.Demo3) {
      result.pm_count = 150
      result.pm_mc = 15
      result.pm_status = 'poor'
      result.voc_count = 164
      result.voc_mc = 16.4
      result.voc_status = 'good'
      result.humidity = 51.1
      result.temperature = 25.3
    }
    return result
  }

  public getDeviceTelemetryRange({
    deviceId,
    startTime,
    interval,
    endTime,
  }: { deviceId: number, startTime: Date, endTime: Date, interval: APIInterval }): DeviceTelemetry[] {
    return this.demoDeviceService.getDeviceTelemetryRange({
      deviceId,
      startTime,
      interval,
      endTime,
    })
  }

  public getDevicePerformance(demoName: UserDemoName): DeviceTelemetryQuality {
    const result: DeviceTelemetryQuality = {
      total_time: 0,
      data_time: 0,
      pm_good_time: 0,
      pm_fair_time: 0,
      pm_poor_time: 0,
      voc_good_time: 0,
      voc_fair_time: 0,
      voc_poor_time: 0,
      humidity_low_poor_time: 0,
      humidity_low_fair_time: 0,
      humidity_good_time: 0,
      humidity_high_fair_time: 0,
      humidity_high_poor_time: 0,
    }
    if (demoName === UserDemoName.Demo1) {
      result.total_time = 100
      result.data_time = 100
      result.pm_poor_time = 90
    }
    if (demoName === UserDemoName.Demo2) {
      result.total_time = 100
      result.data_time = 100
      result.humidity_high_poor_time = 90
    }
    if (demoName === UserDemoName.Demo3) {
      result.total_time = 100
      result.data_time = 100
      result.voc_poor_time = 10
    }
    return result
  }
}

export default DemoDwellingService
