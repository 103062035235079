/**
 * Create anchor tag to download file and remove the element right after
 * @param fileName - string
 */
export const downloadFile = (fileName: string, baseUrl: string) => {
  const link = document.createElement('a')
  link.setAttribute('target', '_blank')
  link.download = fileName
  link.href = baseUrl + fileName
  link.click()
  link.remove()
}