import React, { useEffect, useState } from 'react'
import 'ui/drag-and-drop/drag-and-drop.scss'
import {
  Box, Button, makeStyles, Typography,
} from '@material-ui/core'
import { theme } from 'theme'
import DeleteIcon from '@material-ui/icons/Delete'
import { validateFileType } from 'utils/file-utils'
import { HavenLinks } from 'utils/constants/resources/links'
import { useMixPanel } from 'features/analytics/mixpanel-provider'

const useStyles = makeStyles({
  dragContainer: {
    borderColor: theme.palette.secondary.main,
    borderWidth: '2px',
    borderStyle: 'dashed',
  },
  warningMsg: {
    color: theme.palette.error.main,
    marginBottom: 0,
  },
})

const DragAndDrop = (props: any) => {
  const classes = useStyles()
  const { mixpanel } = useMixPanel()

  const dropRef = React.createRef<HTMLDivElement>()

  const [isDragging, setIsDragging] = useState<boolean>(true)
  const [dragCounter, setDragCounter] = useState<number>(0)
  const [image, setImage] = useState<any>()
  const [imageSizeError, setImageSizeError] = useState<boolean>(false)
  const [imageTypeError, setImageTypeError] = useState<boolean>(false)

  useEffect(() => {
    const div = dropRef.current
    const fileInput = document.getElementById('file-upload-input')
    // Add or remove listeners based on the state changes
    const addListeners = () => {
      if (div) {
        div.addEventListener('dragenter', handleDragIn)
        div.addEventListener('dragleave', handleDragOut)
        div.addEventListener('dragover', handleDrag)
        div.addEventListener('drop', handleDrop)
      } else {
        removeListeners()
      }
      if (fileInput) {
        fileInput.addEventListener('change', handleFileSelect, false)
      }
    }

    const removeListeners = () => {
      if (div) {
        div.removeEventListener('dragenter', handleDragIn)
        div.removeEventListener('dragleave', handleDragOut)
        div.removeEventListener('dragover', handleDrag)
        div.removeEventListener('drop', handleDrop)
      }
      if (fileInput) {
        fileInput.removeEventListener('change', handleFileSelect, false)
      }
    }

    // mount
    addListeners()

    // unmount
    return () => {
      removeListeners()
    }
  })

  /**
     * Prevent defaults when dragging element over to valid drop zone
     * @param e - DragEvent
     */
  const handleDrag = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }

  /**
     * Set isDragging to true when dragging file
     * @param e - DragEvent
     */
  const handleDragIn = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()

    setDragCounter(dragCounter + 1)
    if (e.dataTransfer && e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setIsDragging(true)
    }
  }

  /**
     * Set image to undefined to allow re-upload when image is dragged out from drop zone
     * @param e - DragEvent
     */
  const handleDragOut = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()

    setImage(undefined)
    setDragCounter(dragCounter - 1)
    if (dragCounter > 0) {
      return
    }
    setIsDragging(true)
  }

  /**
     * Read image file (if valid) for preview display when dropping file on drag and drop zone
     * @param e - DragEvent
     */
  const handleDrop = (e: DragEvent) => {
    if (mixpanel) {
      mixpanel.track('pp_companyPage_logoDropAction')
    }
    e.preventDefault()
    e.stopPropagation()

    setIsDragging(false)

    if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const logoFile = e.dataTransfer.files[0]
      if (logoFile && validateFile(logoFile)) {
        readLogoFile(logoFile)
        setImage(logoFile)
        e.dataTransfer.clearData()
        setDragCounter(0)
      }
    }
  }

  /**
     * Trigger hidden file upload input it when user click on the button
     */
  const handleFileUpload = () => {
    if (mixpanel) {
      mixpanel.track('pp_companyPage_logoFileUploadButton')
    }
    const input = document.getElementById('file-upload-input')

    if (input) {
      input.click()
    }
  }

  /**
     * Reads the file uploaded from choosing local files
     * Display file (if valid)
     * Set isDragging to false
     * @param e Event
     */
  const handleFileSelect = (e: Event) => {
    const target = e.target as HTMLInputElement

    setIsDragging(false)

    if ( !target || !target.files || target.files.length === 0) {
      return
    }

    const logoFile = target.files[0]

    if (logoFile && validateFile(logoFile)) {
      readLogoFile(logoFile)
      setImage(logoFile)
      setDragCounter(0)
      setIsDragging(false)
    }

  }

  /**
     * Deletes the logo file from drag and drop or selected from the prompt of file upload input
     */
  const handleLogoDelete = () => {
    if (mixpanel) {
      mixpanel.track('pp_companyPage_logoDeleteButton')
    }
    const logo = document.getElementById('logo-preview')

    if (logo) {
      logo.setAttribute('src', '')
      props.handleDrop('') // set image data string to empty
    }
    setIsDragging(true)
    setImage(undefined)
  }

  /**
     * Validate file size and format before sending to server
     * Size limit: 6291456 bytes/ 6MB
     * Format allowed: .jpeg/.png/.gif
     * @param file - File
     */
  const validateFile = (file: File): boolean => {

    // set error to prevent image of invalid files being previewed
    setImageTypeError(!validateFileType(file))
    setImageSizeError(file.size >= 6291456)

    if (validateFileType(file) && file.size < 6291456) {
      return true
    }

    props.handleDrop('') // set image data string to empty if didn't pass validation

    return false
  }

  /**
     * Sets the 'src' attribute for the img file (img only visible when image is valid)
     * @param file - File
     */
  const readLogoFile = (file: File) => {

    const reader = new FileReader()

    reader.onload = (event) => {
      const fileReader = event.target as FileReader

      const logo = document.getElementById('logo-preview')

      if (logo) {
        logo.setAttribute('src', fileReader.result as string)
        props.handleDrop(fileReader.result as string) // return file to dialog
      }
    }

    reader.readAsDataURL(file)
  }

  return (
    <div ref={dropRef} id="drag-drop-container" className={classes.dragContainer}>
      { isDragging &&
                <div className="drag-inner-overlay">
                  <div>
                    {!image &&
                            <Box display="flex" alignItems="center" style={{
                              height: '200px',
                            }} justifyContent="center" flexDirection="column">
                              <img alt="add logo icon" className="display-block" width="65" src={HavenLinks.S3 + 'add-image-icon.png'} />
                              <br />
                              <Typography color="textPrimary">Drag and drop or <Button id="file_upload" onClick={handleFileUpload}>choose from your own files</Button></Typography>
                              <Typography color="textPrimary" variant="body2">(Welcome formats include jpgs, pngs and gifs)</Typography>
                              <input type="file"
                                id="file-upload-input"
                                accept="image/jpeg, image/png, .gif" hidden></input>
                            </Box>
                    }
                  </div>
                </div>
      }
      { !isDragging &&
                <Box>
                  <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" style={{
                    height: '196px',
                  }}>
                    {!imageTypeError && !imageSizeError && image &&
                            <img id="logo-preview" alt="logo preview" />
                    }
                    <ul hidden={!imageTypeError && !imageSizeError}>
                      { imageTypeError &&
                                <li className={classes.warningMsg}>Only jpeg, png or gif type is allowed</li>
                      }
                      { imageSizeError &&
                                <li className={classes.warningMsg}>File size must be smaller than 6MB</li>
                      }
                    </ul>
                  </Box>
                  <Button id="logo_delete" variant="outlined" color="secondary" size="small" style={{
                    minWidth: '40px', minHeight: '40px',
                  }} onClick={handleLogoDelete}>
                    <DeleteIcon fontSize="small" />
                  </Button>
                </Box>
      }

      {props.children}
    </div>
  )
}

export default DragAndDrop
