import * as React from 'react'
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles, Snackbar } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  snackbar: {
    bottom: 90,
  },
}))

function Alert({ severity, children, ...rest }) {
  const classes = useStyles()

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={6000}
      className={classes.snackbar}
      {...rest}>
      <MuiAlert elevation={6} variant="filled" severity={severity} children={children} />
    </Snackbar>
  )
}

export default Alert
