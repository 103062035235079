import * as React from 'react'
import { Typography, makeStyles } from "@material-ui/core"
import useAdmin from 'utils/hooks/useAdmin'
import { usePageTitle } from 'utils/hooks/router'
import { useMixPanel } from 'features/analytics'

const useClasses = makeStyles(theme => ({
    root: {
        minHeight: '100vh',
        backgroundColor: theme.palette.charcoalLightestTint,
    },
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: '33% 1fr',
        gridGap: '16px',
        alignItems: 'flex-start',
    },
    card: {
        background: 'white',
        borderRadius: '6px',
        padding: '16px',
        paddingLeft: '24px',
        paddingRight: '24px',
    },
    info: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        columnGap: '8px',
        rowGap: '16px',
    },
    infoColumn: {
    },
}))

function Info() {
    const classes = useClasses()
    const adminUser = useAdmin()

    return (
        <div className={classes.gridContainer}>
            <div className={classes.card}>
                <div className={classes.info}>
                    <Typography variant='subtitle1'>
                        Lastname
                    </Typography>
                    <Typography>
                        {adminUser?.last_name}
                    </Typography>
                    <Typography variant='subtitle1'>
                        Firstname
                    </Typography>
                    <Typography>
                        {adminUser?.first_name}
                    </Typography>
                    <Typography variant='subtitle1'>
                        Email
                    </Typography>
                    <Typography>
                        {adminUser?.email}
                    </Typography>
                    <Typography variant='subtitle1'>
                        Phone
                    </Typography>
                    <Typography>
                        {adminUser?.phone}
                    </Typography>
                </div>
            </div>
        </div>
    )
}

function Profile() {
    const classes = useClasses()
    const adminUser = useAdmin()
    const { mixpanel } = useMixPanel()

    usePageTitle(adminUser ? `Customer ${adminUser.first_name} - Contact ` : 'Customer - Contact')

    React.useEffect(() => {
        if (mixpanel) {
            mixpanel.track_pageview()
        }
    }, [mixpanel])

    return (
        <div className={classes.root}>
            <Typography style={{ marginBottom: '24px' }} variant='h6'>
                Customer info</Typography>
            <Info />
        </div>
    )
}

export {
    Info,
    useClasses,
}

export default Profile
