import { UserDemoName } from 'state-mngt/models/user'
import { createSelector } from 'reselect'
import { Device, DeviceTelemetry, DeviceType } from 'state-mngt/models/device'
import { Equipment } from 'state-mngt/models/equipment'
import { APIInterval } from 'utils/constants/time-interval-api'
import {
  createDemo1Telemetry, pickTelemetry, compose, combineTelemetry, demo1Range,
} from 'state-mngt/services/data/demo-service/services/demo-dwelling-service/services/demo-device-service/utils'

export const getDemoNameByDeviceId = (deviceId: number): UserDemoName => {
  switch (deviceId) {
    case -1: {
      return UserDemoName.Demo1
    }
    case -2: {
      return UserDemoName.Demo2
    }
    case -3: {
      return UserDemoName.Demo3
    }
    default: {
      return UserDemoName.Demo3
    }
  }
}

class DemoDeviceService {
  public static getCamIds(devices: Device[]): number[] {
    return devices.filter((device) => device.type === DeviceType.Cam).map(({ id }) => id)
  }
  constructor() {}

  getDevices(demoName: UserDemoName, dwellingId: number): Device[] {
    const camDevice = {
      id: -1,
      type: DeviceType.Cam,
      name: 'Haven CAM device',
      dwelling_id: dwellingId,
      install_timestamp: null,
      hub_id: '',
      hub_device_id: '',
      plastic_serial_number: '0000-0127',
      pcb_serial_number: '',
      zone: 'Downstairs',
      equipment_id: null,
    }
    switch (demoName) {
      case UserDemoName.Demo1: {
        return [
          {
            ...camDevice,
            install_timestamp: demo1Range.installed.toISOString(),
            id: -1,
          },
        ]
      }
      case UserDemoName.Demo2: {
        return [
          {
            ...camDevice,
            install_timestamp: demo1Range.installed.toISOString(),
            id: -2,
          },
        ]
      }
      case UserDemoName.Demo3: {
        return [
          {
            ...camDevice,
            install_timestamp: demo1Range.installed.toISOString(),
            id: -3,
          },
        ]
      }
      default: {
        return [camDevice]
      }
    }
  }

  getEquipment(demoName: UserDemoName, dwellingId: number): Equipment[] {
    switch (demoName) {
      default: {
        return []
      }
    }
  }

  private getTelemetry = createSelector(
    (demoName: UserDemoName) => demoName,
    (demoName: UserDemoName): DeviceTelemetry[] => {
      if (demoName === UserDemoName.Demo1) {
        return createDemo1Telemetry<DeviceTelemetry[]>([])
      }
      return []
    },
  )

  getDeviceTelemetryRange({
    deviceId,
    startTime,
    interval,
    endTime,
  }: { deviceId: number, startTime: Date, endTime: Date, interval: APIInterval }): DeviceTelemetry[] {
    const demoName = getDemoNameByDeviceId(deviceId)
    const baseTelemetry = this.getTelemetry(demoName)
    const result = compose(
      pickTelemetry(startTime, endTime, interval),
      combineTelemetry(interval),
    )(baseTelemetry)
    return result
  }
}

export default DemoDeviceService
