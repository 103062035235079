import { Switch, withStyles } from '@material-ui/core'
import { theme } from 'theme'

const PrimarySwitch = withStyles({
  switchBase: {
    color: '#F7FBFD',
    '&$checked': {
      color: theme.palette.primary.main,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  checked: {
  },
  track: {
    opacity: 1,
    backgroundColor: theme.palette.grey[300],
  },
})(Switch)

export default PrimarySwitch
