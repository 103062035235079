import { Reducer } from 'redux'
import {
  ActionType,
  CleanDwellingContentAction,
  SetDwellingCacChannelsAction,
  SetDwellingContentAction, SetMappedCacIds, SetControllersChannelInfoAction, SetMonitorsByZoneAction,
} from 'state-mngt/actions/dwelling-actions'
import { CacChannelInfo, DwellingContent, MonitorByZone } from 'state-mngt/models/dwelling'
import { DeviceCacChannels } from 'state-mngt/models/device'


interface DwellingState {
  isLoading: boolean;
  dwellingContent?: DwellingContent;
  deviceCacChannels: {
    [id: string]: DeviceCacChannels,
  };
  cacIdsByCamId: {
    [id: string]: number[];
  };
  controllersChannelsInfo: CacChannelInfo[];
  monitorsByZone: MonitorByZone[];
  isMonitorsByZoneLoading: boolean;
}

const initState: DwellingState = {
  isLoading: true,
  deviceCacChannels: {
  },
  cacIdsByCamId: {
  },
  controllersChannelsInfo: [],
  monitorsByZone: [],
  isMonitorsByZoneLoading: true,
}

type ReducerAction = SetDwellingContentAction
  | CleanDwellingContentAction
  | SetDwellingCacChannelsAction
  | SetMappedCacIds
  | SetMonitorsByZoneAction 
  | SetControllersChannelInfoAction;

const dwelling: Reducer<DwellingState, ReducerAction> = (state = initState, action): DwellingState => {
  switch (action.type) {
    case ActionType.SET_DWELLING_CONTENT: {
      return {
        ...state,
        dwellingContent: action.payload,
        isLoading: false,
      }
    }
    case ActionType.CLEAN_DWELLING_CONTENT: {
      return {
        ...initState,
      }
    }
    case ActionType.SET_DWELLING_CAC_CHANNELS: {
      return {
        ...state,
        deviceCacChannels: {
          ...state.deviceCacChannels,
          [action.payload.deviceId]: action.payload,
        },
      }
    }
    case ActionType.SET_MAPPED_CAC_IDS: {
      return {
        ...state,
        cacIdsByCamId: {
          ...state.cacIdsByCamId,
          [action.payload.id]: action.payload.ids,
        },
      }
    }
    case ActionType.SET_CONTROLLERS_CHANNELS_INFO: {
      return {
        ...state,
        controllersChannelsInfo: [...action.payload],
      }
    }
    case ActionType.SET_MONITORS_BY_ZONE: {
      return {
        ...state,
        monitorsByZone: [...action.payload],
        isMonitorsByZoneLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default dwelling
