import {
  ActionType,
  SetVentilatingDehumidifierAutomationsActivationDetailsAction,
  SetVentilatingDehumidifierAutomationsAction,
  SetLoadingVentilatingDehumidifierAutomations,
  SetLoadingVentilatingDehumidifierAutomationsActivationDetails, CleanVentilatingDehumidifierAutomationsAndDetails,
} from 'state-mngt/actions/automation-actions'
import {
  VentilatingDehumidifierAutomationActivation,
  VentilatingDehumidifierAutomations,
} from 'state-mngt/models/automation'


interface AutomationState {
  ventilatingDehumidifierAutomations: VentilatingDehumidifierAutomations;
  ventilatingDehumidifierAutomationsActivationDetails: VentilatingDehumidifierAutomationActivation[];
  isLoadingVentilatingDehumidifierAutomations: boolean;
  isLoadingVentilatingDehumidifierAutomationsActivationDetails: boolean;
}

const initState: AutomationState = {
  ventilatingDehumidifierAutomations: {
    num_automations: 0,
    automations: [],
  },
  ventilatingDehumidifierAutomationsActivationDetails: [],
  isLoadingVentilatingDehumidifierAutomations: false,
  isLoadingVentilatingDehumidifierAutomationsActivationDetails: false,
}


type ReducerAction = SetVentilatingDehumidifierAutomationsAction
  | SetVentilatingDehumidifierAutomationsActivationDetailsAction
  | SetLoadingVentilatingDehumidifierAutomations | SetLoadingVentilatingDehumidifierAutomationsActivationDetails
  | CleanVentilatingDehumidifierAutomationsAndDetails;


export const automations = (state = initState, action: ReducerAction): AutomationState => {
  switch (action.type) {
    case ActionType.SetVentilatingDehumidifierAutomations: {
      return {
        ...state,
        ventilatingDehumidifierAutomations: action.payload,
      }
    }
    case ActionType.SetVentilatingDehumidifierAutomationsActivationDetails: {
      return {
        ...state,
        ventilatingDehumidifierAutomationsActivationDetails: [
          ...action.payload,
        ],
      }
    }
    case ActionType.SetLoadingVentilatingDehumidifierAutomations: {
      return {
        ...state,
        isLoadingVentilatingDehumidifierAutomations: action.payload,
      }
    }
    case ActionType.SetLoadingVentilatingDehumidifierAutomationsActivationDetails: {
      return {
        ...state,
        isLoadingVentilatingDehumidifierAutomationsActivationDetails: action.payload,
      }
    }
    case ActionType.CleanVentilatingDehumidifierAutomationsAndDetails: {
      return {
        ...initState,
      }
    }
    default: {
      return state
    }
  }
}
