import {
  Card,
  Box,
  Button,
  Typography,
  FormControl,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { distributionOptions } from 'utils/constants/company-lookup-options'
import { OnboardingProfile } from 'state-mngt/models/onboarding-profile'
import { useMixPanel } from 'features/analytics/mixpanel-provider'

export const FormSix = (props: {
  profile: OnboardingProfile;
  onNextValueChange: (value: number) => void;
  onIsValidValueChange: (formNum: number, isValid: boolean) => void;
  onProfileChange: (profile: OnboardingProfile) => void;
  isParentFormValid: boolean;
}) => {
  const {
    profile, isParentFormValid, onNextValueChange, onIsValidValueChange, onProfileChange,
  } = props
  const { mixpanel } = useMixPanel()

  const [distribution, setDistribution] = useState<string[]>([]) // required
  const [otherDistributors, setOtherDistributors] = useState<string>('') // required if others is checked

  const [btnVisible, setBtnVisible] = useState<boolean>(false)
  const [activeCard, setActiveCard] = useState<boolean>(true)

  useEffect(() => {
    if (distribution.length > 0) {
      if (distribution.includes('others') && otherDistributors.trim() === '') {
        onIsValidValueChange(4, false)
      } else {
        onIsValidValueChange(4, true)
      }
    } else {
      onIsValidValueChange(4, false)
    }
  }, [distribution, otherDistributors, onIsValidValueChange])

  /**
   * callback fn for 'Next' click
   * set btn visibility
   * send progress change to parent component
   * send profile to parent component
   */
  const handleNext = () => {
    if (mixpanel) {
      mixpanel.track('pp_onboardingPage_cardFour_nextButton')
    }

    const replaceOthers = distribution.filter((i) => i !== 'others')

    if (distribution.includes('others') && otherDistributors.trim() !== '') {
      replaceOthers.push(otherDistributors)
    }
    const updatedProfile: OnboardingProfile = {
      ...profile,
      distribution: replaceOthers,
    }

    onProfileChange(updatedProfile)
    onNextValueChange(90)
    setBtnVisible(false)
    setActiveCard(false)
  }

  /**
   * callback fn for distribution value change
   * sets distribution state
   * @param event - ChangeEvent
   */
  const handleDistributionChange = (event: React.ChangeEvent<{ value: unknown }>, checked: boolean) => {
    const input = event.target.value as string
    const tempData = [...distribution]
    const indexOfInput = tempData.indexOf(input)

    if (checked && (indexOfInput === -1)) {
      tempData.push(input)
    } else if (indexOfInput !== -1) {
      tempData.splice(indexOfInput, 1)
    }

    setDistribution(tempData)
    
    const updatedProfile: OnboardingProfile = {
      ...profile,
      distribution: tempData,
    }

    onProfileChange(updatedProfile)

    if (tempData.length > 0) {
      setBtnVisible(true)
    } else {
      setBtnVisible(false)
    }
  }

  /**
   * callback fn for other distributor value change
   * sets other distributor state
   * @param event - ChangeEvent
   */
  const handleOtherDistributorsChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const input = e.target.value as string
    setOtherDistributors(input)
  
    if (input.trim() !== '') {
      const currentDist = distribution.filter((i) => i !== 'others')
      currentDist.push(input)
  
      const updatedProfile: OnboardingProfile = {
        ...profile,
        distribution: currentDist,
      }
  
      onProfileChange(updatedProfile)
    }
  }


  return (
    <Box>
      <Card
        elevation={3}
        style={{
          width: '570px',
          padding: '2rem',
          margin: '2rem 0',
        }}
        className={activeCard ? 'onboarding-card' : 'onboarding-fade'}
      >
        <Typography variant="caption">Please select at least one checkbox to proceed*</Typography>
        <Box mt={1} mb={2} display="flex">
          <Typography><b>For distribution, we're set up with</b></Typography>
        </Box>
        <FormControl component="fieldset" variant="standard">
          <FormGroup>
            <Grid container spacing={2}>
              {distributionOptions.map((option, index) => (
                <Grid item xs={12} sm={3} md={6} key={index}>
                  <Box display="flex">
                    <FormControlLabel
                      key={distributionOptions[index].value}
                      control={
                        <Checkbox 
                          checked={distribution.includes(option.value)} 
                          onChange={handleDistributionChange} 
                          name={option.value}
                          value={option.value}
                        />
                      }
                      label={distributionOptions[index].label}
                      style={{
                        marginRight: 0,
                        padding: 0,
                      }}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </FormGroup>
        </FormControl>
        {distribution.includes('others') && (
          <Box mt={2} display="flex">
            <Typography>Other distributors*</Typography>
            <TextField
              id="onboarding-distribution-others"
              required
              size="small"
              placeholder="List distributor(s)"
              defaultValue={otherDistributors.trim()}
              error={otherDistributors.trim() === ''}
              style={{
                marginLeft: '1rem',
                width: '200px',
              }}
              helperText={otherDistributors.trim() === '' ? 'Distributor is required if "Others" is checked' : ''}
              onChange={handleOtherDistributorsChange}
            />
          </Box>
        )}

        {btnVisible && activeCard && (
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              onClick={handleNext}
              disabled={ 
                !isParentFormValid || !distribution.length || 
                (distribution.includes('others') && !otherDistributors.length) 
              }
            >
            Next
            </Button>
          </Box>
        )}
      </Card>
    </Box>
  )
}
