/**
 * links used for static content
 * Note 'S3' is frequently used for images uploaded to AWS S3 bucket
 */
export enum HavenLinks {
    S3= 'https://haven-pro-web-portal.s3-us-west-2.amazonaws.com/',
    SUPPORT= 'https://support.pro.haveniaq.com/',
    FORUM= 'https://forum.haveniaq.com/',
    BUY_CAM= 'https://support.pro.haveniaq.com/hc/en-us/articles/1500004080402',
    PRO_RETURN= 'https://support.pro.haveniaq.com/hc/en-us/requests/new?ticket_form_id=1500000421062',
    CUSTOMER_RETURN= 'https://support.haveniaq.com/hc/en-us/requests/new?ticket_form_id=1500000421062',
    TOS= 'http://haveniaq.com/tos',
    MEMBERSHIP_SUPPORT= 'https://support.pro.haveniaq.com/hc/en-us/requests/new?ticket_form_id=1500000421102',
    CUSTOMER_SUPPORT= 'https://support.haveniaq.com/',
    PRO_SUPPORT= 'https://support.pro.haveniaq.com/',
    TZOA_HOME= 'https://tzoa.com/', // used in footer only,
    CALENDLY='https://calendly.com/haven-pro/demo',
    MarketingS3 = 'https://s3.us-west-2.amazonaws.com/static.haveniaq.com/marketing',
}
