import { TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Country } from 'utils/constants/province-lookup-options'

const PostalCodeInput = (props: {
    inputValue: string,
    country: string,
    name?: string,
    id: string,
    label?: string,
    placeholder?: string,
    touched: boolean,
    fullWidth: boolean,
    onPostalCodeValueChange: (value: string) => void,
    onPostalCodeErrorChange: (err: boolean) => void
}) => {

  const {
    inputValue, onPostalCodeValueChange, onPostalCodeErrorChange, country, name, id, label, placeholder, touched, fullWidth,
  } = props

  const [postalCodeError, setPostalCodeError] = useState<string>((inputValue === '' && touched) ? country === Country.CANADA ? 'Postal code is required' : 'Zipcode is required' : '')
  const [isInputTouched, setIsInputTouched] = useState<boolean>(touched)
  const [postalCode, setPostalCode] = useState<string>(inputValue)

  useEffect(()=> {
    if(isInputTouched) {
      validatePostalCode(country, postalCode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, postalCode, isInputTouched])

  /**
     * Validate zip / postal code field
     * @param event - ChangeEvent
     */
  const handleZipcodeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setIsInputTouched(true)
    const value = event.target.value as string
    validatePostalCode(country, value)
  }

  /**
     * Set zip/postal code value if valid
     * Set error message if format is not valid or empty
     * @param value - string
     */
  const validatePostalCode = (country: string, value: string) => {

    let regex = new RegExp(/^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/i) // canadian postal code

    if (value === '') {
      setPostalCodeError(country === Country.CANADA ? 'Postal code is required' : 'Zipcode is required' )
      onPostalCodeErrorChange(true)
      return
    }

    setPostalCode(value)

    if (country === Country.AMERICA) {
      regex = new RegExp(/^[0-9]{5}(?:-[0-9]{4})?$/) // us zipcode
    }

    if (value.match(regex) !== null) {
      setPostalCodeError('')
      onPostalCodeErrorChange(false)
      onPostalCodeValueChange(value)
    } else {
      setPostalCodeError(country === Country.AMERICA ? 'Invalid format for US zipcode, e.g. 11111' : 'Invalid format for Canadian postal code, e.g. V1R 1S2')
      onPostalCodeErrorChange(true)
    }
  }

  return(
    <TextField
      name={name}
      id={id}
      fullWidth={fullWidth}
      label={label}
      required
      placeholder={placeholder}
      defaultValue={inputValue}
      onChange={handleZipcodeChange}
      error={postalCodeError !== ''}
      helperText={postalCodeError}
    />
  )
}

export default PostalCodeInput
