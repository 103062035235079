/**
 * Query used on HAVEN API requests that uses pagination for retrieving data.
 */
export interface HavenApiPaginationQuery {
  order?: string;
  limit?: number;
  offset?: number;
  search?: string;
}

// https://teamtzoa.atlassian.net/wiki/spaces/HA/pages/1848573977/Error+Codes
export enum HavenApiErrorCode {
  NO_ERROR,
  UNKNOWN_ERROR,
  INTERNAL_ERROR,
  MISSING_PARAMETER,
  INVALID_PARAMETER,
  NOT_FOUND,
  INVALID_PERMISSION,
  DUPLICATE,
  PASSWORD_STRENGTH,
  NO_TELEMETRY_AVAILABLE,
  NOT_INSTALLED,
  CONFLICT,
  INVALID_FORMAT,
  INVALID_ENCODING,
  DEVICE_TIMEOUT,
  INSTALL_TIMEOUT,
  INVALID_DEVICE_TYPE,
  INVALID_ADDRESS,
  INVALID_STATUS,
  EXPIRED,
}

/**
 * Interface for mapping any HTTP error returned from the Haven API.
 */
export interface HttpError {
  code: HavenApiErrorCode;
  message: string;
}

export interface CancelableFetch<R = any> {
  promise: Promise<R>;
  controller: AbortController;
}

/**
 * Interface for wrapping fetch requests to network resources. Requests can be aborted.
 */
export interface RequestsAdapter {
  make: <R>(fetch: CancelableFetch<R> | Promise<R>) => Promise<R>;
  throwOnAbort: (error: HttpError) => undefined,
  abortAll: () => void;
}
