import {
  createStore, combineReducers, applyMiddleware, AnyAction,
} from 'redux'
import thunk, { ThunkMiddleware } from 'redux-thunk'
import { automations } from 'state-mngt/reducers/automation-reducer'
import customer from 'state-mngt/reducers/customer-reducer'
import device from 'state-mngt/reducers/device-reducer'
import dwelling from 'state-mngt/reducers/dwelling-reducer'
import report from 'state-mngt/reducers/report-reducer'
import company from 'state-mngt/reducers/company-reducer'
import user from 'state-mngt/reducers/user-reducer'
import error from 'state-mngt/reducers/error-reducer'
import { composeWithDevTools } from 'redux-devtools-extension'

const rootReducer = combineReducers({
  user,
  customer,
  dwelling,
  device,
  company,
  report,
  automations,
  error,
})

const enhancer = process.env.NODE_ENV === 'production' ?
  applyMiddleware(thunk as ThunkMiddleware<RootState, AnyAction>) :
  composeWithDevTools(applyMiddleware(thunk as ThunkMiddleware<RootState, AnyAction>))

const store = createStore(
  rootReducer,
  enhancer,
)

// Infer the `RootState` and `AppDispatch` types from the store itself so that they can be referenced as needed.
// Inferring these types from the store itself means that they correctly update as you add more state slices or modify
// middleware settings.
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export default store
