import {
  Slider,
  Typography,
  withStyles,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Stack from 'ui/stack'
import Emphasis from './emphasis'
import { InputControlProps } from './input'
import Muted from './muted'
import {
  selectTempInput,
  setTemporaryRuleInput,
  useRulesStore,
} from './store'
import {
  displayUnit,
  humanizeMeasurement,
  isNumber,
  max,
  min,
} from './util'
import useConvertTemperatureUnits from 'utils/hooks/useConvertTemperatureUnits'

const marks = ({ min, max, unit }) => [
  {
    value: min,
    label: `${min}${unit}`,
  },
  {
    value: max,
    label: `${max}${unit}`,
  },
]

const IndoorHumiditySlider = withStyles({
  track: {
    backgroundColor: 'transparent !important',
  },
  rail: {
    background: 'linear-gradient(to right,  #ff2f00 0%, #feb52d 15%, #0ddc0d 50%, #feb52d 85%, #ff2f00 100% )',
  },
  mark: {
    backgroundColor: 'transparent !important',
  },
})(Slider)

function Humidity({
  ruleId,
  inputId,
}: InputControlProps) {
  const [localValue, setLocalValue] = useState<number | undefined>() // store it locally just so it updates visually on change without rendering the whole tree
  const [localRange, setLocalRange] = useState<number[] | undefined>() // store it locally just so it updates visually on change without rendering the whole tree

  // useInitTemporaryRule(ruleId)
  // inputs all have different shapes so typing them is borken
  const input = useRulesStore(selectTempInput<any>(ruleId, inputId))

  const { format, display, convertedUnit } = useConvertTemperatureUnits(input)

  useEffect(() => {
    if (!input) return
    if (isNumber(input.start_set_point)) setLocalValue(display(input.start_set_point))
  }, [input?.start_set_point, display])

  useEffect(() => {
    if (isNumber(input?.high_end_start_set_point) && isNumber(input?.low_end_start_set_point)) {
      setLocalRange([
        display(input.low_end_start_set_point),
        display(input.high_end_start_set_point),
      ])
    }
  }, [
    input?.high_end_start_set_point,
    input?.low_end_start_set_point,
    display,
  ])

  const handleChangeCommitted = (event, value) => {
    if (Array.isArray(value)) {
      setTemporaryRuleInput(ruleId, input?.id, {
        high_end_start_set_point: format(value[1]),
        low_end_start_set_point: format(value[0]),
      })
    } else {
      setTemporaryRuleInput(ruleId, input?.id, {
        start_set_point: format(value),
      })
    }
  }

  if (!input) return null

  const onChange = (event, value) => {
    if (Array.isArray(value)) {
      setLocalRange(value)
    } else {
      setLocalValue(value)
    }
  }

  const {
    measurement,
    rising,
    type,
  } = input

  const _min = min(measurement, convertedUnit, rising)
  const _max = max(measurement, convertedUnit, rising)

  const _marks = marks({
    min: min(measurement, convertedUnit, rising),
    max: max(measurement, convertedUnit, rising),
    unit: displayUnit(convertedUnit || input.measurement),
  })

  const triggerAction = input.rising ? 'rises above' : 'drops below'
  const humanizedMeasurement = humanizeMeasurement(type, measurement)

  const renderDidactic = () => {
    if (localRange && isNumber(localRange[0]) && isNumber(localRange[1])) {
      return (
        <>
          <Emphasis>
            {humanizedMeasurement}
          </Emphasis>
          {` `}is below{` `}
          <Emphasis>
            {localRange[0]}
          </Emphasis>
          {` `}or above{` `}
          <Emphasis>
            {localRange[1]}{displayUnit(convertedUnit || measurement)}
          </Emphasis>
        </>
      )
    }
    if (isNumber(localValue)) {
      return (
        <>
          <Emphasis>
            {humanizedMeasurement}
          </Emphasis>
          {` `} {triggerAction} {` `}
          <Emphasis>
            {localValue}{displayUnit(convertedUnit || measurement)}
          </Emphasis>
        </>
      )
    }
    return null
  }

  return (
    <Stack>
      <Typography
        variant='body1'
      >
        <Muted>
          {renderDidactic()}
        </Muted>
      </Typography>
      {(isNumber(localValue) || localRange?.length) && (
        <IndoorHumiditySlider
          track={rising ? 'inverted' : 'normal'}
          value={localValue || localRange}
          min={_min}
          max={_max}
          marks={_marks}
          onChange={onChange}
          onChangeCommitted={handleChangeCommitted}
          aria-labelledby={input.type} />
      )}
    </Stack>
  )
}

export {
  min,
  max,
}

export default Humidity
