import { UserDemoName } from 'state-mngt/models/user'
import { Dwelling, DwellingContent } from 'state-mngt/models/dwelling'
import { Device, DeviceTelemetry, DeviceTelemetryQuality } from 'state-mngt/models/device'
import { Equipment } from 'state-mngt/models/equipment'
import { APIInterval } from 'utils/constants/time-interval-api'
import demoDwellingService from 'state-mngt/services/data/demo-service/services/demo-dwelling-service'

class DemoService {
  private readonly dwellings: {
    [customerId: string]: Dwelling;
  } = {
    }
  private readonly dwellingContents: {
    [customerId: string]: DwellingContent;
  } = {
    }
  private readonly devices: {
    [deviceId: string]: Device;
  } = {
    }
  private readonly equipments: {
    [equipmentId: string]: Equipment;
  } = {
    }
  private readonly demoNameByDeviceIdMap: {
    [deviceId: string]: UserDemoName;
  } = {
    }

  constructor() {
    Object.values(UserDemoName).forEach((userDemoName) => {
      const {
        dwellingId, dwelling, dwellingContent, devices, equipment,
      } =
        demoDwellingService.getDwelling(userDemoName)
      this.dwellings[dwellingId] = dwelling
      this.dwellingContents[dwellingId] = dwellingContent
      devices.forEach((device) => {
        this.devices[device.id] = device
        this.demoNameByDeviceIdMap[device.id] = userDemoName
      })
      equipment.forEach((equipment) => {
        this.equipments[equipment.id] = equipment
      })
    })
  }

  getDwelling(dwellingId: number): Dwelling {
    return this.dwellings[dwellingId]
  }

  getDwellings(): Dwelling[] {
    return Object.values(this.dwellings)
  }

  isDemoDwellingId(dwellingId: number): boolean {
    return !!this.dwellings[dwellingId]
  }

  getDwellingContent(dwellingId: number): DwellingContent {
    return this.dwellingContents[dwellingId]
  }

  isDemoDevice(deviceId: number): boolean {
    return !!this.devices[deviceId]
  }

  getDemoNameByDeviceId(deviceId: number): UserDemoName {
    return this.demoNameByDeviceIdMap[deviceId]
  }

  getDevicePerformance({ deviceId }: {
    deviceId: number;
  }): DeviceTelemetryQuality {
    return demoDwellingService.getDevicePerformance(this.getDemoNameByDeviceId(deviceId))
  }

  getDeviceTelemetryCurrent(deviceId: number): DeviceTelemetry {
    return demoDwellingService.getDeviceTelemetryCurrent(this.getDemoNameByDeviceId(deviceId))
  }

  getDeviceTelemetryRange({
    deviceId,
    startTime,
    interval,
    endTime,
  }: {
    deviceId: number;
    startTime: Date;
    endTime: Date;
    interval: APIInterval;
  }): DeviceTelemetry[] {
    return demoDwellingService.getDeviceTelemetryRange({
      deviceId,
      startTime,
      interval,
      endTime,
    })
  }

  getDemoNameByDwellingId(dwellingId: number): UserDemoName | null {
    switch (dwellingId) {
      case -1: {
        return UserDemoName.Demo1
      }
      case -2: {
        return UserDemoName.Demo2
      }
      case -3: {
        return UserDemoName.Demo3
      }
      default: {
        return null
      }
    }
  }
}

export default DemoService
