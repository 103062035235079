import {
  Card,
  Box,
  Button,
  Typography,
  FormControl,
  MenuItem,
  Select,
  Input,
  Checkbox,
  IconButton,
  FormControlLabel,
  FormGroup,
  Grid,
  Divider,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import {
  companyResidentialFocusesOptions,
  companyCommercialFocusesOptions,
} from 'utils/constants/company-lookup-options'
import { OnboardingProfile } from 'state-mngt/models/onboarding-profile'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import InfoTooltip from 'ui/info-tooltip'
import { useMixPanel } from 'features/analytics/mixpanel-provider'

export const FormFive = (props: {
  profile: OnboardingProfile;
  onNextValueChange: (value: number) => void;
  onIsValidValueChange: (formNum: number, isValid: boolean) => void;
  onProfileChange: (profile: OnboardingProfile) => void;
  isParentFormValid: boolean;
}) => {
  const {
    profile, isParentFormValid, onNextValueChange, onIsValidValueChange, onProfileChange,
  } = props
  const { mixpanel } = useMixPanel()

  const [hasResidentialFocus, setHasResidentialFocus] = useState<boolean>(true)
  const [hasCommercialFocus, setHasCommercialFocus] = useState<boolean>(true)
  const [focuses, setFocuses] = useState<string[]>([]) // required

  const [btnVisible, setBtnVisible] = useState<boolean>(false)
  const [activeCard, setActiveCard] = useState<boolean>(true)

  useEffect(() => {
    if (focuses.length > 0) {
      onIsValidValueChange(4, true)
    } else {
      onIsValidValueChange(4, false)
    }
  }, [focuses, onIsValidValueChange])

  /**
   * callback fn for 'Next' click
   * set btn visibility
   * send progress change to parent component
   * send profile to parent component
   */
  const handleNext = () => {
    if (mixpanel) {
      mixpanel.track('pp_onboardingPage_cardFour_nextButton')
    }

    const updatedProfile: OnboardingProfile = {
      ...profile,
      focuses,
    }

    onProfileChange(updatedProfile)
    onNextValueChange(80)
    setBtnVisible(false)
    setActiveCard(false)
  }

  /**
   * callback fn for do/do not perform building science projects.
   * sets the boolean flag perform building science projects.
   * @param event - ChangeEvent
   */
  const handleResidentialFocusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const input = event.target.value as number
    setHasResidentialFocus(input === 1 ? true : false)

    if (input !== 1) {
      const tempData = [...focuses]
      
      companyResidentialFocusesOptions.forEach((option => {
        const indexOf = tempData.indexOf(option.value)
        if (indexOf !== -1) {
          tempData.splice(indexOf, 1)
        }
      }))

      setFocuses(tempData)

      const updatedProfile: OnboardingProfile = {
        ...profile,
        focuses: tempData,
      }
      
      onProfileChange(updatedProfile)
    }
  }

  /**
   * callback fn for do/do not perform building science projects.
   * sets the boolean flag perform building science projects.
   * @param event - ChangeEvent
   */
  const handleCommercialFocusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const input = event.target.value as number
    setHasCommercialFocus(input === 1 ? true : false)
    
    if (input !== 1) {
      const tempData = [...focuses]
      
      companyCommercialFocusesOptions.forEach((option => {
        const indexOf = tempData.indexOf(option.value)
        if (indexOf !== -1) {
          tempData.splice(indexOf, 1)
        }
      }))

      setFocuses(tempData)

      const updatedProfile: OnboardingProfile = {
        ...profile,
        focuses: tempData,
      }
      
      onProfileChange(updatedProfile)
    }
  }

  /**
   * callback fn for focuses value change
   * sets focuses state
   * @param event - ChangeEvent
   */
  const handleFocusesChange = (event: React.ChangeEvent<{ value: unknown }>, checked: boolean) => {
    const input = event.target.value as string
    const tempData = [...focuses]
    const indexOfInput = tempData.indexOf(input)

    if (checked && (indexOfInput === -1)) {
      tempData.push(input)
    } else if (indexOfInput !== -1) {
      tempData.splice(indexOfInput, 1)
    }

    setFocuses(tempData)

    const updatedProfile: OnboardingProfile = {
      ...profile,
      focuses: tempData,
    }

    onProfileChange(updatedProfile)

    if (tempData.length > 0) {
      setBtnVisible(true)
    } else {
      setBtnVisible(false)
    }
  }

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 280,
        width: 250,
      },
    },
    getContentAnchorEl: null,
  }

  return (
    <Box>
      <Card
        elevation={3}
        style={{
          width: '570px',
          padding: '2rem',
          margin: '2rem 0',
        }}
        className={activeCard ? 'onboarding-card' : 'onboarding-fade'}
      >
        <Typography variant="caption">Please select at least one checkbox to proceed*</Typography>
        <Box mt={2} mb={2} display="flex">
          <Typography><b>We</b></Typography>
          <FormControl
            size="small"
            style={{
              marginLeft: '1rem',
              width: '80px',
            }}
          >
            <Select
              id="onboarding-sales-team"
              defaultValue={1}
              onChange={handleResidentialFocusChange}
              input={<Input />}
              MenuProps={MenuProps}
            >
              <MenuItem value={1}><b>do</b></MenuItem>
              <MenuItem value={0}><b>do not</b></MenuItem>
            </Select>
          </FormControl>
            &nbsp;
          <Typography><b>focus on Residential</b></Typography>
        </Box>
        { hasResidentialFocus && (
          <FormControl component="fieldset" variant="standard">
            <FormGroup>
              <Grid container spacing={2}>
                {companyResidentialFocusesOptions.map((option, index) => (
                  <Grid item xs={12} sm={3} md={6} key={index}>
                    <Box display="flex">
                      <FormControlLabel
                        key={option.value}
                        control={
                          <Checkbox 
                            checked={focuses.includes(option.value)} 
                            onChange={handleFocusesChange} 
                            name={option.value}
                            value={option.value}
                          />
                        }
                        label={option.label}
                        style={{
                          marginRight: 0,
                          padding: 0, 
                        }}
                      />
                      <InfoTooltip placement="right-start" arrow interactive title={option.info}>
                        <IconButton
                          style={{
                            marginLeft: 0,
                            padding: 0, 
                          }}
                        >
                          <InfoOutlinedIcon
                            color="secondary"
                            style={{
                              margin: '.4rem',
                            }}
                            fontSize="small"
                          />
                        </IconButton>
                      </InfoTooltip>
                    </Box>
                    
                  </Grid>
                ))}
              </Grid>
            </FormGroup>
          </FormControl>
        )}

        <Divider 
          orientation="horizontal" 
          style={{
            marginTop: '10px', marginBottom: '10px', 
          }}
        />

        <Box mt={2} mb={2} display="flex">
          <Typography><b>We</b></Typography>
          <FormControl
            size="small"
            style={{
              marginLeft: '1rem',
              width: '80px',
            }}
          >
            <Select
              id="onboarding-sales-team"
              defaultValue={1}
              onChange={handleCommercialFocusChange}
              input={<Input />}
              MenuProps={MenuProps}
            >
              <MenuItem value={1}><b>do</b></MenuItem>
              <MenuItem value={0}><b>do not</b></MenuItem>
            </Select>
          </FormControl>
            &nbsp;
          <Typography><b>focus on Commercial/Industrial</b></Typography>
        </Box>
        { hasCommercialFocus && (
          <FormControl component="fieldset" variant="standard">
            <FormGroup>
              <Grid container spacing={2}>
                {companyCommercialFocusesOptions.map((option, index) => (
                  <Grid item xs={12} sm={3} md={6} key={index}>
                    <Box display="flex">
                      <FormControlLabel
                        key={option.value}
                        control={
                          <Checkbox 
                            checked={focuses.includes(option.value)} 
                            onChange={handleFocusesChange} 
                            name={option.value}
                            value={option.value}
                          />
                        }
                        label={option.label}
                        style={{
                          marginRight: 0,
                          padding: 0, 
                        }}
                      />
                      <InfoTooltip placement="right-start" arrow interactive title={option.info}>
                        <IconButton
                          style={{
                            marginLeft: 0,
                            padding: 0, 
                          }}
                        >
                          <InfoOutlinedIcon
                            color="secondary"
                            style={{
                              margin: '.4rem',
                            }}
                            fontSize="small"
                          />
                        </IconButton>
                      </InfoTooltip>
                    </Box>
                    
                  </Grid>
                ))}
              </Grid>
            </FormGroup>
          </FormControl>
        )}

        {btnVisible && activeCard && (
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              onClick={handleNext}
              disabled={ !isParentFormValid || !focuses.length }
            >
            Next
            </Button>
          </Box>
        )}
      </Card>
    </Box>
  )
}
