import React from 'react'
import { Avatar, Box } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import { DeviceTelemetryQuality } from 'state-mngt/models/device'
import { PieChartData } from 'ui/pie-chart/pie-chart'
import InfoTooltip from 'ui/info-tooltip'
import { theme } from 'theme'
import { EcosenseTelemetry } from 'stores/ecosense'

/**
 * Generate issue type column given the dwelling's worst device quality.
 * @param poor_pm_time_percentage - poor_pm_time / total_time.
 * @param poor_voc_time_percentage - poor_voc_time / total_time.
 * @param poor_humidity_time_percentage - poor_humidity_time / total_time.
 */
export const getIssueType = (
  poor_pm_time_percentage: number, poor_voc_time_percentage: number, poor_humidity_time_percentage: number,
): JSX.Element => {
  return (
    <Box display="flex" justifyContent="center">
      <InfoTooltip title="PM2.5"><Avatar style={{
        width: '1.5rem',
        height: '1.5rem',
        marginRight: '.5rem',
        backgroundColor: poor_pm_time_percentage > 0.02 ? theme.palette.grey[300] : theme.palette.grey[200],
      }}>P</Avatar></InfoTooltip>
      <InfoTooltip title="tVOC"><Avatar style={{
        width: '1.5rem',
        height: '1.5rem',
        marginRight: '.5rem',
        backgroundColor: poor_voc_time_percentage > 0.02 ? theme.palette.grey[300] : theme.palette.grey[200],
      }}>V</Avatar></InfoTooltip>
      <InfoTooltip title="%RH"><Avatar style={{
        width: '1.5rem',
        height: '1.5rem',
        backgroundColor: poor_humidity_time_percentage > 0.02 ? theme.palette.grey[300] : theme.palette.grey[200],
      }}>H</Avatar></InfoTooltip>
    </Box>
  )
}

/**
* Generate priority column given the dwelling's worst device average time in the poor threshold.
* @param poor_average_time_percentage - average all three PM, VOC and Humidity poor time.
*/
export const getPriority = (poor_average_time_percentage: number): JSX.Element => {
  if (poor_average_time_percentage > .15) {
    return (
      <InfoTooltip title="High priority! Customer has spent >15% of HAVEN-observed time in the Poor threshold in the last month.">
        <ArrowUpwardIcon style={{
          color: theme.palette.error.main,
          border: `1px solid ${theme.palette.error.main}`,
          borderRadius: '50%',
        }} />
      </InfoTooltip>
    )
  } else if (poor_average_time_percentage > .05) {
    return (
      <InfoTooltip title="Medium priority! Customer has spent 5-15% of HAVEN-observed time in the Poor threshold in the last month.">
        <ArrowUpwardIcon style={{
          color: theme.palette.error.main,
        }} />
      </InfoTooltip>
    )
  } else if (poor_average_time_percentage > .02) {
    return (
      <InfoTooltip title="Low priority: Customer has spent 2-5% of HAVEN-observed time in the Poor threshold in the last month.">
        <KeyboardArrowUpIcon style={{
          color: theme.palette.warning.main,
        }} />
      </InfoTooltip>
    )
  }

  return (
    <InfoTooltip title="Customer has spent <2% of HAVEN-observed time in the Poor threshold in the last month.">
      <KeyboardArrowDownIcon style={{
        color: theme.palette.grey[300],
      }} />
    </InfoTooltip>
  )
}

export const getRadonQuality = (data: EcosenseTelemetry[]) => {
  return []
}

/**
 * for rendering PM performance pie chart
 * @param data - DeviceTelemetryQuality
 * @returns pieChartData[]
 */
export const getPMQuality = (data: DeviceTelemetryQuality) => {
  const {
    pm_good_time,
    pm_fair_time,
    pm_poor_time,
    total_time,
  } = data

  const result : PieChartData[] = [
    {
      name: 'Poor',
      y: pm_poor_time / total_time,
    },
    {
      name: 'Fair',
      y: pm_fair_time / total_time,
    },
    {
      name: 'Good',
      y: pm_good_time / total_time,
    },
    {
      name: 'No Data',
      y: (total_time - (pm_poor_time + pm_fair_time + pm_good_time)) / total_time,
    },
  ]

  return result
}

/**
 * for rendering VOC performance pie chart
 * @param data - DeviceTelemetryQuality
 * @returns pieChartData[]
 */
export const getVOCQuality = (data: DeviceTelemetryQuality) => {
  const {
    voc_good_time, voc_fair_time, voc_poor_time, total_time,
  } = data

  const result : PieChartData[] = [
    {
      name: 'Poor',
      y: voc_poor_time / total_time,
    },
    {
      name: 'Fair',
      y: voc_fair_time / total_time,
    },
    {
      name: 'Good',
      y: voc_good_time / total_time,
    },
    {
      name: 'No Data',
      y: (total_time - (voc_poor_time + voc_fair_time + voc_good_time)) / total_time,
    },
  ]

  return result
}

/**
 * for rendering Humidity performance pie chart
 * @param data - DeviceTelemetryQuality
 * @returns pieChartData[]
 */
export const getRHQuality = (data: DeviceTelemetryQuality) => {
  const {
    humidity_low_poor_time, humidity_low_fair_time, humidity_good_time, 
    humidity_high_fair_time, humidity_high_poor_time, total_time,
  } = data

  const result : PieChartData[] = [
    {
      name: 'Above - Poor',
      y: humidity_high_poor_time / total_time,
    },
    {
      name: 'Above - Fair',
      y: humidity_high_fair_time / total_time,
    },
    {
      name: 'Optimal',
      y: humidity_good_time / total_time,
    },
    {
      name: 'Below - Fair',
      y: humidity_low_fair_time / total_time,
    },
    {
      name: 'Below - Poor',
      y: humidity_low_poor_time / total_time,
    },
    {
      name: 'No Data',
      y: (total_time - (
        humidity_low_poor_time + humidity_low_fair_time + humidity_good_time + 
        humidity_high_fair_time + humidity_high_poor_time)
      ) / total_time,
    },
  ]

  return result
}
