import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import chartTourStep from 'features/product-tour/charts-page/chart-tour-step.jpg'
import { CHARTS_PAGE_TOUR_SELECTORS } from 'features/product-tour/charts-page/charts-page-tour-steps'


const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 1041,
    maxHeight: 818,
    width: 'auto',
    height: 'auto',
  },
  image: {
    width: '100%',
    paddingBottom: '78%',
    backgroundPosition: 'top center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
}))

export const ChartImagePlaceholder = () => {
  const classes = useStyles()

  return (
    <Box id={CHARTS_PAGE_TOUR_SELECTORS.DEVICE_CHART_HISTORY} className={classes.root}>
      <div
        className={classes.image}
        style={{
          backgroundImage: `url(${chartTourStep})`,
        }}
      />
    </Box>
  )
}
