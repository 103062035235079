import React, { useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  createStyles,
} from '@material-ui/core'
import { User } from 'state-mngt/models/user'
import 'features/preferences/account/account.scss'
import { useMixPanel } from 'features/analytics/mixpanel-provider'
import { useSelector } from 'react-redux'
import PreferencesSideMenuLayout from 'features/preferences/preferences-side-menu-layout'
import { usePageTitle } from 'utils/hooks/router'
import { selectIsLoadingUser, selectLoadingUserError, selectUser } from 'state-mngt/selectors/user-selectors'


const useStyles = makeStyles(() =>
  createStyles({
    formValue: {
    },
    switchMargin: {
      marginRight: '1rem',
    },
  }),
)


/**
 * Display user information
 */
const AccountInformation = () => {
  const classes = useStyles()
  const { mixpanel } = useMixPanel()

  const user = useSelector(selectUser)
  const isLoadingUser = useSelector(selectIsLoadingUser)
  const loadingUserError = useSelector(selectLoadingUserError)

  const [dbUser, setDbUser] = useState<User>()
  const [init, setInit] = useState<boolean>(true)

  usePageTitle('My Account')


  useEffect(() => {
    if (init && user) {
      setDbUser(user)
      setInit(false)
    }
  }, [user, init])

  useEffect(() => {
    if (mixpanel) {
      if (mixpanel) {
        mixpanel.track('pp_accountPage_open')
      }
    }
  }, [mixpanel])


  if (isLoadingUser) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
        <CircularProgress />
      </Box>
    )
  }


  return (
    <PreferencesSideMenuLayout>
      <Box my={2} mx={2}>
        <Grid container>
          <Grid item sm={4}>
            <Typography variant="h6">Personal Details</Typography>
            {loadingUserError && (
              <p>
                {user?.name},{' '}
                {'Couldn\'t fetch user information. Contact admin to sure your account is setup correctly.'}
              </p>
            )}
            {!loadingUserError && !isLoadingUser && (
              <>
                <List aria-label="Customer detail list" className="account-list">
                  <ListItem>
                    <ListItemText
                      className="account-form-label"
                      primary="First Name"
                      primaryTypographyProps={{
                        variant: 'subtitle1',
                      }}
                    />
                    <ListItemText
                      primaryTypographyProps={{
                        variant: 'body1',
                      }}
                      className={classes.formValue + ' account-form-value'}
                      primary={dbUser?.first_name}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      className="account-form-label"
                      primary="Last Name"
                      primaryTypographyProps={{
                        variant: 'subtitle1',
                      }}
                    />
                    <ListItemText
                      className={classes.formValue + ' account-form-value'}
                      primary={dbUser?.last_name}
                      primaryTypographyProps={{
                        variant: 'body1',
                      }}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      className="account-form-label"
                      primary="Email"
                      primaryTypographyProps={{
                        variant: 'subtitle1',
                      }}
                    />
                    <ListItemText
                      className={classes.formValue + ' account-form-value'}
                      primary={dbUser?.email}
                      primaryTypographyProps={{
                        variant: 'body1',
                      }}
                    />
                  </ListItem>
                </List>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </PreferencesSideMenuLayout>
  )
}

export default AccountInformation
