import { Tooltip, withStyles, Theme } from '@material-ui/core'

/**
 * Display info type tooltip with info background color
 * @param theme Theme object
 * @returns CustomTooltip JSX.element
 */
const TourTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.brand.contrast.main,
    color: theme.palette.text.primary,
    maxWidth: 300,
    fontWeight: 300,
    fontSize: '1.25rem',
    padding: theme.spacing(1),
    lineHeight: '1.4',
    borderRadius: 4,
  },
  arrow: {
    '&::before': {
      backgroundColor: theme.brand.contrast.main,
      border: `1px solid ${theme.brand.contrast.main}`,
    },
  },
}))(Tooltip)

export default TourTooltip
