import React from 'react'
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  emphasis: {
    fontWeight: 500,
    color: theme.palette.secondaryGreyTint,
  },
}))

const Emphasis = props => {
  const classes = useStyles()
  return <span className={classes.emphasis} {...props} />
}

export default Emphasis

