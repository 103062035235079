import React, { PropsWithChildren } from 'react'
import { TourProvider } from '@reactour/tour'
import { Position, PositionProps } from '@reactour/popover/src/Popover'
import { theme } from 'theme'

export const getTourProviderStyle = () => {
  return {
    // The tour step popup dialog.
    popover: (base: any) => ({
      ...base,
      padding: 0,
      borderRadius: 10,
    }),
    // The background/backdrop area that is NOT highlighted.
    maskWrapper: (base: any) => ({
      ...base,
      color: theme.palette.grey[500],
      opacity: '50%',
    }),
    // The background/backdrop area that is highlighted.
    maskArea: (base: any) => ({
      ...base,
      rx: 10,
    }),
  }
}

const ContentComponent = (props: any) => {
  const content = props.steps[props.currentStep].content

  return (
    <>
      {/* If content is a function then pass some additional properties to handle navigation. */}
      {typeof content === 'function' ?
        content({
          ...props,
          steps: props.steps,
        }) :
        content}
    </>
  )
}

function calcPopoverPosition(sizes: PositionProps):  Position {
  const { windowHeight, height } = sizes
  // Bottom left of the screen.
  return [20, windowHeight - height - 20]
}

export const TourProviderWithCustomOptions = ({ children }: PropsWithChildren<{}>) => {
  return (
    <TourProvider
      steps={[]}
      ContentComponent={ContentComponent}
      styles={getTourProviderStyle()}
      position={calcPopoverPosition}
      disableDotsNavigation={true}
      disableKeyboardNavigation={true}
      showNavigation={false}
      showCloseButton={false}
      showBadge={false}
      disableFocusLock={true}
      disableInteraction={true}
      onClickMask={() => {}} // Do nothing. Disabled!
    >
      {children}
    </TourProvider>
  )
}
