import httpService from 'state-mngt/services/data/http-service'
import { Country } from 'utils/constants/province-lookup-options'

export interface Taxes {
  amount: number
  percentage: string
  type: string
}

export interface InitPaymentResponse {
  client_secret: string
  unit_amount: number
  currency: string
  payment_intent_id: string
  taxes: Taxes[]
}

class StripeService {
  /**
     * calls API to get session id to direct user to stripe's checkout page
     * @param qty - number
     * @returns void
     */
  fetchCheckoutSession = async (qty: number): Promise<{ id: string }> => {
    return httpService.post('/stripe/checkout_session/', {
      quantity: qty,
    })
  }

  initPayment = async ({ quantity, country }: { quantity: number, country: Country }): Promise<InitPaymentResponse> => {
    const {
      client_secret, unit_amount, currency, payment_indent_id, taxes, 
    } = await httpService.get(
      `/stripe/payment_intent?quantity=${quantity}&country=${country}`)

    return {
      client_secret,
      unit_amount,
      currency,
      payment_intent_id: payment_indent_id,
      taxes,
    }
  }

  calculateTax = async ({
    quantity, country, payment_intent_id, postal_code, 
  }:
    { quantity: number, country: string, postal_code: string, payment_intent_id: string }): Promise<InitPaymentResponse> => {

    const {
      taxes, unit_amount, currency, client_secret, 
    } = await httpService.get(
      `/stripe/payment_intent?quantity=${quantity}&country=${country}&payment_intent_id=${payment_intent_id}&postal_code=${postal_code}`)

    return {
      payment_intent_id,
      client_secret,
      unit_amount,
      currency,
      taxes,
    }
  }
}

const stripeService = new StripeService()
export default stripeService
