import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, CircularProgress } from '@material-ui/core'
import { TeamMember, UserRole } from 'state-mngt/models/user'
import { useEffectOnce } from 'utils/hooks/lifecycle'
import TeamListTable from 'features/preferences/team-mngt/team-list/components/team-list-table'
import TeamListAdd from 'features/preferences/team-mngt/team-list/components/team-list-add'
import TeamConfirmationDialog from 'features/preferences/team-mngt/team-confirmation-dialog'
import userService from 'state-mngt/services/user-service'
import { selectCompanyId } from 'state-mngt/selectors/company-selectors'
import {
  deleteInviteForUserToJoinCompany,
  deleteMemberRole,
  getCompanyMembersAndInvites, inviteUserToJoinCompany,
  resendInviteForUserToServiceCompany,
} from 'state-mngt/actions/company-actions'
import { RootState } from 'state-mngt/store'
import { ServiceCompanyInviteResult } from 'state-mngt/models/serviceCompany'
import { MemberStatus } from 'utils/constants/member-enums'
import { useSnackbar } from 'notistack'
import {
  ErrorAutoHideWithDismissAction, SuccessAutoHideWithDismissAction,
} from 'ui/custom-snackbar-provider'
import { HavenApiErrorCode, HttpError } from 'state-mngt/models/http'
import { useMixPanel } from 'features/analytics/mixpanel-provider'

const TeamList = () => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { mixpanel } = useMixPanel()

  const [items, setItems] = useState<TeamMember[]>([])
  const [resendItem, setResendItem] = useState<null | TeamMember>(null)
  const [removeItem, setRemoveItem] = useState<null | TeamMember>(null)
  const companyId = useSelector(selectCompanyId)
  const isLoadingMembersAndInvites = useSelector((state: RootState) => state.company.isLoadingMembersAndInvites)
  const companyMembersAndInvites = useSelector((state : RootState) => state.company.membersAndInvites)

  const onStartResend = useCallback((item: TeamMember) => {
    if(mixpanel) {
      mixpanel.track('pp_teamMgmtPage_resendInvitationButton_openDialog')
    }

    setResendItem(item)
  }, [])

  const onCancelResend = useCallback(() => {
    setResendItem(null)
  }, [])

  const onResend = useCallback(async (resendItems: TeamMember[]) => {
    const user = resendItems[0]

    if (companyId && user.invite_code) {
      if (user.status === MemberStatus.PENDING) {
        // @ts-ignore
        (dispatch(
          resendInviteForUserToServiceCompany(user.invite_code)) as Promise<ServiceCompanyInviteResult>
        ).then(() => {
          enqueueSnackbar('Your invitation has been resent.', SuccessAutoHideWithDismissAction())
          dispatch(getCompanyMembersAndInvites(companyId))
        }).catch((reason => {
          enqueueSnackbar(
            <div>
              Uhoh, invitation couldn’t be sent at this time. Please check your connection and try again later.<br/>
              <b>• Error Code: {reason.code}</b>
            </div>,
            ErrorAutoHideWithDismissAction(),
          )
        }))
      } else if (user.status === MemberStatus.EXPIRED) {
        // @ts-ignore
        (dispatch(
          inviteUserToJoinCompany(
            {
              service_company_id: companyId, team: user.team, email: user.email,
            },
          ),
        ) as Promise<ServiceCompanyInviteResult>)
          .then(() => {
            enqueueSnackbar('Your invitation has been resent.', SuccessAutoHideWithDismissAction())
            dispatch(getCompanyMembersAndInvites(companyId))
          })
          .catch((reason => {
            enqueueSnackbar(
              <div>
                Uhoh, invitation couldn’t be sent at this time. Please check your connection and try again later.<br/>
                <b>• Error Code: {reason.code}</b>
              </div>,
              ErrorAutoHideWithDismissAction(),
            )
          }))
      } else {
        // THIS CASE IS NOT SUPPOSED TO HAPPEN since we do not visually allow the user to resend if it's active but still
        // we have this safety check here.
        enqueueSnackbar(
          <div>
            Uhoh, invitation couldn’t be sent at this time.&nbsp;
            A user with this email address has already been added to a service company.&nbsp;<br/>
            <b>• Error Code: {HavenApiErrorCode.INTERNAL_ERROR}</b>
          </div>,
          ErrorAutoHideWithDismissAction(),
        )
      }

      onCancelResend()
    }
  }, [enqueueSnackbar, companyId, onCancelResend])

  const onStartRemove = useCallback((item: TeamMember) => {
    if(mixpanel) {
      mixpanel.track('pp_teamMgmtPage_removeMemberButton_openDialog')
    }

    setRemoveItem(item)
  }, [])

  const onCancelRemove = useCallback(() => {
    setRemoveItem(null)
  }, [])

  const onRemove = useCallback((removeItems: TeamMember[]) => {
    const itemToRemove = removeItems[0]

    if (itemToRemove.status === MemberStatus.ACTIVE) {
      if (companyId && itemToRemove.id && itemToRemove.roles) {
        const promisesDispatch: Promise<HttpError | void>[] = []

        for (const role of itemToRemove.roles) {
          const userRole: UserRole = {
            user_id: itemToRemove.id,
            service_company_id: companyId,
            role,
          }

          promisesDispatch.push(
            // @ts-ignore
            (dispatch(deleteMemberRole(userRole)) as Promise<HttpError | void>),
          )
        }

        (Promise as any).allSettled(promisesDispatch)
          .then((results: Object[]) => {
            const allRejected = results.filter(currentElement => {
              // @ts-ignore
              return currentElement.status === 'rejected'
            })

            const allFulfilled = results.filter(currentElement => {
              // @ts-ignore
              return currentElement.status === 'fulfilled'
            })

            if (allRejected && allRejected.length > 0) {
              enqueueSnackbar(
                <div>
                  Uhoh, this team member couldn’t be removed at this time.
                  &nbsp;Please check your connection and try again later.
                </div>,
                ErrorAutoHideWithDismissAction(),
              )
            } else if (allFulfilled && allFulfilled.length > 0) {
              enqueueSnackbar('Your team-mngt has been updated successfully.', SuccessAutoHideWithDismissAction())
              // Reload the entire list, so we can show if there is an old expired invite for the user that
              // has just been deleted.
              dispatch(getCompanyMembersAndInvites(companyId))
            }
          })
      }
    } else {
      if (companyId && itemToRemove.invite_code) {
        // @ts-ignore
        (dispatch(deleteInviteForUserToJoinCompany(
          {
            service_company_id: companyId, invite_code: itemToRemove.invite_code,
          },
        )) as Promise<ServiceCompanyInviteResult>)
          .then(() => {
            enqueueSnackbar('Your team-mngt has been updated successfully.', SuccessAutoHideWithDismissAction())
            dispatch(getCompanyMembersAndInvites(companyId))
          })
          .catch((reason => {
            enqueueSnackbar(
              <div>
                Uhoh, this team member couldn’t be removed at this time.
                &nbsp;Please check your connection and try again later.<br/>
                <b>• Error Code: {reason.code}</b>
              </div>,
              ErrorAutoHideWithDismissAction(),
            )
          }))
      }
    }

    onCancelRemove()
  }, [enqueueSnackbar, companyId, onCancelRemove])

  const onSave = useCallback((members: TeamMember[]): Promise<void> => {
    if (companyId) {
      return Promise.all(members.map(member => userService.updateUserTeam(companyId, member.id, member.team)))
        .then(() => {
          const getUpdatedItems = (items: TeamMember[]) => items.map(
            (item) => {
              const updatedMember = members.find((member) => member.id === item.id)
              if (updatedMember) {
                return updatedMember
              }
              return item
            },
          )
          setItems(getUpdatedItems)
          enqueueSnackbar('Members updated successfully.', SuccessAutoHideWithDismissAction())
        })
        .catch((reason) => {
          enqueueSnackbar(
            <div>
              Uhoh, your changes couldn’t be saved at this time. Please check your connection and try again later.<br/>
              <b>• Error Code: {reason.code}</b>
            </div>,
            ErrorAutoHideWithDismissAction(),
          )
        })
    } else {
      enqueueSnackbar(
        <div>
          Uhoh, your changes couldn’t be saved at this time. Please check your connection and try again later.<br/>
          <b>• Error Code: {HavenApiErrorCode.INTERNAL_ERROR}</b>
        </div>,
        ErrorAutoHideWithDismissAction(),
      )

      return Promise.reject<void>()
    }
  }, [enqueueSnackbar, companyId, setItems])

  useEffectOnce((markAsCalled) => {
    if (companyId) {
      markAsCalled()
      dispatch(getCompanyMembersAndInvites(companyId))
    }
  }, [companyId])

  useEffect(() => {
    if (companyMembersAndInvites && !isLoadingMembersAndInvites) {
      setItems(companyMembersAndInvites)
    }
  }, [companyMembersAndInvites, isLoadingMembersAndInvites])

  return (
    <Box p={2}>
      {isLoadingMembersAndInvites ? (
        <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
          <CircularProgress />
        </Box>
      ) : (
        <Box mb={4}>
          <TeamListTable
            items={items}
            onSave={onSave}
            onStartResend={onStartResend}
            onStartRemove={onStartRemove}
          />
        </Box>
      )}
      <TeamListAdd />
      {resendItem && (
        <TeamConfirmationDialog
          type="resend"
          items={[resendItem]}
          onCancel={onCancelResend}
          onConfirm={onResend}
        />
      )}
      {removeItem && (
        <TeamConfirmationDialog
          type="remove"
          items={[removeItem]}
          onCancel={onCancelRemove}
          onConfirm={onRemove}
        />
      )}
    </Box>
  )
}

export default TeamList
