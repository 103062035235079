import { RootState } from 'state-mngt/store'
import {
  Dwelling,
  DwellingPermission,
} from 'state-mngt/models/dwelling'

export const selectDwellingDetails = (state: RootState): Dwelling | undefined => state.customer.customerDetails

export const selectIsLoadingDwellingPermission = (state: RootState): boolean =>
  state.customer.isLoadingDwellingPermission

export const selectDwellingPermission = (state: RootState): DwellingPermission[] | undefined =>
  state.customer.dwellingPermission
