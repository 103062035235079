import { RequestsAdapter, HavenApiErrorCode } from 'state-mngt/models/http'
import { APIInterval } from 'utils/constants/time-interval-api'
import { TimeInterval } from 'utils/constants/time-interval'

/**
 * Wrapper adapter for sending cancelable(abort) requests to our Data Source.
 */
export const createRequestsAdapter = (): RequestsAdapter => {
  let abortControllers: AbortController[] = []

  return {
    make: (cancelableFetch) => {
      if ('controller' in cancelableFetch) {
        abortControllers.push(cancelableFetch.controller)
        return cancelableFetch.promise
      }

      return cancelableFetch
    },
    throwOnAbort: (error) => {
      if (error.code === HavenApiErrorCode.INTERNAL_ERROR) {
        throw error
      }

      return undefined
    },
    abortAll: () => {
      abortControllers.forEach((abortController) => abortController.abort())
      abortControllers = []
    },
  }
}

/**
 * calculate interval for user
 * @param startTime - Date
 * @param endTime - Date
 * < 24 hours: use '5m'
 * > 60 days: use '1d'
 * otherwise defaults to '1h'
 */
export const getApiTimeInterval = (startTime: Date | null, endTime: Date | null): APIInterval => {
  let autoInterval = APIInterval.ONE_HOUR
  if (!startTime || !endTime) return autoInterval

  if (endTime.getTime() - startTime.getTime() < (TimeInterval.DAY * 5)) {
    autoInterval = APIInterval.FIVE_MIN
  }

  if (endTime.getTime() - startTime.getTime() > (TimeInterval.DAY * 100)) {
    autoInterval = APIInterval.ONE_DAY
  }

  return autoInterval
}
