import { useAuth0 } from '@auth0/auth0-react'
import { Grid, Typography } from '@material-ui/core'
import 'features/home/home.scss'
import React, { useEffect, useState } from 'react'
import { usePageTitle } from 'utils/hooks/router'
import useDecodedSearchParams from 'utils/hooks/useDecodedSearchParams'

/**
 * It redirects the user to the Auth0 login-redirect page in special circumstances, for example, if the user bookmark
 * the Auth0 login page and try to access after its "state" parameter is invalid.
 * This redirect will also be used to link to Auth0 login-redirect page using a QR Code.
 * @constructor
 */
export const LoginRedirect = () => {
  usePageTitle('Redirecting to login-redirect')

  const [searchParams] = useDecodedSearchParams()
  const redirectTo = searchParams.get('redirect_to') || ''
  const [showRedirectMessage, setShowRedirectMessage] = useState(false)
  const { loginWithRedirect } = useAuth0()

  useEffect(() => {
    setTimeout(() => {
      setShowRedirectMessage(true)
      setTimeout(() => loginWithRedirect({
        override_redirect_uri_to: redirectTo,
      }), 2000)
    }, 2000)
  })

  return (
    <>
      <Grid
        container
        className="home-page"
        direction="column"
        alignItems="center"
        style={{
          minHeight: '100%',
          verticalAlign: 'top',
        }}
      >
        <Grid item
          style={{
            margin: '20px',
          }}
        >
          <Typography variant="h1">Welcome to HAVEN!</Typography>
          <Typography variant="h1">We're excited to help you improve your HVAC service business.</Typography>
          <Typography variant="h5">Step 1 - sign up!</Typography>
          <br />
          {showRedirectMessage && <Typography variant="h5">Redirecting...</Typography>}
        </Grid>
      </Grid>
    </>
  )
}
