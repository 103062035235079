import { TelemetryDataType } from 'utils/constants/telemetry-data-type'
import { Device, DeviceTelemetry } from 'state-mngt/models/device'

export const GET_DEVICE_DETAILS = 'device/GET_DEVICE_DETAILS'
export const SET_DEVICE_TELEMETRY_CURRENT = 'device/SET_DEVICE_TELEMETRY_CURRENT'
export const SET_DEVICE_DATA_SETTING = 'device/SET_DEVICE_DATA_SETTING'

export interface SetDeviceAction {
  type: typeof GET_DEVICE_DETAILS;
  device: Device;
}

export interface DeviceDataSetting {
  secondType: TelemetryDataType | '';
  startTime: number;
  endTime: number;
  selectedOverlayOptions: string[];
  timeFrame?: string;
}

export interface SetDeviceCurrentTelemetryAction {
  type: typeof SET_DEVICE_TELEMETRY_CURRENT;
  deviceCurrentTelemetry: DeviceTelemetry
}
export interface SetDeviceDataSettingAction {
  type: typeof SET_DEVICE_DATA_SETTING;
  deviceDataSetting: DeviceDataSetting
}

export const setDevice = (device: Device): SetDeviceAction => ({
  type: GET_DEVICE_DETAILS,
  device,
})

export const setDeviceCurrentTelemetry = (
  (deviceCurrentTelemetry: DeviceTelemetry): SetDeviceCurrentTelemetryAction => ({
    type: SET_DEVICE_TELEMETRY_CURRENT,
    deviceCurrentTelemetry,
  })
)

export const setDeviceDataSetting = (deviceDataSetting: DeviceDataSetting): SetDeviceDataSettingAction => ({
  type: SET_DEVICE_DATA_SETTING,
  deviceDataSetting,
})
