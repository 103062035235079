import React from 'react'
import { StepType } from '@reactour/tour'
import {
  CloseStepCallbackFn,
  NextStepCallbackFn,
  PreviousStepCallbackFn,
  TourStepContent,
} from 'ui/tour-step-content'
import { Typography } from '@material-ui/core'


/**
 * CSS selectors of DOM elements to be highlighted during the product tour.
 */
export const RESOURCES_PAGE_TOUR_SELECTORS = {
  GUIDES: 'tab-name-guides',
  BROCHURES: 'tab-name-brochures',
  SPECS: 'tab-name-specs',
  CASES: 'tab-name-cases',
  VIDEOS: 'tab-name-videos',
}

/**
 * Get an array of steps used with "@reactour/tour" library for the resources page.
 * Each step contains elements that will be highlighted using the CSS selectors and popovers, a custom popup with an
 * explanation about the current step and buttons for going back or forward between the steps.
 * @param previousStepHandler PreviousStepCallbackFn function for handling the click of the previous button.
 * @param nextStepHandler NextStepCallbackFn function for handling the click of the next button.
 * @param closeStepHandler CloseStepCallbackFn function for handling the click of the close icon button.
 */
export const getResourcesPageTourSteps = (previousStepHandler: PreviousStepCallbackFn,
  nextStepHandler: NextStepCallbackFn, closeStepHandler: CloseStepCallbackFn) : StepType[] => {

  const tourStepName = 'resourcesPageTour'

  return [
    {
      selector: `[data-tour="${RESOURCES_PAGE_TOUR_SELECTORS.GUIDES}"]`,
      content: (props: any) => (
        <TourStepContent
          title="Guides & playbooks"
          tourStepName={tourStepName}
          content={
            <>
              <Typography variant="body1" gutterBottom>
                There's no better place to start - here you'll find our road-tested strategies for success with HAVEN
                in your HVAC Business!
              </Typography>
            </>
          }
          currentStep={props.currentStep + 1}
          totalSteps={props.steps.length}
          previousStepText="Previous"
          nextStepText="Next"
          onPreviousStep={previousStepHandler}
          onNextStep={nextStepHandler}
          onCloseStep={closeStepHandler}
        />
      ),
    },
    {
      selector: `[data-tour="${RESOURCES_PAGE_TOUR_SELECTORS.BROCHURES}"]`,
      content: (props: any) => (
        <TourStepContent
          title="Brochures"
          tourStepName={tourStepName}
          content={
            <>
              <Typography variant="body1" gutterBottom>
                Find out how HAVEN works for HVAC Pros like yourself, homeowners and even light commercial installs!
              </Typography>
            </>
          }
          currentStep={props.currentStep + 1}
          totalSteps={props.steps.length}
          previousStepText="Previous"
          nextStepText="Next"
          onPreviousStep={previousStepHandler}
          onNextStep={nextStepHandler}
          onCloseStep={closeStepHandler}
        />
      ),
    },
    {
      selector: `[data-tour="${RESOURCES_PAGE_TOUR_SELECTORS.SPECS}"]`,
      content: (props: any) => (
        <TourStepContent
          title="Spec sheets"
          tourStepName={tourStepName}
          content={
            <>
              <Typography variant="body1" gutterBottom>
                Let's get technical! Here you'll find the details on the HAVEN Central Air Monitor and Controller,
                including detection capabilities, installation requirements, and interactivity.
              </Typography>
            </>
          }
          currentStep={props.currentStep + 1}
          totalSteps={props.steps.length}
          previousStepText="Previous"
          nextStepText="Next"
          onPreviousStep={previousStepHandler}
          onNextStep={nextStepHandler}
          onCloseStep={closeStepHandler}
        />
      ),
    },
    {
      selector: `[data-tour="${RESOURCES_PAGE_TOUR_SELECTORS.CASES}"]`,
      content: (props: any) => (
        <TourStepContent
          title="Case studies"
          tourStepName={tourStepName}
          content={
            <>
              <Typography variant="body1" gutterBottom>
                Real-world case studies on how HAVEN has helped HVAC Pros <b>#gobeyondcomfort</b> and allow their
                customers to <b>#breathebetter.</b>
              </Typography>
            </>
          }
          currentStep={props.currentStep + 1}
          totalSteps={props.steps.length}
          previousStepText="Previous"
          nextStepText="Next"
          onPreviousStep={previousStepHandler}
          onNextStep={nextStepHandler}
          onCloseStep={closeStepHandler}
        />
      ),
    },
    {
      selector: `[data-tour="${RESOURCES_PAGE_TOUR_SELECTORS.VIDEOS}"]`,
      content: (props: any) => (
        <TourStepContent
          title="Video links"
          tourStepName={tourStepName}
          content={
            <>
              <Typography variant="body1" gutterBottom>
                All the best and most useful info condensed into informative and fun videos.
              </Typography>
              <br/>
              <Typography variant="body1">
                Reach out to our marketing team at <b>marketing@haveniaq.com</b> to get mobile- and tablet-friendly
                assets to use in the field.
              </Typography>
            </>
          }
          currentStep={props.currentStep + 1}
          totalSteps={props.steps.length}
          previousStepText="Previous"
          nextStepText="Next"
          onPreviousStep={previousStepHandler}
          onNextStep={nextStepHandler}
          onCloseStep={closeStepHandler}
        />
      ),
    },
  ]
}
