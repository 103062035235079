import React from 'react'
import { makeStyles } from "@material-ui/core"

interface DotProps {
  active: boolean
}

const useClasses = makeStyles(theme => ({
  dot: (props: DotProps) => ({
    animation: props.active ?
      'blink 2s infinite' :
      'none',
    background: props.active ?
      theme.palette.blewStream :
      theme.palette.charcoalTint,
    width: '8px',
    height: '8px',
    borderRadius: '50%',
  }),
  '@global @keyframes blink': {
    '0%, 100%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0,
    },
  },
}))

const Dot = ({ active, ...rest }: DotProps) => {
  const classes = useClasses({
    active,
  })
  return (
    <div
      title={active ? 'On' : 'Off'}
      className={classes.dot}
      {...rest} />
  )
}

export default Dot
