import {
  Box, Button, Typography, Grid, CircularProgress,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { RootState } from 'state-mngt/store'
import CompanyDetails from 'features/preferences/company/company-details'
import { Alert, AlertTitle } from '@material-ui/lab'
import EditIcon from '@material-ui/icons/Edit'
import CompanyEditDialog from 'features/preferences/company/company-edit-dialog'
import { getCompanyMembers } from 'state-mngt/actions/company-actions'
import { useMixPanel } from 'features/analytics/mixpanel-provider'
import { companyDetailsTooltip } from 'utils/constants/tooltip-content'
import { connect, ConnectedProps } from 'react-redux'
import PreferencesSideMenuLayout from 'features/preferences/preferences-side-menu-layout'
import { usePageTitle } from 'utils/hooks/router'

const mapStateToProps = (state: RootState) => {
  const { company, user } = state
  return {
    companyDetails: company.companyDetails,
    isLoadingCompany: company.isLoading,
    role: user.role,
    members: company.members,
    isLoadingMembers: company.isLoadingMembers,
  }
}

const connector = connect(mapStateToProps, {
  getCompanyMembers,
})

type Props = ConnectedProps<typeof connector>;

const CompanyTemplate = (props: Props) => {
  const { mixpanel } = useMixPanel()
  const {
    role, companyDetails, isLoadingCompany, getCompanyMembers,
  } = props

  usePageTitle('My Company')
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  useEffect(() => {
    /**
         * call API to get member list if the user is admin for company
         * set members state
         */
    const getMembers = async () => {
      if (!companyDetails || !companyDetails.id) {
        return []
      }

      getCompanyMembers(companyDetails.id)
    }

    if(role && role.manager) {
      getMembers()
    }

  }, [companyDetails, role, getCompanyMembers])

  useEffect(() => {
    if(mixpanel) {
      mixpanel.track('pp_companyPage_open')
    }
  }, [mixpanel])

  /**
     * callback fn for Edit Company pencil icon click
     */
  const handleDialogOpen = () => {
    if (mixpanel) {
      mixpanel.track('pp_companyPage_editCompanyDetailsButton')
    }
    setOpenDialog(true)
  }


  return (
    <PreferencesSideMenuLayout>
      <Box my={2} mx={2}>
        { companyDetails && openDialog &&
          <CompanyEditDialog
            open={openDialog}
            companyDetails={companyDetails}
            setOpen={setOpenDialog} />
        }
        <Grid container>
          <Grid item sm={5}>
            <Box display="flex">
              <Box display="flex">
                <Typography variant="h6">Service Company Details</Typography>
                {companyDetailsTooltip}
              </Box>
              {role?.manager && (
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  style={{
                    minWidth: '40px',
                    minHeight: '40px',
                  }}
                  onClick={handleDialogOpen}
                >
                  <EditIcon fontSize="small" />
                </Button>
              )}
            </Box>
            {isLoadingCompany &&
              <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
                <CircularProgress />
              </Box>
            }
            {!isLoadingCompany && !companyDetails &&
              <Box pr={2} mt={2}>
                <Alert severity="error">
                  <AlertTitle>Couldn&apos;t load Company Details</AlertTitle>
                      Please try again later.
                </Alert>
              </Box>
            }
            {companyDetails && !isLoadingCompany && <CompanyDetails companyDetails={companyDetails} />}
          </Grid>
        </Grid>
      </Box>
    </PreferencesSideMenuLayout>
  )

}

const Company = connector(CompanyTemplate)

export default Company
