import useDeviceTelemetry from './useDeviceTelemetry'
import { useEffect, useState } from 'react'
import useHasMonitor from 'utils/hooks/useHasMonitorInZone'
import useMonitorInCurrentZone from './useMonitorInCurrentZone'

// gets monitor telemetry from
// currently selected zone
const useMonitorTelemetry = (dataMin: Date, dataMax: Date) => {
  const [loading, setLoading] = useState(true)
  const hasMonitor = useHasMonitor()
  const monitor = useMonitorInCurrentZone()

  useEffect(() => {
    if (!hasMonitor) setLoading(false)
  }, [hasMonitor])

  const telemetry = useDeviceTelemetry({
    deviceId: monitor?.id,
    setLoading,
    dataMin,
    dataMax,
  })

  return {
    telemetry, hasMonitor, loading, 
  }
}

export default useMonitorTelemetry
