import React, { useEffect } from 'react'
import {
  Dialog, makeStyles, Typography, DialogContent, DialogActions, Button, DialogTitle, IconButton,
} from '@material-ui/core'
import { useMixPanel } from 'features/analytics/mixpanel-provider'
import { theme } from 'theme'
import CloseIcon from '@material-ui/icons/Close'
import { ArrowForward } from '@material-ui/icons'
import CustomersPageIntroImage from 'features/product-tour/customers-page/customers-page-intro.png'

const useStyles = makeStyles({
  dialogTitle: {
    margin: 0,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '500px',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

export interface CustomersPageIntroDialogProps {
  isOpen: boolean;
  onContinue?: () => void;
  onClose?: () => void;
}


export const CustomersPageIntroDialog = (props: CustomersPageIntroDialogProps) => {
  const classes = useStyles()
  const { isOpen = true, onContinue, onClose } = props
  const { mixpanel } = useMixPanel()


  const handleContinue = () => {
    if (mixpanel) {
      mixpanel.track('pp_productTour_customersPageIntroDialog_continueButton')
    }

    if (onContinue) {
      onContinue()
    }
  }

  const handleCloseDialog = () => {
    if (mixpanel) {
      mixpanel.track('pp_productTour_customersPageIntroDialog_closeButton')
    }

    if (onClose) {
      onClose()
    }
  }

  useEffect(() => {
    if (mixpanel && isOpen) {
      mixpanel.track('pp_productTour_customersPageIntroDialog_open')
    }
  }, [mixpanel, isOpen])


  return (
    <Dialog
      id="customers-page-intro-dialog"
      aria-labelledby="customers-page-intro-dialog-title"
      open={isOpen}
      onClose={ (event: object, reason: string) => {
        if (reason !== 'backdropClick') {
          handleCloseDialog()
        }
      }} >

      <DialogTitle id="customers-page-intro-dialog-title" disableTypography className={classes.dialogTitle}>
        <Typography variant="h3" color="secondary">Customers page</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseDialog}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <img
          src={CustomersPageIntroImage}
          alt="Customers Page Intro Image"
          style={{
            marginBottom: '30px',
          }}
        />
        <Typography variant="body1" color="textPrimary">
          This page helps you manage and prioritize your HAVEN customers' homes based on their IAQ data.
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={handleContinue}
          style={{
            margin: '1rem',
            minWidth: '220px',
          }}>
          <Typography variant="button">Continue</Typography>
          <ArrowForward style={{
            marginLeft: '7px',
            width: '17px',
            height: '17px',
          }}/>
        </Button>
      </DialogActions>
    </Dialog>
  )
}
