import { CacDeviceTelemetry } from 'state-mngt/models/device'
import { EquipmentType } from 'state-mngt/models/equipment'
import { TimeUnit } from 'utils/constants/time-interval'
import { convertToTimeUnit } from 'utils/time-utils'


export const getEquipmentNameForAutomation = (type: EquipmentType, 
  isVentilatingDehumidifierOutdoorDamperAutomation: boolean, isVentilatingDehumidifierFan: boolean): string => {
  switch (type) {
    case EquipmentType.Cah: {
      return 'Central air handler fan'
    }
    case EquipmentType.DeHumidifier: {
      return 'Dehumidifier'
    }
    case EquipmentType.Humidifier: {
      return 'Humidifier'
    }
    case EquipmentType.Erv: {
      return 'Mechanical Ventilation'
    }
    case EquipmentType.Ventilating_dehumidifier: {
      if (isVentilatingDehumidifierFan) {
        return 'Ventilating dehumidifier fan'
      } else {
        return (isVentilatingDehumidifierOutdoorDamperAutomation ? 
          'Ventilating dehumidifier' : 'Ventilating dehumidifier compressor')
      }
    }
    case EquipmentType.Outdoor_air_damper: {
      return 'Outdoor damper'
    }
    default: {
      return 'Other'
    }
  }
}

export const getFakeCacDeviceTelemetry = (startTime: Date, endTime: Date): CacDeviceTelemetry[] => {

  const telemetry: CacDeviceTelemetry[] = []
  // Store in milliseconds because we will do date operations using this time unit.
  let currentTime: number = startTime.getTime()
  const fiveMinInMs = convertToTimeUnit(1, TimeUnit.Days, TimeUnit.Milliseconds)

  while (currentTime < endTime.getTime()) {
    telemetry.push({
      channel0: true,
      channel1: true,
      timestamp: new Date(currentTime).toISOString(), //startTime.toISOString() 
    })

    currentTime = currentTime + fiveMinInMs
  }

  return telemetry
}