import React from 'react'
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  style: {
    color: theme.palette.charcoalGrey,
  },
}))

const Muted = props => {
  const classes = useStyles()
  return <span className={classes.style} {...props} />
}

export default Muted

