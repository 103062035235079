import * as React from 'react'
import PhoneNumberInput from 'react-phone-input-material-ui'
import {
    Backdrop,
    Button,
    Modal,
    Fade,
    TextField,
    Typography,
    createStyles,
    makeStyles,
    Box,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
} from "@material-ui/core"
import Stack from 'ui/stack'
import { Controller, useForm } from 'react-hook-form'
import httpService from 'state-mngt/services/data/http-service'
import useSWR from 'swr'
import { User } from 'state-mngt/models/user'
import { generatePath, useNavigate } from 'react-router-dom'
import { ROUTE_PATHS } from 'routes/routes'
import ButtonSpinner from 'features/customer-drill-down/equipment/button-spinner'
import { useSkipFirstRender } from 'features/customer-drill-down/equipment/util'
import Error from 'features/customer-drill-down/equipment/error'
import { apiUrl } from 'utils/api'
import { capitalize } from 'utils/string-utils'
import { formatPhone } from 'features/onboarding'
import { setUser } from 'state-mngt/actions/user-actions'
import useCurrentDwellingDetails from 'utils/hooks/useCurrentDwellingDetails'
import { useAppSelector } from 'utils/hooks/reduxTypes'
import { selectCompanyId } from 'state-mngt/selectors/company-selectors'

const padding = '32px'

const useStyles = makeStyles(theme => createStyles({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '390px',
        backgroundColor: 'white',
        borderRadius: '11px',
    },
    modalContent: {
        padding: `24px ${padding} ${padding}`,
    },
    error: {
        padding: `0 ${padding} ${padding}`,
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '24px',
        '@media (max-width: 768px)': {
            gridTemplateColumns: '1fr',
        },
    },
}))

const regions = {
    USA: [
        'Alabama',
        'Alaska',
        'Arizona',
        'Arkansas',
        'California',
        'Colorado',
        'Connecticut',
        'Delaware',
        'Florida',
        'Georgia',
        'Hawaii',
        'Idaho',
        'Illinois',
        'Indiana',
        'Iowa',
        'Kansas',
        'Kentucky',
        'Louisiana',
        'Maine',
        'Maryland',
        'Massachusetts',
        'Michigan',
        'Minnesota',
        'Mississippi',
        'Missouri',
        'Montana',
        'Nebraska',
        'Nevada',
        'New Hampshire',
        'New Jersey',
        'New Mexico',
        'New York',
        'North Carolina',
        'North Dakota',
        'Ohio',
        'Oklahoma',
        'Oregon',
        'Pennsylvania',
        'Rhode Island',
        'South Carolina',
        'Tennessee',
        'Texas',
        'Utah',
        'Vermont',
        'Virginia',
        'Washington',
        'West Virginia',
        'Wisconsin',
        'Wyoming',
    ],
    Canada: [
        'Alberta',
        'British Columbia',
        'Manitoba',
        'New Brunswick',
        'Newfoundland and Labrador',
        'Northwest Territories',
        'Nova Scotia',
        'Nunavut',
        'Ontario',
        'Prince Edward Island',
        'Quebec',
        'Saskatchewan',
        'Yukon',
    ],
}

const isPostalCode = (x: string) => x.match('^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$')
const isZipCode = (x: string) => x.match('^[0-9]{5}(?:-[0-9]{4})?$')

function DwellingForm({ onClose, userId }) {
    const classes = useStyles()

    const navigate = useNavigate()
    const [error, setError] = React.useState('')
    const [loading, setLoading] = React.useState(false)

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control,
        watch,
    } = useForm({ mode: 'onChange' })

    const country = watch('country')
    const province = watch('province')
    const postal_code = watch('postal_code')

    const companyId = useAppSelector(selectCompanyId)

    useSkipFirstRender(() => {
        if (!country) return
        const to = setTimeout(() => setValue('province', regions[country][0]), 300)
        return () => clearTimeout(to)
    }, [country])

    const onSubmitDwelling = data => {
        if (!userId) return console.warn('[onSubmitDwelling] Need an admin id')

        /**
         * creating a dwelling includes two implicit
         * properties: preferred_service_company_id
         * and admin_id
         */
        const save = async () => {
            setLoading(true)
            setError('')
            try {
                const response = await httpService.post(`/dwelling`, {
                    ...data,
                    admin_id: userId,
                    preferred_service_company_id: companyId,
                })
                // console.log(response)
                const { id } = response
                navigate(generatePath(ROUTE_PATHS.customers.details.root.absolute, {
                    dwellingId: id,
                }))
            } catch (e) {
                console.error(e)
                // @ts-ignore
                setError(e?.message || 'Network error')
                setLoading(false)
            }
        }

        if (!Object.keys(errors).length) save()
    }

    return (
        <form onSubmit={handleSubmit(onSubmitDwelling)}>
            <div className={classes.paper}>
                <div className={classes.modalContent}>
                    <Stack spacing={4}>
                        <Stack spacing={1}>
                            <Typography variant='h3'>
                                Create customer
                            </Typography>
                            <Typography>
                                Please enter the dwelling details
                            </Typography>
                        </Stack>
                        <Stack
                            justify='flex-start'
                            width='100%'
                            itemStyles={[{
                                width: '100%',
                            }]}
                        >
                            <TextField
                                label='Dwelling name'
                                size='small'
                                variant='outlined'
                                fullWidth
                                error={Boolean(errors['name'])}
                                autoFocus
                                {...register('name')}
                            />
                            <TextField
                                label='Address'
                                size='small'
                                variant='outlined'
                                fullWidth
                                error={Boolean(errors['street_1'])}
                                {...register('street_1')}
                            />
                            <TextField
                                label='Optional PO Box, Unit, etc.'
                                size='small'
                                variant='outlined'
                                fullWidth
                                error={Boolean(errors['street_2'])}
                                {...register('street_2')}
                            />
                            <FormControl
                                size='small'
                                variant='outlined'
                                fullWidth
                            >
                                <InputLabel htmlFor="country">Country</InputLabel>
                                <Controller
                                    control={control}
                                    name='country'
                                    render={({ field: { onChange, ...rest } }) => (
                                        <Select
                                            {...rest}
                                            // onChange={e => setValue('country', e.target.value)}
                                            id={`country`}
                                            labelId={`country`}
                                            label='Country'
                                            defaultValue='Canada'
                                            error={Boolean(errors['country'])}
                                            {...register('country')}
                                        >
                                            <MenuItem value='Canada'>Canada</MenuItem>
                                            <MenuItem value='USA'>USA</MenuItem>
                                        </Select>
                                    )}
                                />
                            </FormControl>
                            <FormControl
                                size='small'
                                variant='outlined'
                                fullWidth
                            >
                                <InputLabel htmlFor="province">{country === 'USA' ? 'State' : 'Province'}</InputLabel>
                                <Select
                                    id={`province`}
                                    labelId={`province`}
                                    label='province'
                                    value={province || regions[country || 'Canada'][1]}
                                    error={Boolean(errors['province'])}
                                    // onChange={e => setValue('province', e.target.value)}
                                    {...register('province')}
                                >
                                    {regions[country || 'Canada'].map(x => (
                                        <MenuItem key={x} value={x}>{x}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                label='City'
                                size='small'
                                variant='outlined'
                                fullWidth
                                error={Boolean(errors['city'])}
                                {...register('city')}
                            />
                            <TextField
                                label={country === 'USA' ? 'Zip code' : 'Postal code'}
                                size='small'
                                variant='outlined'
                                value={postal_code || ''}
                                fullWidth
                                error={Boolean(errors['postal_code'])}
                                {...register('postal_code', {
                                    validate: value =>
                                        Boolean(isZipCode(value) || isPostalCode(value)),
                                    setValueAs: value =>
                                        value.toUpperCase(),
                                })}
                            />
                        </Stack>
                    </Stack>
                </div>
                <Box
                    padding={4}
                    pt={0}
                >
                    <Box
                        display='flex'
                        justifyContent='space-between'
                    >
                        <Button
                            variant='outlined'
                            color='secondary'
                            size='small'
                            disabled={loading}
                            onClick={onClose}
                        >Discard</Button>
                        <Button
                            type='submit'
                            variant='contained'
                            color='primary'
                            size='small'
                            disabled={loading}
                        >{loading ? <ButtonSpinner /> : <>Save</>}</Button>
                    </Box>
                    {error ? (
                        <Typography style={{ marginTop: '8px' }}>
                            <Error>
                                {capitalize(error)}
                            </Error>
                        </Typography>
                    ) : null}
                </Box>
            </div>
        </form>
    )
}

function CreateNewUser({
    open,
    onClose,
}) {
    const classes = useStyles()

    const {
        register,
        unregister,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm({ mode: 'onChange' })

    const [step, setStep] = React.useState(1)
    const [userId, setUserId] = React.useState<string | null>(null)
    const [error, setError] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const [exists, setExists] = React.useState('')

    const navigate = useNavigate()

    // try to fetch the user here just so it's in cache
    const { data: user } = useSWR<User>(userId && `${apiUrl}/user/${userId}`)

    const getUser = async (email: string) => {
        try {
            const { id } = await httpService.get<User>(`/user?email=${email}`)
            setUserId(`${id}`)
        } catch (e) {
            console.error(e)
        }
    }

    React.useEffect(() => {
        if (!register) return
        register('phone')
        return () => {
            if (step === 0) unregister('phone')
        }
    }, [register, unregister])

    React.useEffect(() => {
        if (userId) setStep(1)
    }, [userId])

    React.useEffect(() => {
        if (exists) getUser(exists)
    }, [exists])

    const onSubmitUser = data => {
        const save = async () => {
            setLoading(true)
            setError('')
            try {
                const response = await httpService.post(`/user`, {
                    ...data,
                    role: 2,
                    password: 'Password1!',
                })
                // const response = await httpService.post(`/user`, {...data, role: 2})
                const { id } = response
                setUserId(id)
            } catch (e) {
                // @ts-ignore
                if (e?.code === 7) {
                    // user exists
                    return setExists(data.email)
                }
                console.error(e)
                // @ts-ignore
                setError(e?.message || 'Network error')
                setLoading(false)
            }
        }

        if (!Object.keys(errors).length) save()
    }

    const onChangePhone = value =>
        setValue('phone', formatPhone(value))

    React.useEffect(() => {
        if (user) navigate(`${ROUTE_PATHS.user.absolute}/${user.id}`)
    }, [JSON.stringify(user)])

    return (
        <Modal
            aria-describedby="create-new-customer-modal"
            className={classes.modal}
            open={open}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div>
                    {step === 0 ? (
                        <form onSubmit={handleSubmit(onSubmitUser)}>
                            <div className={classes.paper}>
                                <div className={classes.modalContent}>
                                    <Stack spacing={4}>
                                        <Stack spacing={1}>
                                            <Typography variant='h3'>
                                                Create customer
                                            </Typography>
                                            <Typography>
                                                Please enter the customer details. When you click “Send invitation” an email with a temporary password will be automatically sent to the homeowner to complete the account creation.
                                            </Typography>
                                        </Stack>
                                        <Stack
                                            justify='flex-start'
                                            width='100%'
                                            itemStyles={[{
                                                width: '100%',
                                            }]}
                                        >
                                            <TextField
                                                label='Firstname *'
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                error={Boolean(errors['first_name'])}
                                                autoFocus
                                                {...register('first_name')}
                                            />
                                            <TextField
                                                label='Lastname *'
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                error={Boolean(errors['last_name'])}
                                                {...register('last_name')}
                                            />
                                            <TextField
                                                label='Email *'
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                error={Boolean(errors['email'])}
                                                {...register('email')}
                                            />
                                            <PhoneNumberInput
                                                inputProps={{
                                                    label: 'Phone',
                                                    size: 'small',
                                                    variant: 'outlined',
                                                    error: Boolean(errors['phone']),
                                                }}
                                                onChange={onChangePhone}
                                                defaultMask='(...) ... ....'
                                                placeholder='(123) 555 5555'
                                                alwaysDefaultMask
                                                disableCountryCode
                                                component={TextField}
                                            />
                                        </Stack>
                                    </Stack>
                                </div>
                                <Box
                                    padding={4}
                                    pt={0}
                                >
                                    <Box
                                        display='flex'
                                        justifyContent='space-between'
                                    >
                                        <Button
                                            variant='outlined'
                                            color='secondary'
                                            size='small'
                                            disabled={loading}
                                            onClick={onClose}
                                        >Discard</Button>
                                        <Button
                                            type='submit'
                                            variant='contained'
                                            color='primary'
                                            size='small'
                                            disabled={loading}
                                        >{loading ? <ButtonSpinner /> : <>Save</>}</Button>
                                    </Box>
                                    {error ? (
                                        <Typography style={{ marginTop: '8px' }}>
                                            <Error>
                                                {capitalize(error)}
                                            </Error>
                                        </Typography>
                                    ) : null}
                                </Box>
                            </div>
                        </form>
                    ) : (
                        <DwellingForm
                            onClose={onClose}
                            userId={userId}
                        />
                    )}
                </div>
            </Fade>
        </Modal>
    )
}

export default CreateNewUser
