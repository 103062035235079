import React from "react"
import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core'
import Combobox from 'ui/combobox'
import Switch from 'ui/switch'
import useStore, {
    DuctFilterStateKey,
    resetDuctData,
    resetFilterData,
    selectDuctData,
    selectFilterData,
    setDuctData,
    setFilterData,
} from "stores/install"

const defaultDuctState = {
    duct_type: 'Return',
    duct_shape: 'rectangular',
    duct_material: 'rigid',
    duct_width: 10,
    duct_height: 10,
}

const defaultFilterState = {
    type: 'disposable pleated',
    rating: 'MERV 13',
    width: 10,
    height: 10,
    depth: 1.0,
}

export interface DuctState {
    duct_type: string
    duct_shape: string
    duct_material: string
    duct_width: number
    duct_height: number
}

export interface FilterState {
    type: string
    filter_shape: string
    filter_material: string
    width: number
    height: number
}

// export interface DuctState {
//     'airHandlerDuct': {
//         // duct: string
//         type: string
//         shape: string
//         material: string
//         width: number
//         height: number
//     },
//     'airHandlerFilter': {
//         type: string
//         rating: string
//         width: number
//         height: number
//         thickness: number
//     },
// }

// const formatDuctInformationForNetwork = (ductInformation: DuctState) => ({
//     'duct_type': ductInformation.airHandlerDuct.type,
//     'duct_shape': ductInformation.airHandlerDuct.shape,
//     'duct_material': ductInformation.airHandlerDuct.material,
//     'duct_width': ductInformation.airHandlerDuct.width,
//     'duct_height': ductInformation.airHandlerDuct.height,
// })

interface DuctInformationProps {
    stateKey: DuctFilterStateKey,
    // onChange: (x: ReturnType<typeof formatDuctInformationForNetwork>) => any
    onHide?: () => void
    isToggleable?: boolean
}

function DuctInformation({
    stateKey,
    onHide = () => { },
    isToggleable = true,
}: DuctInformationProps) {
    const [show, setShow] = React.useState(false)

    const ductInformation = useStore(selectDuctData(stateKey))
    const filterInformation = useStore(selectFilterData(stateKey))

    React.useEffect(() => {
        if (!ductInformation) setDuctData(stateKey)(defaultDuctState) // set defaults only if nothing has been set
        if (!filterInformation) setFilterData(stateKey)(defaultFilterState)
    }, [stateKey])

    React.useEffect(() => {
        if (!show) return
        if (!ductInformation) setDuctData(stateKey)(defaultDuctState) // set defaults only if nothing has been set
        if (!filterInformation) setFilterData(stateKey)(defaultFilterState)
    }, [isToggleable, show])

    React.useEffect(() => {
        if (isToggleable && !show) {
            setDuctData(stateKey)(defaultDuctState) // set defaults only if nothing has been set
            setFilterData(stateKey)(defaultFilterState)
        }
        // reset the state if a user changes equipment
        if (isToggleable && !show) {
            resetFilterData(stateKey)
            resetDuctData(stateKey)
            onHide()
        }

    }, [show, isToggleable])

    const ductSetter = key => value =>
        setDuctData(stateKey)({ [key]: value })

    const filterSetter = key => value =>
        setFilterData(stateKey)({ [key]: value })

    const hasData = ductInformation && filterInformation

    return (
        <Box mt={3}>
            {isToggleable && (
                <Box
                    display='flex'
                    width='100%'
                    alignItems='center'
                    justifyContent='space-between'
                >
                    <p style={{ color: 'gray' }}>Add duct and filter information</p>
                    <Switch
                        checked={show}
                        onChange={e => setShow(e.target.checked)}
                    />
                </Box>
            )}
            {hasData && (!isToggleable || show) ? (
                <>
                    <Box>
                        <Typography variant='subtitle2'>
                            Air handler duct
                        </Typography>
                        <Box mt={2}>
                            <Combobox
                                options={[{
                                    title: 'Return',
                                }, {
                                    title: 'Supply',
                                }, {
                                    title: 'Outdoor intake',
                                }]}
                                value={ductInformation.duct_type}
                                onChange={ductSetter('duct_type')}
                                label='Type'
                            />
                        </Box>
                        <Box mt={2}>
                            <FormControl
                                size='small'
                                variant='outlined'
                                fullWidth
                            >
                                <InputLabel>
                                    Duct shape
                                </InputLabel>
                                <Select
                                    id={`shape`}
                                    name={`shape`}
                                    labelId={`shape`}
                                    label='Duct shape'
                                    value={ductInformation.duct_shape}
                                    onChange={e => ductSetter('duct_shape')(e.target.value)}
                                >
                                    <MenuItem value='rectangular'>
                                        Rectangular
                                    </MenuItem>
                                    <MenuItem value='round'>
                                        Round
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        {ductInformation.duct_shape === 'rectangular' ? (
                            <Box
                                display='grid'
                                gridTemplateColumns='1fr 1fr'
                                gridGap='8px'
                                mt={2}
                            >
                                <TextField
                                    size='small'
                                    variant='outlined'
                                    label='Width (in)'
                                    type='number'
                                    value={ductInformation.duct_width}
                                    onChange={e => ductSetter('duct_width')(e.target.value)}
                                />
                                <TextField
                                    size='small'
                                    variant='outlined'
                                    label='Height (in)'
                                    type='number'
                                    value={ductInformation.duct_height}
                                    onChange={e => ductSetter('duct_height')(e.target.value)}
                                />
                            </Box>
                        ) : (
                            <Box
                                mt={2}
                                width='100%'
                            >
                                <TextField
                                    size='small'
                                    variant='outlined'
                                    label='Diameter (in)'
                                    type='number'
                                    value={ductInformation.duct_width}
                                    fullWidth
                                    onChange={e => {
                                        ductSetter('duct_width')(e.target.value)
                                        ductSetter('duct_height')(e.target.value)
                                    }}
                                />
                            </Box>
                        )}
                        <Box mt={2}>
                            <FormControl
                                size='small'
                                variant='outlined'
                                fullWidth
                            >
                                <InputLabel>
                                    Material
                                </InputLabel>
                                <Select
                                    id={`material`}
                                    name={`material`}
                                    labelId={`material`}
                                    label='Material'
                                    value={ductInformation.duct_material}
                                    onChange={e => ductSetter('duct_material')(e.target.value)}
                                >
                                    <MenuItem value='rigid'>
                                        Rigid (metal)
                                    </MenuItem>
                                    <MenuItem value='duct board'>
                                        Duct board
                                    </MenuItem>
                                    <MenuItem value='flex'>
                                        Flex duct
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box mt={3}>
                        <Typography variant='subtitle2'>
                            Air handler filter
                        </Typography>
                        <Box mt={2}>
                            <FormControl
                                size='small'
                                variant='outlined'
                                fullWidth
                            >
                                <InputLabel>
                                    Filter type
                                </InputLabel>
                                <Select
                                    id={`filterType`}
                                    name={`filterType`}
                                    labelId={`filterType`}
                                    label='Filter type'
                                    value={filterInformation.type}
                                    onChange={e => filterSetter('type')(e.target.value)}
                                >
                                    <MenuItem value='disposable fiberglass'>
                                        Disposable fiberglass
                                    </MenuItem>
                                    <MenuItem value='disposable pleated'>
                                        Disposable pleated
                                    </MenuItem>
                                    <MenuItem value='disposable electrostatic'>
                                        Disposable electrostatic
                                    </MenuItem>
                                    <MenuItem value='permanent electrostatic'>
                                        Permanent electrostatic
                                    </MenuItem>
                                    <MenuItem value='electronic filter'>
                                        Electronic filter
                                    </MenuItem>
                                    <MenuItem value='hepa'>
                                        HEPA
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box mt={2}>
                            <FormControl
                                size='small'
                                variant='outlined'
                                fullWidth
                            >
                                <InputLabel>
                                    Rating
                                </InputLabel>
                                <Select
                                    id={`filterRating`}
                                    name={`filterRating`}
                                    labelId={`filterRating`}
                                    label='Rating'
                                    value={filterInformation['rating']}
                                    onChange={e => filterSetter('rating')(e.target.value)}
                                >
                                    <MenuItem value='MERV 6'>
                                        MERV 6
                                    </MenuItem>
                                    <MenuItem value='MERV 8'>
                                        MERV 8
                                    </MenuItem>
                                    <MenuItem value='MERV 11'>
                                        MERV 11
                                    </MenuItem>
                                    <MenuItem value='MERV 12'>
                                        MERV 12
                                    </MenuItem>
                                    <MenuItem value='MERV 13'>
                                        MERV 13
                                    </MenuItem>
                                    <MenuItem value='MERV 15'>
                                        MERV 15
                                    </MenuItem>
                                    <MenuItem value='MERV 16'>
                                        MERV 16
                                    </MenuItem>
                                    <MenuItem value='MPR 300'>
                                        MPR 300
                                    </MenuItem>
                                    <MenuItem value='MPR 600'>
                                        MPR 600
                                    </MenuItem>
                                    <MenuItem value='MPR 1000'>
                                        MPR 1000
                                    </MenuItem>
                                    <MenuItem value='MPR 1200'>
                                        MPR 1200
                                    </MenuItem>
                                    <MenuItem value='MPR 1500'>
                                        MPR 1500
                                    </MenuItem>
                                    <MenuItem value='MPR 1900'>
                                        MPR 1900
                                    </MenuItem>
                                    <MenuItem value='MPR 2200'>
                                        MPR 2200
                                    </MenuItem>
                                    <MenuItem value='MPR 2800'>
                                        MPR 2800
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box
                            display='grid'
                            gridTemplateColumns='1fr 1fr'
                            gridGap='8px'
                            mt={2}
                        >
                            <TextField
                                size='small'
                                variant='outlined'
                                id='filter-width'
                                label='Width (in)'
                                type='number'
                                value={filterInformation['width']}
                                onChange={e => filterSetter('width')(e.target.value)}
                            />
                            <TextField
                                size='small'
                                variant='outlined'
                                id='filter-height'
                                label='Height (in)'
                                type='number'
                                value={filterInformation['height']}
                                onChange={e => filterSetter('height')(e.target.value)}
                            />
                        </Box>
                        <Box
                            mt={2}
                            width='100%'
                        >
                            <FormControl
                                size='small'
                                variant='outlined'
                                fullWidth
                            >
                                <InputLabel>
                                    Thickness (in)
                                </InputLabel>
                                <Select
                                    id={`filterThickness`}
                                    name={`filterThickness`}
                                    labelId={`filterThickness`}
                                    label='Thickness (in)'
                                    value={filterInformation['depth']}
                                    onChange={e => filterSetter('depth')(e.target.value)}
                                >
                                    <MenuItem value={1.0}>1</MenuItem>
                                    <MenuItem value={1.75}>1.75</MenuItem>
                                    <MenuItem value={2.0}>2</MenuItem>
                                    <MenuItem value={3.0}>3</MenuItem>
                                    <MenuItem value={4.375}>4.375</MenuItem>
                                    <MenuItem value={5.0}>5</MenuItem>
                                    <MenuItem value={5.5}>5.5</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                </>
            ) : (null)}
        </Box >
    )
}

export default DuctInformation
