export enum DeviceTriggerType {
  Schedule = 'schedule',
  Humidity = 'humidity',
  Temperature = 'temperature',
  tVOC = 'voc',
  PM = 'pm25',
  None = 'none',
}

/**
 * These are the different ranges of air quality that can be used when determining automation conditions.
 */
export enum AutomationRange {
  RANGE_GOOD = 'good',
  RANGE_FAIR = 'fair',
  RANGE_POOR = 'poor',
}

/**
 * These are the different ranges of outdoor air quality that can be used when determining automation conditions.
 */
export enum OutdoorAqiRange {
  RANGE_GOOD = 2,
  RANGE_FAIR = 3,
}

export interface VentilatingDehumidifierAutomations {
  num_automations: number,
  automations: VentilatingDehumidifierAutomationActivation[],
}

export interface VentilatingDehumidifierAutomationActivation {
  id: number;
  cam_device_id: number,
  damper_device_id: number,
  damper_channel_index: number,
  dehumidifier_dehumidification_device_id: number,
  dehumidifier_dehumidification_channel_index: number,
  dehumidifier_fan_device_id?: number,
  dehumidifier_fan_channel_index?: number,
  central_air_handler_device_id?: number,
  central_air_handler_channel_index?: number,
  damper_equipment_id: number,
  dehumidifier_equipment_id: number,
  central_air_handler_equipment_id?: number,
  dewpoint_set_point: number,
  dewpoint_set_point_unit: string,
  voc_set_point: string,
  pm25_set_point: string,
  outdoor_aqi_set_point: number,
  active: boolean,
}
