import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core'
import * as React from 'react'
import { Cancel } from '@material-ui/icons'
import monitorIcon from '../../icons/monitor-icon.svg'
import {
    Card,
    InnerCard,
} from './new-install'
import Muted from '../muted'
import NewController, { formattedBrands } from './new-controller'
import useStore, {
    selectMonitorEquipment,
    selectMonitorBrand,
    setIsValid,
    setMonitorData,
    selectMonitorModel,
} from 'stores/install'
import DuctInformation from './duct-information'
import Combobox from 'ui/combobox'
import AddNew from './add-new'

function NewMonitor() {
    const [open, setOpen] = React.useState(false)
    const brandRef = React.useRef<HTMLInputElement>(null)

    const brand = useStore(selectMonitorBrand)
    const model = useStore(selectMonitorModel)
    const equipment = useStore(selectMonitorEquipment)

    const setEquipment = equipment => setMonitorData({ equipment })
    const setBrand = brand => setMonitorData({ brand })
    const setModel = model => setMonitorData({ model })

    const onSelectEquipment = (e) => setEquipment(e.target.value)
    const showBrand = equipment && (equipment !== 'Select...')

    React.useEffect(() => {
        if (showBrand && brandRef.current) brandRef.current.focus()
    }, [showBrand, brandRef.current])

    React.useEffect(() => {
        const isValid = Boolean(brand && equipment && (equipment !== 'Select...'))
        setIsValid(1)(isValid)
        if (isValid) setOpen(true)
    }, [
        brand,
        equipment,
    ])

    return (
        <>
            {!open ? (
                <AddNew onClick={() => setOpen(true)}>
                    Add monitor
                </AddNew>
            ) : (
                <InnerCard p={2}>
                    <Box
                        display='flex'
                        alignItems='center'
                        width='100%'
                        gridGap='16px'
                    >
                        <img src={monitorIcon} />
                        <Box flex='1'>
                            <Typography style={{ fontWeight: 'normal' }}>HAVEN monitor</Typography>
                            <Muted>New</Muted>
                        </Box>
                        <Cancel
                            onClick={() => setOpen(false)}
                            style={{ cursor: 'pointer' }}
                        />
                    </Box>
                    <Box mt={3}>
                        <p>Choose the blower equipment that the monitor will be installed with</p>
                        <FormControl
                            size='small'
                            variant='outlined'
                            fullWidth
                        >
                            <InputLabel
                                htmlFor='equipment'
                            >Equipment</InputLabel>
                            <Select
                                id='equipment'
                                name='equipment'
                                label='Equipment'
                                value={equipment}
                                onChange={onSelectEquipment}
                            >
                                <MenuItem disabled value='Select...'>
                                    Select...
                                </MenuItem>
                                <MenuItem value='central_air_handler'>
                                    Air handler blower fan
                                </MenuItem>
                                <MenuItem value='mechanical_ventilation'>
                                    Mechanical ventilation fan
                                </MenuItem>
                                <MenuItem value='ventilating_dehumidifier_fan'>
                                    Ventilating dehumidifier fan
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    {showBrand && (
                        <>
                            <Box mt={2}>
                                <Combobox
                                    value={brand}
                                    options={formattedBrands}
                                    onChange={setBrand}
                                    label='Equipment brand'
                                    ref={brandRef}
                                />
                            </Box>
                            <Box mt={2}>
                                <TextField
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    label='Equipment model'
                                    value={model}
                                    onChange={e => setModel(e.target.value)}
                                />
                            </Box>
                        </>
                    )}
                    <DuctInformation
                        isToggleable={false}
                        stateKey='monitor'
                    />
                </InnerCard>
            )}
        </>
    )
}

function EquipmentStep() {
    return (
        <>
            <Typography variant='h3'>
                Set up equipment
            </Typography>
            <Box
                mt={4}
                display='grid'
                gridGap='16px'
                width='100%'
                gridTemplateColumns={[
                    '1fr',
                    '1fr 1fr',
                    '400px 400px 400px',
                ]}
                alignItems='start'
            >
                <Card p={2}>
                    <p>
                        Add HAVEN monitor that will be used with gathering telemtry
                    </p>
                    <NewMonitor />
                </Card>
                <Card p={2}>
                    <p>
                        Add HAVEN controller that will be used with gathering telemtry
                    </p>
                    <NewController controller={0} />
                </Card>
                <Card p={2}>
                    <p>
                        Add HAVEN controller that will be used with gathering telemtry
                    </p>
                    <NewController controller={1} />
                </Card>
            </Box>
        </>
    )
}

export {
    DuctInformation,
}

export default EquipmentStep
