import { CacDeviceTelemetry, DeviceTelemetry } from 'state-mngt/models/device'
import {
  SeriesXrangeOptions,
  XrangePointOptionsObject,
  YAxisOptions,
} from 'highcharts'
import { TELEMETRY_THRESHOLD } from 'utils/constants/telemetry-threshold'
import { GanttSeriesOptions, GanttSeriesType, SERIES_COLORS } from 'features/customer-drill-down/charts/device-chart/models'
import { CacChannelInfo, CacChannelTelemetry } from 'state-mngt/models/dwelling'
import { getNormalizedTelemetry } from 'features/customer-drill-down/charts/device-chart/chart-utils'
import { getFakeCacDeviceTelemetry } from 'utils/equipment-utils'

export const getSeriesColor = (index: number): string => {
  return SERIES_COLORS[index % SERIES_COLORS.length]
}

/**
 * Create gantt chart data from telemetry data
 * @param telemetryData - DeviceTelemetry[]
 * @returns data for gantt chart - XrangePointOptionsObject
 */
export const getAirflowData = (telemetryData: DeviceTelemetry[]): XrangePointOptionsObject[] => {
  // return empty array if input is empty array
  if (telemetryData.length === 0) {
    return []
  }

  const modifiedArr = getNormalizedTelemetry<DeviceTelemetry>(telemetryData, {
    timestamp: '',
    airflow: 0,
    voc_mc: 0,
    voc_count: 0,
    pm_count: 0,
    pm_mc: 0,
    humidity: 0,
    temperature: 0,
    voc_status: '',
    pm_status: '',
    combined_status: '',
    highest_factor: '',
  })

  const dataArray: XrangePointOptionsObject[] = []
  let dataPoint: XrangePointOptionsObject = {
  }

  let prevAirflow = false
  let currentAirflow = Math.abs(modifiedArr[0].airflow) >= TELEMETRY_THRESHOLD.airflow

  // check first data point in array, if it has airflow then add x value
  if (currentAirflow) {
    dataPoint = {
      x: new Date(modifiedArr[0].timestamp).getTime(),
      y: 0,
    }
  }

  // loop through the remaining data points (excluding index 0), push data points to data array
  for (let i = 1; i < modifiedArr.length; i++) {
    prevAirflow = Math.abs(modifiedArr[i - 1].airflow) >= TELEMETRY_THRESHOLD.airflow
    currentAirflow = Math.abs(modifiedArr[i].airflow) >= TELEMETRY_THRESHOLD.airflow

    if (currentAirflow !== prevAirflow) {
      if (currentAirflow) {
        dataPoint = {
          x: new Date(modifiedArr[i].timestamp).getTime(),
          y: 0,
        }
      } else {
        dataPoint.x2 = new Date(modifiedArr[i - 1].timestamp).getTime()
        dataArray.push(dataPoint)
      }
    }
  }
  // check last data point, if it's active, push data point to array
  if (currentAirflow) {
    dataPoint.x2 = new Date(modifiedArr[modifiedArr.length - 1].timestamp).getTime()
    dataArray.push(dataPoint)
  }

  return dataArray
}


// turn telemetry into horizontal bar chart plot points
export const getCacChannelData = (telemetryData: CacDeviceTelemetry[], channelNumber: number)
  : XrangePointOptionsObject[] => {

  // return empty array if input is empty array
  if (telemetryData.length === 0) {
    return []
  }

  const modifiedArr = getNormalizedTelemetry<CacDeviceTelemetry>(telemetryData, {
    timestamp: '',
    channel0: false,
    channel1: false,
  })
  const propertyKey = channelNumber === 1 ? 'channel0' : 'channel1'

  const dataArray: XrangePointOptionsObject[] = []
  let dataPoint: XrangePointOptionsObject = {
  }

  let prevBar = false
  let currentBar = modifiedArr[0][propertyKey]

  // check first data point in array, if it has airflow then add x value
  if (currentBar) {
    dataPoint = {
      x: new Date(modifiedArr[0].timestamp).getTime(),
      y: 0,
    }
  }

  // loop through the remaining data points (excluding index 0), push data points to data array
  for (let i = 1; i < modifiedArr.length; i++) {
    prevBar = modifiedArr[i - 1][propertyKey]
    currentBar = modifiedArr[i][propertyKey]

    if (currentBar !== prevBar) {
      if (currentBar) {
        dataPoint = {
          x: new Date(modifiedArr[i].timestamp).getTime(),
          y: 0,
        }
      } else {
        dataPoint.x2 = new Date(modifiedArr[i - 1].timestamp).getTime()
        dataArray.push(dataPoint)
      }
    }
  }
  // check last data point, if it's active, push data point to array
  if (currentBar) {
    dataPoint.x2 = new Date(modifiedArr[modifiedArr.length - 1].timestamp).getTime()
    dataArray.push(dataPoint)
  }

  return dataArray
}

export const getGanttSeries = (startTime: Date, endTime: Date, data: DeviceTelemetry[],
  cacChannelsInfo: CacChannelInfo[], channelsData: CacChannelTelemetry[],
  monitorInstalledOnEquipmentName: string | undefined): GanttSeriesOptions[] => {

  const airflowReturn = (monitorInstalledOnEquipmentName ? `${monitorInstalledOnEquipmentName} return` : 'return')

  return [{
    seriesType: GanttSeriesType.Airflow,
    title: `<b>Airflow</b><br><span style="{{textAlign: left}}">[${airflowReturn}]</span>`,
    color: '#EE84E8',
    data,
  },
  ...cacChannelsInfo.map(({
    equipmentName, channelNumber, deviceId, plasticSerialNumber, isHardwiredVentilatingDehumidifierFan,
  }, index): GanttSeriesOptions => {

    const channelData = channelsData.find((channel) => channel.id === deviceId)
    const currentData = channelData ? channelData.data : []
    const controllerPlasticSerialNumber = isHardwiredVentilatingDehumidifierFan ? plasticSerialNumber : (
      `HVN Controller S/N: ${plasticSerialNumber}`
    )
    const equipmentTitle = `<b>${equipmentName}</b></br>
          <span style="{{textAlign: left}}">[${controllerPlasticSerialNumber}]</span>`

    return {
      channelNumber,
      seriesType: GanttSeriesType.CacChannel,
      title: equipmentTitle,
      color: getSeriesColor(index),
      data: isHardwiredVentilatingDehumidifierFan ? getFakeCacDeviceTelemetry(startTime, endTime) : currentData,
      //data: currentData
    }
  }),
  ]
}
