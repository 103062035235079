import React, { useCallback } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RootState } from 'state-mngt/store'
import {
  makeStyles, Box, Typography, Button,
} from '@material-ui/core'
import { isErrorShown, getErrorType } from 'state-mngt/selectors/error-selectors'
import { selectUser } from 'state-mngt/selectors/user-selectors'
import { ErrorType } from 'state-mngt/models/error'
import BackTimer from 'ui/error-page/back-timer'
import logo from 'ui/error-page/images/logo.png'
import startBg from 'ui/error-page/images/start-bg.png'
import endBg from 'ui/error-page/images/end-bg.png'
import requestTimeout from 'ui/error-page/images/request-timeout.svg'
import { useAuth0 } from '@auth0/auth0-react'

const connector = connect((state: RootState) => ({
  isErrorShown: isErrorShown(state),
  errorType: getErrorType(state),
  userEmail: selectUser(state)?.email || 'admin',
}), {
})

type Props = ConnectedProps<typeof connector>;

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 9999,
    backgroundColor: theme.palette.background.default,
    '&::before': {
      content: '""',
      position: 'absolute',
      backgroundImage: `url(${startBg})`,
      width: 219,
      height: 227,
      left: 0,
      bottom: 0,
      pointerEvents: 'none',
      zIndex: -1,
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      backgroundImage: `url(${endBg})`,
      width: 538,
      height: 609,
      right: 0,
      top: 0,
      pointerEvents: 'none',
      zIndex: -1,
    },
  },
  list: {
    display: 'inline-block',
    textAlign: 'left',
  },
}))

const ErrorPage: React.FC<Props> = ({ isErrorShown, errorType, userEmail }) => {
  const classes = useStyles()
  const { logout } = useAuth0()

  const onFinish = useCallback(() => {
    localStorage.removeItem('token')
    logout({
      returnTo: window.location.origin,
    })
  }, [logout])

  const onRefreshPage = useCallback(() => {
    window.location.reload()
  }, [])

  if (!isErrorShown) {
    return null
  }

  return (
    <Box className={classes.root} p={2} display="flex" flexDirection="column">
      <Box mb={2}>
        <img src={logo} alt="" />
      </Box>
      <Box flex="auto" overflow="auto">
        <Box
          minHeight="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {errorType === ErrorType.AdminUser && (
            <Box textAlign="center">
              <Box mb={2}>
                <Typography variant="body1">
                  You can’t use <strong>{userEmail}</strong> for the Pro Portal.<br />
                  Logging you out.
                </Typography>
              </Box>
              <Box mb={2}>
                <BackTimer durationMs={5000} onFinish={onFinish} />
              </Box>
              <Typography variant="body1">
              Please login with a normal HAVEN account to view data.
              </Typography>
            </Box>
          )}
          {errorType === ErrorType.RequestTimeout && (
            <Box textAlign="center">
              <Box mb={4}>
                <img
                  style={{
                    maxWidth: '100%',
                  }}
                  height="auto"
                  src={requestTimeout}
                  alt=""
                />
              </Box>
              <Box mb={3}>
                <Typography variant="h2" gutterBottom>
                  Oh crap!
                </Typography>
                <Typography variant="body1">
                  We’re having some trouble connecting to the HAVEN server right now.<br />
                  Here are some suggestions to continue:
                </Typography>
              </Box>
              <Box mb={3}>
                <ul className={classes.list}>
                  <li>
                    <Typography variant="subtitle2">Check your internet connection (it might be you!)</Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">Refresh the page</Typography>
                  </li>
                </ul>
              </Box>
              <Box mb={3}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onRefreshPage}
                >
                  Refresh the page now
                </Button>
              </Box>
              <Typography variant="body2">
                If the issue persists, please try again later
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default connector(ErrorPage)
