/**
 * Constant for label names of type data
 */
export const CHART_DATA_TYPE_LABELS = {
  PM_MC:'<span>PM2.5 (ug/m<sup>3</sup>)</span>',
  PM_COUNT: '<span>PM2.5 (count/m<sup>3</sup>)</span>',
  VOC_MC: '<span>tVOC (ug/m<sup>3</sup>)</span>',
  VOC_COUNT: '<span>tVOC (PPB)</span>',
  INDOOR_DEW_POINT_F: '<span>Indoor Dew Point (<sup>o</sup>F)</span>',/* Robin test */
  INDOOR_DEW_POINT_C: '<span>Indoor Dew Point (<sup>o</sup>C)</span>', /* Robin test */ 
  HUMIDITY: '<span>Indoor RH (%)</span>',
  TEMPERATURE_F: '<span>Indoor Temperature (<sup>o</sup>F)</span>',
  TEMPERATURE_C: '<span>Indoor Temperature (<sup>o</sup>C)</span>', 
  OUTDOOR_AQI: '<span>Outdoor AQI</span>', 
  OUTDOOR_DEW_POINT_F: '<span>Outdoor Dew Point (<sup>o</sup>F)</span>',
  OUTDOOR_DEW_POINT_C: '<span>Outdoor Dew Point (<sup>o</sup>C)</span>',
  OUTDOOR_HUMIDITY: '<span>Outdoor RH (%)</span>',
  OUTDOOR_TEMPERATURE_F: '<span>Outdoor Temperature (<sup>o</sup>F)</span>',
  OUTDOOR_TEMPERATURE_C: '<span>Outdoor Temperature (<sup>o</sup>C)</span>',
}

export const GANTT_CHART = {
  barHeight: 20,
  barGap: 8,
  seriesHeight: 20 + 8,
  axisHeight: 36 + 4,
  axisLabelWidth: 140,
}
