import React from 'react'
import {
  Dialog, makeStyles, Typography, DialogContent, DialogActions, Button, DialogTitle, IconButton,
} from '@material-ui/core'
import { useMixPanel } from 'features/analytics/mixpanel-provider'
import { theme } from 'theme'
import CloseIcon from '@material-ui/icons/Close'
import { ArrowForward } from '@material-ui/icons'
import ResourcesPageIntroImage from 'features/product-tour/resources-page/resources-page-intro.png'


const useStyles = makeStyles({
  dialogTitle: {
    margin: 0,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '500px',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})


export interface ResourcesPageIntroDialogProps {
  isOpen: boolean;
  onContinue?: () => void;
  onClose?: () => void;
}


export const ResourcesPageIntroDialog = (props: ResourcesPageIntroDialogProps) => {
  const classes = useStyles()
  const { isOpen = true, onContinue, onClose } = props
  const { mixpanel } = useMixPanel()


  const handleContinue = () => {
    if (mixpanel) {
      mixpanel.track('pp_productTour_resourcesPageIntroDialog_continueButton')
    }

    if (onContinue) {
      onContinue()
    }
  }

  const handleCloseDialog = () => {
    if (mixpanel) {
      mixpanel.track('pp_productTour_resourcesPageIntroDialog_closeButton')
    }

    if (onClose) {
      onClose()
    }
  }


  return (
    <Dialog
      id="resources-page-intro-dialog"
      aria-labelledby="resourcesPage-intro-dialog-title"
      open={isOpen}
      onClose={ (event: object, reason: string) => {
        if (reason !== 'backdropClick') {
          handleCloseDialog()
        }
      }}>
      <DialogTitle id="resourcesPage-intro-dialog-title" disableTypography className={classes.dialogTitle}>
        <Typography variant="h3" color="secondary">Resources page</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseDialog}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <img
          src={ResourcesPageIntroImage}
          alt="Resources Page Intro Image"
          style={{
            marginBottom: '30px',
          }}
        />
        <Typography variant="body1" color="textPrimary">
          Here's the home of the HAVEN library, where you'll find everything from sales playbooks to brochures, spec
          sheets and beyond!<br/><br/>
          Looking for something specific?<br/>
          Reach out to marketing@haveniaq.com to submit your requests!
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={handleContinue}
          style={{
            margin: '1rem',
            minWidth: '220px',
          }}>
          <Typography variant="button">Continue</Typography>
          <ArrowForward style={{
            marginLeft: '7px',
            width: '17px',
            height: '17px',
          }}/>
        </Button>
      </DialogActions>
    </Dialog>
  )
}
