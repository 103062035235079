/**
 * Threshold for determine whether device has active airflow
 * if lower than 0.2, device is deemed inactive
 * Note: airflow can be negative, in that case compare the absolute value
 */

export const TELEMETRY_THRESHOLD = {
  airflow: 0.2,
  pmMcFair: 11.8,
  pmMcPoor: 33.3,
  pmCountFair: 20.5,
  pmCountPoor: 58,
}

export enum TelemetryStatus {
  Good = 'Good',
  Fair = 'Fair',
  Poor = 'Poor',
}
