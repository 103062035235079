import useCurrentZone from "utils/hooks/useCurrentZone"
import { selectDevicesByZone, useDevicesStore } from "./store"
import { useParams } from "react-router-dom"

function useDevicesInZone() {
  const zone = useCurrentZone()
  const { dwellingId } = useParams()
  const devices = useDevicesStore(selectDevicesByZone(parseInt(dwellingId || ''))(zone))
  return devices
}

export default useDevicesInZone
