import React, { useEffect, useState } from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core'
import { ReportOptions } from 'state-mngt/models/report'
import { theme } from 'theme'
import { addPropsToTab } from 'utils/add-tab-prop'
import TabPanel from 'ui/tab-panel/tab-panel'
import ReportPreview from 'features/build-report/report-preview'
import { ReportPageTab, ReportPageTabs } from 'features/build-report/report-page-tabs'
import { useAllDeviceQuality } from 'features/customer-drill-down/charts/customer-history/historyPieCharts'

const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
  },
  tabLabels: {
    color: theme.palette.primary.main,
    textAlign: 'left',
  },
}))

/**
 * Display Customer Tabs on customer drill down page
 */
export const ReportTabs = (props: {
  options: ReportOptions[]
  tabClicked: number
  startTime: Date
  endTime: Date
  tabClick: any
}) => {
  const {
    options,
    tabClicked,
    startTime,
    endTime,
    tabClick,
  } = props

  const [tabValue, setTabValue] = useState<number>(tabClicked ? tabClicked : 0)
  const totalPageNum = options.filter((o) => o.isChecked).length

  const deviceQuality = useAllDeviceQuality({
    startTime, endTime, 
  })

  /**
   * callback function to save selected tab value to state
   * @param event click event
   * @param newValue new tab value based on which tab is clicked
   */
  const handleTabClick = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
    tabClick(newValue)
  }

  useEffect(() => {
    setTabValue(tabClicked)
  }, [tabClicked])

  useEffect(() => {
    // set tab value to first page when the length of selected data type has been changed
    if (totalPageNum < options.length) {
      setTabValue(0)
      tabClick(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPageNum])

  const classes = useStyles()

  return (
    <>
      <Box
        className={classes.root}
      >
        {options.filter((o) => o.isChecked).length > 0 && (
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            py={4}
            margin="0 auto">
            <Typography variant="subtitle1">Page preview</Typography>
            <ReportPageTabs
              value={tabValue === options.filter((o) => o.isChecked).length ? tabValue - 1 : tabValue}
              onChange={handleTabClick}
              indicatorColor="primary"
              textColor="primary"
              aria-label="report page tabs"
            >
              {options
                .filter((o) => o.isChecked)
                .map((value, index) => {
                  return (
                    // @ts-ignore
                    <ReportPageTab
                      label={`${index + 1}`}
                      key={value.id || value.type}
                      {...addPropsToTab('report', index)}
                    />
                  )
                })}
            </ReportPageTabs>
          </Box>
        )}
        <div>
          {options
            .filter((o) => o.isChecked)
            .map((value, index) => {
              return (
                <TabPanel
                  renderOnInactive
                  activeValue={tabValue}
                  tabValue={index}
                  style={{
                    width: '840px',
                    margin: '0 auto',
                  }}
                  key={value.id || value.type}
                >
                  <ReportPreview
                    deviceQuality={deviceQuality}
                    option={value}
                    pageNum={index + 1}
                    startTime={startTime}
                    endTime={endTime}
                    totalPageNum={totalPageNum}
                  />
                </TabPanel>
              )
            })}
        </div>
        <div id='report-to-send'>
          {options
            .filter((o) => o.isChecked)
            .map((value, index) => {
              return (
                <div
                  key={value.id || value.type}
                  id='report-page'
                  className="for-print"
                >
                  <ReportPreview
                    deviceQuality={deviceQuality}
                    option={value}
                    pageNum={index + 1}
                    startTime={startTime}
                    endTime={endTime}
                    totalPageNum={totalPageNum}
                  />
                </div>
              )
            })}
        </div>
      </Box>
    </>
  )
}
