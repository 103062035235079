import { theme } from 'theme'
import { TELEMETRY_THRESHOLD } from 'utils/constants/telemetry-threshold'

const pmMcOptions = {
  width: 600,
  height: 140,
  greenFrom: 0,
  greenTo: TELEMETRY_THRESHOLD.pmMcFair - 0.1, // good
  yellowFrom: TELEMETRY_THRESHOLD.pmMcFair,
  yellowTo: TELEMETRY_THRESHOLD.pmMcPoor - 0.1, // fair
  redFrom: TELEMETRY_THRESHOLD.pmMcPoor,
  redTo: 200, // poor
  redColor: theme.palette.error.main,
  yellowColor: theme.palette.warning.main,
  greenColor: theme.palette.success.main,
  minorTicks: 4,
  max: 200,
  majorTicks: ['0', '20', '40', '60', '80', '100', '120', '140', '160', '180', '200'],
}

const pmCountOptions = {
  max: 200,
  min: 0,
  width: 600,
  height: 140,
  greenFrom: 0,
  greenTo: TELEMETRY_THRESHOLD.pmCountFair - 0.1, // good
  yellowFrom: TELEMETRY_THRESHOLD.pmCountFair,
  yellowTo: TELEMETRY_THRESHOLD.pmCountPoor - 0.1, // fair
  redFrom: TELEMETRY_THRESHOLD.pmCountPoor,
  redTo: 200, // poor
  redColor: theme.palette.error.main,
  yellowColor: theme.palette.warning.main,
  greenColor: theme.palette.success.main,
  minorTicks: 4,
  majorTicks: ['0', '20', '40', '60', '80', '100', '120', '140', '160', '180', '200'],
}

const vocMcOptions = {
  max: 3000,
  width: 600,
  height: 140,
  greenFrom: 0,
  greenTo: 499, // good
  yellowFrom: 500,
  yellowTo: 1999, // fair
  redFrom: 2000,
  redTo: 3000, // poor
  redColor: theme.palette.error.main,
  yellowColor: theme.palette.warning.main,
  greenColor: theme.palette.success.main,
  minorTicks: 4,
  majorTicks: ['0', '500', '1000', '1500', '2000', '2500', '3000'],
}

const vocCountOptions = {
  max: 1200,
  width: 600,
  height: 140,
  greenFrom: 0,
  greenTo: 211, // good
  yellowFrom: 212,
  yellowTo: 849, // fair
  redFrom: 850,
  redTo: 1700, // poor
  redColor: theme.palette.error.main,
  yellowColor: theme.palette.warning.main,
  greenColor: theme.palette.success.main,
  minorTicks: 4,
  majorTicks: ['0', '200', '400', '600', '800', '1000', '1200'],
}

const rhOptions = {
  max: 100,
  width: 600,
  height: 140,
  minorTicks: 4,
  majorTicks: ['0', '20', '40', '60', '80', '100'],
}

class GaugeService {
  /**
   * gauge option of PM
   * @param pmValue number
   */
  pmGauge(pmValue: number, pm_isMc: boolean) {
    const data = google.visualization.arrayToDataTable([
      ['Label', 'Value'],
      ['', pmValue],
    ])

    const chart = new google.visualization.Gauge(document.getElementById('pm_div'))
    chart.draw(data, pm_isMc ? pmMcOptions : pmCountOptions)
  }
  /**
   * Gauge option for VOC
   * @param vocValue number
   */
  vocGauge(vocValue: number, voc_isMc: boolean) {
    const data = google.visualization.arrayToDataTable([
      ['Label', 'Value'],
      ['', vocValue],
    ])

    const chart = new google.visualization.Gauge(document.getElementById('voc_div'))
    chart.draw(data, voc_isMc ? vocMcOptions : vocCountOptions)
  }
  /**
   * Gauge option for humidity
   * @param humidity number
   */
  rhGauge(humidity: number) {
    const data = google.visualization.arrayToDataTable([
      ['Label', 'Value'],
      ['', humidity],
    ])

    const chart = new google.visualization.Gauge(document.getElementById('rh_div'))
    chart.draw(data, rhOptions)
  }
}
const gaugeService = new GaugeService()
export default gaugeService
