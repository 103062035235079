import React, { useEffect } from 'react'
import {
  Routes, Route, useLocation, useNavigate,
} from 'react-router-dom'
import 'app/app.scss'
import ProtectedRoute from 'features/protect-route'
import { Home } from 'features/home'
import { Preferences } from 'routes'
import Nav from 'ui/navigation-bar'
import { ROUTE_PATHS } from 'routes/routes'
import NotFound from 'ui/not-found'
import { Resources } from 'routes'
import { Onboarding } from 'features/onboarding'
import { JoinServiceCompany, JoinServiceCompanyOnBoarding } from 'features/join-service-company'
import {
  AlreadyBelongsToServiceCompany,
  InviteCodeExpired,
  InviteCodeUsed,
  ServiceCompanyDoesNotExist,
} from 'features/join-service-company/error'
import { LoginRedirect } from 'routes/login-redirect'
import PaymentRedirect from 'features/payment-redirect'
import { Customers } from 'routes/customers'
import Team from 'features/preferences/team-mngt'
import { MemberRole } from 'utils/constants/member-enums'
import PageError from 'ui/error-page'
import { useDispatch, useSelector } from 'react-redux'
import { getCompanyRole, getUser } from 'state-mngt/actions/user-actions'
import { useAuth0 } from '@auth0/auth0-react'
import { Box, CircularProgress } from '@material-ui/core'
import { setError } from 'state-mngt/actions/error-actions'
import { ErrorType } from 'state-mngt/models/error'
import { selectUserRole, selectUser } from 'state-mngt/selectors/user-selectors'
import { RootState } from 'state-mngt/store'
import { HowToBuy } from 'routes/how-to-buy'
// import User from 'routes/user'

const AppComponent = () => {
  // Ensure that the SDK has finished loading by checking that isLoading is false before accessing the isAuthenticated property.
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const companyRole = useSelector(selectUserRole)
  const userState = useSelector((state: RootState) => state.user)
  const user = useSelector(selectUser)

  useEffect(() => {
    // Make sure Auth0 SDK has finished loading and has authenticated the user before requesting Access Token.
    if (!isLoading && isAuthenticated) {
      (async () => {
        await getAccessTokenSilently()
          .then((token) => {
            localStorage.setItem('token', token)
            dispatch(getUser())
            dispatch(getCompanyRole())

            const redirect = window.localStorage.getItem('hvn-redirect')
            window.localStorage.removeItem('hvn-redirect')
            if (redirect) navigate(`${redirect}`)
          })
          .catch((reason => {
            // Send user to redirect login page.
            navigate(`${ROUTE_PATHS.login.absolute}?pathname=${location.pathname}${location.search}`, {
              replace: true,
            })
          }))
      })()
    }
  }, [isLoading, isAuthenticated])

  // When admin user is authenticated, set error type to AdminUser to trigger auto logout via error page.
  useEffect(() => {
    if (user?.role === 0) {
      dispatch(setError(ErrorType.AdminUser))
    }
  }, [user])

  // When user didn't join a service company, force redirect to onboarding page.
  useEffect(() => {
    if (userState && !(userState.isLoadingRole) && companyRole && companyRole.service_company_id === -1) {
      navigate(ROUTE_PATHS.onboarding.absolute, {
        replace: true,
      })
    }
  }, [userState, companyRole])

  // When user has joined a service company but does not have a name, force redirect to join onboarding page.
  useEffect(() => {
    if (companyRole && companyRole.service_company_id !== -1 && user && (!user.first_name || !user.last_name)) {
      navigate(ROUTE_PATHS.joinServiceCompany.onboarding.absolute, {
        replace: true,
      })
    }
  }, [user, companyRole])

  // It renders loading overlay if Auth0 SDK is still loading.
  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
        <CircularProgress />
      </Box>
    )
  }

  // Even after Auth0 SDK load is finished render loading overlay until we get the user and company role to decide
  // which page to navigate. It might be the onboarding, join service company onboarding or admin user error page.
  if (!isLoading && isAuthenticated && (!user || !userState || !companyRole)) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <>
      {/* Rules for which routes to render Nav bar. */}
      {
        (!isLoading && isAuthenticated && (
          location.pathname != ROUTE_PATHS.root &&
          location.pathname != ROUTE_PATHS.onboarding.absolute &&
          location.pathname != ROUTE_PATHS.joinServiceCompany.root.absolute &&
          location.pathname != ROUTE_PATHS.joinServiceCompany.onboarding.absolute &&
          location.pathname != ROUTE_PATHS.login.absolute
        )) &&
        <Nav />
      }
      {/* App routing only if Auth0 SDK has finished loading and all the previous conditions were passed. */}
      {
        !isLoading &&
        <>
          <PageError />
          <Routes>
            <Route path={ROUTE_PATHS.root} element={<Home />} />
            <Route path={ROUTE_PATHS.login.absolute} element={<LoginRedirect />} />

            <Route path={ROUTE_PATHS.onboarding.absolute} element={<ProtectedRoute><Onboarding /></ProtectedRoute>} />

            <Route path={ROUTE_PATHS.joinServiceCompany.root.absolute} element={<JoinServiceCompany />} />
            <Route
              path={ROUTE_PATHS.joinServiceCompany.onboarding.absolute}
              element={<ProtectedRoute><JoinServiceCompanyOnBoarding /></ProtectedRoute>}
            />
            <Route
              path={ROUTE_PATHS.joinServiceCompany.error.alreadyBelongsToServiceCompany.absolute}
              element={<AlreadyBelongsToServiceCompany />}
            />
            <Route
              path={ROUTE_PATHS.joinServiceCompany.error.serviceCompanyDoesNotExist.absolute}
              element={<ServiceCompanyDoesNotExist />}
            />
            <Route
              path={ROUTE_PATHS.joinServiceCompany.error.inviteCodeExpired.absolute}
              element={<InviteCodeExpired />}
            />
            <Route
              path={ROUTE_PATHS.joinServiceCompany.error.inviteCodeUsed.absolute}
              element={<InviteCodeUsed />}
            />

            {/* Path have trailing '*' wildcard because its rendering descendant (sub) routes. */}
            <Route
              path={ROUTE_PATHS.customers.root.absolute + ROUTE_PATHS.trailingDescendantRoutes}
              element={<ProtectedRoute><Customers /></ProtectedRoute>}
            />

            {/* <Route
              path={ROUTE_PATHS.user.absolute + '/:userId'}
              element={<ProtectedRoute><User /></ProtectedRoute>}
            /> */}

            <Route
              path={ROUTE_PATHS.resources.root.absolute + ROUTE_PATHS.trailingDescendantRoutes}
              element={<ProtectedRoute><Resources /></ProtectedRoute>}
            />

            <Route
              path={ROUTE_PATHS.howToBuy.root.absolute + ROUTE_PATHS.trailingDescendantRoutes}
              element={<ProtectedRoute><HowToBuy /></ProtectedRoute>}
            />

            <Route
              path={ROUTE_PATHS.teamMngt.root.absolute + ROUTE_PATHS.trailingDescendantRoutes}
              element={
                <ProtectedRoute
                  roles={[MemberRole.ADMIN]}
                  notAllowedRedirectPath={ROUTE_PATHS.customers.root.absolute}
                >
                  <Team />
                </ProtectedRoute>
              }
            />

            <Route
              path={ROUTE_PATHS.preferences.root.absolute + ROUTE_PATHS.trailingDescendantRoutes}
              element={<ProtectedRoute><Preferences /></ProtectedRoute>} />

            <Route path={ROUTE_PATHS.paymentRedirect.absolute} element={<ProtectedRoute><PaymentRedirect /></ProtectedRoute>} />
            <Route path={ROUTE_PATHS.notFound.absolute} element={<NotFound />} />
            <Route element={<NotFound />} />
          </Routes>
        </>
      }
    </>
  )
}

export default AppComponent
