import { useEffect, useState } from "react"
import useCurrentDwellingContents from "./useCurrentDwellingContents"
import useCurrentZone from "./useCurrentZone"

function useHasMonitorInZone() {
  const [hasMonitor, setHasMonitor] = useState(true)
  const zone = useCurrentZone()
  const content = useCurrentDwellingContents()

  useEffect(() => {
    if (content?.devices) {
      setHasMonitor(content.devices.some(x =>
        (x.zone.toLowerCase().trim() === zone.toLowerCase().trim()) && (x.type === 'cam')))
    }
  }, [
    zone,
    JSON.stringify(content?.devices),
  ])

  return hasMonitor
}

export default useHasMonitorInZone
