import { ActionPayload } from 'state-mngt/models/redux'
import { ErrorType } from 'state-mngt/models/error'

export enum ActionType {
  SetError = 'error/SET_ERROR',
}

export type SetErrorAction = ActionPayload<ActionType.SetError, ErrorType>;

export const setError = (errorType: ErrorType): SetErrorAction => ({
  type: ActionType.SetError,
  payload: errorType,
})

