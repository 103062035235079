import React, { useEffect }  from 'react'
import { Routes, Route }  from 'react-router-dom'
import { ROUTE_PATHS } from 'routes/routes'
import { usePageTitle } from 'utils/hooks/router'
import TeamList from 'features/preferences/team-mngt/team-list'
import TeamAdd from 'features/preferences/team-mngt/team-add'
import { useMixPanel } from 'features/analytics/mixpanel-provider'

const Team = () => {
  usePageTitle('Team')

  const { mixpanel } = useMixPanel()

  useEffect(() => {
    if(mixpanel) {
      mixpanel.track('pp_teamMgmtPage_open')
    }
  }, [mixpanel])

  return (
    <>
      {/* Descendant Routes, MUST USE RELATIVE PATHS TO MATCH THE ROUTES. */}
      <Routes>
        {/* Index Route - A child route with no path that renders in the parent's outlet at the parent's URL. */}
        <Route index element={<TeamList />}  />
        <Route path={ROUTE_PATHS.teamMngt.add.relative} element={<TeamAdd />}  />
      </Routes>
    </>
  )
}

export default Team
