import React from 'react'
import {
  Box, Button, Typography,
} from '@material-ui/core'


interface Props {
  isCompleted?: boolean;
  isNext?: boolean;
  imageUrl: string;
  label: React.ReactNode;
  onClick?: () => void;
  button?: React.ReactNode;
  buttonIcon?: React.ReactNode;
}


/**
 * Renders an image with a label and a button representing a demo data item.
 * @param button
 * @param isCompleted
 * @param isNext
 * @param imageUrl
 * @param label
 * @param onClick
 * @param buttonIcon
 * @constructor
 */
const DemoOptionItem: React.FC<Props> = ({
  button, isCompleted, isNext, imageUrl, label, onClick, buttonIcon,
}) => {

  return (
    <Box textAlign="center" width="100%">
      <Box position="relative" mb={1}>
        <img src={imageUrl} alt="" />
      </Box>
      <Box mb={4} minHeight={48}>
        <Typography variant="body2">{label}</Typography>
      </Box>
      {!button ? (
        <>
          {isCompleted && (
            <Button
              endIcon={buttonIcon}
              onClick={onClick}
              color="primary"
              variant={isNext ? 'contained' : 'outlined'}
              fullWidth
            >
              View again
            </Button>
          )}
          {!isCompleted && (
            <Button
              endIcon={buttonIcon}
              onClick={onClick}
              color="primary"
              variant={isNext ? 'contained' : 'outlined'}
              fullWidth
            >
              Start
            </Button>
          )}
        </>
      ) : (
        button
      )}
    </Box>
  )
}

export default DemoOptionItem
