import { useEffect } from "react"
import { useParams } from "react-router-dom"
import userService from "state-mngt/services/user-service"
import useDwellingStore, { selectPermissions } from "stores/dwelling"
import useUserStore, { selectUser } from "stores/user"

function useAdmin() {
  const { dwellingId } = useParams()
  const dwellingPermissions = useDwellingStore(selectPermissions(parseInt(dwellingId || '')))

  const adminUserId = dwellingPermissions.reduce((prev, curr) => {
    if (curr.permission === 0) return curr.user_id
    return prev
  }, null as number | null)

  const user = useUserStore(selectUser(adminUserId))
  const set = user => useUserStore.setState(prev =>
    ({
      ...prev, [user.id]: user, 
    }))

  useEffect(() => {
    if (user) return
    if (!adminUserId) return

    const get = async () => {
      try {
        const dbUser = await userService.getUserDetails(adminUserId)
        set(dbUser)
      } catch (e) {
        console.error(e)
      }
    }

    get()
  }, [user?.id, adminUserId])

  return user
}

export default useAdmin
