import React from 'react'
import { Typography, Box } from '@material-ui/core'
import { usePageTitle } from 'utils/hooks/router'

/**
 * Renders Not Found page
 */
const NotFound = () => {
  usePageTitle('404 Not Found')

  return (
    <Box m={2}>
      <Typography variant="h4" color="secondary" align="center">404 Not Found</Typography>
    </Box>
  )
}

export default NotFound
