import * as React from 'react'
import { Box, BoxProps } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

const AddNew = ({ children, ...rest }: BoxProps) => {
    return (
        <Box
            borderRadius='11px'
            display='flex'
            alignItems='center'
            padding={2}
            gridGap={2}
            border='1px dashed #00C4C1'
            bgcolor='white'
            cursor='pointer'
            sx={{
                // @ts-ignore
                cursor: 'pointer',
                transition: '.2s all',
                '&:hover': {
                    boxShadow: '0px 2px 2px rgba(0,0,0,0.1), 0 0 12px rgba(0,0,0,0.1)',
                    transition: '.2s all',
                },
            }}
            {...rest}>
            <AddIcon />
            {children}
        </Box>
    )
}

export default AddNew
