import { Auth0ClientOptions } from '@auth0/auth0-spa-js'

interface Config {
  auth0: Auth0ClientOptions;
  baseUrl: string;
  mixpanelId: string;
  stripePublicKey: string;
}

const config: Config  = {
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
    redirect_uri: window.location.origin,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  },
  baseUrl: process.env.REACT_APP_API_BASE_URL as string,
  mixpanelId: process.env.REACT_APP_MIXPANEL_ID as string,
  stripePublicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY as string,
}

export default config
