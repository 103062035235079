import React from 'react'
import { Box, Card } from "@material-ui/core"
import useClasses from './useClasses'

function AutomationRuleCard(props) {
  const classes = useClasses()
  return (
    <Card className={classes.rule} {...props} />
  )
}

function AutomationRuleCardNoPadding(props) {
  const classes = useClasses()
  return (
    <Card className={classes.ruleNoPadding} {...props} />
  )
}

function Padding(props) {
  const classes = useClasses()
  return (
    <Box className={classes.padding}  {...props} />
  )
}

export {
  AutomationRuleCardNoPadding,
  Padding,
}
export default AutomationRuleCard
