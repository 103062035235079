import { OutdoorAir } from 'state-mngt/models/outdoor-air'
import dwellingService from 'state-mngt/services/dwelling-service'
import { MonitorTelemetry } from 'types'
import { APIInterval } from 'utils/constants/time-interval-api'
import { create } from 'zustand'
import { round } from './line-chart-utils'

interface TelemetryStore {
    [key: number]: {
        [key: string]: MonitorTelemetry[],
    }
}

export interface OutdoorAirStore {
    data: { [postal_code: string]: OutdoorAir[] }
    get: (
        postalCode: string,
        startTime: Date,
        endTime: Date,
        setLoading: any,
    ) => Promise<void>
}

export const sanitize = (x: string) => x.toLowerCase().replace(' ', '')

const roundAll = (outdoorAir: OutdoorAir) => ({
  ...outdoorAir,
  temperature: round(outdoorAir.temperature),
  humidity: round(outdoorAir.humidity),
  dew_point: round(outdoorAir.dew_point),
})

const outdoorAirDataExists = (data: OutdoorAir[], start: Date, stop: Date) => {
  if (!data?.length) return false

  const oneHour = 60000 * 60
  const withinOneHour = (x: OutdoorAir, y: Date) =>
    Math.abs(new Date(x.timestamp).getTime() - y.getTime()) < oneHour

  const hasStartPoint = data.some(x => withinOneHour(x, start))
  const hasEndPoint = data.some(x => withinOneHour(x, stop))

  return hasStartPoint && hasEndPoint
}

const useOutdoorAirStore = create<OutdoorAirStore>((set, get) => ({
  data: {
  },
  get: async (postalCode, start, end, setLoading) => {
    if (outdoorAirDataExists(get().data[postalCode], start, end)) return

    setLoading(true)

    const oneHour = 1000 * 60 * 60
    const oneDay = oneHour * 24
    const oneMonth = oneDay * 30
    const diff = end.getTime() - start.getTime()

    const setter = data =>
      set(prev => ({
        ...prev,
        data: {
          ...prev.data,
          [postalCode]: dedupe([
            ...(prev.data[postalCode] || []),
            ...data,
          ].sort((x, y) =>
            new Date(x.timestamp).getTime() - new Date(y.timestamp).getTime())),
        },
      }))

    if (diff > oneMonth) {
      let startTime = start.getTime()
      let endTime = startTime + oneMonth

      const requests = diff / oneMonth // number of requests
      const proms: Promise<any>[] = []

      for (let i = 0; i < requests; i++) {
        proms.push(
          dwellingService.getOutdoorAir(
            postalCode,
            new Date(startTime),
            new Date(endTime),
          ),
        )
        startTime = endTime
        endTime = Math.min(endTime + oneMonth, Date.now())
      }

      try {
        const data = await Promise.all(proms)

        if (data) {
          const _data = data
            .filter(x => x)
            .flatMap(x => x.map(y => roundAll(y)))
          setter(_data)
        }
      } catch (e) {
        console.error(e)
      }
    } else {
      try {
        const data = await dwellingService.getOutdoorAir(
          postalCode,
          start,
          end,
        )

        if (data) {
          const _data = data.map(x => roundAll(x))
          setter(_data)
        }
      } catch (e) {
        console.error(e)
      }
    }

    setLoading(false)
  },
}))

const sort = (arr: MonitorTelemetry[]) =>
  arr.sort((x, y) =>
    new Date(x.timestamp).getTime() - new Date(y.timestamp).getTime())

const range = (start: Date, end: Date) => (telemetry: MonitorTelemetry[]) => {
  return telemetry.filter((x) =>
    (new Date(x.timestamp).getTime() > start.getTime()) &&
        new Date(x.timestamp).getTime() < end.getTime())
}

const useTelemetryStore = create<TelemetryStore>(set => ({
}))

// checks if the chart is missing data, if yes returns
// the earliest point on the chart it can find
const useIsIncomplete = (startTime: Date, endTime: Date, interval: string, deviceId?: number) => {
  const data = useTelemetryStore.getState()
  const emptyState = {
    earliest: null, latest: null, 
  }

  if (!data) return emptyState
  if (!deviceId) return emptyState

  const _data = data[deviceId]?.[interval]

  if (!_data?.length) return emptyState

  const earliest = (new Date(_data[0].timestamp) >= startTime) ?
    new Date(_data[0].timestamp) : null
  const latest = (new Date(_data[_data.length - 1].timestamp) <= endTime) ?
    new Date(_data[0].timestamp) : null
  return {
    earliest, latest, 
  }
}

const useDataExists = (startTime: Date, endTime: Date, interval: string, deviceId?: number) => {
  const data = useTelemetryStore.getState()
  if (!deviceId) return null
  data[deviceId]
    ?.[interval]
    ?.some(data => new Date(data.timestamp) >= startTime && new Date(data.timestamp) <= endTime)
}

interface SelectTelemetryOpts {
    deviceId?: number
    dataMin?: Date
    dataMax?: Date
    interval?: string
}

const selectTelemetry = ({
  deviceId, dataMin, dataMax, interval, 
}: SelectTelemetryOpts) => (store: TelemetryStore) => {
  if (!deviceId || !dataMin || !dataMax || !interval) return []
  if (!store[deviceId]) return []
  if (!store[deviceId][interval]) return []

  return range(dataMin, dataMax)(store[deviceId][interval])
}

const selectAllTelemetry = ({ deviceId, interval }: SelectTelemetryOpts) => (store: TelemetryStore) => {
  if (!deviceId || !interval) return []
  if (!store[deviceId]) return []
  if (!store[deviceId][interval]) return []

  return store[deviceId]?.[interval]
}

const dedupe = (arr: any[]) => {
  const map = new Map()
  arr.forEach(obj => {
    if (!map.has(obj.timestamp)) {
      map.set(obj.timestamp, obj)
    }
  })
  return Array.from(map.values())
}

const set = (deviceId?: number) =>
  ({ interval, data }: { interval: APIInterval, data: MonitorTelemetry[] }) =>
    deviceId ?
      useTelemetryStore.setState(prev => ({
        ...prev,
        [deviceId]: {
          ...(prev[deviceId] || {
          }),
          [interval]: dedupe(sort([
            ...(prev[deviceId]?.[interval] || []),
            ...data,
          ])),
        },
      })) :
      (_x) => null

export {
  set,
  useIsIncomplete,
  useDataExists,
  selectTelemetry,
  selectAllTelemetry,
  useTelemetryStore,
  useOutdoorAirStore,
}
