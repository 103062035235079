import config from "config"

// @ts-ignore
const fetcher = (...args) => fetch(...args).then(res => res.json())

const apiUrl = `${config.baseUrl}`

export {
    apiUrl,
    fetcher,
}
