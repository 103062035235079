import React, { PropsWithChildren } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppState, Auth0Provider } from '@auth0/auth0-react'
import config from 'config'

export const Auth0ProviderWithHistory = ({ children }: PropsWithChildren<{}>) => {
  const navigate = useNavigate()

  const onRedirectCallback = (appState: AppState) => {
    navigate(appState?.returnTo || window.location.pathname)
  }

  return (
    <Auth0Provider
      domain={config.auth0.domain}
      clientId={config.auth0.client_id}
      redirectUri={config.auth0.redirect_uri}
      audience={config.auth0.audience}
      useRefreshTokens={true}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithHistory
