import React from 'react'
import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { getPieChartOptions, getReportPieChartOptions } from 'ui/pie-chart/pie-chart-utils'
import { Box } from '@material-ui/core'

export interface PieChartData {
  name: string;
  y: number;
}

window.Highcharts = Highcharts

const PieChart = (props: { data: PieChartData[], colors: string[], title: string, isReport: boolean }) => {
  const {
    data,
    colors,
    title,
    isReport,
  } = props

  const options = isReport ?
    getReportPieChartOptions(data, colors) :
    getPieChartOptions(data, colors, title)

  if (!data.length) return (
    <Box
      width='100%'
      display='flex'
      justifyContent='center'
      alignItems='center'
    >
      <p style={{
        color: '#878787', 
      }}>No data</p>
    </Box>
  )

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      allowChartUpdate={true}
    />
  )
}

export default PieChart
