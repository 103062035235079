import React from 'react'
import Humidity from './humidity'
import { selectInput, useRulesStore } from './store'
import AirFlow from './airflow'
import OutdoorAir from './outdoor-air'
import OutdoorWeatherRange from './outdoor-weather-range'

export interface InputControlProps {
  inputId: number
  ruleId: number
}

function AutomationInputControl({
  ruleId,
  inputId,
}: InputControlProps) {
  const input = useRulesStore(selectInput(ruleId, inputId))
  if (!input) return null

  switch (input.measurement) {
    case 'pm25':
    case 'voc': {
      return (
        <AirFlow
          ruleId={ruleId}
          inputId={inputId}
        />
      )
    }
    case 'humidity':
    case 'dew_point': {
      return (
        <Humidity
          ruleId={ruleId}
          inputId={inputId}
        />
      )
    }
    case 'temperature': {
      return (
        <OutdoorWeatherRange
          ruleId={ruleId}
          input={input}
        />
      )
    }
    case 'epa_aqi': {
      return (
        <OutdoorAir
          ruleId={ruleId}
          inputId={inputId}
        />
      )
    }
    default: return null
  }
}

export default AutomationInputControl
