import * as React from 'react'
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete"
import { TextField } from '@material-ui/core'

const filter = createFilterOptions()

interface ComboboxProps {
    options: { title: string }[]
    value: string
    label: string
    disabled?: boolean
    onChange: (value: string) => any
}

const Combobox = React.forwardRef<any, ComboboxProps>(({
    options,
    value,
    label,
    onChange,
    disabled,
    ...rest }: ComboboxProps, ref) => {
    return (
        <Autocomplete
            value={value}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            freeSolo
            renderInput={p =>
                <TextField
                    {...p}
                    inputRef={ref}
                    variant='outlined'
                    size='small'
                    label={label}
                    disabled={disabled}
                />}
            options={options}
            getOptionLabel={opt => {
                // Value selected with enter, right from the input
                if (typeof opt === 'string') {
                    return opt
                }
                // Add "xxx" opt created dynamically
                // @ts-ignore
                if (opt.inputValue) {
                    // @ts-ignore
                    return opt.inputValue
                }
                // Regular opt
                // @ts-ignore
                return opt.title
            }}
            onChange={(event, newValue: any) => {
                if (typeof newValue === 'string') {
                    onChange(newValue)
                } else if (newValue && newValue?.inputValue) {
                    // Create a new value from the user input
                    onChange(newValue?.inputValue)
                } else if (newValue && newValue?.title) {
                    onChange(newValue?.title)
                } else {
                    onChange('')
                }
            }}
            // @ts-ignore
            renderOption={opt => opt.title}
            // @ts-ignore - materialui types are a trainwreck
            filterOptions={(options, params: any) => {
                const filtered = filter(options, params)
                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                    filtered.push({
                        inputValue: params.inputValue,
                        title: `Add "${params.inputValue}"`,
                    })
                }

                return filtered
            }}
            {...rest}
        />
    )
})

export default Combobox
