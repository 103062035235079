export const HISTORY_QUERY_KEYS = {
  zone: 'zone',
  startTime: 'startTime',
  endTime: 'endTime',
  interval: 'interval',
  timeFrame: 'timeFrame',
  dataType: 'dataType',
  devices: 'devices',
  secondDataType: 'secondDataType',
  selectedOverlays: 'selectedOverlays',
  customTime: 'customTime',
  dataTypes: 'dataTypes',
  demoStep: 'demoStep',
  zoomStart: 'zStart',
  zoomEnd: 'zEnd',
}
