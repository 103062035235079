import useAdmin from "utils/hooks/useAdmin";
import { create } from "zustand";

type features = 'install'

const flags = {
    'install': {
        global: Boolean(process.env.NODE_ENV === 'development'),
        // global: false,
        users: [],
    }
}

interface FeatureFlagStore {
    [key: string]: {
        global: boolean
        users: number[]
    }
}

const useFeatureFlagStore = create<FeatureFlagStore>(set => flags)
const selectFeatureFlag = (feature: features) => (store: FeatureFlagStore) => store[feature]

const useIsEnabled = (feature: features) => {
    const user = useAdmin()
    const flag = useFeatureFlagStore(selectFeatureFlag(feature))
    return flag.users.includes(user?.id || 0) || flag.global
}

export {
    useIsEnabled
}

export default useFeatureFlagStore
