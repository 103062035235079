import React from 'react'
import { Typography, makeStyles } from "@material-ui/core"

const useClasses = makeStyles(theme => ({
  pillarTitle: {
    fontWeight: 600,
    fontSize: 16,
    // textAlign: 'center',
  },
}))

function ColumnTitle(props) {
  const classes = useClasses()
  return <Typography className={classes.pillarTitle} {...props} />
}

export default ColumnTitle
