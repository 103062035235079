import * as React from 'react'
import {
    Box,
    BoxProps,
    Button,
    Fade,
    Typography,
} from "@material-ui/core"
import useAdmin from 'utils/hooks/useAdmin'
import DwellingStep from './dwelling-step'
import EquipmentStep from './equipment-step'
import { theme } from 'theme'
import InterlockStep from './interlock-step'
import useStore, {
    selectError,
    selectIsLoading,
    selectIsValid,
    selectProceedFnc,
    selectStep,
    setPrevStep,
    setStep,
} from 'stores/install'
import ButtonSpinner from '../button-spinner'
import { CheckCircle } from '@material-ui/icons'
import { capitalize } from 'utils/string-utils'
import AutomationStep from './automation-step'
import Alert from 'ui/alert'

function Step({
    children,
    currentStep = 0,
    number = 0,
    ...rest
}) {
    const active = currentStep >= number
    const complete = currentStep > number

    const bgcolor = active ? '#606F78' : '#EAF1F5'
    const color = active ? 'white' : '#606F78'
    const fontWeight = active ? 'bold' : 'normal'

    const isFirst = number === 0
    const isLast = number === 3

    return (
        <Box
            height='36px'
            position='relative'
            width='156px'
            borderRadius='4px'
            style={{ background: bgcolor }}
        >
            <Box
                display='flex'
                alignItems='center'
                height='100%'
                ml={isFirst ? 2 : 4}
            >
                <Typography style={{
                    color,
                    fontWeight,
                    fontSize: '12px',
                    display: 'flex',
                    alignItems: 'center',
                    gridGap: '4px',
                }}>
                    {complete ? <CheckCircle style={{ fontSize: '16px', color: '#22ff22' }} /> : `${number + 1}. `}
                    {children}
                </Typography>
            </Box>
            {isLast ? null : (
                <>
                    <Box
                        borderRadius={2}
                        position='absolute'
                        top='5px'
                        right='-11px'
                        height='26px'
                        width='26px'
                        zIndex={99}
                        style={{
                            transform: 'rotate(45deg)',
                            background: bgcolor,
                        }}
                    />
                    <Box
                        borderRadius={2}
                        position='absolute'
                        top='4px'
                        right='-16px'
                        bgcolor='white'
                        height='28.5px'
                        width='28.5px'
                        zIndex={98}
                        style={{
                            transform: 'rotate(45deg)'
                        }}
                    />
                </>
            )}
        </Box>
    )
}

function StepNavigator({ step }) {
    return (
        <Box display={['none', 'none', 'flex']}>
            <Step
                currentStep={step}
                number={0}
            >
                Dwelling
            </Step>
            <Step
                currentStep={step}
                number={1}
            >
                Equipment
            </Step>
            <Step
                currentStep={step}
                number={2}
            >
                Interlocks
            </Step>
            <Step
                currentStep={step}
                number={3}
            >
                Automations
            </Step>
        </Box>
    )
}

const Card = (props: BoxProps) => {
    return (
        <Box
            borderRadius='11px'
            bgcolor='white'
            {...props}
        />
    )
}

const InnerCard = (props: BoxProps) => {
    return (
        <Box
            borderRadius='11px'
            border='1px solid #EAF1F5'
            {...props} />
    )
}

function NewInstall({ open, onClose, setZone }) {
    const adminUser = useAdmin()
    const step = useStore(selectStep)
    const handleNext = useStore(selectProceedFnc)
    const isValid = useStore(selectIsValid)
    const loading = useStore(selectIsLoading)
    const error = useStore(selectError)
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false)

    const _onClose = () => {
        setStep(0)
        onClose()
    }

    React.useEffect(() => {
        setErrorAlertOpen(Boolean(error))
    }, [error])

    const renderStep = () => {
        switch (step) {
            case 0: return <DwellingStep setZoneOnUrl={setZone} installIsOpen={open} />
            case 1: return <EquipmentStep />
            case 2: return <InterlockStep />
            case 3: return <AutomationStep />
        }
    }

    return (
        <Fade in={open}>
            <div>
                <Box
                    width='100vw'
                    height='100vh'
                    position='absolute'
                    top='0'
                    left='0'
                    bgcolor='#F7FBFD'
                    zIndex={1}
                    overflow='scroll'
                >
                    <Box
                        display='flex'
                        justifyContent='space-between'
                        paddingY={2}
                        paddingX={4}
                        borderBottom={`1px solid ${theme.palette.charcoalLightTint}`}
                    >
                        <Button onClick={_onClose}>
                            ← Back
                        </Button>
                        <Typography>
                            {adminUser?.first_name} {adminUser?.last_name}
                        </Typography>
                    </Box>
                    <Box
                        paddingX={4}
                        paddingBottom='132px'
                        mt={6}>
                        {renderStep()}
                    </Box>
                </Box>
                <Box
                    position='fixed'
                    display='flex'
                    bottom='0'
                    left='0'
                    width='100%'
                    paddingY={2}
                    paddingX={4}
                    justifyContent={'space-between'}
                    alignItems='center'
                    zIndex={2}
                    bgcolor='white'
                    borderTop={`1px solid ${theme.palette.charcoalLightTint}`}
                >
                    {step > 0 ? (
                        <Button
                            color='primary'
                            size='small'
                            onClick={setPrevStep}
                            variant='outlined'
                        >
                            ← Previous
                        </Button>
                    ) : (
                        <Box width='101px' height='1px' />
                    )}
                    <StepNavigator step={step} />
                    <Box display='flex' alignItems='center'>
                        {error ? (
                            <Alert open={errorAlertOpen} severity='error'>{capitalize(error)}</Alert>
                        ) : null}
                        <Button
                            color='primary'
                            size='small'
                            disabled={!isValid || loading}
                            style={{ width: '69.41px' }}
                            onClick={handleNext}
                            variant='contained'>
                            {loading ? <ButtonSpinner /> : <>Next →</>}
                        </Button>
                    </Box>
                </Box>
            </div>
        </Fade>
    )
}

export {
    Card,
    InnerCard,
}

export default NewInstall
