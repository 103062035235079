import { ServiceCompanyRole } from 'state-mngt/models/serviceCompany'
import { User, UserProfile, UserDemoName } from 'state-mngt/models/user'
import httpService from 'state-mngt/services/data/http-service'
import { MemberTeam } from 'utils/constants/member-enums'
import { CancelableFetch } from 'state-mngt/models/http'
import { getProductTourFakeUser, isProductTourFakeUser } from 'features/product-tour/pro-portal-tour'

/**
 * User service class
 */
class UserService {
  /**
   * get the user detail for the user who is logged in
   */
  getUser = async (): Promise<User> => {
    return httpService.get('/user')
  }

  /**
   * Get user details given user id
   * @param id - number
   */
  getUserDetails = async(id: number): Promise<User> => {
    if (isProductTourFakeUser(id)) {
      return Promise.resolve(getProductTourFakeUser())
    }

    return httpService.get('/user/' + id)
  }

  /**
   * Update user details given user id
   * @param id - number
   * @param user - User
   */
  updateUserDetails = async(id: number, user: User): Promise<void> => {
    return httpService.post('/user/' + id, user)
  }

  /**
   * Update user details given user id
   * @param id - number
   * @param user - User
   */
  getUserProfile = async(id: number): Promise<UserProfile> => {
    return httpService.get('/user/' + id + '/profile')
  }

  /**
   * calls (GET) /service_company_role endpoint to get company roles for user
   */
  getServiceCompanyRole = async (): Promise<ServiceCompanyRole> => {
    return httpService.get('/service_company_role')
  }

  getUserTeam = (id: number): CancelableFetch<MemberTeam> => {
    return httpService.getWithAbort(`/user/${id}/service_company_team`)
  }

  updateUserTeam = (companyId: number, id: number, team: MemberTeam): Promise<MemberTeam> => {
    return httpService.post(`/user/${id}/service_company_team`, {
      service_company_id : companyId,
      team,
    })
  }

  getUserCompletedDemos = (id: number): Promise<{ demos: UserDemoName[] }> => {
    return httpService.get(`/user/${id}/pro_portal_demo`)
  }

  saveUserCompletedDemos = (id: number, demoNames: UserDemoName[]): Promise<void> => {
    return httpService.post(`/user/${id}/pro_portal_demo`, {
      demos: demoNames,
    })
  }
}

const userService = new UserService()

export default userService
